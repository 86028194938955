import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'
import { Button, Form, Input, FormGroup, Label, InputGroup, FormText, Row, Col, FormFeedback } from 'reactstrap'
import editIcon from 'src/assets/images/editIcon.svg'
import { Dialog } from '@blueprintjs/core'
import { QRCodeSVG } from 'qrcode.react'

const MFA_HELP_URL = '/docs/profile/#multi-factor-authentication'

@inject('store')
@observer
class DetailsTab extends React.Component<{ store?: RootStore } & WithNamespaces> {
    handleUpdateDetails = (event: any) => {
        this.props.store!.settingsUIStore.updateDetails(event.target.id, event.target.value)
    }

    handleInputFocus = (event: any) => {
        this.props.store!.settingsUIStore.setFormFieldFocused(event.target.id, true)
    }

    handleInputBlur = (event: any) => {
        this.props.store!.settingsUIStore.setFormFieldFocused(event.target.id, false)
    }

    handleSaveDetails = () => {
        this.props.store!.settingsUIStore.saveUserDetails()
        this.props.store!.settingsUIStore.toggleUserDetailsEdit()
    }

    handleOnKeyPress = (event: any, action: () => void) => {
        switch (event.key) {
            case 'Enter':
                event.preventDefault()
                // Handle "enter" or "return" key press
                action()
                break
            default:
                return
        }
    }

    handleGlobalSignOut = () => {
        this.props.store!.authStore.signOut(true)
    }

    render() {
        const me = this.props.store!.userStore.me
        const myOrg = this.props.store!.orgStore.myOrg

        if (!me || !myOrg) {
            return null
        }

        const settingsUIStore = this.props.store!.settingsUIStore
        const authStore = this.props.store!.authStore

        const manualURL = process.env.REACT_APP_OW_HELP_DOCS ?? ''

        return (
            <React.Fragment>
                <div className='details-tab'>
                    <div className='details-title'>
                        <h2>{i18n.t('settingsPage.yourDetails')}</h2>
                        <Button
                            className='custom-button-edit'
                            onClick={settingsUIStore.toggleUserDetailsEdit}
                            color='primary'
                        >
                            <img src={editIcon} alt='Edit' height={12} />
                        </Button>
                    </div>
                    <br />
                    <Row>
                        <Col lg={3} md={4} sm={6} xs={6}>
                            <h4>{i18n.t('settingsPage.firstName')}</h4>
                            <h5 className='selectable-text'>{me.firstName}</h5>
                            <h4>{i18n.t('settingsPage.emailAddress')}</h4>
                            <h5 className='selectable-text'>{me.email}</h5>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={6}>
                            <h4>{i18n.t('settingsPage.lastName')}</h4>
                            <h5 className='selectable-text'>{me.lastName}</h5>
                            <h4>{i18n.t('settingsPage.phoneNumber')}</h4>
                            <h5 className='selectable-text'>{me.phone}</h5>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col lg={3} md={4} sm={6} xs={6}>
                            <h4>{i18n.t('settingsPage.organisation')}</h4>
                            <h5 className='selectable-text'>{myOrg.name}</h5>
                        </Col>
                        <Col lg={3} md={4} sm={6} xs={6}>
                            <h4>{i18n.t('settingsPage.access')}</h4>
                            <h5 className='selectable-text'>{me.roleString}</h5>
                        </Col>
                    </Row>
                    <hr />
                    <h2>{i18n.t('settingsPage.changePassword')}</h2>
                    <br />
                    <Row>
                        <Col lg={4} md={6} sm={12} xs={12}>
                            <Form
                                onKeyPress={e =>
                                    this.handleOnKeyPress(e, this.props.store!.settingsUIStore.handleChangePassword)
                                }
                                className='auth-form mb-3'
                            >
                                <FormGroup className='custom-form-group'>
                                    <Label>
                                        <h4>{i18n.t('settingsPage.currentPassword')}</h4>
                                    </Label>
                                    <InputGroup className='custom-input'>
                                        <Input
                                            id='currentPassword'
                                            name='currentPassword'
                                            type='password'
                                            onChange={settingsUIStore.handleChangePasswordInput}
                                        />
                                    </InputGroup>
                                    <br />
                                    <Label>
                                        <h4>{i18n.t('settingsPage.newPassword')}</h4>
                                    </Label>
                                    <InputGroup className='custom-input'>
                                        <Input
                                            id='newPassword'
                                            name='newPassword'
                                            type='password'
                                            onChange={settingsUIStore.handleChangePasswordInput}
                                        />
                                    </InputGroup>
                                    <FormText>{i18n.t('authPage.newPasswordHelpText')}</FormText>
                                    {/* TODO: Validation feedback from Amplify */}
                                    <br />
                                    <Label>
                                        <h4>{i18n.t('settingsPage.verifyNewPassword')}</h4>
                                    </Label>
                                    <InputGroup className='custom-input'>
                                        <Input
                                            id='verifyNewPassword'
                                            name='verifyNewPassword'
                                            type='password'
                                            onChange={settingsUIStore.handleChangePasswordInput}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <Button
                                    onClick={settingsUIStore.handleChangePassword}
                                    color='primary'
                                    disabled={!settingsUIStore.isChangePasswordValid}
                                >
                                    {i18n.t('actions.submit')}
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                    <br />
                    <h2>{i18n.t('settingsPage.mfa')}</h2>
                    <br />
                    <Row>
                        <Col lg={4} md={6} sm={12} xs={12}>
                            <Form className='auth-form mb-3'>
                                <Label className={authStore.isFetchingMFAStatus ? 'bp3-skeleton' : ''}>
                                    <span
                                        className={
                                            'bp3-tag' +
                                            (authStore.mfaEnabled ? ' bp3-intent-success' : ' bp3-intent-danger')
                                        }
                                    >
                                        {authStore.mfaEnabled ? 'Enabled' : 'Not enabled'}
                                    </span>
                                </Label>
                                <br />
                                <Label className={authStore.isFetchingMFAStatus ? 'bp3-skeleton' : ''}>
                                    <h4>
                                        {authStore.mfaEnabled
                                            ? 'Multi-factor authentication is enabled for your account.'
                                            : 'Enabling multi-factor authentication improves security for your account.'}
                                    </h4>
                                </Label>
                                <br />
                                {!authStore.isFetchingMFAStatus && (
                                    <>
                                        <Button onClick={() => settingsUIStore.toggleManageMFA()} color='primary'>
                                            {i18n.t('actions.manage')}
                                        </Button>
                                        {authStore.mfaEnabled && (
                                            <Button
                                                className='ml-2'
                                                onClick={() => settingsUIStore.handleRemoveMFA()}
                                                color='danger'
                                            >
                                                {i18n.t('actions.disable')}
                                            </Button>
                                        )}
                                    </>
                                )}
                            </Form>
                        </Col>
                    </Row>
                </div>
                {/* Edit details dialog */}
                <Dialog
                    isOpen={settingsUIStore.isUserDetailsEditable}
                    className='custom-dialog'
                    canOutsideClickClose={false}
                    title={i18n.t('settingsPage.updateDetails')}
                    onClose={settingsUIStore.toggleUserDetailsEdit}
                >
                    <div className='details-form'>
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for='firstName'>{i18n.t('settingsPage.firstName')}</Label>
                                    <Input
                                        id='firstName'
                                        type='text'
                                        defaultValue={me.firstName}
                                        onChange={this.handleUpdateDetails}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='email'>{i18n.t('settingsPage.emailAddress')}</Label>
                                    <Input id='email' type='text' value={me.email} disabled />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for='lastName'>{i18n.t('settingsPage.lastName')}</Label>
                                    <Input
                                        id='lastName'
                                        type='text'
                                        defaultValue={me.lastName}
                                        onChange={this.handleUpdateDetails}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for='phone'>{i18n.t('settingsPage.phoneNumber')}</Label>
                                    <Input
                                        id='phone'
                                        type='text'
                                        defaultValue={me.phone}
                                        onChange={this.handleUpdateDetails}
                                        onFocus={this.handleInputFocus}
                                        onBlur={this.handleInputBlur}
                                        invalid={!settingsUIStore.isPhoneValid}
                                    />
                                    <FormFeedback>{i18n.t('feedback.errors.invalidPhone')}</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for='organisation'>{i18n.t('settingsPage.organisation')}</Label>
                                    <Input id='organisation' type='text' value={myOrg.name} disabled />
                                </FormGroup>
                            </Col>
                            <Col xs={6}>
                                <FormGroup>
                                    <Label for='role'>{i18n.t('settingsPage.access')}</Label>
                                    <Input type='text' value={me.roleString} disabled />
                                    {(me.isSuperUser || me.isAdmin) && (
                                        <FormText>{i18n.t('feedback.info.userRoleDisabled')}</FormText>
                                    )}
                                </FormGroup>
                            </Col>
                        </Row>
                        <div className='dialog-actions'>
                            <Button
                                className='custom-button-large'
                                onClick={settingsUIStore.saveUserDetails}
                                color='primary'
                                disabled={
                                    this.props.store!.userStore.isUpdating ||
                                    !settingsUIStore.isPhoneValid ||
                                    !(settingsUIStore.pendingUserDetails && settingsUIStore.pendingUserDetails.isDirty)
                                }
                            >
                                {i18n.t('actions.save')}
                            </Button>
                        </div>
                    </div>
                </Dialog>
                {/* Manage MFA dialog */}
                <Dialog
                    isOpen={settingsUIStore.isManageMFAOpen}
                    className='custom-dialog'
                    canOutsideClickClose={false}
                    title={i18n.t('settingsPage.manageMFA')}
                    onClose={settingsUIStore.toggleManageMFA}
                >
                    <div className='details-form'>
                        {authStore.mfaEnabled && (
                            <div className='bp3-callout bp3-intent-warning mb-2'>
                                {`You're about to change your multi-factor authentication device. This will invalidate
                                your current multi-factor authentication device.`}
                            </div>
                        )}
                        <Row>
                            <Col xs={12}>
                                <h2 className='text-black'>Authentication verification</h2>
                                <p>
                                    {`Scan the QR code with the authentication app on your phone. If you can't scan the QR
                                    code, you can `}
                                    <span
                                        className='text-underline text-clickable text-primary'
                                        onClick={() => settingsUIStore.toggleMFATextCode()}
                                    >
                                        <b>enter this text code</b>
                                    </span>{' '}
                                    instead.
                                </p>
                                <div>
                                    <a href={manualURL + MFA_HELP_URL} target='_blank' rel='noopener noreferrer'>
                                        <Button color='primary'>Help setting up an authenticator app</Button>
                                    </a>
                                </div>
                                <div className='qr-container mt-4'>
                                    {settingsUIStore.MFAQRCode ? (
                                        <QRCodeSVG value={settingsUIStore.MFAQRCode} height={128} width={128} />
                                    ) : (
                                        <div className='qr-loading bp3-skeleton' />
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={12} md={6}>
                                <Form
                                    onKeyPress={e =>
                                        this.handleOnKeyPress(
                                            e,
                                            this.props.store!.settingsUIStore.submitMFAVerification
                                        )
                                    }
                                    className='auth-form mb-3'
                                >
                                    <FormGroup>
                                        <Label for='code'>Enter the code from the application</Label>
                                        <Input
                                            autoFocus
                                            id='code'
                                            name='code'
                                            type='text'
                                            placeholder='6-digit code'
                                            pattern='[0-9]{6}'
                                            maxLength={6}
                                            autoComplete='off'
                                            onChange={e => settingsUIStore.handleChangeMFAVerification(e.target.value)}
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <div className='dialog-actions'>
                            <Button
                                className='custom-button-large'
                                onClick={settingsUIStore.submitMFAVerification}
                                color='primary'
                                disabled={!settingsUIStore.isMFAVerificationValid || authStore.isFetchingMFAStatus}
                            >
                                {i18n.t('actions.submitCode')}
                            </Button>
                        </div>
                    </div>
                </Dialog>
                {/* MFA text code dialog */}
                <Dialog
                    isOpen={settingsUIStore.isMFATextCodeOpen}
                    className='custom-dialog'
                    title='Your multi-factor secret'
                    onClose={settingsUIStore.toggleMFATextCode}
                >
                    <div className='details-form'>
                        <Row>
                            <Col xs={12}>
                                <pre className='selectable-text'>{settingsUIStore.MFASetupCode}</pre>
                            </Col>
                        </Row>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default withNamespaces()(DetailsTab)
