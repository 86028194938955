import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import { inject, observer } from 'mobx-react'
import RootStore from '../../../../common/RootStore'

import { Dialog } from '@blueprintjs/core'
import { Badge } from 'reactstrap'
import imageBroken from '../../../../assets/images/imageBroken.svg'
import playsIcon from '../../../../assets/images/playsIcon.svg'
import resolutionIcon from '../../../../assets/images/resolutionIcon.svg'

@inject('store')
@observer
class ExpandImageDialog extends React.Component<{ store?: RootStore } & WithNamespaces> {
    toggleExpandImageDialog = () => {
        this.props.store!.reportsUIStore.toggleExpandImageDialog()
    }

    handleImageResponseError = (event: any) => {
        // Prevent infinte loop if fallback src invalid
        event.target.onerror = null
        event.target.src = imageBroken
        event.target.className = 'expanded-img-error'
    }

    render() {
        const displayImage = this.props.store!.reportsUIStore.expandedDisplayImage

        if (!displayImage) {
            return null
        }

        return (
            <div>
                <Dialog
                    isOpen={this.props.store!.reportsUIStore.expandImageDialogOpen}
                    title={i18n.t('reportsPage.imagePreview')}
                    onClose={this.toggleExpandImageDialog}
                    className='custom-dialog expand-image-dialog'
                >
                    <div
                        style={{
                            height: displayImage.height,
                        }}
                        className='dialog-content image-expanded-dialog-content'
                    >
                        <img
                            // Display at native size if possible, otherwise
                            // will be scaled to fit viewport
                            style={{
                                height: displayImage.height,
                                width: displayImage.width,
                            }}
                            className='expanded-img'
                            src={displayImage.URL ? displayImage.URL : imageBroken}
                            alt={displayImage.displayableFilename}
                            onError={this.handleImageResponseError}
                        />
                        <h3 className='mr-auto'>{displayImage.displayableFilename}</h3>
                        <div className='expanded-image-badge-wrapper mr-auto'>
                            <Badge className='expanded-image-badge'>
                                <img src={playsIcon} alt='Total plays' height={10} />
                                <span>{displayImage.totalPlays}</span>
                            </Badge>{' '}
                            <Badge className='expanded-image-badge'>
                                <img src={resolutionIcon} alt='Resolution' height={13} />
                                <span>{displayImage.resolution}</span>
                            </Badge>
                        </div>
                    </div>
                </Dialog>
            </div>
        )
    }
}

export default withNamespaces()(ExpandImageDialog)
