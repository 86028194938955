import { RouterFlag } from './BaseRouter'
import onAPI from './onAPI'

export default abstract class StringsRouter<SearchParams> {
    protected abstract route: string
    protected abstract flags: number

    async readList(): Promise<string[]> {
        if (!(this.flags & RouterFlag.list)) {
            return Promise.reject('Invalid route')
        }
        const strings: string[] = await onAPI.fetchJSON(this.route + 's')
        return strings
    }

    async searchList(searchParams: SearchParams): Promise<string[]> {
        if (!(this.flags & RouterFlag.search)) {
            return Promise.reject('Invalid route')
        }
        const strings: string[] = await onAPI.fetchJSON(this.route + 's/search', searchParams)
        return strings
    }
}
