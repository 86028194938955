import User, { UserJSON } from '../common/models/User'
import BaseRouter, { EmptySearchParams, RouterFlag } from './BaseRouter'
import onAPI from './onAPI'

import { UserPreferencesJSON, UserNotification } from 'src/common/models/UserSettings'

class UserRouter extends BaseRouter<User, UserJSON, EmptySearchParams> {
    protected route = '/user'

    protected flags =
        RouterFlag.create |
        RouterFlag.read |
        RouterFlag.update |
        RouterFlag.delete |
        RouterFlag.list |
        RouterFlag.restore |
        RouterFlag.permanentlyDelete

    async readMe(): Promise<User> {
        const userJson: UserJSON = await onAPI.fetchJSON('/me')
        return new User(userJson)
    }

    async updateUserPreferences(userPreferences: UserPreferencesJSON): Promise<void> {
        return onAPI.fetchJSON('/user-preferences', userPreferences)
    }

    async updateUserNotifications(userNotifications: UserNotification[]): Promise<void> {
        return onAPI.fetchJSON('/user-notifications', userNotifications)
    }

    async resendUserInvitation(id: string): Promise<boolean> {
        return onAPI.fetchJSON(this.route + '/' + id + '/resend', null)
    }

    protected newItem(json: UserJSON): User {
        return new User(json)
    }
}

const userRouter = new UserRouter()
export default userRouter
