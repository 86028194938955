import { action } from 'mobx'
import RootStore from 'src/common/RootStore'

import AdminStoreItem from './AdminStoreItem'
import Organisation, { OrganisationPermission } from 'src/common/models/Organisation'

export default class AdminStoreOrganisation extends AdminStoreItem<Organisation> {
    constructor(rootStore: RootStore) {
        super()
        this.store = rootStore.orgStore
    }

    @action setNewItem = () => {
        this.item = Organisation.newOrg()
    }

    @action setItemToUpdate = (org: Organisation) => {
        this.item = new Organisation(org)
        this.item.associatedOrganisations = org.associatedOrganisations
        if (this.item.associatedOrganisations) {
            this.item.associatedOrganisations.forEach(associatedOrg => {
                this.item!.associatedOrganisationIds.set(associatedOrg.id!, { permissions: associatedOrg.permissions })
            })
        }
    }

    @action setParentId = (id: string) => {
        // left empty on purpose as orgs don't have parents
    }

    @action updateItemForm = (key: string, value: any) => {
        // Form was updated
        this.formUpdated = true
        switch (key) {
            case 'name':
                this.item!.name = value
                break
            case 'country':
                this.item!.country = value
                break
            case 'zipCode':
                this.item!.zipCode = value
                break
            case 'maxScreens':
                this.item!.maxScreens = Number(value) ?? 0
                break
            case 'associatedOrganisations':
                this.item!.associatedOrganisationIds.clear()

                const selectedOrgs: string[] = value
                selectedOrgs.forEach(orgId => {
                    this.item!.associatedOrganisationIds.set(orgId, { permissions: OrganisationPermission.read })
                })
                break
            case 'writeAccess':
                const selectedPermissions: { orgId: string; permissions: OrganisationPermission } = value
                const permissionsToUpdate =
                    this.item!.associatedOrganisationIds || new Map<string, { permissions: OrganisationPermission }>()
                permissionsToUpdate.set(selectedPermissions.orgId, { permissions: selectedPermissions.permissions })

                this.item!.associatedOrganisationIds = permissionsToUpdate
                break
            case 'allowedRoles':
                this.item!.roles = value
                break
            default:
                return
        }

        // If all form fields are filled, form can be submitted
        this.createItemFormValid = this.item!.hasCompletedDataFields
    }
}
