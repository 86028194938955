import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// mobx
import { observer, inject } from 'mobx-react'
import RootStore from '../RootStore'

import LockedOverlay from 'src/common/components/LockedOverlay'
import { Icon } from '@blueprintjs/core'
import { Colour } from '../utils/Colour'

@inject('store')
@observer
class OrgSelectionWrapper extends React.Component<{ store?: RootStore } & WithNamespaces> {
    private router = this.props.store!.router

    render() {
        const { children } = this.props

        const me = this.props.store!.userStore.me
        const myOrg = this.props.store!.orgStore.myOrg

        if (
            !me ||
            !myOrg ||
            this.props.store!.authStore.isFetching ||
            this.router.params?.org ||
            this.router.currentView?.rootPath === '/settings' ||
            this.router.currentView?.rootPath === '/mission-control' ||
            !this.props.store!.orgStore.hasMultipleOpsOrganisations
        ) {
            return children
        }

        return (
            <div className='grey-container'>
                <div className='diagnostics-container custom-container-fluid'>
                    <LockedOverlay isFullscreen>
                        <div className='text-center'>
                            <Icon className='mb-4' icon='arrow-up' iconSize={54} color={Colour.primary} />
                            <h1>{i18n.t('common.selectOrg')}</h1>
                        </div>
                    </LockedOverlay>
                </div>
            </div>
        )
    }
}

export default withNamespaces()(OrgSelectionWrapper)
