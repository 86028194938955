import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import Media from 'src/common/models/Media'

import { Colour } from 'src/common/utils/Colour'
import { Icon, Tooltip, Position } from '@blueprintjs/core'
import { Button } from 'reactstrap'

interface ScheduleCellProps {
    media: Media
    screenId: string
    playerDiv?: HTMLElement
}

@inject('store')
@observer
class ScheduleCell extends React.Component<{ store?: RootStore } & ScheduleCellProps> {
    private mediaManager = this.props.store!.mediaStore.getMediaManager(this.props.screenId)
    handleOpenDialog = () => {
        this.mediaManager.toggleScheduleDialog(this.props.media)
    }

    render() {
        const me = this.props.store!.userStore.me
        const media = this.props.media
        const screen = this.props.store!.screenStore.findItem(this.props.screenId)

        if (!me || !media || !screen) {
            return null
        }

        const startDate = media.startDate
        const stopDate = media.stopDate
        const hasSchedule = !!(startDate || stopDate)

        return (
            <React.Fragment>
                <Tooltip
                    boundary='viewport'
                    content={
                        hasSchedule
                            ? (startDate ? startDate.format(me.dateTimeFormat) : 'Now') +
                              ' > ' +
                              (stopDate ? stopDate.format(me.dateTimeFormat) : 'Indefinite')
                            : 'Set schedule'
                    }
                    hoverOpenDelay={!hasSchedule ? 300 : undefined}
                    position={Position.TOP}
                    portalContainer={this.props.playerDiv}
                >
                    <Button className='table-action-lg custom-button-secondary' onClick={this.handleOpenDialog}>
                        <Icon
                            className='player-table-schedule-icon'
                            color={hasSchedule ? Colour.primaryLight : undefined}
                            icon='timeline-events'
                            iconSize={14}
                        />
                    </Button>
                </Tooltip>
            </React.Fragment>
        )
    }
}

export default ScheduleCell
