import { UserRole, RoleType } from './Roles'
import { observable, computed } from 'mobx'
import { Omit } from 'react-router'
import { rootStore } from '../RootStore'
import DomainStore from '../stores/DomainStore'
import BaseModel from './BaseModel'
import moment from 'moment'
import 'moment-timezone'

import i18n from 'src/i18n'

import { shortDateTimeFormat, mobileDateTimeFormat } from '../utils/DateTime'

import Organisation from './Organisation'
import { UserNotificationType, AlertMethod, UserPreferences } from './UserSettings'

// User preference defaults
const DEFAULT_LANGUAGE = 'en'
const DEFAULT_TIME_ZONE = moment.tz.guess()
const DEFAULT_TIME_FORMAT = 'h:mm:ss a'
const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY'

class User extends BaseModel {
    static newUser(): User {
        return new User({
            email: '',
            emailVerified: false,
            phone: '',
            firstName: '',
            lastName: '',
            organisationId: '',
            roles: null,
            preferences: null,
            notifications: null,
            iotPolicyAttached: false,
            hasConsoleAccess: false,
            firstLogin: false,
        })
    }

    @observable email: string
    @observable emailVerified: boolean
    @observable phone?: string
    @observable firstName: string
    @observable lastName: string
    @observable organisationId: string
    @observable preferences: UserPreferences | null
    @observable notifications: Map<UserNotificationType, AlertMethod[]> | null
    @observable iotPolicyAttached: boolean
    @observable hasConsoleAccess: boolean
    @observable firstLogin: boolean

    // This should not be use externally. Use the userRole() accessors
    @observable roles?: RoleType[] | null

    constructor(json: UserJSON) {
        super(json)
        this.email = json.email
        this.emailVerified = json.emailVerified
        this.phone = json.phone
        this.firstName = json.firstName
        this.lastName = json.lastName
        this.roles = json.roles
        this.organisationId = json.organisationId
        if (json.preferences) {
            this.preferences = new UserPreferences(json.preferences)
        }
        const notifications: any = json.notifications // this isn't really a map, so force it to an any for parsing
        if (notifications) {
            this.notifications = new Map<UserNotificationType, AlertMethod[]>()
            for (const notification of notifications) {
                this.notifications.set(notification.type, notification.methods)
            }
        }
        this.iotPolicyAttached = json.iotPolicyAttached
        this.hasConsoleAccess = json.hasConsoleAccess
        this.firstLogin = json.firstLogin
    }

    static get store(): DomainStore<User> | undefined {
        return rootStore.userStore
    }

    @computed get name(): string {
        return this.firstName + ' ' + this.lastName
    }

    @computed get organisation(): Organisation | undefined {
        return rootStore.orgStore.findItem(this.organisationId)
    }

    @computed get hasCompletedDataFields(): boolean {
        return (
            this.firstName !== '' &&
            this.lastName !== '' &&
            this.email !== '' &&
            !!(this.roles && this.roles.length > 0)
        )
    }

    toJSON(includeDates?: boolean): UserJSON {
        const json = super.toJSON(includeDates) as UserJSON
        json.email = this.email
        json.emailVerified = this.emailVerified
        json.phone = this.phone
        json.firstName = this.firstName
        json.lastName = this.lastName
        json.roles = this.roles
        json.organisationId = this.organisationId
        json.preferences = this.preferences
        json.notifications = this.notifications
        json.iotPolicyAttached = this.iotPolicyAttached
        json.hasConsoleAccess = this.hasConsoleAccess
        json.firstLogin = this.firstLogin
        return json
    }

    @computed get userRole(): UserRole {
        if (!this.roles) {
            return new UserRole([RoleType.none])
        }

        return new UserRole(this.roles)
    }

    @computed get isSuperUser(): boolean {
        return this.roles?.includes(RoleType.superUser) ?? false
    }

    @computed get isAdmin(): boolean {
        return this.roles?.includes(RoleType.admin) ?? false
    }

    @computed get isOwned(): boolean {
        return rootStore.authStore.isAdminsOrg(this.organisationId)
    }

    @computed get canEdit(): boolean {
        return this.isOwned
    }

    @computed get canDelete(): boolean {
        return this.isOwned && this.id !== rootStore.userStore.me?.id
    }

    @computed get showDeletedItems(): boolean {
        return this.isSuperUser || this.isAdmin
    }

    @computed get allowPermanentDelete(): boolean {
        // Only allow Superuser to permanently delete items
        return this.isSuperUser
    }

    @computed get roleString(): string {
        if (this.isSuperUser) {
            return i18n.t('adminPage.roles.' + RoleType.superUser)
        }
        if (this.isAdmin) {
            return i18n.t('adminPage.roles.' + RoleType.admin)
        }
        return (
            this.roles
                ?.filter(role => role !== RoleType.superUser && role !== RoleType.admin)
                .map(role => i18n.t('adminPage.roleStrings.' + role))
                .join(', ') ?? ''
        )
    }

    @computed get locale(): string {
        return window.navigator.userLanguage || window.navigator.language
    }

    @computed get language(): string {
        return this.preferences?.language ?? DEFAULT_LANGUAGE
    }

    @computed get timeZone(): string {
        return this.preferences?.timeZone ?? DEFAULT_TIME_ZONE
    }

    @computed get timeFormat(): string {
        return this.preferences?.timeFormat ?? DEFAULT_TIME_FORMAT
    }

    @computed get dateFormat(): string {
        return this.preferences?.dateFormat ?? DEFAULT_DATE_FORMAT
    }

    @computed get dateTimeFormat(): string {
        return this.dateFormat + ', ' + this.timeFormat
    }

    @computed get shortDateTimeFormat(): string {
        return shortDateTimeFormat(this.dateTimeFormat)
    }

    @computed get mobileDateTimeFormat(): string {
        return mobileDateTimeFormat(this.dateTimeFormat)
    }

    @computed get mobileAdjustedTimeFormat(): string {
        return rootStore.appUIStore.isMobile ? this.mobileDateTimeFormat : this.dateTimeFormat
    }
}

export type UserJSON = Omit<
    User,
    | 'toJSON'
    | 'isValid'
    | 'name'
    | 'organisation'
    | 'hasCompletedDataFields'
    | 'userRole'
    | 'isSuperUser'
    | 'isAdmin'
    | 'isOwned'
    | 'canEdit'
    | 'canDelete'
    | 'showDeletedItems'
    | 'allowPermanentDelete'
    | 'roleString'
    | 'locale'
    | 'language'
    | 'timeZone'
    | 'timeFormat'
    | 'dateFormat'
    | 'dateTimeFormat'
    | 'shortDateTimeFormat'
    | 'mobileDateTimeFormat'
    | 'mobileAdjustedTimeFormat'
>

export default User
