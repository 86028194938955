import * as React from 'react'
import i18n from 'src/i18n'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import Screen from 'src/common/models/Screen'
import { ScreenInfoType } from '../../../../stores/ScreenDiagnosticsUIStore'

import { Transition } from 'react-spring/renderprops'
import brightnessIcon from 'src/assets/images/brightnessIcon.svg'
import controllerIcon from 'src/assets/images/controllerIcon.svg'
import errorWarningIcon from 'src/assets/images/errorWarningIcon.svg'
import tileIcon from 'src/assets/images/tileIcon.svg'
import { capitalizeFirstLetter } from 'src/common/utils/String'
import { millisecondsToString } from 'src/common/utils/Number'
import { Icon } from '@blueprintjs/core'
import { secondsDurationToString } from 'src/common/utils/Duration'
import matchSorter from 'match-sorter'
import { ScreenState } from 'src/common/models/ScreenState'
import { OutageReason } from 'src/common/models/AlertReason'

interface ScreenNameCellProps {
    name: string
    screen: Screen
}

@inject('store')
@observer
class ScreenNameCell extends React.Component<{ store?: RootStore } & ScreenNameCellProps> {
    state: { cellScreenConnected: string } = {
        cellScreenConnected: '',
    }

    constructor(props: ScreenNameCellProps) {
        super(props)
        this.state = {
            cellScreenConnected: this.props.screen.isConnected + this.props.screen.state,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps: ScreenNameCellProps) => {
        // Update state if sort index changes
        if (nextProps.screen.sortIndex !== this.props.screen.sortIndex) {
            this.setState({ cellScreenConnected: nextProps.screen.isConnected + nextProps.screen.state })
        }
    }

    render() {
        const name = this.props.name
        const screen = this.props.screen
        if (!name || !screen) {
            return null
        }

        const screenInfoValue = this.props.store!.screenDiagnosticsUIStore.screenInfoValue

        const transitionItems = []

        let cellIcon: JSX.Element | null = null
        let cellText = ''

        // Attempt to match labels
        const labelData = screen.labels?.[screenInfoValue]
        if (labelData) {
            cellIcon = <Icon icon='tag' iconSize={15} />
            cellText = labelData
        } else {
            const screenInfoUnavailable = !screen.isConnected && screen.outageReason !== OutageReason.controllerMissing
            if (!screenInfoUnavailable && screenInfoValue === ScreenInfoType.brightness) {
                cellIcon = <img src={brightnessIcon} alt='Source' />
                cellText = String(screen.brightness)
            } else if (screenInfoValue === ScreenInfoType.version) {
                cellIcon = <img src={controllerIcon} alt='Source' />
                cellText = screen.comptroller?.connectVersion || 'Unknown'
            } else if (screenInfoValue === ScreenInfoType.connectDaemon) {
                const icon = screen.comptroller?.connectDaemon ? controllerIcon : errorWarningIcon
                cellIcon = <img src={icon} alt='Source' />
                cellText = screen.connectDaemonStatus
            } else if (screenInfoValue === ScreenInfoType.controllerVersion) {
                cellIcon = <img src={controllerIcon} alt='Source' />
                cellText = screen.comptroller?.lednetVersion || 'Unknown'
                // } else if (!screenInfoUnavailable && screenInfoValue === ScreenInfoType.uptime) {
                //     cellIcon = <img src={controllerIcon} alt='Source' />
                //     cellText = secondsDurationToString(screen.comptroller?.lednetUptime || 0)
            } else if (!screenInfoUnavailable && screenInfoValue === ScreenInfoType.lednetDaemon) {
                const icon = screen.comptroller?.lednetDaemon ? controllerIcon : errorWarningIcon
                cellIcon = <img src={icon} alt='Source' />
                cellText = screen.lednetDaemonStatus
            } else if (screenInfoValue === ScreenInfoType.firmwareVersion) {
                cellIcon = <img src={tileIcon} alt='Source' />
                cellText = screen.firmwareRange || ''
            } else if (screenInfoValue === ScreenInfoType.manufacturer) {
                cellIcon = <img src={controllerIcon} alt='Source' />
                cellText = screen.manufacturerName
            } else {
                cellIcon = <img src={controllerIcon} alt='Source' />
                cellText = screen.screenStatusString
            }
        }

        transitionItems.push({ cellIcon, cellText })

        const searchQuery = this.props.store!.screenDiagnosticsUIStore.searchQueryValue?.toLowerCase()

        const labelMatches =
            searchQuery && screen.labelKeyValuePairs
                ? matchSorter(Object.values(screen.labelKeyValuePairs), searchQuery, {
                      keys: [(item: { key: string; value: string }) => item.value.toLowerCase()],
                  })
                : []
        const labelMatch = labelMatches.length > 0 && labelMatches[0]

        return (
            <>
                <div className='diagnostics-overview-screen-heading'>
                    <h6 className='ellipsis'>{name}</h6>
                    <Transition
                        items={transitionItems}
                        keys={
                            screen.isConnected + screen.state !== this.state.cellScreenConnected
                                ? screen.state
                                : undefined
                        }
                        initial={{ position: 'absolute' } as React.CSSProperties}
                        from={{ position: 'absolute', opacity: 0 } as React.CSSProperties}
                        enter={{ opacity: 1 }}
                        leave={{ opacity: 0 }}
                        config={{ duration: 1000 }}
                    >
                        {/* eslint-disable-next-line react/display-name */}
                        {item => props => (
                            <div style={props} className='screen-subheading'>
                                <div className='screen-subheading-icon'>{item.cellIcon}</div>
                                <h6>{item.cellText}</h6>
                            </div>
                        )}
                    </Transition>
                </div>
                {labelMatch && (
                    <span className='screen-table-label-match'>
                        {labelMatch.key}: {labelMatch.value}
                    </span>
                )}
            </>
        )
    }
}

export default ScreenNameCell
