import * as React from 'react'

import loadingSpinner from '../../assets/images/loadingSpinner.svg'
import loadingSpinnerDark from '../../assets/images/loadingSpinnerDark.svg'

interface LoadingSpinnerProps {
    dark?: boolean
}

class LoadingSpinner extends React.Component<LoadingSpinnerProps> {
    render() {
        return (
            <div className='loading-spinner'>
                <img src={this.props.dark ? loadingSpinnerDark : loadingSpinner} alt='Loading' />
            </div>
        )
    }
}

export default LoadingSpinner
