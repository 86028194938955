import * as React from 'react'

// i18n
import i18n from 'src/i18n'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import ReportScope from './components/ReportScope'
import ReportFilters from './components/ReportFilters'
import ResultsTable from './components/ResultsTable'
import SelectedImages from './components/SelectedImages'
import ExpandImageDialog from './components/modals/ExpandImageDialog'
import ImageSummaryDrawer from './components/ImageSummaryDrawer'
import ExportReportDialog from './components/modals/ExportReportDialog'
import BackToTopButton from 'src/common/components/BackToTopButton'

@inject('store')
@observer
class Reports extends React.Component<{ store?: RootStore }> {
    componentDidMount() {
        window.addEventListener('scroll', this.onScroll)
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll)
    }

    onScroll = () => {
        const reportsUIStore = this.props.store!.reportsUIStore

        // TODO: Breakpoints under md
        if (window.pageYOffset >= this.props.store!.reportsUIStore.pageScrollThreshold) {
            reportsUIStore.toggleContentPageScrolled(true)
        } else {
            reportsUIStore.toggleContentPageScrolled(false)
        }
    }

    render() {
        return (
            <div>
                {/* Leave the above element as a div, for styling reasons */}
                <div className='grey-container'>
                    <div className='custom-container-fluid reports-container'>
                        <ReportScope />
                        {this.props.store!.reportsUIStore.reportScopeSet ? (
                            <React.Fragment>
                                {this.props.store!.reportsUIStore.reportFiltersOpen && <ReportFilters />}
                                <ResultsTable />
                            </React.Fragment>
                        ) : (
                            <div className='reports-non-ideal-state'>
                                <h2>{i18n.t('reportsPage.noScope')}</h2>
                            </div>
                        )}
                    </div>
                </div>
                <SelectedImages />
                <ExpandImageDialog />
                <ExportReportDialog />
                <ImageSummaryDrawer />
                {this.props.store!.reportsUIStore.contentPageScrolled && <BackToTopButton />}
            </div>
        )
    }
}

export default Reports
