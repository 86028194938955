import * as React from 'react'
import { Collapse, Icon } from '@blueprintjs/core'

interface CollapsibleSectionProps extends React.Component {
    isOpen?: boolean
    title: JSX.Element | string
    titleStyle?: React.CSSProperties
    style?: React.CSSProperties
    className?: string
    onClick?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
}

export default class CollapsibleSection extends React.Component<CollapsibleSectionProps> {
    state = {
        isOpen: true,
    }

    handleToggleCollapse = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        this.setState({ isOpen: !this.state.isOpen })

        return this.props.onClick && this.props.onClick(e)
    }

    render() {
        const { isOpen, title, titleStyle, style, className, children } = this.props
        const isCollapseOpen = isOpen ?? this.state.isOpen
        return (
            <div style={style} className={className}>
                <span style={titleStyle} className='collapsible-title' onClick={this.handleToggleCollapse}>
                    {title}
                    <Icon
                        icon='chevron-up'
                        iconSize={20}
                        style={{ transform: !isCollapseOpen ? 'rotate(-180deg)' : '' }}
                    />
                </span>
                <Collapse isOpen={isCollapseOpen} keepChildrenMounted>
                    {children}
                </Collapse>
            </div>
        )
    }
}
