export const eventPreventDefault = (event: any) => {
    event.preventDefault()
}

export const eventStopPropagation = (event: any) => {
    event.stopPropagation()
}

export const formIntegerValidation = (event: any) => {
    // Allow delete, tab, left arrow, right arrow and backspace keys
    if (
        !(
            event.keyCode === 8 ||
            event.keyCode === 9 ||
            event.keyCode === 37 ||
            event.keyCode === 39 ||
            event.keyCode === 46
        ) &&
        isNaN(Number(event.key))
    ) {
        event.preventDefault()
    }
}

export const formFloatValidation = (event: any) => {
    // Allow delete, tab, left arrow, right arrow, backspace and decimal point keys
    if (
        !(
            event.keyCode === 8 ||
            event.keyCode === 9 ||
            event.keyCode === 37 ||
            event.keyCode === 39 ||
            event.keyCode === 46 ||
            event.keyCode === 110 ||
            event.keyCode === 190
        ) &&
        isNaN(Number(event.key))
    ) {
        event.preventDefault()
    }
}

export const formFocusSelectAll = (event: any) => {
    event.target.select()
}
