import * as React from 'react'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import Media from 'src/common/models/Media'

import matchSorter from 'match-sorter'

import { AutoSizer } from 'react-virtualized'
import DraggableVirtualizedTable, {
    DraggableVirtualizedCol,
} from 'src/common/components/virtualized/DraggableVirtualizedTable'
import ImageCell from './tableComponents/ImageCell'
import DwellTimeCell from './tableComponents/DwellTimeCell'
import ScheduleCell from './tableComponents/ScheduleCell'
import DuplicateCell from './tableComponents/DuplicateCell'
import ActionsCell from './tableComponents/ActionsCell'
import moment from 'moment'

interface PlayerTableProps {
    screenId: string
    isFetching: boolean
    isUpdating: boolean
    playerDiv?: HTMLElement
}

@inject('store')
@observer
class PlayerTable extends React.Component<{ store?: RootStore } & PlayerTableProps> {
    formatDateAdded = (lastModified: moment.Moment | string) => {
        if (!lastModified) {
            return undefined
        }

        return moment(lastModified).format(this.props.store!.userStore.me?.dateTimeFormat)
    }

    handleNewOrder = (oldIndex: any, newIndex: any) => {
        const mediaManager = this.props.store!.mediaStore.getMediaManager(this.props.screenId)
        const offset = mediaManager.displayMediaList.length > 0 ? mediaManager.displayMediaList[0].index : 0
        mediaManager.updateMediaOrder(oldIndex + offset, newIndex + offset)
    }

    getRowClassname = (media: Media): string => {
        if (!media) {
            return ''
        }

        let className = 'player-row'
        if (media.isPendingDelete) {
            className = className + ' pending-delete'
        } else if (media.isPendingUpload) {
            className = className + ' pending-upload'
        }
        return className
    }

    render() {
        if (!this.props.screenId) {
            return
        }
        const mediaManager = this.props.store!.mediaStore.getMediaManager(this.props.screenId)
        let mediaList = mediaManager.displayMediaList

        const playerManager = this.props.store!.playerStore.getManager(this.props.screenId)
        if (playerManager?.searchQuery) {
            mediaList = matchSorter(mediaList, playerManager.searchQuery.value, {
                keys: playerManager.searchQuery.dataKeys,
            })
        }

        let isDragAllowed = true
        if (playerManager && playerManager.searchQuery && playerManager.searchQuery.value) {
            isDragAllowed = false
        }

        const data = {
            removeScreenFilesSet: [...mediaManager.removeScreenFilesSet],
            addScreenFilesSet: [...mediaManager.addScreenFilesSet],
            mediaList,
        }

        const cols: DraggableVirtualizedCol[] = [
            {
                dataKey: 'image',
                label: '',
                width: 100,
                className: 'player-table-img',
                cellElement: (cellData: any, rowData: Media) => (
                    <ImageCell
                        formatDateAdded={this.formatDateAdded}
                        cellData={cellData}
                        media={rowData}
                        screenId={this.props.screenId}
                        playerDiv={this.props.playerDiv}
                    />
                ),
                disableSort: true,
            },
            {
                dataKey: 'name',
                label: 'Name',
                minWidth: 100,
                flexGrow: 1,
                disableSort: true,
            },
            {
                dataKey: 'dwellTime',
                label: 'Dwell Time',
                width: 100,
                className: 'player-table-dwell-time',
                cellElement: (cellData: string, rowData: Media) => (
                    <DwellTimeCell media={rowData} screenId={this.props.screenId} />
                ),
                disableSort: true,
            },
            {
                dataKey: 'schedule',
                label: '',
                width: 35,
                className: 'player-table-schedule',
                cellElement: (cellData: string, rowData: Media) => (
                    <ScheduleCell media={rowData} screenId={this.props.screenId} playerDiv={this.props.playerDiv} />
                ),
                disableSort: true,
            },
            {
                dataKey: 'duplicate',
                label: '',
                width: 35,
                className: 'player-table-duplicate',
                cellElement: (cellData: string, rowData: Media) => (
                    <DuplicateCell media={rowData} screenId={this.props.screenId} playerDiv={this.props.playerDiv} />
                ),
                disableSort: true,
            },
            {
                dataKey: 'actions',
                label: '',
                width: 50,
                className: 'player-table-action',
                cellElement: (cellData: string, rowData: Media) => (
                    <ActionsCell media={rowData} screenId={this.props.screenId} playerDiv={this.props.playerDiv} />
                ),
                disableSort: true,
            },
        ]

        const rows = mediaList

        return (
            <React.Fragment>
                {mediaList.length > 0 ? (
                    <div
                        className='player-table'
                        style={{
                            height: mediaManager.mediaGroupsEnabled ? 'calc(100vh - 428px)' : '',
                        }}
                    >
                        <AutoSizer>
                            {({ width, height }) => (
                                <DraggableVirtualizedTable
                                    cols={cols}
                                    rows={rows || []}
                                    width={width}
                                    height={height}
                                    rowHeight={70}
                                    rowClassName={this.getRowClassname}
                                    data={data}
                                    isSearching={!!playerManager?.searchQuery}
                                    onSort={this.handleNewOrder}
                                    popoutId={this.props.screenId}
                                    draggable={isDragAllowed}
                                    hashes={mediaManager.hashSet}
                                />
                            )}
                        </AutoSizer>
                    </div>
                ) : (
                    <div className='player-no-results'>
                        <h2>No results</h2>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default PlayerTable
