import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { AdminTabName } from '../../container'
import { AdminTabData } from '../../models/AdminTabData'

import { Button } from 'reactstrap'
import editIcon from 'src/assets/images/editIcon.svg'
import deleteIcon from 'src/assets/images/deleteIcon.svg'
import refreshIcon from 'src/assets/images/refreshIcon.svg'
import uploadIcon from 'src/assets/images/uploadIcon.svg'
import { Tooltip, Position, Icon } from '@blueprintjs/core'

import CopyToClipboard from 'src/common/components/CopyToClipboard'
import clipboardIcon from 'src/assets/images/clipboardIcon.svg'

interface ActionCellProps {
    tabName: AdminTabName
    tabData: AdminTabData
    rowData: any
    cellData: any
}

@inject('store')
@observer
class ActionCell extends React.Component<{ store?: RootStore } & ActionCellProps> {
    render() {
        const me = this.props.store!.userStore.me
        const tabName = this.props.tabName
        const rowData = this.props.rowData
        if (!me || !rowData) {
            return null
        }

        const isMobile = this.props.store!.appUIStore.isMobile

        return (
            <div className='actions-cell'>
                {tabName === AdminTabName.screens ? (
                    <Tooltip
                        boundary='viewport'
                        content='Copy Identifiers'
                        hoverOpenDelay={300}
                        position={Position.TOP}
                        targetClassName='table-action-wrapper'
                        disabled={isMobile}
                    >
                        <Button
                            className='custom-button-secondary table-action'
                            onClick={this.props.store!.adminUIStore.setScreenToCopyIdentifiers.bind(this, rowData.id)}
                        >
                            <img src={clipboardIcon} alt='Copy to clipboard' height={12} />
                        </Button>
                    </Tooltip>
                ) : (
                    <div className='table-action-wrapper'>
                        <CopyToClipboard text={rowData.id || ''} label='ID' />
                    </div>
                )}
                {!(me.showDeletedItems && rowData.deletedAt !== null) ? (
                    <>
                        {rowData.canEdit && (
                            <Tooltip
                                boundary='viewport'
                                content='Edit'
                                hoverOpenDelay={300}
                                position={Position.TOP}
                                targetClassName='table-action-wrapper'
                                disabled={isMobile}
                            >
                                <Button
                                    className='table-action'
                                    onClick={this.props.store!.adminUIStore.setItemToEdit.bind(
                                        this,
                                        tabName,
                                        rowData.id
                                    )}
                                    color='primary'
                                >
                                    <img src={editIcon} alt='Edit' height={12} />
                                </Button>
                            </Tooltip>
                        )}
                        {rowData.canDelete && (
                            <Tooltip
                                boundary='viewport'
                                content='Delete'
                                hoverOpenDelay={300}
                                position={Position.TOP}
                                targetClassName='table-action-wrapper'
                                disabled={isMobile}
                            >
                                <Button
                                    className='table-action'
                                    onClick={this.props.store!.adminUIStore.setItemToDelete.bind(
                                        this,
                                        tabName,
                                        rowData.id
                                    )}
                                    color='danger'
                                >
                                    <img src={deleteIcon} alt='Delete' height={12} />
                                </Button>
                            </Tooltip>
                        )}
                    </>
                ) : (
                    <>
                        {this.props.store!.adminUIStore.selectedOrg?.canEdit && (
                            <Tooltip
                                boundary='viewport'
                                content='Restore'
                                hoverOpenDelay={300}
                                position={Position.TOP}
                                targetClassName='table-action-wrapper'
                                disabled={isMobile}
                            >
                                <Button
                                    className='table-action'
                                    onClick={this.props.store!.adminUIStore.setItemToRestore.bind(
                                        this,
                                        tabName,
                                        rowData.id
                                    )}
                                    color='success'
                                >
                                    <img src={refreshIcon} alt='Restore' height={12} />
                                </Button>
                            </Tooltip>
                        )}
                        {me.allowPermanentDelete && tabName !== AdminTabName.organisations && (
                            <Tooltip
                                boundary='viewport'
                                content='Permanently delete'
                                hoverOpenDelay={300}
                                position={Position.TOP}
                                targetClassName='table-action-wrapper'
                                disabled={isMobile}
                            >
                                <Button
                                    className='table-action'
                                    onClick={this.props.store!.adminUIStore.setItemToPermanentlyDelete.bind(
                                        this,
                                        tabName,
                                        rowData.id
                                    )}
                                    color='danger'
                                >
                                    <img src={deleteIcon} alt='Permanently delete' height={12} />
                                </Button>
                            </Tooltip>
                        )}
                    </>
                )}
                {tabName === AdminTabName.users && rowData.deletedAt === null && !rowData.emailVerified && (
                    <Tooltip
                        boundary='viewport'
                        content='Resend user invitation email'
                        hoverOpenDelay={300}
                        position={Position.TOP}
                        targetClassName={'table-action-wrapper' + (!rowData.canDelete ? ' fill-left' : '')}
                    >
                        <Button
                            className='table-action'
                            onClick={this.props.store!.adminUIStore.resendUserInvitation.bind(this, rowData.id)}
                            color='success'
                        >
                            <Icon icon='envelope' />
                        </Button>
                    </Tooltip>
                )}
                {tabName === AdminTabName.organisations && (
                    <Tooltip
                        boundary='viewport'
                        content='Org logo'
                        hoverOpenDelay={300}
                        position={Position.TOP}
                        targetClassName='table-action-wrapper'
                        disabled={isMobile}
                    >
                        <Button
                            className='table-action'
                            onClick={this.props.store!.adminUIStore.setItemToEditLogo.bind(this, tabName, rowData.id)}
                            color='success'
                        >
                            <img src={uploadIcon} alt='Edit' height={12} />
                        </Button>
                    </Tooltip>
                )}
            </div>
        )
    }
}

export default ActionCell
