export const checkEmail = (email: string): boolean => 
    // eslint-disable-next-line
     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
    )


export const checkSpaces = (stringToCheck: string): boolean => {
    const spacesRegEx = new RegExp('^[ ]|[ ]{2,}') // Either a leading space or more than one space consecutively
    return spacesRegEx.test(stringToCheck)
}

export const checkAmpersand = (stringToCheck: string): boolean => {
    const ampersandRegEx = new RegExp('[&]') // Matches ampersand character
    return ampersandRegEx.test(stringToCheck)
}
