export function crossBrowserCloneNode(element: HTMLElement, targetDocument: HTMLDocument) {
    const cloned = targetDocument.createElement(element.tagName) as HTMLElement
    cloned.innerHTML = element.innerHTML

    if (element.hasAttributes()) {
        // element.attributes does not have a Symbol.iterator method
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < element.attributes.length; i++) {
            const attribute = element.attributes[i]
            cloned.setAttribute(attribute.name, attribute.value)
        }
    }

    return cloned
}
