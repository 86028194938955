import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { eventPreventDefault } from '../utils/EventUtils'

import { InputGroup, Button } from 'reactstrap'
import Slider from 'rc-slider'
import zoomMinusIcon from 'src/assets/images/zoomMinusIcon.svg'
import zoomPlusIcon from 'src/assets/images/zoomPlusIcon.svg'
import { Icon } from '@blueprintjs/core'
import { Colour } from '../utils/Colour'

interface ZoomSliderProps {
    handleZoomLevelChange: (value: number) => void
    defaultZoomLevel: number
    minZoom: number
    maxZoom: number
    className?: string
}

@inject('store')
@observer
class ZoomSlider extends React.Component<{ store?: RootStore } & ZoomSliderProps> {
    state = { selectedZoomLevel: this.props.defaultZoomLevel }

    handleZoomLevelChange = (value: number) => {
        const minZoom = this.props.minZoom
        const maxZoom = this.props.maxZoom
        if (value >= minZoom && value <= maxZoom) {
            this.setState({ selectedZoomLevel: value })
            this.props.handleZoomLevelChange(value)
        }
    }

    render() {
        const selectedZoomLevel = this.state.selectedZoomLevel

        const isMobile = this.props.store!.appUIStore.isMobile

        return (
            <InputGroup
                className={'custom-input-group zoom-slider' + (this.props.className ? ' ' + this.props.className : '')}
            >
                <Button
                    className='custom-button-link'
                    onClick={this.handleZoomLevelChange.bind(this, selectedZoomLevel - 1)}
                    color='link'
                >
                    <img
                        src={zoomMinusIcon}
                        alt='Decrease zoom'
                        height={14.8}
                        draggable={false}
                        onDragStart={eventPreventDefault}
                    />
                </Button>
                {!isMobile ? (
                    <Slider
                        className='custom-slider'
                        onChange={this.handleZoomLevelChange}
                        min={this.props.minZoom}
                        max={this.props.maxZoom}
                        value={selectedZoomLevel}
                    />
                ) : (
                    <Icon className='ml-1 mr-1' icon='minus' iconSize={24} color={Colour.white} />
                )}
                <Button
                    className='custom-button-link'
                    onClick={this.handleZoomLevelChange.bind(this, selectedZoomLevel + 1)}
                    color='link'
                >
                    <img
                        src={zoomPlusIcon}
                        alt='Increase zoom'
                        height={14.8}
                        draggable={false}
                        onDragStart={eventPreventDefault}
                    />
                </Button>
            </InputGroup>
        )
    }
}

export default ZoomSlider
