import * as React from 'react'

// i18n
import i18n from 'src/i18n'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { BasicDiagnosticsStatType, ControllerStatType, TileMapStatType } from 'src/common/models/ControllerStat'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

import AssetLogButton from './AssetLogButton'
import TileViewZoom from './TileViewZoom'
import ConsoleButton from 'src/common/components/ConsoleButton'
import DiagnosticsBackArrow from './DiagnosticsBackArrow'
import { Form, Label, Button, InputGroup, FormGroup } from 'reactstrap'
import Select from 'react-select'
import { DropdownIndicator, Option, NoOptionsMessage } from 'src/common/components/SelectComponents'
import { Icon, Position, Drawer } from '@blueprintjs/core'
import editIcon from 'src/assets/images/editIcon.svg'
import Checkbox from 'src/common/components/Checkbox'
import { Manufacturer } from 'src/common/models/Screen'

@inject('store')
@observer
class TileViewControls extends React.Component<{ store?: RootStore }> {
    handleBackToList = () => {
        this.props.store!.tileViewStore.sendStopStatFeedCommand()
    }

    render() {
        const currentScreen = this.props.store!.screenDiagnosticsUIStore.currentScreen
        const currentScreenConfiguration = this.props.store!.screenDiagnosticsUIStore.currentScreenConfiguration
        const tileViewUIStore = this.props.store!.tileViewUIStore

        const me = this.props.store!.userStore.me

        if (!currentScreen || !currentScreenConfiguration || !me) {
            return null
        }

        const controllerStatOptions: Array<{ value: TileMapStatType; label: string }> = currentScreen.basicStatTypes
            .map(stat => ({
                value: stat as TileMapStatType,
                label: i18n.t('diagnosticsPage.basicStat.' + stat),
            }))
            .concat(
                currentScreen.controllerStatTypes.map(stat => ({
                    value: stat as TileMapStatType,
                    label: i18n.t('diagnosticsPage.controllerStat.' + stat),
                }))
            )

        const isMobile = this.props.store!.appUIStore.isMobile

        return (
            <React.Fragment>
                <div className='tile-view-controls-wrapper'>
                    <Form className='tile-view-controls' inline onSubmit={eventPreventDefault}>
                        <InputGroup
                            className={
                                'custom-input-group screen-overview-wrapper' +
                                (!isMobile ? ' custom-input-group-inline' : '')
                            }
                        >
                            <DiagnosticsBackArrow screenId={currentScreen.id} />
                            <div className='ml-4 screen-overview'>
                                <div className='screen-name selectable-text'>
                                    <h2>{currentScreen.name}</h2>
                                </div>
                                <div className='screen-details'>
                                    <div className='details'>
                                        <h4>Manufacturer</h4>
                                        <h5>{currentScreen.manufacturerName}</h5>
                                    </div>
                                    {currentScreen.manufacturer !== Manufacturer.novaStar && (
                                        <div className='details'>
                                            <h4>Sections</h4>
                                            <h5>{currentScreenConfiguration.sections.length}</h5>
                                        </div>
                                    )}
                                    <div className='details'>
                                        <h4>
                                            {currentScreen.manufacturer === Manufacturer.novaStar
                                                ? 'Cabinets'
                                                : 'Tiles'}
                                        </h4>
                                        <h5>{currentScreenConfiguration.tileCount}</h5>
                                    </div>
                                </div>
                            </div>
                            {!isMobile &&
                                currentScreen.manufacturer === Manufacturer.candelic &&
                                (me.isSuperUser || me.isAdmin) && <AssetLogButton />}
                        </InputGroup>
                        {!isMobile && (
                            <React.Fragment>
                                {(currentScreen.manufacturer === Manufacturer.candelic || me.isSuperUser) && (
                                    <InputGroup className={'custom-input-group custom-input-group-inline stat-select'}>
                                        <Label className='custom-label custom-label-inline'>
                                            <h5>Section Gaps</h5>
                                        </Label>
                                        <Checkbox
                                            isChecked={tileViewUIStore.sectionGaps}
                                            handleOnClick={tileViewUIStore.toggleSectionGaps}
                                        />
                                    </InputGroup>
                                )}
                                <InputGroup className='custom-input-group custom-input-group-inline stat-select'>
                                    <Label className='custom-label custom-label-inline'>
                                        <h5>{i18n.t('diagnosticsPage.view')}</h5>
                                    </Label>
                                    <Select
                                        className='custom-select-wrapper'
                                        classNamePrefix='custom-select'
                                        blurInputOnSelect
                                        onChange={tileViewUIStore.handleUpdateStat}
                                        options={controllerStatOptions}
                                        defaultValue={controllerStatOptions[0]}
                                        value={tileViewUIStore.currentStat}
                                        components={{ DropdownIndicator, Option, NoOptionsMessage }}
                                    />
                                </InputGroup>
                                {tileViewUIStore.showHeatmap && (
                                    <FormGroup className='custom-form-group custom-form-group-inline checkbox-form-group'>
                                        <Label className='custom-label custom-label-inline'>
                                            <h5>{i18n.t('diagnosticsPage.minMax')}</h5>
                                        </Label>
                                        <Checkbox
                                            isChecked={tileViewUIStore.normaliseMinMax}
                                            handleOnClick={tileViewUIStore.toggleNormaliseMinMax}
                                        />
                                    </FormGroup>
                                )}
                                <ConsoleButton currentScreen={currentScreen} />
                            </React.Fragment>
                        )}
                    </Form>
                    <Button
                        className='custom-button-small mobile-settings-toggle d-md-none'
                        color='primary'
                        onClick={tileViewUIStore.toggleMobileControls}
                    >
                        <Icon icon='settings' iconSize={16} />
                    </Button>
                </div>
                <div className='bottom-controls'>
                    {isMobile && (
                        <InputGroup className='custom-input-group stat-select'>
                            <Label className='custom-label custom-label-inline'>
                                <h5>{i18n.t('diagnosticsPage.view')}</h5>
                            </Label>
                            <Select
                                className='custom-select-wrapper'
                                classNamePrefix='custom-select'
                                blurInputOnSelect
                                onChange={tileViewUIStore.handleUpdateStat}
                                options={controllerStatOptions}
                                defaultValue={controllerStatOptions[0]}
                                value={tileViewUIStore.currentStat}
                                components={{ DropdownIndicator, Option, NoOptionsMessage }}
                                menuPlacement='auto'
                            />
                        </InputGroup>
                    )}
                    {!isMobile && currentScreenConfiguration.hasLogoBox && (
                        <div className='update-logo-box'>
                            <Button
                                className='custom-button-large custom-button-secondary'
                                onClick={tileViewUIStore.setLogoBoxDialog.bind(this, true)}
                            >
                                <img src={editIcon} alt='Edit' />
                                <span>{i18n.t('diagnosticsPage.logoBox')}</span>
                            </Button>
                        </div>
                    )}
                    <TileViewZoom />
                </div>
                {/* Show mobile controls drawer if open */}
                {isMobile && (
                    <Drawer
                        isOpen={tileViewUIStore.mobileControlsOpen}
                        onClose={tileViewUIStore.toggleMobileControls}
                        position={Position.TOP}
                        size={190}
                        portalClassName='custom-drawer'
                        className='mobile-controls-drawer'
                    >
                        <div className='drawer-buttons'>
                            {tileViewUIStore.showHeatmap && (
                                <Form className='mb-2 mr-3' inline onSubmit={eventPreventDefault}>
                                    <FormGroup className='custom-form-group checkbox-form-group'>
                                        <Label className='custom-label custom-label-inline'>
                                            <h5>{i18n.t('diagnosticsPage.minMax')}</h5>
                                        </Label>
                                        <Checkbox
                                            isChecked={tileViewUIStore.normaliseMinMax}
                                            handleOnClick={tileViewUIStore.toggleNormaliseMinMax}
                                        />
                                    </FormGroup>
                                </Form>
                            )}
                            <div className='mb-2'>
                                <ConsoleButton currentScreen={currentScreen} />
                            </div>
                            {currentScreenConfiguration.hasLogoBox && (
                                <Button
                                    className='mb-2 custom-button-small custom-button-icon-left custom-button-secondary'
                                    onClick={tileViewUIStore.setLogoBoxDialog.bind(this, true)}
                                >
                                    <img src={editIcon} alt='Edit' />
                                    <span>{i18n.t('diagnosticsPage.logoBox')}</span>
                                </Button>
                            )}
                        </div>
                        {(me.isSuperUser || me.isAdmin) && (
                            <InputGroup className='custom-input-group asset-log-group'>
                                <Label className='custom-label custom-label-inline'>
                                    <h5>Download asset management report</h5>
                                </Label>
                                <AssetLogButton />
                            </InputGroup>
                        )}
                    </Drawer>
                )}
            </React.Fragment>
        )
    }
}

export default TileViewControls
