import { observable, computed, action } from 'mobx'
import { Omit } from 'react-router'

import DiagnosticsErrorGroup from './DiagnosticsErrorGroup'
import { ErrorSeverity } from './DiagnosticErrorEnums'
import { ScreenState } from './ScreenState'
import DiagnosticsError from './DiagnosticsError'
import { OutageReason, StoppageReason } from './AlertReason'

class ScreenErrorGroup {
    static newScreenErrorGroup(): ScreenErrorGroup {
        return new ScreenErrorGroup({
            errors: new Map<string, DiagnosticsErrorGroup>(),
            state: ScreenState.unknown,
            isConnected: false,
            stoppageReason: undefined,
            outageReason: undefined,
            brightness: 0,
        })
    }

    @observable errors: Map<string, DiagnosticsErrorGroup>
    @observable state: ScreenState
    @observable isConnected: boolean
    @observable brightness: number
    @observable outageReason?: OutageReason
    @observable stoppageReason?: StoppageReason

    constructor(json: ScreenErrorJSON) {
        this.errors = new Map<string, DiagnosticsErrorGroup>()
        const errors: any = json.errors // this isn't really a map, so force it to an any for parsing
        Object.keys(errors).forEach(key => {
            json.errors[key].error = key
            this.errors.set(key, new DiagnosticsErrorGroup(json.errors[key]))
        })
        this.state = json.state
        this.isConnected = json.isConnected ? json.isConnected : false
        this.brightness = json.brightness ? json.brightness : 0
        this.outageReason = json.outageReason
        this.stoppageReason = json.stoppageReason
    }

    @computed get totalErrors(): number {
        let count = 0
        this.errors.forEach(element => {
            count += element.totalErrors
        })
        return count
    }

    @computed get totalAssignedErrors(): number {
        let count = 0
        this.errors.forEach(element => {
            count += element.totalAssignedErrors
        })
        return count
    }

    get totalUnassignedErrors(): number {
        return this.totalErrors - this.totalAssignedErrors
    }

    @computed get maxSeverity(): ErrorSeverity {
        let max = 0
        this.errors.forEach(element => {
            if (element.severity > max) {
                max = element.severity
            }
        })
        return max
    }

    @computed get maxUnassignedSeverity(): ErrorSeverity {
        let max = 0
        this.errors.forEach(element => {
            if (element.totalAssignedErrors !== element.totalErrors && element.severity > max) {
                max = element.severity
            }
        })
        return max
    }

    @computed get errorGroupListForDiagnostics(): DiagnosticsErrorGroup[] {
        return [...this.errors.values()].sort((a, b) => a.priority - b.priority)
    }

    @action getErrorFromHash(errorHash: string): DiagnosticsError | undefined {
        let error: DiagnosticsError | undefined
        this.errors.forEach(diagnosticsErrorGroup => {
            const foundError = diagnosticsErrorGroup.errorList.find(
                diagnosticsError => diagnosticsError.hash === errorHash
            )
            if (foundError) {
                error = foundError
            }
        })
        return error
    }
}

export type ScreenErrorJSON = Omit<
    ScreenErrorGroup,
    | 'totalErrors'
    | 'totalAssignedErrors'
    | 'totalUnassignedErrors'
    | 'maxSeverity'
    | 'maxUnassignedSeverity'
    | 'errorGroupListForDiagnostics'
    | 'getErrorFromHash'
>

export default ScreenErrorGroup
