import { SoftwareUpdateOptions } from 'src/modules/admin/stores/AdminUIStore'
import onAPI from './onAPI'
import { AdminBatchAction, ConnectVersion } from 'src/common/stores/AdminStore'

class ConnectVersionRouter {
    private connectRoute = '/connect-update'

    async getConnectVersions(): Promise<ConnectVersion[]> {
        const json: ConnectVersion[] = await onAPI.fetchJSON(this.connectRoute)
        return json
    }

    async updateConnectVersions(comptrollerIds: string[], version: string): Promise<void> {
        return onAPI.fetchJSON(this.connectRoute, {
            comptrollerIds,
            version,
        })
    }

    async updateSoftware(
        comptrollerIds: string[],
        software: AdminBatchAction,
        options: SoftwareUpdateOptions
    ): Promise<void> {
        return onAPI.fetchJSON('/software-update', {
            comptrollerIds,
            update: {
                component: software,
                ...options,
            },
        })
    }

    async getFinalUrl(url: string): Promise<string | null> {
        try {
            const response = await fetch(url, {
                method: 'HEAD',
                redirect: 'follow',
                headers: {
                    Origin: 'https://app.outwatch.io',
                    Accept: '*/*',
                    'Cache-Control': 'no-cache',
                    Pragma: 'no-cache',
                },
            })

            // Check if the response redirected to another URL
            if (response.status !== 200) {
                return null
            }
            const finalUrl = response.url
            return finalUrl
        } catch (error) {
            console.error('Error while fetching the final URL:', error)
            return null
        }
    }

    extractVersionFromUrl(url: string): string | null {
        const versionPattern = /\/(v?\d+\.\d+\.\d+)\//
        const match = url.match(versionPattern)
        return match ? match[1] : null
    }

    async getLEDNetVersion(version: string): Promise<string | null> {
        const baseUrl = 'https://d2dyuu47yz7suu.cloudfront.net/'
        let finalUrl: string

        if (!version) {
            finalUrl = `${baseUrl}latest/LEDNet_Install.exe`
        } else if (version.startsWith('v')) {
            finalUrl = `${baseUrl}${version}/LEDNet_Install.exe`
        } else {
            finalUrl = `${baseUrl}v${version}/LEDNet_Install.exe`
        }

        const resolvedUrl = await this.getFinalUrl(finalUrl)
        if (resolvedUrl) {
            const extractedVersion = this.extractVersionFromUrl(resolvedUrl)
            return extractedVersion
        }
        return null
    }
}

const connectVersionRouter = new ConnectVersionRouter()
export default connectVersionRouter
