import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { eventStopPropagation } from 'src/common/utils/EventUtils'

import DisplayImage from 'src/common/models/DisplayImage'

import { Table, Button } from 'reactstrap'
import { Tooltip, Intent, Position, Icon } from '@blueprintjs/core'
import Checkbox from 'src/common/components/Checkbox'
import CopyToClipboard from 'src/common/components/CopyToClipboard'
import LoadingSpinner from 'src/common/components/LoadingSpinner'
import imageUnnamedError from '../../../assets/images/imageUnnamedError.svg'
import imageBroken from '../../../assets/images/imageBroken.svg'
import playsIcon from '../../../assets/images/playsIcon.svg'
import resolutionIcon from '../../../assets/images/resolutionIcon.svg'
import screensIcon from '../../../assets/images/screensIcon.svg'
import timeIcon from '../../../assets/images/timeIcon.svg'
import viewsIcon from '../../../assets/images/viewsIcon.svg'
import moment from 'moment'
import 'moment-duration-format'

@inject('store')
@observer
class ResultsTable extends React.Component<{ store?: RootStore } & WithNamespaces> {
    toggleImageSummaryDrawer = (displayImage: DisplayImage, event: any) => {
        event.stopPropagation()

        this.props.store!.reportsUIStore.requestNewReport(displayImage.id)
    }

    toggleExpandImageDialog = (displayImage: DisplayImage, event: any) => {
        event.stopPropagation()

        this.props.store!.reportsUIStore.toggleExpandImageDialog(displayImage)
    }

    handleCheckboxSelect = (displayImage: DisplayImage, event: any) => {
        event.stopPropagation()

        if (!this.props.store!.reportsUIStore.checkIfImageSelected(displayImage)) {
            this.props.store!.reportsUIStore.addImageToSelectedArray(displayImage)
        } else {
            this.props.store!.reportsUIStore.removeImageFromSelectedArray(displayImage)
        }
    }

    handleImageResponseError = (event: any) => {
        // Prevent infinte loop if fallback src invalid
        event.target.onerror = null
        event.target.src = imageBroken
    }

    displayImageRow = (displayImage: DisplayImage) => {
        if (displayImage) {
            // Convert total and average time into moment object
            const totalTime = moment.duration(displayImage.totalTime, 'seconds')
            const averageTime = moment.duration(displayImage.averageTime, 'seconds')

            const isImageSelected = this.props.store!.reportsUIStore.displayImagesSelected.find(
                image => image.id === displayImage.id
            )

            return (
                <tr
                    key={displayImage.id}
                    className={
                        'results-table-row results-table-row-selectable' +
                        (isImageSelected ? ' results-table-row-selected' : '') +
                        (displayImage.hasError ? ' results-table-row-error' : '')
                    }
                    onClick={this.handleCheckboxSelect.bind(this, displayImage)}
                >
                    <td className='p-0'>
                        <table>
                            <tbody>
                                <tr className='results-table-row-inner'>
                                    <td className='select-image-wrapper'>
                                        <Checkbox
                                            isChecked={!!isImageSelected}
                                            handleOnClick={this.handleCheckboxSelect.bind(this, displayImage)}
                                        />
                                    </td>
                                    <td className='results-table-image-cell'>
                                        {displayImage.unnamed && (
                                            <Tooltip
                                                boundary={'viewport'}
                                                content={i18n.t('reportsPage.imageUnnamedError')}
                                                intent={Intent.DANGER}
                                                position={Position.TOP}
                                                className='image-unnamed-tooltip'
                                            >
                                                <img
                                                    className='display-image-error'
                                                    src={imageUnnamedError}
                                                    alt='Unnamed'
                                                />
                                            </Tooltip>
                                        )}
                                        {displayImage.thumbnailURL ? (
                                            <img
                                                className='results-table-image'
                                                onClick={this.toggleExpandImageDialog.bind(this, displayImage)}
                                                src={displayImage.thumbnailURL}
                                                alt={displayImage.displayableFilename}
                                                // Handles if response not 200
                                                onError={this.handleImageResponseError}
                                            />
                                        ) : (
                                            <Tooltip
                                                boundary={'viewport'}
                                                content={i18n.t('reportsPage.imageMissingURLError')}
                                                intent={Intent.DANGER}
                                                position={Position.TOP}
                                            >
                                                <div className='display-image-missing-container'>
                                                    <img
                                                        className='results-table-image'
                                                        src={imageBroken}
                                                        alt='Broken'
                                                    />
                                                </div>
                                            </Tooltip>
                                        )}
                                    </td>
                                    {/* Stop propogation on entire cell to prevent falsely selecting image */}
                                    <td
                                        className='align-middle results-table-open-report'
                                        onClick={eventStopPropagation}
                                    >
                                        <Tooltip
                                            boundary={'viewport'}
                                            content={i18n.t('reportsPage.openReport')}
                                            hoverOpenDelay={300}
                                            position={Position.TOP}
                                        >
                                            <Button
                                                className='custom-button-edit'
                                                onClick={this.toggleImageSummaryDrawer.bind(this, displayImage)}
                                                color='primary'
                                            >
                                                <Icon icon='document-open' iconSize={14} />
                                            </Button>
                                        </Tooltip>
                                    </td>
                                    <td>
                                        <table className='results-table-icons-table'>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        colSpan={5}
                                                        className='align-middle results-table-title d-none d-md-table-cell'
                                                    >
                                                        <div className='copyable-title'>
                                                            <CopyToClipboard
                                                                text={displayImage.fileName}
                                                                label='filename'
                                                            />
                                                            {/* TODO: Middle truncate long text */}
                                                            {displayImage.displayableFilename}
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <table className='results-table-icons-table'>
                                            <tbody>
                                                <tr>
                                                    <td
                                                        className='results-table-cell-plays align-middle'
                                                        style={{ width: '110px', maxWidth: '110px' }}
                                                    >
                                                        <img src={playsIcon} alt='Total plays' height={10} />{' '}
                                                        {displayImage.totalPlays}
                                                    </td>
                                                    <td
                                                        className='results-table-cell-resolution align-middle d-none d-md-table-cell'
                                                        style={{ width: '120px', maxWidth: '120px' }}
                                                    >
                                                        <img src={resolutionIcon} alt='Resolution' height={13} />
                                                        {displayImage.resolution}
                                                    </td>
                                                    <td
                                                        className='results-table-cell-screens align-middle d-none d-md-table-cell'
                                                        style={{ width: '120px', maxWidth: '120px' }}
                                                    >
                                                        <img src={screensIcon} alt='Screens' height={8} />
                                                        {displayImage.totalScreens}{' '}
                                                        {displayImage.totalScreens === 1
                                                            ? i18n.t('reportsPage.screen')
                                                            : i18n.t('reportsPage.screens')}
                                                    </td>
                                                    <td
                                                        className='results-table-cell-time align-middle d-none d-md-table-cell'
                                                        style={{ width: '200px', maxWidth: '200px' }}
                                                    >
                                                        <img src={timeIcon} alt='Total time' height={13} />
                                                        {totalTime.format('H[h] m[m] s[s]')} /{' '}
                                                        {averageTime.format('s[s]')} {i18n.t('common.average')}
                                                    </td>
                                                    <td
                                                        className='results-table-cell-last-played align-middle d-none d-md-table-cell'
                                                        style={{ width: '196px', maxWidth: '196px' }}
                                                    >
                                                        <img src={viewsIcon} alt='Last played' height={7.6} />
                                                        {moment(displayImage.lastPlayed).format(
                                                            this.props.store!.userStore.me?.dateTimeFormat
                                                        )}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr className='results-table-filename-row d-md-none'>
                                    <td colSpan={4} className='align-middle results-table-title'>
                                        <div className='copyable-title'>
                                            <CopyToClipboard text={displayImage.fileName} label='filename' />
                                            {/* TODO: Middle truncate long text */}
                                            {displayImage.displayableFilename}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
            )
        } else {
            return null
        }
    }

    render() {
        const displayImageStore = this.props.store!.displayImageStore
        const isDisplayImagesFetching = displayImageStore.isDisplayImagesFetching
        const reportsUIStore = this.props.store!.reportsUIStore
        const displayImages = reportsUIStore.filteredItems

        return (
            <React.Fragment>
                {isDisplayImagesFetching || (displayImages && displayImages.length > 0) ? (
                    <div className='results-table custom-container ml-auto mr-auto'>
                        <Table responsive>
                            <tbody>{displayImages.map(displayImage => this.displayImageRow(displayImage))}</tbody>
                        </Table>
                        {isDisplayImagesFetching && <LoadingSpinner />}
                    </div>
                ) : (
                    <div className='reports-non-ideal-state'>
                        <h2>{i18n.t('reportsPage.noResults')}</h2>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default withNamespaces()(ResultsTable)
