import { ErrorSeverity } from '../models/DiagnosticErrorEnums'
import { Outage, Error, RecurringError, Info } from '../../modules/diagnostics/global/submodules/digest/GlobalDigest'

export enum Colour {
    none = '#666666',
    primary = '#6340F1',
    success = '#78C803',
    outage = '#FE1F55',
    stoppage = '#F6973B',
    warning = '#FFD051',
    criticalWarning = '#F6973B',
    danger = '#FE2260',
    resolve = '#39d23e',
    assign = '#1262e8',
    pageDark = '#252525',
    primaryLight = '#48aff0',
    white = '#ffffff',
    black = '#000000',
    grey = '#4E4E4E',
    darkGrey = '#131313',
}

export const severityColour = (severity: ErrorSeverity): Colour => {
    switch (severity) {
        case ErrorSeverity.noError:
            return Colour.success
        case ErrorSeverity.warning:
            return Colour.warning
        case ErrorSeverity.visualFault:
            return Colour.danger
        case ErrorSeverity.criticalWarning:
            return Colour.criticalWarning
        case ErrorSeverity.noConnection:
            return Colour.none
    }
}

export const tagColour = (key: Outage | Error | RecurringError | string): string => {
    switch (key) {
        case Outage.outages:
            return Colour.outage
        case Outage.stoppages:
            return Colour.stoppage
        case Error.faults:
        case Error.newFaults:
        case RecurringError.recurringFaults:
            return Colour.danger
        case Error.critical:
        case Error.newCritical:
        case Error.criticalWarnings:
        case RecurringError.recurringCritical:
            return Colour.criticalWarning
        case Error.warnings:
        case Error.newWarnings:
        case RecurringError.recurringWarnings:
            return Colour.warning
        case Info.healthy:
            return Colour.success
        default:
            return ''
    }
}

export function darkenColor(hexColor: string, darkenPercent: number): string {
    darkenPercent = Math.max(0, Math.min(100, darkenPercent))

    let r = parseInt(hexColor.slice(1, 3), 16)
    let g = parseInt(hexColor.slice(3, 5), 16)
    let b = parseInt(hexColor.slice(5, 7), 16)

    const factor = (100 - darkenPercent) / 100

    r = Math.round(r * factor)
    g = Math.round(g * factor)
    b = Math.round(b * factor)

    const darkenedHex =
        '#' +
        [r, g, b]
            .map(x => {
                const hex = x.toString(16)
                return hex.length === 1 ? '0' + hex : hex
            })
            .join('')

    return darkenedHex
}
