class GridRect {
    x: number
    y: number
    width: number
    height: number

    constructor(x: number, y: number, width: number, height: number) {
        this.x = x
        this.y = y
        this.width = width
        this.height = height
    }

    stringRepresentation = (): string => `x: ${this.x}, y: ${this.y}, width: ${this.width}, height: ${this.height}`

    gridRepresentation = (): string => `${this.y + 1} / ${this.x + 1} / ${this.y + 1 + this.height} / ${this.x + 1 + this.width}`

    intersectsRect = (other: GridRect): boolean => (
            this.y < other.y + other.height &&
            other.y < this.y + this.height &&
            this.x < other.x + other.width &&
            other.x < this.x + this.width
        )

    useXOffset = (other: GridRect): boolean => Math.abs(this.x - other.x) > Math.abs(this.y - other.y)
}

export default GridRect
