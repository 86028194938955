import { observable, computed } from 'mobx'
import { Omit } from 'react-router'
import moment from 'moment'

import { rootStore } from '../RootStore'

import Organisation from './Organisation'

class ScreenStatusesContainer {
    @observable organisationId: string
    @observable outages?: number
    @observable stoppages?: number
    @observable faults?: number
    @observable criticalWarnings?: number
    @observable warnings?: number
    @observable subscriptionKey?: string
    @observable subscriptionTTL?: number
    @observable updatedAt?: moment.Moment

    constructor(json: ScreenStatusesContainerJSON) {
        this.organisationId = json.organisationId
        this.outages = json.outages
        this.stoppages = json.stoppages
        this.faults = json.faults
        this.criticalWarnings = json.criticalWarnings
        this.warnings = json.warnings
        this.subscriptionKey = json.subscriptionKey
        this.subscriptionTTL = json.subscriptionTTL
        this.updatedAt = json.updatedAt ?? moment()
    }

    @computed get organisation(): Organisation | undefined {
        return rootStore.orgStore.findItem(this.organisationId)
    }

    @computed get totalCount(): number {
        return (
            (this.outages ?? 0) +
            (this.stoppages ?? 0) +
            (this.faults ?? 0) +
            (this.criticalWarnings ?? 0) +
            (this.warnings ?? 0)
        )
    }

    @computed get noErrorCount(): number {
        const screenCount = this.organisation?.screens.filter(s => s.enabled).length ?? 0
        return screenCount - this.totalCount
    }

    @computed get isConcern(): boolean {
        return (this.outages ?? 0) > 0 || (this.stoppages ?? 0) > 0 || (this.faults ?? 0) > 0
    }
}

export type ScreenStatusesContainerJSON = Omit<
    ScreenStatusesContainer,
    'organisation' | 'totalCount' | 'noErrorCount' | 'isConcern'
>

export default ScreenStatusesContainer
