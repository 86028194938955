import { observable } from 'mobx'
import BaseModel from './BaseModel'
import { Omit } from 'react-router'
import { DisplayImageSummaryParams } from './DisplayImage'
// import moment from 'moment'

export enum ReportFormat {
    csv = 'CSV',
    pdf = 'PDF',
    json = 'JSON',
}

export enum ReportType {
    candelic = 'Candelic',
    talon = 'Talon',
}

export interface DisplayReportParameters extends DisplayImageSummaryParams {
    name: string
    format: ReportFormat
    type: ReportType
}

class DisplayReport extends BaseModel {
    static newDisplayReport(): DisplayReport {
        return new DisplayReport({
            name: '',
            // startDate: moment(),
            // stopDate: moment(),
            // screenIds: [],
            // displayImageIds: [],
            type: ReportType.candelic,
            format: ReportFormat.csv,
            signedURL: '',
        })
    }

    @observable signedURL: string
    @observable name: string
    @observable format: ReportFormat
    @observable type: ReportType

    constructor(json: DisplayReportJSON) {
        super(json)

        this.name = json.name
        // this.startDate = json.startDate
        // this.stopDate = json.stopDate
        // this.screenIds = json.screenIds
        // this.displayImageIds = json.displayImageIds
        this.type = json.type
        this.format = json.format
        this.signedURL = json.signedURL
    }

    toJSON(includeDates?: boolean): DisplayReportJSON {
        const json = super.toJSON(includeDates) as DisplayReportJSON
        // For now, these are never written out, so this hasn't been fully implemented
        return json
    }
}

export type DisplayReportJSON = Omit<DisplayReport, 'toJSON' | 'isValid' | 'isOwned' | 'canEdit' | 'canDelete'>

export default DisplayReport
