export enum OutageReason {
    network = 'network',
    controllerMissing = 'controllerMissing',
    detached = 'detached',
}

export enum StoppageReason {
    controllerExplicit = 'controllerExplicit',
    controllerUnexpected = 'controllerUnexpected',
    screenMissing = 'screenMissing',
    blank = 'blank',
    pattern = 'pattern',
    frozen = 'frozen',
}
export type AlertReason = OutageReason | StoppageReason

export const AlertReasonDescriptions: {
    [key in AlertReason]: string
} = {
    [OutageReason.network]: 'OW Connect is disconnected',
    [OutageReason.controllerMissing]: 'The controller is missing',
    [OutageReason.detached]: 'Screen was detached from connect by a user',
    [StoppageReason.controllerExplicit]: 'Stopped by user/scheduler (expected)',
    [StoppageReason.controllerUnexpected]: 'Stopped by controller due to an error',
    [StoppageReason.screenMissing]: 'The screen is missing',
    [StoppageReason.blank]: 'The screen is blank',
    [StoppageReason.pattern]: 'The screen is showing a pattern',
    [StoppageReason.frozen]: 'The screen is frozen',
}

export const AlertReasonShortDescriptions: {
    [key in AlertReason]: string
} = {
    [OutageReason.network]: 'Outage: Connect',
    [OutageReason.controllerMissing]: 'Outage: Controller',
    [OutageReason.detached]: 'Outage: Detached',
    [StoppageReason.controllerExplicit]: 'Stoppage: User',
    [StoppageReason.controllerUnexpected]: 'Stoppage: Controller',
    [StoppageReason.screenMissing]: 'Stoppage: Missing',
    [StoppageReason.blank]: 'Stoppage: Blank',
    [StoppageReason.pattern]: 'Stoppage: Pattern',
    [StoppageReason.frozen]: 'Stoppage: Frozen',
}
