const config = {
    Auth: {
        authenticationFlowType: 'USER_SRP_AUTH',
        mandatorySignIn: true,
        region: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
}

const iot = {
    aws_pubsub_region: process.env.REACT_APP_AWS_REGION,
    aws_pubsub_endpoint: process.env.REACT_APP_IOT_PUBSUB_ENDPOINT,
}

export { config, iot }
