import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import views from 'src/config/views'

import Screen from 'src/common/models/Screen'
import ScreenStatusCell from '../submodules/screen-current-errors/components/tableComponents/ScreenStatusCell'
import ScreenNameCell from '../submodules/screen-current-errors/components/tableComponents/ScreenNameCell'
import { ScreenState } from 'src/common/models/ScreenState'

import matchSorter from 'match-sorter'

import { AutoSizer, SortDirection } from 'react-virtualized'
import BaseVirtualizedTable, { BaseVirtualizedTableCol } from 'src/common/components/virtualized/BaseVirtualizedTable'
import { ScreenTabName } from '../stores/ScreenDiagnosticsUIStore'
import { Icon } from '@blueprintjs/core'

const DEFAULT_ROW_HEIGHT = 60

@inject('store')
@observer
class ScreenTable extends React.Component<{ store?: RootStore } & WithNamespaces> {
    handleRowClick = ({ rowData }: any) => {
        if (this.props.store!.screenDiagnosticsUIStore.isScreenConfigurationPopulating) {
            // Ignore if already fetching a screen configuration
            return
        }

        const screen = rowData
        if (!screen) {
            return
        }

        const router = this.props.store!.router
        router.goTo(
            views.diagnosticsScreen,
            {
                ...router.params,
                screen: screen.id,
                tab: ScreenTabName.current,
            },
            this.props.store!
        )
    }

    getRowClassname = (screen: Screen): string => {
        if (!screen) {
            return ''
        }
        const currentScreen = this.props.store!.screenDiagnosticsUIStore.currentScreen

        let className = ''
        if (currentScreen && screen.id === currentScreen.id!) {
            className = className + ' vtr-selected'
        }
        if (!screen.isConnected || screen.state === ScreenState.stopped || screen.state === ScreenState.enumerated) {
            className = className + ' vtr-stopped'
        }
        return className
    }

    noRowsRenderer = (): JSX.Element => (
        <div className='bvt-no-results'>
            <h2>No results</h2>
        </div>
    )

    render() {
        const screenDiagnosticsUIStore = this.props.store!.screenDiagnosticsUIStore
        let screensList = screenDiagnosticsUIStore.screensForDiagnostics
        const searchQueryValue = screenDiagnosticsUIStore.searchQueryValue
        if (searchQueryValue) {
            // Use match sorter to filter against screen name and id
            screensList = matchSorter(screensList, searchQueryValue, {
                keys: [
                    {
                        threshold: matchSorter.rankings.EQUAL,
                        key: 'id',
                    },
                    'name',
                    {
                        threshold: matchSorter.rankings.STARTS_WITH,
                        key: (item: { labelKeyValuePairs: Array<{ key: string; value: string }> }) => {
                            if (item.labelKeyValuePairs && item.labelKeyValuePairs.length > 0) {
                                return item.labelKeyValuePairs.map((l: { key: string; value: string }) => l.value)
                            }
                            return []
                        },
                    },
                ],
            })
        }

        const isErrorsFetching = this.props.store!.diagnosticsStore.isErrorsFetching

        const data = {
            screenStates: screensList?.map(screen => screen.state),
            screensList,
            // Force re-render when current screen changes
            currentScreen: this.props.store!.screenDiagnosticsUIStore.currentScreen,
        }

        const cols: BaseVirtualizedTableCol[] = [
            {
                dataKey: 'sortIndex',
                label: i18n.t('diagnosticsPage.tableHeaders.status'),
                width: 80,
                className: 'status-col',
                cellElement: (cellData: number, rowData: Screen) => (
                    <ScreenStatusCell sortIndex={cellData} screen={rowData} />
                ),
            },
            {
                dataKey: 'name',
                label: i18n.t('diagnosticsPage.tableHeaders.name'),
                flexGrow: 1,
                className: 'screen-info-cell',
                cellElement: (cellData: string, rowData: any) => <ScreenNameCell name={cellData} screen={rowData} />,
            },
            {
                dataKey: 'avior',
                label: '',
                width: 20,
                className: 'screen-avior-cell',
                cellElement: (cellData: string, rowData: any) =>
                    rowData.hasAvior && <Icon icon='power' size={16} color='white' />,
            },
        ]

        let rows: Screen[] | Array<Record<string, any>>
        if (!isErrorsFetching && screensList) {
            rows = screensList.map((screen: any, i) => {
                // Add sort index to preserve custom status sort
                screen.sortIndex = i
                return screen
            })
        } else {
            // Generate some blank rows while data is fetching
            const blankRowAmount = Math.floor((window.innerHeight - 296) / DEFAULT_ROW_HEIGHT)
            rows =
                blankRowAmount >= 1
                    ? new Array(blankRowAmount)
                          // Use column names as loading placeholder
                          .fill(cols.map(col => ({ col: String(col) })))
                    : []
        }

        return (
            <div className='diagnostics-screens-table'>
                <AutoSizer>
                    {({ width, height }) => (
                        <BaseVirtualizedTable
                            cols={cols}
                            rows={rows}
                            width={width}
                            height={height}
                            rowHeight={60}
                            headerHeight={40}
                            data={data}
                            isLoading={isErrorsFetching}
                            isSearching={!!searchQueryValue}
                            sortBy='sortIndex'
                            sortDirection={SortDirection.DESC}
                            rowClassName={this.getRowClassname}
                            onRowClick={this.handleRowClick}
                            noRowsRenderer={this.noRowsRenderer}
                        />
                    )}
                </AutoSizer>
            </div>
        )
    }
}

export default withNamespaces()(ScreenTable)
