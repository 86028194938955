import * as React from 'react'
import { Button } from 'reactstrap'

import tickIcon from 'src/assets/images/tickIcon.svg'

interface CheckboxProps {
    isChecked: boolean
    isDisabled?: boolean
    className?: string
    handleOnClick?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) &
        ((event: React.MouseEvent<any, MouseEvent>) => void)
}

class Checkbox extends React.Component<CheckboxProps> {
    render() {
        const { isChecked, isDisabled, className, handleOnClick } = this.props
        return (
            <Button className={'custom-checkbox ' + className} onClick={handleOnClick} disabled={isDisabled}>
                {isChecked && <img className='custom-checkbox-icon' src={tickIcon} alt='Tick' />}
            </Button>
        )
    }
}

export default Checkbox
