import * as React from 'react'

// i18n
import i18n from 'src/i18n'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

import HelpLabel from 'src/common/components/HelpLabel'
import { Form, InputGroup, Label, Button } from 'reactstrap'
import DatePicker from 'src/common/components/DatePicker'
import Select from 'react-select'
import {
    ValueContainer,
    ClearIndicator,
    DropdownIndicator,
    Option,
    NoOptionsMessage,
    ValueLabelPair,
    GroupHeading,
} from 'src/common/components/SelectComponents'
import { Boundary } from '@blueprintjs/core'
import moment from 'moment'
import 'moment-timezone'

@inject('store')
@observer
export default class ScreenErrorHistoryScope extends React.Component<{ store?: RootStore }> {
    state: {
        boundary: Boundary | undefined
    } = {
        boundary: undefined,
    }

    handleRangeChange = (newDateRange: [moment.Moment | undefined, moment.Moment | undefined]) => {
        this.props.store!.screenDiagnosticsUIStore.screenErrorHistoryManager.updateSearchParams(
            'dateRange',
            newDateRange
        )
    }

    handleBoundaryChange = (boundary: Boundary | undefined) => {
        this.setState({ boundary })
    }

    handleErrorOptionChange = (selectedOptions: ValueLabelPair[]) => {
        this.props.store!.screenDiagnosticsUIStore.screenErrorHistoryManager.updateSearchParams(
            'errors',
            selectedOptions.map(option => option.value)
        )
    }

    handleScreenErrorLogScopeSubmit = () => {
        const screenErrorHistoryManager = this.props.store!.screenDiagnosticsUIStore.screenErrorHistoryManager
        if (screenErrorHistoryManager.areControlsOpen) {
            // Collapse the controls form on search
            screenErrorHistoryManager.toggleControls()
        }
        screenErrorHistoryManager.searchHistory(false)
    }

    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return null
        }

        const screenErrorHistoryManager = this.props.store!.screenDiagnosticsUIStore.screenErrorHistoryManager

        const startDate = screenErrorHistoryManager.startDate
        const endDate = screenErrorHistoryManager.endDate
        // Allow selecting back one month from end date
        const minDate =
            this.state.boundary === Boundary.START && endDate
                ? endDate
                      .clone()
                      .subtract(1, 'month')
                      .startOf('day')
                : undefined
        // Allow selecting forward one calendar month from state date, as long as it's not in the future
        // Fallback to now
        let maxDate =
            this.state.boundary === Boundary.END && startDate
                ? startDate
                      .clone()
                      .add(1, 'month')
                      .endOf('day')
                : moment()
        if (maxDate.isAfter(moment())) {
            // Prevent max date going beyond now
            maxDate = moment()
        }

        return (
            <Form className='datepicker-inline' inline onSubmit={eventPreventDefault}>
                <InputGroup className='custom-input-group'>
                    <HelpLabel
                        label={
                            i18n.t('diagnosticsPage.errorHistoryScopeRange') +
                            ' (' +
                            moment.tz(me.timeZone).format('z') +
                            ')'
                        }
                    />
                    <DatePicker
                        handleChange={this.handleRangeChange}
                        handleBoundaryChange={this.handleBoundaryChange}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        shortcuts={[
                            {
                                label: i18n.t('common.dates.shortcuts.24Hours'),
                                includeTime: true,
                                dateRange: [
                                    moment()
                                        .subtract(24, 'hours')
                                        .toDate(),
                                    moment().toDate(),
                                ],
                            },
                            {
                                label: i18n.t('common.dates.shortcuts.48Hours'),
                                includeTime: true,
                                dateRange: [
                                    moment()
                                        .subtract(48, 'hours')
                                        .toDate(),
                                    moment().toDate(),
                                ],
                            },
                            {
                                label: i18n.t('common.dates.shortcuts.72Hours'),
                                includeTime: true,
                                dateRange: [
                                    moment()
                                        .subtract(72, 'hours')
                                        .toDate(),
                                    moment().toDate(),
                                ],
                            },
                            {
                                label: i18n.t('common.dates.shortcuts.thisWeek'),
                                includeTime: true,
                                dateRange: [
                                    moment()
                                        .startOf('isoWeek') // isoWeek uses Monday as start day
                                        .toDate(),
                                    moment().toDate(),
                                ],
                            },
                            {
                                label: i18n.t('common.dates.shortcuts.thisMonth'),
                                includeTime: true,
                                dateRange: [
                                    moment()
                                        .startOf('month')
                                        .toDate(),
                                    moment().toDate(),
                                ],
                            },
                        ]}
                    />
                </InputGroup>
                <InputGroup className='custom-input-group'>
                    <Label className='custom-label'>
                        <h6>{i18n.t('diagnosticsPage.errorHistoryScopeCodes')}</h6>
                    </Label>
                    <Select
                        className='custom-select-wrapper'
                        classNamePrefix='custom-select'
                        isMulti
                        hideSelectedOptions={false}
                        closeMenuOnSelect={false}
                        blurInputOnSelect={false}
                        onChange={this.handleErrorOptionChange}
                        options={screenErrorHistoryManager.errorCodeOptionsGrouped}
                        placeholder={i18n.t('common.all')}
                        components={{
                            ValueContainer,
                            ClearIndicator,
                            DropdownIndicator,
                            Option,
                            NoOptionsMessage,
                            GroupHeading,
                        }}
                        menuPlacement='auto'
                    />
                </InputGroup>
                <InputGroup className='scope-button'>
                    <Button
                        onClick={this.handleScreenErrorLogScopeSubmit}
                        className='custom-button-large'
                        color='primary'
                        disabled={!(endDate && startDate)}
                    >
                        {i18n.t('actions.apply')}
                    </Button>
                </InputGroup>
            </Form>
        )
    }
}
