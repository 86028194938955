export const capitalizeFirstLetter = (stringToCapitalize: string): string => stringToCapitalize.charAt(0).toUpperCase() + stringToCapitalize.slice(1)

export const middleEllipsis = (stringToTruncate: string, maxLength: number): string => {
    if (!stringToTruncate) {
        return stringToTruncate
    }
    if (maxLength < 1) {
        return stringToTruncate
    }
    if (stringToTruncate.length <= maxLength) {
        return stringToTruncate
    }
    if (maxLength === 1) {
        return stringToTruncate.substring(0, 1) + '...'
    }

    const midpoint = Math.ceil(stringToTruncate.length / 2)
    const toremove = stringToTruncate.length - maxLength
    const lstrip = Math.ceil(toremove / 2)
    const rstrip = toremove - lstrip
    return stringToTruncate.substring(0, midpoint - lstrip) + '...' + stringToTruncate.substring(midpoint + rstrip)
}

export const subscriptionTopics = (prefix: string, subscriptionKey: string): [string, string] => {
    const inboundTopic = `${prefix}/${subscriptionKey}/in`
    const outboundTopic = `${prefix}/${subscriptionKey}/out`
    return [inboundTopic, outboundTopic]
}
