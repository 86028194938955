import * as React from 'react'
import { List, ListProps, ListRowProps } from 'react-virtualized'

import 'react-virtualized/styles.css'

const DEFAULT_ROW_HEIGHT = 50

export interface BaseVirtualizedListProps extends Partial<ListProps> {
    width: number // Forces type to number
    height: number // Forces type to number
    rowHeight?: number // Optional using Partial<T>
    rowCount: number // Forces type to number
    data?: any // Signals to the table that something has changed when deleting or marking a row as added
    recomputeOnDataChange?: boolean // Optional flag to recompute row heights on data change
}

class BaseVirtualizedList extends React.Component<BaseVirtualizedListProps> {
    baseListRef: any

    componentDidUpdate(prevProps: BaseVirtualizedListProps) {
        // Recompute row heights if flag set
        if (this.props.recomputeOnDataChange && this.props.data !== prevProps.data) {
            this.baseListRef?.recomputeRowHeights()
        }
    }

    rowRenderer = ({ key, index, style }: ListRowProps) => (
            <div key={key} style={style}>
                {String(this.props.list[index])}
            </div>
        )

    render() {
        const { data, className } = this.props

        const rowHeight = this.props.rowHeightGetter || this.props.rowHeight || DEFAULT_ROW_HEIGHT
        const rowRenderer = this.props.rowRenderer || this.rowRenderer

        return (
            <div className='custom-scrollbars'>
                <List
                    {...this.props}
                    ref={instance => (this.baseListRef = instance)}
                    data={data}
                    rowHeight={rowHeight}
                    rowRenderer={rowRenderer}
                    className={'bvl-list' + (className ? ' ' + className : '')}
                />
            </div>
        )
    }
}

export default BaseVirtualizedList
