import BaseRouter, { EmptySearchParams, RouterFlag } from './BaseRouter'
import onAPI from './onAPI'

import Screen, { ScreenJSON } from '../common/models/Screen'
import { LogoBoxLocation } from 'src/common/models/LogoBoxLocation'

class ScreenRouter extends BaseRouter<Screen, ScreenJSON, EmptySearchParams> {
    protected route = '/screen'

    protected flags =
        RouterFlag.create |
        RouterFlag.read |
        RouterFlag.update |
        RouterFlag.delete |
        RouterFlag.list |
        RouterFlag.restore |
        RouterFlag.permanentlyDelete

    async populateScreenConfiguration(screenId: string) {
        const json: any = await onAPI.fetchJSON('/screen-configuration/' + screenId)
        return json
    }

    async setLogoBoxLocation(screenId: string, location: LogoBoxLocation | null) {
        const json: any = await onAPI.fetchJSON('/screen-configuration/' + screenId + '/logo-box-location', {
            logoBoxLocation: location,
        })
        return json
    }

    protected newItem(json: ScreenJSON): Screen {
        return new Screen(json)
    }

    async avior(id: string, domain: string, payload?: string): Promise<any> {
        const json: any = await onAPI.fetchJSON('/screen/' + id + '/avior', {
            domain,
            payload,
        })
        return json
    }
}

const screenRouter = new ScreenRouter()
export default screenRouter
