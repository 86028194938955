import { RouterFlag } from './BaseRouter'
import StringsRouter from './StringsRouter'
import { ResolutionSearchParams } from 'src/common/models/DisplayImage'

class ResolutionRouter extends StringsRouter<ResolutionSearchParams> {
    protected route = '/resolution'

    protected flags = RouterFlag.search | RouterFlag.list
}

const resolutionRouter = new ResolutionRouter()
export default resolutionRouter
