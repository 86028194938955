import { observable, computed } from 'mobx'

import { Omit } from 'react-router'

export enum TransitionType {
    fade = 'fade',
    snap = 'snap',
}

class MediaSettings {
    static newMediaSettings(): MediaSettings {
        return new MediaSettings({
            dwellTime: 0,
            transitionType: TransitionType.fade,
            transitionTime: 0,
        })
    }

    @observable dwellTime: number
    @observable transitionType: TransitionType
    @observable transitionTime: number

    constructor(json: MediaSettingsJSON) {
        this.dwellTime = json.dwellTime
        this.transitionType = json.transitionType
        this.transitionTime = json.transitionTime
    }

    @computed get minDwellTime(): number {
        if (this.transitionType === TransitionType.snap) {
            return 3
        }

        return this.transitionTime + 3
    }

    @computed get maxTransitionTime(): number {
        return this.dwellTime - 3
    }
}

export type MediaSettingsJSON = Omit<MediaSettings, 'minDwellTime' | 'maxTransitionTime'>

export default MediaSettings
