import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'
import { Link } from 'mobx-router'
import views from 'src/config/views'

import backChevron from 'src/assets/images/backChevron.svg'

interface DiagnosticsBackArrowProps {
    screenId?: string
    mobileOnly?: boolean
}

@inject('store')
@observer
class DiagnosticsBackArrow extends React.Component<DiagnosticsBackArrowProps & { store?: RootStore }> {
    render() {
        return (
            <Link
                view={views.diagnosticsScreen}
                store={this.props.store!}
                params={{ ...this.props.store!.router?.params, screen: this.props.screenId, tab: undefined }}
            >
                <img
                    className={'diagnostics-back-arrow' + (this.props.mobileOnly ? ' d-md-none' : '')}
                    src={backChevron}
                    alt='Back'
                />
            </Link>
        )
    }
}

export default DiagnosticsBackArrow
