import { computed, observable } from 'mobx'
import { Omit } from 'react-router'

import { rootStore } from '../RootStore'

import BaseModel from './BaseModel'
import ScreenErrorGroup from './ScreenErrorGroup'
import { ScreenState } from './ScreenState'
import { LogoBoxLocation } from './LogoBoxLocation'
import { ErrorSeverity } from './DiagnosticErrorEnums'
import { darkenColor, severityColour } from '../utils/Colour'
import DomainStore from '../stores/DomainStore'
import Organisation, { OrganisationPermission } from './Organisation'
import { TileType } from './Tile'
import moment from 'moment'
import i18n from 'src/i18n'
import { BasicDiagnosticsStatType, ControllerStatType } from './ControllerStat'
import { OutageReason, StoppageReason, AlertReasonShortDescriptions } from './AlertReason'
import ScreenConfiguration from './ScreenConfiguration'

export enum Manufacturer {
    candelic = 'candelic',
    novaStar = 'novaStar',
}

export interface ComptrollerData {
    lednetDaemon: false
    lednetRestart: false
    lednetUptime: 0 // seconds
    lednetVersion: ''
    lednetDaemonVersion: ''
    connectVersion: ''
    connectDaemon: false
}

interface Geometry {
    r: number
    c: number
    w: number
    h: number
    x: number
    y: number
}

export class ScreenStats {
    @observable jitter: 0
    @observable vsyncDelays: 0
    @observable vsyncDrops: 0
    @observable hardwareVsync: 0
    @observable threadVsync: 0
    @observable threadEngine: 0
    @observable threadQueue: 0

    static newScreenStats(): ScreenStats {
        return new ScreenStats({
            jitter: 0,
            vsyncDelays: 0,
            vsyncDrops: 0,
            hardwareVsync: 0,
            threadVsync: 0,
            threadEngine: 0,
            threadQueue: 0,
        })
    }

    constructor(json: ScreenStatsJSON) {
        this.jitter = json.jitter
        this.vsyncDelays = json.vsyncDelays
        this.vsyncDrops = json.vsyncDrops
        this.hardwareVsync = json.hardwareVsync
        this.threadVsync = json.threadVsync
        this.threadEngine = json.threadEngine
        this.threadQueue = json.threadQueue
    }

    toJSON(): ScreenStatsJSON {
        const json = {} as ScreenStatsJSON
        json.jitter = this.jitter
        json.vsyncDelays = this.vsyncDelays
        json.vsyncDrops = this.vsyncDrops
        json.hardwareVsync = this.hardwareVsync
        json.threadVsync = this.threadVsync
        json.threadEngine = this.threadEngine
        json.threadQueue = this.threadQueue
        return json
    }
}

export type ScreenStatsJSON = Omit<ScreenStats, 'toJSON'>

class Comptroller extends BaseModel {
    static newComptroller(): Comptroller {
        return new Comptroller({
            fingerprint: '',
            connectVersion: '',
            connectDaemon: false,
            lednetDaemon: false,
            lednetDaemonVersion: undefined,
            lednetRestart: false,
            lednetUptime: 0,
            lednetVersion: '',
        })
    }
    @observable fingerprint: string
    @observable connectVersion: string
    @observable connectDaemon: boolean
    @observable lednetDaemon: boolean
    @observable lednetDaemonVersion?: string
    @observable lednetRestart: boolean
    @observable lednetUptime: number
    @observable lednetVersion: string

    constructor(json: any) {
        super(json)
        this.fingerprint = json.fingerprint
        this.connectVersion = json.connectVersion
        this.connectDaemon = json.connectDaemon
        this.lednetDaemon = json.lednetDaemon
        this.lednetDaemonVersion = json.lednetDaemonVersion
        this.lednetRestart = json.lednetRestart
        this.lednetUptime = json.lednetUptime
        this.lednetVersion = json.lednetVersion
    }

    toJSON(includeDates?: boolean): ComptrollerJSON {
        const json = super.toJSON(includeDates) as ComptrollerJSON
        json.fingerprint = this.fingerprint
        return json
    }
}
export type ComptrollerJSON = Omit<Comptroller, 'toJSON'>

class Screen extends BaseModel {
    static newScreen(): Screen {
        return new Screen({
            name: '',
            comptrollerId: undefined,
            resolution: '',
            state: ScreenState.unknown,
            tilesWide: 0,
            tilesHigh: 0,
            tileWidth: 0,
            tileHeight: 0,
            tileType: null,
            logoBoxLocation: null,
            sortIndex: 0,
            aviorId: undefined,
            hasCamera: false,
            organisationId: '',
            contentOwnerId: null,
            operationsManagerId: null,
            enabled: false,
            isConnected: false,
            isTest: false,
            scheduledDowntime: [],
            minFirmware: '',
            maxFirmware: '',
            playerName: '',
            playlistMd5: '',
            playlistInSync: false,
            playerSyncState: '',
            comptroller: undefined,
            stats: ScreenStats.newScreenStats(),
            labels: undefined,
            location: undefined,
            manufacturer: Manufacturer.candelic,
            outageReason: undefined,
            stoppageReason: undefined,
            currentConfiguration: undefined,
            geometryString: '',
        })
    }

    static get store(): DomainStore<Screen> {
        return rootStore.screenStore
    }

    @observable name: string
    @observable comptrollerId?: string
    @observable resolution?: string
    @observable state: ScreenState
    @observable tilesWide: number
    @observable tilesHigh: number
    @observable tileWidth: number
    @observable tileHeight: number
    @observable tileType: TileType | null
    @observable logoBoxLocation?: LogoBoxLocation | null
    @observable sortIndex?: number
    @observable organisationId: string
    @observable aviorId?: string
    @observable aviorPassword?: string
    @observable hasCamera: boolean
    @observable contentOwnerId?: string | null
    @observable operationsManagerId?: string | null
    @observable enabled: boolean
    @observable isConnected: boolean
    @observable isTest: boolean
    @observable scheduledDowntime: string[][]
    @observable minFirmware: string
    @observable maxFirmware: string
    @observable playerName: string
    @observable playlistMd5: string
    @observable playlistInSync: boolean
    @observable lastPlaylistUpdate?: moment.Moment
    @observable playerSyncState: string
    @observable comptroller?: Comptroller
    @observable stats: ScreenStats
    @observable labels?: {
        [uuid: string]: string
    }
    @observable location?: [number, number]
    @observable manufacturer: Manufacturer
    @observable outageReason?: OutageReason
    @observable stoppageReason?: StoppageReason
    @observable currentConfiguration?: ScreenConfiguration
    @observable geometryString: string

    constructor(json: ScreenJSON) {
        super(json)

        this.name = json.name
        this.comptrollerId = json.comptrollerId
        this.resolution = json.resolution
        this.state = json.state
        this.tilesWide = json.tilesWide
        this.tilesHigh = json.tilesHigh
        this.tileWidth = json.tileWidth
        this.tileHeight = json.tileHeight
        this.tileType = json.tileType
        this.logoBoxLocation = json.logoBoxLocation
        this.organisationId = json.organisationId
        this.aviorId = json.aviorId
        this.hasCamera = json.hasCamera
        this.contentOwnerId = json.contentOwnerId
        this.operationsManagerId = json.operationsManagerId
        this.enabled = json.enabled
        this.isTest = json.isTest
        this.isConnected = json.isConnected
        this.scheduledDowntime = json.scheduledDowntime
        this.minFirmware = json.minFirmware
        this.maxFirmware = json.maxFirmware
        this.playerName = json.playerName
        this.playlistMd5 = json.playlistMd5
        this.playlistInSync = json.playlistInSync
        this.playerSyncState = json.playerSyncState
        this.stats = json.stats ? new ScreenStats(json.stats) : ScreenStats.newScreenStats()
        this.comptroller = json.comptroller ? new Comptroller(json.comptroller) : undefined
        this.labels = json.labels
        this.location = json.location
        this.manufacturer = Manufacturer[json.manufacturer as keyof typeof Manufacturer]
        this.outageReason = json.outageReason
        this.stoppageReason = json.stoppageReason
        this.currentConfiguration = json.currentConfiguration
        this.geometryString = json['geometry'] || ''
    }

    @computed get geometry(): Geometry[] {
        return this.geometryString === '' ? [] : JSON.parse(this.geometryString)
    }
    @computed get manufacturerName(): string {
        return this.manufacturer === Manufacturer.novaStar ? 'NovaStar' : 'Candelic'
    }

    @computed get organisation(): Organisation | undefined {
        return rootStore.orgStore.findItem(this.organisationId)
    }

    @computed get hasCompletedDataFields(): boolean {
        return this.name !== ''
    }

    @computed get width(): number {
        const split = this.resolution!.split('x', 2)
        return Number(split[0])
    }

    @computed get height(): number {
        const split = this.resolution!.split('x', 2)
        return Number(split[1])
    }

    @computed get aspectRatio(): number {
        return this.width / this.height
    }

    @computed get errorGroup(): ScreenErrorGroup | undefined {
        if (!this.id || !rootStore.diagnosticsStore.errorsContainer) {
            return undefined
        }
        return rootStore.diagnosticsStore.errorsContainer.diagnosticsErrors.get(this.id)
    }

    @computed get unassignedErrorCount(): number {
        if (!this.errorGroup) {
            return 0
        }
        return this.errorGroup.totalErrors - this.errorGroup.totalAssignedErrors
    }

    @computed get errorCount(): number {
        if (!this.errorGroup) {
            return 0
        }
        return this.errorGroup.totalErrors
    }

    @computed get maxSeverity(): ErrorSeverity {
        if (!this.errorGroup) {
            return ErrorSeverity.noError
        }
        return this.errorGroup.maxSeverity
    }

    @computed get maxUnassignedSeverity(): ErrorSeverity {
        if (!this.errorGroup) {
            return ErrorSeverity.noError
        }
        return this.errorGroup.maxUnassignedSeverity
    }

    @computed get severityColour(): string {
        if (!this.isConnected) {
            return severityColour(ErrorSeverity.noConnection)
        }

        let colour = severityColour(this.maxUnassignedSeverity) as string
        if (this.state !== ScreenState.started) {
            colour = darkenColor(colour, 35)
        }
        return colour
    }

    @computed get brightness(): number {
        return this.errorGroup?.brightness ?? 0
    }

    @computed get isOwned(): boolean {
        return rootStore.authStore.isAdminsOrg(this.organisationId)
    }

    @computed get canEdit(): boolean {
        return this.isOwned || this.organisation?.permissions === OrganisationPermission.write
    }

    @computed get isEnabledAndReady(): boolean {
        return this.enabled && !!this.errorGroup
    }

    @computed get hasAvior(): boolean {
        return !!this.aviorId
    }

    @computed get geometryDisplay(): string {
        // old functionality
        if (this.geometry.length === 0) {
            return `${this.tilesWide}x${this.tilesHigh} ${this.tileWidth}x${this.tileHeight}`
        }
        // if the geometry items have a uniform w and h, then use those values. Otherwise, use 'Mixed'
        const width = this.geometry[0].w
        const height = this.geometry[0].h
        const isUniform = this.geometry.every(g => g.w === width && g.h === height)
        const tileString = isUniform ? `${width}x${height}` : 'Mixed'
        return `${this.tilesWide}x${this.tilesHigh} ${tileString}`
    }

    @computed get contentOwner(): Organisation | undefined {
        const me = rootStore.userStore.me
        const myOrg = rootStore.orgStore.myOrg

        if (!me || !myOrg || !this.contentOwnerId) {
            return undefined
        }

        if (me.isSuperUser) {
            return rootStore.orgStore.findItem(this.contentOwnerId)
        } else if (myOrg.id === this.contentOwnerId) {
            return myOrg
        } else {
            return myOrg.associatedOrganisations?.find(organisation => organisation.id === this.contentOwnerId)
        }
    }

    @computed get operationsManager(): Organisation | undefined {
        const me = rootStore.userStore.me
        const myOrg = rootStore.orgStore.myOrg

        if (!me || !myOrg || !this.operationsManagerId) {
            return undefined
        }

        if (me.isSuperUser) {
            return rootStore.orgStore.findItem(this.operationsManagerId)
        } else if (myOrg.id === this.operationsManagerId) {
            return myOrg
        } else {
            return myOrg.associatedOrganisations?.find(organisation => organisation.id === this.operationsManagerId)
        }
    }

    @computed get isAttached(): boolean {
        return !!this.comptroller
    }

    @computed get firmwareRange(): string | undefined {
        if (!this.isAttached) {
            return undefined
        }
        if (this.minFirmware === '' || this.maxFirmware === '') {
            return ''
        }
        return this.maxFirmware === this.minFirmware ? this.maxFirmware : `${this.minFirmware} - ${this.maxFirmware}`
    }

    @computed get supportsConsoleSubscription(): boolean {
        if (!this.isAttached) {
            return false
        }
        return true
    }

    @computed get connectDaemonStatus(): string {
        // if (!this.isAttached || !this.comptroller!.connectDaemon) {
        //     return i18n.t('diagnosticsPage.daemonNotRunning')
        // }
        return i18n.t('diagnosticsPage.daemonRunning')
    }

    @computed get lednetDaemonStatus(): string {
        if (!this.isAttached || !this.comptroller!.lednetDaemon) {
            return i18n.t('diagnosticsPage.daemonNotRunning')
        }
        return this.comptroller!.lednetDaemonVersion || i18n.t('diagnosticsPage.daemonRunning')
    }

    @computed get labelKeys(): string[] {
        // return this.organisation?.screenLabels || {}
        return Object.keys(this.organisation?.screenLabels || {})
    }

    @computed get labelKeyValuePairs(): Array<{ key: string; value: string }> {
        return this.labelKeys.map(key => ({
            key: this.organisation?.screenLabels?.[key] || '',
            value: this.labels?.[key] || '',
        }))
    }

    @computed get basicStatTypes(): BasicDiagnosticsStatType[] {
        switch (this.manufacturer) {
            case Manufacturer.candelic:
                return [
                    BasicDiagnosticsStatType.tnl,
                    BasicDiagnosticsStatType.errors,
                    BasicDiagnosticsStatType.strands,
                    BasicDiagnosticsStatType.model,
                    BasicDiagnosticsStatType.size,
                ]
            case Manufacturer.novaStar:
                return [BasicDiagnosticsStatType.tnl, BasicDiagnosticsStatType.errors, BasicDiagnosticsStatType.size]
        }
    }

    @computed get controllerStatTypes(): ControllerStatType[] {
        switch (this.manufacturer) {
            case Manufacturer.candelic:
                return [
                    ControllerStatType.serial,
                    ControllerStatType.firmware,
                    ControllerStatType.factory,
                    ControllerStatType.incompleteFrames,
                    ControllerStatType.shortFrames,
                    ControllerStatType.longFrames,
                    ControllerStatType.vsyncDrops,
                    ControllerStatType.vsyncPackets,
                    ControllerStatType.minVsync,
                    ControllerStatType.avgVsync,
                    ControllerStatType.maxVsync,
                    ControllerStatType.videoPackets,
                    ControllerStatType.ledFirmware,
                    ControllerStatType.ocLedTest,
                    ControllerStatType.ledChainErrors,
                    ControllerStatType.ledTemp,
                    ControllerStatType.localTemp,
                    ControllerStatType.localVref,
                    ControllerStatType.link,
                    ControllerStatType.statsReceived,
                    ControllerStatType.statusReceived,
                    ControllerStatType.lastComms,
                    ControllerStatType.uptime,
                    ControllerStatType.totalUptime,
                    ControllerStatType.runtime,
                    ControllerStatType.totalRuntime,
                    ControllerStatType.inventory,
                    ControllerStatType.ledVdda,
                    ControllerStatType.minPhase,
                    ControllerStatType.avgPhase,
                    ControllerStatType.stackHeadroom,
                    ControllerStatType.gclkUnlock,
                    ControllerStatType.proof,
                    ControllerStatType.debug,
                ]
            case Manufacturer.novaStar:
                return [
                    ControllerStatType.serial,
                    ControllerStatType.localTemp,
                    ControllerStatType.localVref,
                    ControllerStatType.lastComms,
                ]
        }
    }

    @computed get screenStatusString(): string {
        if (!this.isConnected) {
            if (this.outageReason) {
                return AlertReasonShortDescriptions[this.outageReason]
            }
            return 'Outage'
        } else {
            switch (this.state) {
                case ScreenState.started:
                    return 'Started'
                case ScreenState.enumerated:
                    return 'Enumerated'
                case ScreenState.stopped:
                    if (this.stoppageReason) {
                        return AlertReasonShortDescriptions[this.stoppageReason]
                    }
                    return 'Stopped'
                default:
                    return 'Unknown'
            }
        }
    }

    @computed get connectAvailable(): boolean {
        if (!this.isConnected) {
            if (!this.outageReason || this.outageReason === OutageReason.network) {
                return false
            }
        }
        return true
    }

    @computed get updaterAvailable(): boolean {
        return this.connectAvailable && this.isAttached /* && this.comptroller!.connectDaemon */
    }

    @computed get updaterNotAvailableMessage(): string | undefined {
        if (!this.updaterAvailable) {
            if (!this.connectAvailable) {
                return 'Screen not connected'
            }
            if (!this.isAttached) {
                return 'Screen not attached'
            }
            // if (!this.comptroller!.connectDaemon) {
            //     return 'Connect daemon not running'
            // }
        }
        return undefined
    }

    toJSON(includeDates?: boolean): ScreenJSON {
        const json = super.toJSON(includeDates) as ScreenJSON & {
            fingerprint: string | null
        }
        json.name = this.name
        json.tilesWide = this.tilesWide
        json.tilesHigh = this.tilesHigh
        json.tileWidth = this.tileWidth
        json.tileHeight = this.tileHeight
        json.tileType = this.tileType
        json.comptrollerId = this.comptrollerId
        json.organisationId = this.organisationId
        json.contentOwnerId = this.contentOwnerId
        json.operationsManagerId = this.operationsManagerId
        json.enabled = this.enabled
        json.isTest = this.isTest
        json.resolution = this.resolution
        json.aviorId = this.aviorId
        json.aviorPassword = this.aviorPassword
        json.hasCamera = this.hasCamera
        json.scheduledDowntime = this.scheduledDowntime
        json.minFirmware = this.minFirmware
        json.maxFirmware = this.maxFirmware
        json.playerName = this.playerName
        json.playlistMd5 = this.playlistMd5
        json.playlistInSync = this.playlistInSync
        json.playerSyncState = this.playerSyncState
        json.fingerprint = this.comptroller?.fingerprint || null
        json.labels = this.labels
        json.location = this.location
        json.manufacturer = Manufacturer[this.manufacturer as keyof typeof Manufacturer]

        return json
    }
}

export type ScreenJSON = Omit<
    Screen,
    | 'toJSON'
    | 'isValid'
    | 'hasCompletedDataFields'
    | 'width'
    | 'height'
    | 'aspectRatio'
    | 'errorGroup'
    | 'errorCount'
    | 'maxSeverity'
    | 'geometry'
    | 'geometryDisplay'
    | 'maxUnassignedSeverity'
    | 'severityColour'
    | 'brightness'
    | 'isOwned'
    | 'canEdit'
    | 'canDelete'
    | 'isEnabledAndReady'
    | 'hasAvior'
    | 'organisation'
    | 'contentOwner'
    | 'operationsManager'
    | 'isAttached'
    | 'firmwareRange'
    | 'supportsConsoleSubscription'
    | 'lednetDaemonStatus'
    | 'connectDaemonStatus'
    | 'labelKeys'
    | 'labelKeyValuePairs'
    | 'manufacturerName'
    | 'basicStatTypes'
    | 'controllerStatTypes'
    | 'unassignedErrorCount'
    | 'screenStatusString'
    | 'connectAvailable'
    | 'updaterAvailable'
    | 'updaterNotAvailableMessage'
>

export default Screen
