import { Position, Toaster } from '@blueprintjs/core'
// Singleton toaster instance. Create separate instances for different options

// Toaster for temporary app-wide messages
export const AppToaster = Toaster.create({
    className: 'app-toaster',
    position: Position.BOTTOM_RIGHT,
})

// Toaster for important and undismissable app-wide banner
export const AppBanner = Toaster.create({
    className: 'banner-toaster',
    position: Position.TOP,
})

// Toaster for rendering toasts outside of the parent DOM body
export const ExternalToaster = (container: HTMLElement) => Toaster.create(
        {
            position: Position.BOTTOM_RIGHT,
        },
        container
    )
