import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

interface LockedOverlayProps {
    isFullscreen?: boolean
    style?: React.CSSProperties
}

@inject('store')
@observer
class LockedOverlay extends React.Component<LockedOverlayProps & { store?: RootStore }> {
    render() {
        const { isFullscreen, style, children } = this.props
        return (
            <div className={'locked-overlay' + (isFullscreen ? ' fullscreen-overlay' : '')} style={style}>
                {children}
            </div>
        )
    }
}

export default LockedOverlay
