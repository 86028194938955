import * as React from 'react'

// i18n
import i18n from 'src/i18n'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

import HelpLabel from 'src/common/components/HelpLabel'
import { Form, InputGroup, Button } from 'reactstrap'
import DatePicker from 'src/common/components/DatePicker'
import { Boundary } from '@blueprintjs/core'
import moment from 'moment'
import 'moment-timezone'

@inject('store')
@observer
export default class GlobalDigestScope extends React.Component<{ store?: RootStore }> {
    state: {
        boundary: Boundary | undefined
    } = {
        boundary: undefined,
    }

    handleRangeChange = (newDateRange: [moment.Moment | undefined, moment.Moment | undefined]) => {
        this.props.store!.globalDiagnosticsUIStore.updateDateRange(newDateRange)
    }

    handleBoundaryChange = (boundary: Boundary | undefined) => {
        this.setState({ boundary })
    }

    handleScreenErrorLogScopeSubmit = () => {
        this.props.store!.globalDiagnosticsUIStore.getDigest()
    }

    render() {
        const dateRange = this.props.store!.globalDiagnosticsUIStore.dateRange
        const startDate = dateRange?.[0]
        const endDate = dateRange?.[1]
        // Allow selecting back one month from end date
        const minDate =
            this.state.boundary === Boundary.START && endDate
                ? endDate
                      .clone()
                      .subtract(1, 'month')
                      .startOf('day')
                : undefined
        // Allow selecting forward one calendar month from state date, as long as it's not in the future
        // Fallback to now
        let maxDate =
            this.state.boundary === Boundary.END && startDate
                ? startDate
                      .clone()
                      .add(1, 'month')
                      .endOf('day')
                : moment()
        if (maxDate.isAfter(moment())) {
            // Prevent max date going beyond now
            maxDate = moment()
        }

        return (
            <Form className='datepicker-inline' inline onSubmit={eventPreventDefault}>
                <InputGroup className='custom-input-group custom-input-group-inline'>
                    <HelpLabel
                        label={
                            i18n.t('diagnosticsPage.errorHistoryScopeRange') +
                            ' (' +
                            moment.tz(this.props.store!.userStore.me!.timeZone).format('z') +
                            ')'
                        }
                    />
                    <DatePicker
                        handleChange={this.handleRangeChange}
                        handleBoundaryChange={this.handleBoundaryChange}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        shortcuts={[
                            {
                                label: i18n.t('common.dates.shortcuts.24Hours'),
                                includeTime: true,
                                dateRange: [
                                    moment()
                                        .subtract(24, 'hours')
                                        .toDate(),
                                    moment().toDate(),
                                ],
                            },
                            {
                                label: i18n.t('common.dates.shortcuts.48Hours'),
                                includeTime: true,
                                dateRange: [
                                    moment()
                                        .subtract(48, 'hours')
                                        .toDate(),
                                    moment().toDate(),
                                ],
                            },
                            {
                                label: i18n.t('common.dates.shortcuts.72Hours'),
                                includeTime: true,
                                dateRange: [
                                    moment()
                                        .subtract(72, 'hours')
                                        .toDate(),
                                    moment().toDate(),
                                ],
                            },
                            {
                                label: i18n.t('common.dates.shortcuts.thisWeek'),
                                includeTime: true,
                                dateRange: [
                                    moment()
                                        .startOf('isoWeek') // isoWeek uses Monday as start day
                                        .toDate(),
                                    moment().toDate(),
                                ],
                            },
                            {
                                label: i18n.t('common.dates.shortcuts.thisMonth'),
                                includeTime: true,
                                dateRange: [
                                    moment()
                                        .startOf('month')
                                        .toDate(),
                                    moment().toDate(),
                                ],
                            },
                        ]}
                    />
                </InputGroup>
                <InputGroup className='scope-button'>
                    <Button
                        onClick={this.handleScreenErrorLogScopeSubmit}
                        className='custom-button-large'
                        color='primary'
                        disabled={!(endDate && startDate)}
                    >
                        {i18n.t('actions.apply')}
                    </Button>
                </InputGroup>
            </Form>
        )
    }
}
