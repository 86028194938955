import AdminStoreItem from './AdminStoreItem'
import User from 'src/common/models/User'
import { action, computed, observable } from 'mobx'
import { checkEmail } from 'src/common/utils/Regex'
import phone from 'phone'
import RootStore from 'src/common/RootStore'
import { RoleType } from 'src/common/models/Roles'
import _ from 'lodash'

export default class AdminStoreUser extends AdminStoreItem<User> {
    @observable emailFieldFocused: boolean = false
    @observable phoneFieldFocused: boolean = false
    @observable emailInputValid: boolean = true
    @observable phoneInputValid: boolean = true

    @observable selectedRoles: Set<RoleType>

    orgId?: string
    me?: User

    constructor(rootStore: RootStore) {
        super()
        this.store = rootStore.userStore
        this.orgId = rootStore.userStore.me!.isSuperUser
            ? rootStore.adminUIStore.selectedOrg?.id
            : rootStore.orgStore.myOrg!.id
        this.me = rootStore.userStore.me || undefined
    }

    @computed get isEmailValid(): boolean {
        return this.emailFieldFocused || this.emailInputValid
    }

    @computed get isPhoneValid(): boolean {
        return this.phoneFieldFocused || this.phoneInputValid
    }

    @computed get selfPermissionsChanged(): boolean {
        if (!this.item || !this.item.roles || !this.me || this.item.id !== this.me.id) {
            return false
        }

        return this.item.roles.length > 0 && !_.isEqual(this.item.roles, this.me.roles)
    }

    @action setNewItem = () => {
        this.item = User.newUser()
        this.item.organisationId = this.orgId || ''
    }

    @action setItemToUpdate = (user: User) => {
        this.item = new User(user)
    }

    @action updateItemForm = (field: string, value: any) => {
        // Form was updated
        this.formUpdated = true
        switch (field) {
            case 'email':
                this.item!.email = value
                this.emailInputValid = checkEmail(value)
                break
            case 'phone':
                // Sanitise phone input
                this.item!.phone = phone(value)[0]
                this.phoneInputValid = phone(value).length > 0 || value === ''
                break
            case 'firstName':
                this.item!.firstName = value
                break
            case 'lastName':
                this.item!.lastName = value
                break
            case 'userRole':
                const roles = this.selectedRoles || new Set<RoleType>(this.item!.roles)
                if (value.isChecked) {
                    roles.add(value.role)

                    if (value.role === RoleType.superUser) {
                        roles.add(RoleType.admin)
                        roles.add(RoleType.contentUser)
                        roles.add(RoleType.operator)
                        roles.add(RoleType.audit)
                        this.updateItemForm('hasConsoleAccess', true)
                    }
                } else {
                    roles.delete(value.role)
                }
                this.selectedRoles = roles
                this.item!.roles = Array.from(roles)
                break
            case 'hasConsoleAccess':
                this.item!.hasConsoleAccess = value
                break
            default:
                return
        }

        // If all required form fields are filled and valid, form can be submitted
        this.createItemFormValid = this.item!.hasCompletedDataFields && this.emailInputValid
    }

    // Set state of form field focus
    @action setFormFieldFocused = (field: string, value: boolean) => {
        switch (field) {
            case 'email':
                this.emailFieldFocused = value
                break
            case 'phone':
                this.phoneFieldFocused = value
                break
        }
    }
}
