import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'
import imageBroken from 'src/assets/images/imageBroken.svg'
import DisplayRecord from 'src/common/models/DisplayRecord'
import moment from 'moment'

interface CameraImageCardProps {
    store: RootStore
    displayRecord: DisplayRecord
    displayType: string
}

function CameraImageCard(props: CameraImageCardProps) {
    const { store, displayRecord } = props

    const [loading, setLoading] = useState(true)
    const [imageLoaded, setImageLoaded] = useState(false)
    const screenStore = store.screenStore
    const name =
        (props.displayType === 'byCreative'
            ? screenStore.findItem(displayRecord.screenId)?.name
            : displayRecord.filename) || 'Unknown'

    useEffect(() => {
        // Timeout if image is not found within 10 seconds
        const timeout = setTimeout(() => {
            setLoading(false)
            setImageLoaded(true)
        }, 10 * 1000)

        return () => clearTimeout(timeout)
    }, [])

    useEffect(() => {
        if (loading && imageLoaded) {
            setLoading(false)
        }
    }, [loading, imageLoaded])

    const me = store?.userStore.me
    if (!me) {
        return null
    }

    const imageSrc = displayRecord?.url || imageBroken

    useEffect(() => {
        if (displayRecord?.url) {
            const image = new Image()
            image.src = displayRecord.url
            image.onload = () => {
                setImageLoaded(true)
            }
            image.onerror = () => {
                setImageLoaded(true)
                setLoading(false)
            }
        }
    }, [displayRecord])

    return (
        <div className='camera-image p-2'>
            {loading && !imageLoaded && (
                <div className='waiting mb-2'>
                    <span>Waiting...</span>
                </div>
            )}
            {(!loading || imageLoaded) && (
                <div className={`image-container ${imageSrc === imageBroken ? '' : 'hidden'}`}>
                    <img
                        className={`mb-2 ${imageSrc === imageBroken ? '' : 'hidden'} ${imageLoaded ? 'loaded' : ''}`}
                        src={imageSrc}
                        alt='Camera Image'
                        style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                    />
                </div>
            )}
            <div className={`image-info ${loading && !imageLoaded ? '' : 'hidden'}`}>
                <span className='text-black ellipsis'>
                    {moment(displayRecord.startTime).format(me.dateTimeFormat)} {moment.tz(me.timeZone).format('z')}
                </span>
                <div className='camera-image-file-name'>
                    <h6 className='text-black ellipsis'>{name}</h6>
                </div>
            </div>
        </div>
    )
}

export default observer(CameraImageCard)
