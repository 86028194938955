import { action } from 'mobx'
import RootStore from '../RootStore'

import i18n from 'src/i18n'

import lednetVersionRouter from 'src/api/lednetVersionRouter'

import { AppToaster } from '../components/AppToaster'
import { Intent } from '@blueprintjs/core'
import moment from 'moment'
import connectVersionRouter from 'src/api/connectVersionRouter'
import { SoftwareUpdateOptions } from 'src/modules/admin/stores/AdminUIStore'

export enum AdminBatchAction {
    connect = 'Connect',
    lednet = 'LEDNet',
    system = 'System',
    pcap = 'USBPcap',
    axis = 'AxisMediaParser',
    custom = 'Custom',
}

export interface LednetVersion {
    version: string
    prerelease: boolean
    description: string
    createdAt: moment.Moment
}

export interface ConnectVersion {
    version: string
    prerelease: boolean
    description: string
    createdAt: moment.Moment
}

export default class AdminStore {
    rootStore: RootStore
    lednetRouter = lednetVersionRouter
    connectRouter = connectVersionRouter

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    getLednetVersions() {
        return this.lednetRouter.getLednetVersions()
    }

    getConnectVersions() {
        return this.connectRouter.getConnectVersions()
    }

    @action updateLednetVersions = (comptrollerIds: string[], version: string) =>
        this.lednetRouter
            .updateLednetVersions(comptrollerIds, version)
            .then(() => {
                AppToaster.show({
                    message: i18n.t('feedback.successes.lednetUpdatedSuccessfully'),
                    intent: Intent.SUCCESS,
                })
            })
            .catch(err => {
                console.error(err)

                AppToaster.show({
                    message: i18n.t('feedback.errors.errorUpdatingLednet'),
                    intent: Intent.DANGER,
                })
            })

    @action updateConnectVersions = (comptrollerIds: string[], version: string) =>
        this.connectRouter
            .updateConnectVersions(comptrollerIds, version)
            .then(() => {
                AppToaster.show({
                    message: i18n.t('feedback.successes.connectUpdatedSuccessfully'),
                    intent: Intent.SUCCESS,
                })
            })
            .catch(err => {
                console.error(err)

                AppToaster.show({
                    message: i18n.t('feedback.errors.errorUpdatingConnect'),
                    intent: Intent.DANGER,
                })
            })

    @action updateSoftware = (
        comptrollerIds: string[],
        software: AdminBatchAction,
        softwareOptions: SoftwareUpdateOptions
    ) => {
        this.connectRouter
            .updateSoftware(comptrollerIds, software, softwareOptions)
            .then(() => {
                AppToaster.show({
                    message: i18n.t('feedback.successes.softwareUpdatedSuccessfully'),
                    intent: Intent.SUCCESS,
                })
            })
            .catch(err => {
                console.error(err)

                AppToaster.show({
                    message: i18n.t('feedback.errors.errorUpdatingSoftware'),
                    intent: Intent.DANGER,
                })
            })
    }
}
