import { observable, computed } from 'mobx'
import BaseModel from './BaseModel'
import { Omit } from 'react-router'
import moment from 'moment'

class DisplayImageStats extends BaseModel {
    static newDisplayImageStats(): DisplayImageStats {
        return new DisplayImageStats({
            totalTime: 0,
            averageTime: 0,
            totalPlays: 0,
            totalScreens: 0,
            lastPlayed: moment(),
            firstPlayed: moment(),
        })
    }

    @observable screenId?: string
    @observable totalTime: number
    @observable averageTime: number
    @observable totalPlays: number
    @observable totalScreens: number
    @observable lastPlayed: moment.Moment
    @observable firstPlayed: moment.Moment

    constructor(json: DisplayImageStatsJSON) {
        super(json)
        this.screenId = json.screenId
        this.totalTime = json.totalTime
        this.averageTime = json.averageTime
        this.totalPlays = json.totalPlays
        this.totalScreens = json.totalScreens
        this.lastPlayed = json.lastPlayed
        this.firstPlayed = json.firstPlayed
    }

    @computed get hasCompletedDataFields(): boolean {
        return true
    }

    toJSON(includeDates?: boolean): DisplayImageStatsJSON {
        const json = super.toJSON(includeDates) as DisplayImageStatsJSON
        json.screenId = this.screenId
        json.totalTime = this.totalTime
        json.averageTime = this.averageTime
        json.totalPlays = this.totalPlays
        json.totalScreens = this.totalScreens
        json.lastPlayed = this.lastPlayed
        json.firstPlayed = this.firstPlayed
        return json
    }
}

export type DisplayImageStatsJSON = Omit<
    DisplayImageStats,
    'toJSON' | 'isValid' | 'hasCompletedDataFields' | 'isOwned' | 'canEdit' | 'canDelete'
>

export default DisplayImageStats
