import * as React from 'react'

// i18n
import i18n from 'src/i18n'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import views from 'src/config/views'

import DiagnosticsErrorGroup from 'src/common/models/DiagnosticsErrorGroup'
import DiagnosticsError from 'src/common/models/DiagnosticsError'
import { ErrorDataKey } from 'src/common/models/DiagnosticErrorEnums'

import { AutoSizer, Index, TableCellDataGetterParams } from 'react-virtualized'
import BaseVirtualizedTable, { BaseVirtualizedTableCol } from 'src/common/components/virtualized/BaseVirtualizedTable'
import ErrorDataCell from './tableComponents/ErrorDataCell'
import ErrorAssignedCell from './tableComponents/ErrorAssignedCell'
import moment from 'moment'

interface ErrorTableProps {
    errorGroup: DiagnosticsErrorGroup
}

@inject('store')
@observer
class ErrorTable extends React.Component<ErrorTableProps & { store?: RootStore }> {
    getName = ({ rowData }: TableCellDataGetterParams): string | undefined => {
        const error: DiagnosticsError = rowData
        return error?.errorLabel
    }

    getTimestamp = ({ rowData }: TableCellDataGetterParams): string | null => {
        const error: DiagnosticsError = rowData
        if (!error) {
            return null
        }
        return moment(error.time).format(this.props.store!.userStore.me?.mobileAdjustedTimeFormat)
    }

    handleErrorClick = ({ index }: Index) => {
        const error = this.props.errorGroup.errorListForDiagnostics[index]
        if (!error) {
            return
        }

        const router = this.props.store!.router
        router.goTo(views.diagnosticsScreen, router.params, this.props.store!, {
            errorHistory: error.hash,
        })
    }

    render() {
        const errorGroup = this.props.errorGroup
        if (!errorGroup || !this.props.errorGroup.errorListForDiagnostics) {
            return null
        }

        const errorDataTitle = this.props.store!.appUIStore.isMobile ? 'errorDataAbbr.' : 'errorData.'

        let firstCol: BaseVirtualizedTableCol[] = []
        // Check if first error has adapterId prop
        if (this.props.errorGroup.errorListForDiagnostics.length > 0) {
            if (this.props.errorGroup.errorListForDiagnostics[0].adapterId) {
                // Add adapter name to first column of table
                firstCol = [
                    {
                        dataKey: ErrorDataKey.adapter,
                        label: i18n.t('diagnosticsPage.' + errorDataTitle + ErrorDataKey.adapter),
                        minWidth: 50,
                        cellDataGetter: this.getName,
                    },
                ]
            } else if (this.props.errorGroup.errorListForDiagnostics[0].tileId) {
                firstCol = [
                    {
                        dataKey: ErrorDataKey.tnl,
                        label: i18n.t('diagnosticsPage.' + errorDataTitle + ErrorDataKey.tnl),
                        minWidth: 50,
                        cellDataGetter: this.getName,
                    },
                ]
            }
        }

        const dataCols: BaseVirtualizedTableCol[] = []
        // Check if first error has data prop
        if (
            this.props.errorGroup.errorListForDiagnostics.length > 0 &&
            this.props.errorGroup.errorListForDiagnostics[0].data
        ) {
            for (let key of Object.keys(this.props.errorGroup.errorListForDiagnostics[0].data).reverse()) {
                if (ErrorDataKey[key] === undefined) {
                    // there seems to be an issue with adapterLocalFirmwareMismatch where the data is an empty string key
                    // TODO: Ask Ian about this
                    key = 'versions'
                }
                dataCols.push({
                    dataKey: ErrorDataKey[key],
                    label: i18n.t('diagnosticsPage.' + errorDataTitle + 'data.' + ErrorDataKey[key]),
                    minWidth: 70,
                    flexGrow: 1,
                    cellElement: (_, rowData: DiagnosticsError) => (
                        <ErrorDataCell
                            dataKey={ErrorDataKey[key]}
                            data={rowData.data ? rowData.data[ErrorDataKey[key]] : undefined}
                        />
                    ),
                })
            }
        }

        const cols: BaseVirtualizedTableCol[] = [
            ...firstCol,
            ...dataCols,
            {
                dataKey: ErrorDataKey.time,
                label: i18n.t('diagnosticsPage.' + errorDataTitle + ErrorDataKey.time),
                minWidth: 80,
                flexGrow: 1,
                cellDataGetter: this.getTimestamp,
            },
            {
                dataKey: ErrorDataKey.isAssigned,
                label: i18n.t('diagnosticsPage.' + errorDataTitle + ErrorDataKey.isAssigned),
                minWidth: 40,
                maxWidth: 90,
                className: 'assigned-cell-wrapper',
                cellElement: (cellData: boolean, rowData: DiagnosticsError) => (
                    <ErrorAssignedCell isAssigned={cellData} error={rowData} />
                ),
            },
        ]

        // Disable sorting on headers
        cols.forEach(col => {
            col.disableSort = true
        })

        const rows: DiagnosticsError[] = this.props.errorGroup.errorListForDiagnostics

        const data = {
            assignedErrors: rows.map(row => row.isAssigned),
            totalErrors: this.props.errorGroup.totalErrors,
            data: rows,
        }

        return (
            <div className='error-table'>
                <AutoSizer>
                    {({ width, height }) => (
                        <BaseVirtualizedTable
                            cols={cols}
                            rows={rows}
                            width={width}
                            height={height}
                            rowHeight={34}
                            headerHeight={32}
                            data={data}
                            onRowClick={this.handleErrorClick}
                        />
                    )}
                </AutoSizer>
            </div>
        )
    }
}

export default ErrorTable
