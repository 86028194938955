export const copyToClipboard = (text: string) => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
        navigator.clipboard.writeText(text)
    } else {
        // Workaround for mobile safari
        const activeElement = document.activeElement as HTMLElement
        const newTextarea = document.createElement('textarea')
        newTextarea.className = 'clipboard-copy'
        newTextarea.style.visibility = 'false'
        newTextarea.style.height = '1px'
        newTextarea.style.width = '1px'
        document.body.appendChild(newTextarea)
        newTextarea.value = text
        newTextarea.focus()
        newTextarea.select()
        document.execCommand('copy')
        activeElement.focus()
        document.body.removeChild(newTextarea)
    }
}
