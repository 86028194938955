import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { Link } from 'mobx-router'
import views from 'src/config/views'
import Organisation from 'src/common/models/Organisation'

import OverviewTags from 'src/common/components/OverviewTags'
import { CSSGrid, layout, makeResponsive, measureItems } from 'react-stonecutter'
import RootStore from 'src/common/RootStore'
import { Moment } from 'moment'

@inject('store')
@observer
class StatusGrid extends React.Component<{
    data: Organisation[]
    timestamps: Map<string, Moment>
    store: RootStore
}> {
    Grid = makeResponsive(measureItems(CSSGrid), {
        maxWidth: 99999, // Arbitrarily high value to allow grid to scale
    })

    gridItemRenderer = (org: Organisation, initTimestamp?: Moment) => {
        const updated =
            org.screenStatuses &&
            org.screenStatuses.updatedAt &&
            initTimestamp &&
            org.screenStatuses.updatedAt.isAfter(initTimestamp)

        const screenCount = org.screens.filter(screen => screen.enabled).length

        return (
            <div key={org.id}>
                <Link
                    view={views.diagnosticsScreen}
                    params={{ org: org.id }}
                    store={this.props.store!}
                    router={this.props.store!.router}
                >
                    <div
                        className={
                            'status' +
                            (updated ? (org.screenStatuses!.isConcern ? ' glow-danger' : ' glow-success') : '')
                        }
                    >
                        <div className='mission-control-title'>
                            <div className='mission-control-org'>
                                <h3>{org.name}</h3>
                                <div className='overview-tag'>{`${screenCount} screen${
                                    screenCount !== 1 ? 's' : ''
                                }`}</div>
                            </div>
                            <OverviewTags statuses={org.screenStatuses} fullTags usePortal />
                        </div>
                        <div className='mission-control-logo'>
                            {org.imageURL && <img src={org.imageURL} alt={org.name} />}
                        </div>
                    </div>
                </Link>
            </div>
        )
    }

    render() {
        return (
            <div className='mission-control-grid'>
                <this.Grid
                    component='div'
                    columns={Infinity}
                    columnWidth={250}
                    gutterWidth={20}
                    gutterHeight={20}
                    duration={800}
                    layout={layout.pinterest}
                >
                    {this.props.data.map(i => this.gridItemRenderer(i, this.props.timestamps.get(i.id!)))}
                </this.Grid>
            </div>
        )
    }
}

export default StatusGrid
