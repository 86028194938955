import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from '../../../../common/RootStore'

import i18n from 'src/i18n'

import Select from 'react-select'
import { ClearIndicator, DropdownIndicator, Option, ValueLabelPair } from 'src/common/components/SelectComponents'
import { Form, FormGroup, InputGroup, Label, Input, Row, Col, Button, FormText } from 'reactstrap'
import Toggle from 'react-toggle'
import { formIntegerValidation, eventPreventDefault } from 'src/common/utils/EventUtils'
import { TileType, TileSupport, tileTypeSupport, tileTypeName } from 'src/common/models/Tile'
import { Dialog } from '@blueprintjs/core'
import { Manufacturer } from 'src/common/models/Screen'
import { capitalizeFirstLetter } from 'src/common/utils/String'
import { RoleType } from 'src/common/models/Roles'

// TODO: Figure out how data will be saved before doing more work on this form
@inject('store')
@observer
class CreateScreenForm extends React.Component<{ store?: RootStore }> {
    lastItem: Screen

    state = {
        deviceManagementOpen: false,
        accessControlOpen: false,
        originalItemAviorId: undefined,
    }

    UNSAFE_componentWillMount = () => {
        if (!this.props.store!.adminUIStore.isEditMode) {
            this.props.store!.adminUIStore.assetData.screen.setItem()
        }
        this.setState({
            originalItemAviorId: this.props.store?.adminUIStore.assetData.screen.item?.aviorId,
        })
    }

    handleFormValue = (event: any) => {
        const id = event.target.id
        let value = event.target.value
        if (id === 'enabled' || id === 'isTest' || id === 'hasCamera') {
            value = event.target.checked
        }
        // if key is a screen label, update the labels object
        if (this.props.store!.adminUIStore.assetData.screen.item?.labelKeys.includes(id)) {
            this.props.store!.adminUIStore.assetData.screen.updateItemForm('labels', {
                ...this.props.store!.adminUIStore.assetData.screen.item.labels,
                [id]: value,
            })
            return
        }
        this.props.store!.adminUIStore.assetData.screen.updateItemForm(id, value)
    }

    handleTileTypeSelect = (selectedOption: ValueLabelPair) => {
        this.props.store!.adminUIStore.assetData.screen.updateItemForm('tileType', selectedOption?.value)
    }

    handleFormSelect = (field: string, selectedOption: ValueLabelPair) => {
        this.props.store!.adminUIStore.assetData.screen.updateItemForm(
            field,
            selectedOption ? selectedOption.value : undefined
        )
    }

    render() {
        const me = this.props.store!.userStore.me
        if (!me || !me.organisation) {
            return null
        }

        const adminUIStore = this.props.store!.adminUIStore
        const screenItem = adminUIStore.assetData.screen.item
        const selectedOrg = adminUIStore.selectedOrg
        const associatedOrgs = adminUIStore.associatedOrgs
        const associatedOrgsOptions =
            associatedOrgs && associatedOrgs.map(item => ({ value: item.id, label: item.name }))

        const tileTypeOptionsGrouped = Object.values(TileSupport).map(s => ({
            label: s,
            options: Object.values(TileType)
                .filter(t => tileTypeSupport(t) === s)
                .map(t => ({ value: t, label: tileTypeName(t) })),
        }))

        const defaultContentOwnerOption =
            screenItem && screenItem.contentOwner
                ? { value: screenItem.contentOwner.id, label: screenItem.contentOwner.name }
                : undefined

        const defaultOpsManagerOption =
            screenItem && screenItem.operationsManager
                ? { value: screenItem.operationsManager.id, label: screenItem.operationsManager.name }
                : undefined

        return (
            <React.Fragment>
                <Form className='admin-form' onSubmit={eventPreventDefault}>
                    <FormGroup>
                        <Label for='name'>Name</Label>
                        <Input
                            onChange={this.handleFormValue}
                            type='text'
                            id='name'
                            autoComplete='off'
                            defaultValue={screenItem ? screenItem.name : undefined}
                        />
                    </FormGroup>

                    <Row>
                        <Col md={6} sm={12}>
                            <FormGroup>
                                <Label for='tilesWide'>Tiles wide</Label>
                                <Input
                                    onChange={this.handleFormValue}
                                    type='text'
                                    onKeyDown={formIntegerValidation}
                                    id='tilesWide'
                                    autoComplete='off'
                                    defaultValue={
                                        screenItem && screenItem.tilesWide > 0
                                            ? String(screenItem.tilesWide)
                                            : undefined
                                    }
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6} sm={12}>
                            <FormGroup>
                                <Label for='tilesHigh'>Tiles high</Label>
                                <Input
                                    onChange={this.handleFormValue}
                                    type='text'
                                    onKeyDown={formIntegerValidation}
                                    id='tilesHigh'
                                    autoComplete='off'
                                    defaultValue={
                                        screenItem && screenItem.tilesHigh > 0
                                            ? String(screenItem.tilesHigh)
                                            : undefined
                                    }
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label>Manufacturer</Label>
                        <Select
                            className='custom-select-wrapper custom-select-inverted'
                            classNamePrefix='custom-select'
                            isClearable
                            blurInputOnSelect
                            onChange={this.handleFormSelect.bind(this, 'manufacturer')}
                            options={Object.values(Manufacturer).map(m => ({
                                value: m,
                                label: capitalizeFirstLetter(m),
                            }))}
                            defaultValue={
                                screenItem && screenItem.manufacturer
                                    ? {
                                          value: screenItem.manufacturer,
                                          label: screenItem.manufacturerName,
                                      }
                                    : undefined
                            }
                            components={{ ClearIndicator, DropdownIndicator, Option }}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Tile type</Label>
                        <Select
                            className='custom-select-wrapper custom-select-inverted'
                            classNamePrefix='custom-select'
                            isClearable
                            blurInputOnSelect
                            onChange={this.handleTileTypeSelect}
                            options={tileTypeOptionsGrouped}
                            defaultValue={
                                (screenItem?.tileType && {
                                    value: screenItem.tileType,
                                    label: tileTypeName(screenItem.tileType),
                                }) as ValueLabelPair | undefined
                            }
                            components={{ ClearIndicator, DropdownIndicator, Option }}
                        />
                        <FormText>
                            {`Tile type can be found in LEDNet under the Info tab on the Home screen. If you're unsure
                            which product you have, please contact Candelic support or select "Unknown" to enable manual
                            entry of tile dimensions.`}
                        </FormText>
                    </FormGroup>
                    {adminUIStore.assetData.screen.isTileTypeUnknown && (
                        <Row>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <Label for='tileWidth'>Tile width (px)</Label>
                                    <Input
                                        onChange={this.handleFormValue}
                                        type='text'
                                        onKeyDown={formIntegerValidation}
                                        id='tileWidth'
                                        autoComplete='off'
                                        defaultValue={
                                            screenItem && screenItem.tileWidth > 0
                                                ? String(screenItem.tileWidth)
                                                : undefined
                                        }
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={6} sm={12}>
                                <FormGroup>
                                    <Label for='tileHeight'>Tile height (px)</Label>
                                    <Input
                                        onChange={this.handleFormValue}
                                        type='text'
                                        onKeyDown={formIntegerValidation}
                                        id='tileHeight'
                                        autoComplete='off'
                                        defaultValue={
                                            screenItem && screenItem.tileHeight > 0
                                                ? String(screenItem.tileHeight)
                                                : undefined
                                        }
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    )}
                    {!selectedOrg?.isAdvertiserOnly && (
                        <>
                            <h4>Screen labels</h4>
                            <FormText>
                                Labels are used to display custom information for each screen. They can be set in your
                                Organisation Profile.
                            </FormText>
                            <br />
                            {screenItem?.organisation?.screenLabels ? (
                                Object.entries(screenItem.organisation.screenLabels).map(([key, label]) => (
                                    <FormGroup key={key}>
                                        <Label for={key}>{label}</Label>
                                        <Input
                                            onChange={this.handleFormValue}
                                            type='text'
                                            id={key}
                                            autoComplete='off'
                                            defaultValue={screenItem?.labels?.[key] ?? undefined}
                                        />
                                    </FormGroup>
                                ))
                            ) : (
                                <p>No labels set for organisation.</p>
                            )}
                            <br />
                            {selectedOrg?.roles?.includes(RoleType.operator) && (
                                <FormGroup>
                                    <InputGroup>
                                        <Button
                                            className='custom-button'
                                            onClick={() => this.setState({ deviceManagementOpen: true })}
                                            color='primary'
                                        >
                                            Device Management
                                        </Button>
                                    </InputGroup>
                                </FormGroup>
                            )}
                            {(screenItem?.organisation?.isOwned || screenItem?.isOwned) && (
                                <FormGroup>
                                    <InputGroup>
                                        <Button
                                            className='custom-button'
                                            onClick={() => this.setState({ accessControlOpen: true })}
                                            color='primary'
                                        >
                                            Access Control
                                        </Button>
                                    </InputGroup>
                                </FormGroup>
                            )}
                        </>
                    )}

                    <br />
                    <FormGroup>
                        <Label for='enabled'>Screen enabled</Label>
                        <InputGroup>
                            <Toggle
                                defaultChecked={screenItem?.enabled}
                                className='custom-toggle'
                                icons={false}
                                onChange={this.handleFormValue}
                                id='enabled'
                            />
                        </InputGroup>
                        <FormText>
                            Disabling the screen will prevent it from showing up on the diagnostics page.
                        </FormText>
                    </FormGroup>

                    {me.isSuperUser && (
                        <>
                            <hr />
                            <h4>Superuser</h4>
                            <FormGroup>
                                <Label for='isTest'>Test</Label>
                                <InputGroup>
                                    <Toggle
                                        defaultChecked={screenItem?.isTest}
                                        className='custom-toggle'
                                        icons={false}
                                        onChange={this.handleFormValue}
                                        id='isTest'
                                    />
                                </InputGroup>
                                <FormText>
                                    Marking the screen as a test screen will prevent it from showing up in
                                    notifications.
                                </FormText>
                            </FormGroup>
                        </>
                    )}
                </Form>
                <div className='custom-panel-footer-actions'>
                    <Button
                        className='custom-button-large'
                        onClick={adminUIStore.handleFormSave.bind(this, true)}
                        color='primary'
                        disabled={!adminUIStore.assetData.screen.createItemFormValid}
                    >
                        {i18n.t('actions.save')}
                    </Button>
                </div>

                {/* Access Control */}
                <Dialog
                    className='custom-dialog'
                    title='Access Control'
                    onClose={() => this.setState({ accessControlOpen: false })}
                    isOpen={this.state.accessControlOpen}
                    canOutsideClickClose={false}
                >
                    <div className='dialog-content p-4'>
                        <Form className='admin-form' onSubmit={eventPreventDefault}>
                            <FormGroup>
                                <Label>Content Owner</Label>
                                <Select
                                    className='custom-select-wrapper custom-select-inverted'
                                    classNamePrefix='custom-select'
                                    isClearable
                                    blurInputOnSelect
                                    onChange={this.handleFormSelect.bind(this, 'contentOwner')}
                                    options={associatedOrgsOptions}
                                    defaultValue={defaultContentOwnerOption}
                                    components={{ ClearIndicator, DropdownIndicator, Option }}
                                />
                                <FormText>This organisation will have access to the content for this screen.</FormText>
                            </FormGroup>
                            <FormGroup>
                                <Label>Operations Manager</Label>
                                <Select
                                    className='custom-select-wrapper custom-select-inverted'
                                    classNamePrefix='custom-select'
                                    isClearable
                                    blurInputOnSelect
                                    onChange={this.handleFormSelect.bind(this, 'operationsManager')}
                                    options={associatedOrgsOptions}
                                    defaultValue={defaultOpsManagerOption}
                                    components={{ ClearIndicator, DropdownIndicator, Option }}
                                />
                                <FormText>
                                    This organisation will have access to diagnostics data for this screen.
                                </FormText>
                            </FormGroup>
                        </Form>
                        <div className='dialog-actions'>
                            <Button
                                className='custom-button custom-button-large'
                                onClick={() => this.setState({ accessControlOpen: false })}
                                color='primary'
                            >
                                Done
                            </Button>
                        </div>
                    </div>
                </Dialog>
                {/* Device Management */}
                <Dialog
                    className='custom-dialog'
                    title='Device Management'
                    onClose={() => this.setState({ deviceManagementOpen: false })}
                    isOpen={this.state.deviceManagementOpen}
                    canOutsideClickClose={false}
                >
                    <div className='dialog-content p-4'>
                        {/* Avior */}
                        <Form className='admin-form' onSubmit={eventPreventDefault}>
                            <h4 className='text-black'>Avior</h4>
                            <FormGroup>
                                <Label for='aviorId'>ID</Label>
                                <Input
                                    onChange={this.handleFormValue}
                                    type='text'
                                    id='aviorId'
                                    autoComplete='off'
                                    maxLength={6}
                                    defaultValue={screenItem?.aviorId ?? undefined}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for='aviorPassword'>Password</Label>
                                <Input
                                    onChange={this.handleFormValue}
                                    type='password'
                                    id='aviorPassword'
                                    autoComplete='off'
                                    maxLength={10}
                                    defaultValue={screenItem?.aviorPassword ?? undefined}
                                    placeholder={
                                        this.state.originalItemAviorId &&
                                        this.state.originalItemAviorId === screenItem!.aviorId
                                            ? '••••••••••'
                                            : undefined
                                    }
                                />
                                <FormText>If the ID is updated, the password must be re-entered.</FormText>
                            </FormGroup>
                            {me.isSuperUser && (
                                <>
                                    <br />
                                    <FormGroup>
                                        {/* Camera */}
                                        <h4 className='text-black'>Camera</h4>
                                        <Label for='hasCamera'>Enabled</Label>
                                        <InputGroup>
                                            <Toggle
                                                defaultChecked={screenItem?.hasCamera}
                                                className='custom-toggle'
                                                icons={false}
                                                onChange={this.handleFormValue}
                                                id='hasCamera'
                                            />
                                        </InputGroup>
                                        <FormText>
                                            If the screen has a camera, enabling this will allow the camera to be used
                                            in Live.
                                        </FormText>
                                    </FormGroup>
                                </>
                            )}
                        </Form>
                        <div className='dialog-actions'>
                            <Button
                                className='custom-button custom-button-large'
                                onClick={() => this.setState({ deviceManagementOpen: false })}
                                color='primary'
                            >
                                Done
                            </Button>
                        </div>
                    </div>
                </Dialog>
            </React.Fragment>
        )
    }
}

export default CreateScreenForm
