import Gradient from 'linear-gradient'
import { gt, lt, valid } from 'semver'
import { getHexStringDecimal } from './Number'

export const heatmapColours = (gradient: number[][], normalisedData: number[]): any[] => {
    const baseGradient = new Gradient(gradient)

    // Return an array of hex values based on gradient
    return normalisedData.map((value: number) => baseGradient.calcHex(value))
}

// Takes an array of semver or hexadecimal strings and returns an array of ints in the same order representing if the version is current or not
// Returned index will be 0 if outdated or 1 if the latest version from the provided input
// Can be consumed by the heatmap to highlight versions less than the latest version
// NOTE: Any semver strings will be considered a higher version than hexadecimal strings
export const getCurrentFirmwareVersionIndexes = (versions: Array<string | number>): number[] => {
    // Convert any hexadecimal values to decimals
    versions.forEach((v, i, arr) => {
        if (typeof v === 'string' && v.startsWith('0x')) {
            arr[i] = getHexStringDecimal(v)
        }
    })

    if (versions.every(v => typeof v === 'number')) {
        const maxVersionNumber = Math.max(...(versions as number[]))
        return versions.map(v => (v < maxVersionNumber ? 0 : 1))
    }

    const maxVersionString = versions.reduce((acc: string, cur) => {
        acc = typeof cur === 'string' && valid(cur) && gt(cur, acc) ? cur : acc
        return acc
    }, '0.0.0') as string
    return versions.map(v => (typeof v === 'number' ? 0 : !valid(v) ? 0 : lt(v, maxVersionString) ? 0 : 1))
}
