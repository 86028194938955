import { observable, action, computed, runInAction } from 'mobx'
import RootStore from 'src/common/RootStore'

import { subscriptionTopics } from '../utils/String'

import { CameraCommand, CameraLive } from '../models/Camera'
import MqttSubscription from '../models/MqttSubscription'
import cameraRouter from 'src/api/cameraRouter'
import moment, { Moment } from 'moment'
import displayImageRouter from 'src/api/displayImageRouter'
import { AppToaster } from '../components/AppToaster'
import { Intent } from '@blueprintjs/core'

export enum CameraHistoryMode {
    nearest = 'nearest',
    previous = 'previous',
    next = 'next',
    previousEqual = 'previousEqual',
    nextEqual = 'nextEqual',
}

export default class CameraStore {
    @observable subscriptionKey?: string

    @observable cameraLiveImages: CameraLive[] = []

    @observable timestamp?: Moment

    @observable subscription?: MqttSubscription
    @observable expiry?: Moment
    @observable feedExpired: boolean = false

    subscriptionExpiryTimer?: NodeJS.Timeout

    router = cameraRouter
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @computed get commandPublishTopic(): string | undefined {
        if (!this.subscriptionKey) {
            return undefined
        }
        return subscriptionTopics('camera', this.subscriptionKey)[1]
    }

    @action startCameraFeed = (screenId: string) => {
        cameraRouter
            .subscribeToCameraFeed(screenId, this.subscriptionKey)
            .then(response => {
                const { subscriptionKey, timeout: ttl } = response
                if (!subscriptionKey) {
                    console.error('Subscription key missing')
                    return
                }
                runInAction('setSubscriptionKey', () => {
                    this.subscriptionKey = subscriptionKey
                })

                this.subscription = new MqttSubscription({
                    topics: subscriptionTopics('camera', subscriptionKey),
                    subscriptionKey,
                    callback: data => {
                        if (data.value?.command === CameraCommand.cameraLive) {
                            console.debug('cameraData', data)
                            const cameraLive = new CameraLive(data.value)
                            console.debug('cameraLive', cameraLive)

                            runInAction('setCameraLiveImages', () => {
                                this.cameraLiveImages.push(cameraLive)
                            })
                        }
                    },
                })
                    .setTTL(ttl)
                    .setKeepAliveCommand(CameraCommand.ping)
                this.rootStore.mqttStore.addSubscription(this.subscription)

                this.refreshCameraFeed()
            })
            .catch(error => {
                console.error('error', error)
            })
    }

    @action startTimer = () => {
        if (this.subscriptionExpiryTimer) {
            clearTimeout(this.subscriptionExpiryTimer)
        }

        const expireIn = 1000 * 60 * 2 // 2 minutes

        this.expiry = moment().add(expireIn, 'milliseconds')
        this.feedExpired = false

        this.subscriptionExpiryTimer = setTimeout(() => {
            this.sendStopCameraFeedCommand()
            runInAction('setFeedExpired', () => {
                this.feedExpired = true
                this.cameraLiveImages = []
            })
        }, expireIn)
    }

    @action refreshCameraFeed = () => {
        if (!this.commandPublishTopic) {
            console.error('Subscription key missing')
            return
        }

        this.rootStore.mqttStore.publish(this.commandPublishTopic, {
            command: CameraCommand.cameraFeed,
        })

        this.startTimer()
    }

    @action sendStopCameraFeedCommand = () => {
        if (!this.commandPublishTopic) {
            console.error('Subscription key missing')
            return
        }

        this.rootStore.mqttStore.publish(this.commandPublishTopic, {
            command: CameraCommand.stopCameraFeed,
        })
    }

    @action setTimestamp = (value: Moment) => {
        this.timestamp = value
    }

    @action stopCameraFeed = () => {
        this.sendStopCameraFeedCommand()

        this.expiry = undefined
        this.feedExpired = true

        if (this.subscriptionExpiryTimer) {
            clearTimeout(this.subscriptionExpiryTimer)
        }
    }

    @action close = () => {
        this.stopCameraFeed()
        this.subscriptionKey = undefined
        this.timestamp = undefined
        this.cameraLiveImages = []
        if (this.subscription) {
            this.rootStore.mqttStore.removeSubscription(this.subscription)
            this.subscription = undefined
        }
    }

    requestCameraTimestamp = async (screenId: string, timestamp: Moment, mode: CameraHistoryMode) => {
        try {
            const result = await displayImageRouter.timestamp(screenId, {
                organisationId: this.rootStore.screenStore.findItem(screenId)?.organisationId,
                timestamp,
                mode: mode ?? 'nearest',
            })
            return result
        } catch (error) {
            console.error('Error fetching image for timestamp', error)
            if (error.error === 'no record found') {
                AppToaster.show({
                    message: 'No record found for the requested timestamp',
                    intent: Intent.DANGER,
                })
            }
            throw error
        }
    }
}
