import * as React from 'react'
import { components } from 'react-select'

// i18n
import i18n from 'src/i18n'

import { Button } from 'reactstrap'
import Checkbox from './Checkbox'
import chevronDown from '../../assets/images/chevronDown.svg'
import clearInput from '../../assets/images/clearInput.svg'
import { toJS } from 'mobx'

export interface ValueLabelPair {
    value: any
    label: string
}

// Replaces the value container component for react-select
export const ValueContainer = ({ children, getValue, ...props }: any) => {
    const length = getValue().length
    let placeholder
    if (props.selectProps.inputValue) {
        placeholder = undefined
    } else {
        if (length === 0) {
            placeholder = props.selectProps.placeholder
        } else if (length === 1) {
            placeholder = props.selectProps.value[0].label
        } else if (length > 1) {
            placeholder = length + ' ' + i18n.t('common.selected')
        }
    }

    return (
        <components.ValueContainer {...props}>
            {props.isMulti && (
                <React.Fragment>
                    <div className='custom-select__multi-value custom-value-container'>{placeholder}</div>
                    {/* Prevents rendering 'tags' */}
                    {React.Children.map(children, child => (child && child.type === components.Input ? child : null))}
                </React.Fragment>
            )}
        </components.ValueContainer>
    )
}

// Replaces the clear indicator component for react-select
export const ClearIndicator = (props: any) => {
    function handleClick(e: any) {
        e.stopPropagation()
        e.preventDefault()
    }

    function clearValue() {
        props.clearValue()
    }

    const {
        getStyles,
        innerProps: { ref, ...restInnerProps },
    } = props

    return (
        <div {...restInnerProps} ref={ref}>
            <div onMouseDown={handleClick} onTouchEnd={handleClick}>
                <div style={getStyles('clearIndicator', props)} onClick={clearValue} onTouchEnd={clearValue}>
                    <img className='custom-select-clear-indicator' src={clearInput} alt='Clear' />
                </div>
            </div>
        </div>
    )
}

// Replaces the dropdown indicator component for react-select
export const DropdownIndicator = (props: any) => (
    <div className='select-dropdown-indicator-container'>
        <img
            className='select-dropdown-chevron'
            style={{ transform: props.isFocused ? 'rotate(180deg)' : '' }}
            src={chevronDown}
            alt='Toggle'
        />
    </div>
)

// Replaces the option component for react-select
export const Option = (props: any) => (
    <components.Option
        {...props}
        className={
            (props.isFocused ? ' isFocused' : '') +
            (props.isSelected ? ' isSelected' : '') +
            (!props.isMulti ? ' isSingle' : '')
        }
    >
        <Checkbox isChecked={props.isSelected} isDisabled={props.isDisabled} />
        <span>{props.label}</span>
    </components.Option>
)

// Replaces the text to show when no updates are available
export const NoOptionsMessage = (props: any) => (
    <components.NoOptionsMessage {...props}>{i18n.t('common.noOptions')}</components.NoOptionsMessage>
)

// Replaces the group heading with one to select all items within that group
export const GroupHeading = (props: any) => {
    const { selectProps, children } = props
    const { value, options, onChange, isOptionDisabled } = selectProps
    const group = options.filter((option: any) => option.label === children)[0]
    const groupOptions: ValueLabelPair[] = group.options

    if (isOptionDisabled(groupOptions[0])) {
        // Don't show action if group disabled
        return (
            <div className='select-group-select-all'>
                <components.GroupHeading {...props} />
            </div>
        )
    }

    const groupOptionsValues: string[] = groupOptions.map(option => option.value)
    const allSelected =
        value &&
        value.filter((selectedOption: ValueLabelPair) => groupOptionsValues.includes(selectedOption.value)).length ===
            groupOptions.length

    function handleSelectAllInGroup() {
        const selectedOptionMap = new Map<string, ValueLabelPair>() // Prevent duplicate options
        if (value) {
            value.forEach((option: ValueLabelPair) => {
                const selectedOption = toJS(option)
                selectedOptionMap.set(selectedOption.value, selectedOption)
            })
        }
        groupOptions.forEach((option: ValueLabelPair) => {
            if (!isOptionDisabled(option)) {
                if (!allSelected) {
                    selectedOptionMap.set(option.value, option)
                } else {
                    selectedOptionMap.delete(option.value)
                }
            }
        })

        // Use the select onChange handler to append newly selected array
        onChange(Array.from(selectedOptionMap.values()), 'select-option')
    }

    return (
        <div className='select-group-select-all'>
            <components.GroupHeading {...props} />
            <Button
                onClick={handleSelectAllInGroup}
                className='custom-button-link custom-button-link-sm custom-button-link-black'
                color='link'
            >
                <span>{!allSelected ? i18n.t('actions.selectAll') : i18n.t('actions.unselectAll')}</span>
            </Button>
        </div>
    )
}
