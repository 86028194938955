import * as React from 'react'
import clsx from 'clsx'
import moment, { Moment } from 'moment'

interface TimerProps {
    expiry: Moment
    warning?: number
}

export default function Timer(props: TimerProps) {
    const { expiry, warning = 0 } = props

    const [time, setTime] = React.useState(moment(expiry).diff(moment(), 'seconds'))

    React.useEffect(() => {
        const interval = setInterval(() => {
            setTime(moment(expiry).diff(moment(), 'seconds'))
        }, 1000)
        return () => clearInterval(interval)
    }, [expiry])

    return <span className={clsx(time < warning && 'text-danger')}>Expiring in {time} seconds</span>
}
