import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import Media from 'src/common/models/Media'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

import clearIcon from 'src/assets/images/clearIcon.svg'
import deleteIcon from 'src/assets/images/deleteIcon.svg'
import undoIcon from 'src/assets/images/undoIcon.svg'
import { Button } from 'reactstrap'
import { Tooltip, Position } from '@blueprintjs/core'

interface ActionsCellProps {
    media: Media
    screenId: string
    playerDiv?: HTMLElement
}

@inject('store')
@observer
class ActionsCell extends React.Component<{ store?: RootStore } & ActionsCellProps> {
    handleDelete = (media: Media) => {
        if (!this.props.screenId) {
            return
        }

        this.props.store!.mediaStore.getMediaManager(this.props.screenId).removeMedia(media)
    }

    handleUndoDelete = (media: Media) => {
        if (!this.props.screenId) {
            return
        }

        this.props.store!.mediaStore.getMediaManager(this.props.screenId).undoRemoveMedia(media)
    }

    handleCancelUpload = (media: Media) => {
        if (!this.props.screenId) {
            return
        }

        this.props.store!.mediaStore.getMediaManager(this.props.screenId).cancelUpload(media)
    }

    render() {
        const media = this.props.media

        if (!media) {
            return null
        }

        if (media.isPendingUpload) {
            return (
                // Cancel upload
                <img
                    className='cancel-upload-icon'
                    onClick={this.handleCancelUpload.bind(this, media)}
                    src={clearIcon}
                    alt='Cancel upload'
                    height={20}
                    draggable={false}
                    onDragStart={eventPreventDefault}
                />
            )
        }

        if (!media.isPendingDelete) {
            return (
                // Delete
                <Tooltip
                    boundary='viewport'
                    content='Delete'
                    hoverOpenDelay={300}
                    position={Position.TOP}
                    portalContainer={this.props.playerDiv}
                >
                    <Button className='table-action-lg' onClick={this.handleDelete.bind(this, media)} color='danger'>
                        <img
                            src={deleteIcon}
                            alt='Delete'
                            height={14}
                            draggable={false}
                            onDragStart={eventPreventDefault}
                        />
                    </Button>
                </Tooltip>
            )
        } else {
            return (
                // Undo Delete
                <Tooltip
                    boundary='viewport'
                    content='Undo delete'
                    hoverOpenDelay={300}
                    position={Position.TOP}
                    portalContainer={this.props.playerDiv}
                >
                    <Button className='table-action-lg' onClick={this.handleUndoDelete.bind(this, media)} color='link'>
                        <img
                            src={undoIcon}
                            alt='Undo'
                            height={16}
                            draggable={false}
                            onDragStart={eventPreventDefault}
                        />
                    </Button>
                </Tooltip>
            )
        }
    }
}

export default ActionsCell
