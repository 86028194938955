import onAPI from './onAPI'

class ConsoleRouter {
    protected route = '/console'

    async subscribeToConsole(screenId: string, subscriptionKey?: string) {
        const json: any = await onAPI.fetchJSON(this.route + '/' + screenId + '/subscribe', {
            subscriptionKey,
        })
        return json
    }
}

const consoleRouter = new ConsoleRouter()
export default consoleRouter
