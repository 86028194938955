import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'
import views, { DiagnosticsGlobalTab } from 'src/config/views'

import { TabContent, TabPane } from 'reactstrap'
import NavTabs, { NavTab } from 'src/common/components/navigation/NavTabs'
import GlobalDigest from './submodules/digest/GlobalDigest'
import { Icon, Intent } from '@blueprintjs/core'
import _ from 'lodash'

@inject('store')
@observer
class GlobalDiagnostics extends React.Component<{ store?: RootStore }> {
    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return
        }

        const router = this.props.store!.router
        const currentView = router.params.view || DiagnosticsGlobalTab.digest

        const tabs: NavTab[] = Object.values(DiagnosticsGlobalTab).map(view => ({
            name: i18n.t('diagnosticsPage.screenTabs.' + view),
            view: views.diagnosticsGlobal,
            params: { ...router.params, tab: view },
            active: currentView === view,
        }))

        if (!this.props.store!.globalDiagnosticsUIStore.hasDiagnosticsPermissions) {
            return (
                <div className='diagnostics-global-container'>
                    <div className='non-ideal-state'>
                        <Icon icon='warning-sign' iconSize={54} intent={Intent.DANGER} />
                        <h2>{i18n.t('diagnosticsPage.noOperator')}</h2>
                    </div>
                </div>
            )
        }

        return (
            <div className='diagnostics-global-container'>
                <NavTabs tabs={tabs} showDropdownOnMobile />
                <TabContent activeTab={currentView}>
                    <TabPane tabId={DiagnosticsGlobalTab.digest}>
                        <GlobalDigest />
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

export default GlobalDiagnostics
