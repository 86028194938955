import * as React from 'react'
import { observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

import ExpandImageDialog from '../reports/components/modals/ExpandImageDialog'

import ReportForm from './components/ReportForm'
import ReportResults from './components/ReportResults'

import { Dialog } from '@blueprintjs/core'
import { Button, Form, Input, InputGroup, Label, FormGroup, Card } from 'reactstrap'
import Select from 'react-select'
import { DropdownIndicator, Option } from 'src/common/components/SelectComponents'
import CameraImageCard from './components/CameraImageCard'
import LoadingSpinner from 'src/common/components/LoadingSpinner'
import { TimePicker } from '@blueprintjs/datetime'
import Checkbox from 'src/common/components/Checkbox'

interface CreativesProps {
    store?: RootStore
}

function Creatives(props: CreativesProps) {
    const { store } = props
    const uiStore = store!.creativesUIStore

    const {
        isFetching,
        displayImages,
        isReportDialogOpen,
        isSamplingDialogOpen,
        exportName,
        exportOption,
        isExporting,
        setIsReportDialogOpen,
        setIsSamplingDialogOpen,
        setExportName,
        setExportOption,
        handleExport,
        handleSample,
        isTimeWindowEnabled,
        setIsTimeWindowEnabled,
        timeWindowStartTime,
        timeWindowEndTime,
        setTimeWindowStartTime,
        setTimeWindowEndTime,
        numberOfSamples,
        setNumberOfSamples,
    } = uiStore

    const me = store!.userStore.me
    if (!me) {
        return null
    }

    return (
        <>
            <div className='grey-container'>
                <div className='custom-container-fluid creatives-container'>
                    <main>
                        {!(displayImages.length > 0 || isFetching) ? (
                            <ReportForm store={store} />
                        ) : (
                            <ReportResults store={store} />
                        )}
                    </main>
                </div>
            </div>
            <ExpandImageDialog />
            {/* Generate report dialog */}
            <Dialog
                isOpen={isReportDialogOpen}
                className='custom-dialog'
                title='Create report'
                onClose={() => setIsReportDialogOpen(false)}
                style={{ width: '561px' }}
            >
                <div className='summary-export'>
                    <Form inline onSubmit={eventPreventDefault}>
                        <InputGroup className='custom-input-group custom-input-group-inline custom-input-group-alt'>
                            <Label className='custom-label custom-label-inline align-middle'>
                                <h6 className='text-black'>{i18n.t('reportsPage.reportName')}</h6>
                            </Label>
                            <Input
                                className='form-control-alt'
                                onChange={e => setExportName(e.target.value)}
                                type='text'
                            />
                        </InputGroup>
                        <InputGroup className='custom-input-group custom-input-group-inline custom-input-group-alt'>
                            <Label className='custom-label custom-label-inline align-middle'>
                                <h6 className='text-black'>{i18n.t('common.format')}</h6>
                            </Label>
                            <Select
                                className='custom-select-wrapper custom-select-inverted'
                                classNamePrefix='custom-select'
                                isSearchable={false}
                                blurInputOnSelect
                                onChange={option => setExportOption(option?.value)}
                                options={store!.reportsUIStore.exportOptions}
                                components={{ DropdownIndicator, Option }}
                            />
                        </InputGroup>
                    </Form>
                    <div className='create-button'>
                        <Button
                            onClick={() => handleExport()}
                            className='custom-button custom-button-large mt-4'
                            color='primary'
                            disabled={!exportName || !exportOption || isExporting}
                        >
                            {isExporting ? 'Generating report' : i18n.t('actions.create')}
                        </Button>
                    </div>
                </div>
            </Dialog>
            <Dialog
                isOpen={isSamplingDialogOpen}
                className='custom-dialog'
                title='Image Sampling'
                onClose={() => setIsSamplingDialogOpen(false)}
                style={{ width: '561px' }}
            >
                <div className='summary-export'>
                    <Form onSubmit={eventPreventDefault}>
                        <FormGroup>
                            <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 25px)' }}>
                                <InputGroup className='custom-input-group custom-input-group-inline custom-input-group-alt'>
                                    <Label className='custom-label custom-label-inline align-middle'>
                                        <h6 className='text-black'>{'Time Window'}</h6>
                                    </Label>
                                    <Checkbox
                                        isChecked={isTimeWindowEnabled}
                                        handleOnClick={() => setIsTimeWindowEnabled(!isTimeWindowEnabled)}
                                    />
                                </InputGroup>
                                {isTimeWindowEnabled && (
                                    <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                        <InputGroup
                                            className='custom-input-group custom-input-group-inline custom-input-group-alt'
                                            style={{ width: '50%' }}
                                        >
                                            <Label className='custom-label custom-label-inline align-middle'>
                                                <h6 className='text-black'>{'Start Time'}</h6>
                                            </Label>
                                            <TimePicker
                                                value={timeWindowStartTime}
                                                onChange={newTime => setTimeWindowStartTime(newTime)}
                                                useAmPm
                                            />
                                        </InputGroup>
                                        <InputGroup
                                            className='custom-input-group custom-input-group-inline custom-input-group-alt'
                                            style={{ width: '50%', marginLeft: '-20px' }}
                                        >
                                            <Label className='custom-label custom-label-inline align-middle'>
                                                <h6 className='text-black'>{'End Time'}</h6>
                                            </Label>
                                            <TimePicker
                                                value={timeWindowEndTime}
                                                onChange={newTime => setTimeWindowEndTime(newTime)}
                                                useAmPm
                                            />
                                        </InputGroup>
                                    </div>
                                )}
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <h6 className='text-black'>{'Number of Samples/Screen/Creative'}</h6>
                            <InputGroup
                                className='custom-input-group custom-input-group-inline custom-input-group-alt'
                                style={{ maxWidth: '200px' }}
                            >
                                <Input
                                    className='form-control-alt'
                                    onChange={e => setNumberOfSamples(Number(e.target.value))}
                                    type='number'
                                    value={numberOfSamples}
                                    min='1'
                                />
                            </InputGroup>
                        </FormGroup>{' '}
                    </Form>
                    <div className='create-button'>
                        <Button
                            onClick={() => handleSample()}
                            className='custom-button custom-button-large mt-4'
                            color='primary'
                            disabled={!numberOfSamples || isExporting}
                        >
                            {isExporting ? 'Generating sampling' : 'Generate'}
                        </Button>
                    </div>
                </div>{' '}
            </Dialog>{' '}
            {/* Camera images dialog */}
            <Dialog
                isOpen={uiStore.isCameraImageDialogOpen}
                className='custom-dialog'
                portalClassName='custom-dialog-xl'
                title='Camera images'
                onClose={() => uiStore.closeCameraImageDialog()}
            >
                {uiStore.cameraImageRecords.length === 0 ? (
                    <div className='mt-4 mb-4'>
                        <LoadingSpinner dark />
                    </div>
                ) : (
                    <div className='camera-images'>
                        {uiStore.cameraImageRecords.map((displayRecord, index) => (
                            <CameraImageCard
                                key={index}
                                store={store!}
                                displayRecord={displayRecord}
                                displayType={uiStore.cameraGridType!}
                            />
                        ))}
                    </div>
                )}
            </Dialog>
        </>
    )
}

export default observer(Creatives)
