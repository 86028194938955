import * as React from 'react'

interface TagsProps {
    tags: Array<{
        key: string
        value: string
    }>
}

class Tags extends React.Component<TagsProps> {
    state = {
        copiedTagIndex: -1, // Default value indicating no tag has been copied
    }

    handleCopyClick = (index: number) => {
        const { tags } = this.props
        const { value } = tags[index]

        navigator.clipboard.writeText(value).then(() => {
            this.setState({ copiedTagIndex: index })

            // Clear the "copied" indication after a short delay
            setTimeout(() => {
                this.setState({ copiedTagIndex: -1 })
            }, 2000)
        })
    }

    render() {
        const { tags } = this.props
        const { copiedTagIndex } = this.state

        return (
            <div className='custom-tags-group'>
                {tags.map(({ key, value }, i) => (
                    <button
                        key={i}
                        className={`custom-tag ${copiedTagIndex === i ? 'copied' : ''}`}
                        onClick={() => this.handleCopyClick(i)}
                    >
                        {key}: {value} {copiedTagIndex === i && <span className='copied-indicator'>Copied!</span>}
                    </button>
                ))}
            </div>
        )
    }
}

export default Tags
