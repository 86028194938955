import RootStore from 'src/common/RootStore'
import { observable, action, computed, runInAction } from 'mobx'

import i18n from 'src/i18n'
import views from 'src/config/views'
import errorLogRouter from 'src/api/errorLogRouter'

import Tile from '../models/Tile'
import DiagnosticsError from '../models/DiagnosticsError'
import DiagnosticsErrorLog from '../models/DiagnosticsErrorLog'

import PopoutManager from './PopoutManager'

export default class TileHistoryManager extends PopoutManager {
    @observable historicalErrors?: DiagnosticsError[]
    @observable isCurrentErrorsOpen: boolean = true
    tile: Tile

    constructor(rootStore: RootStore, tile: Tile) {
        super(rootStore, tile)
        this.tile = tile
        this.objectId = this.tile.id!
    }

    @computed get title(): string {
        return (this.tile.tnl ?? i18n.t('placeholders.missingData')) + ' | Error Log'
    }

    // Unimplemented for now
    handleFocusPopout = () => {}

    @action removeManager = () => {
        this.rootStore.tileHistoryStore.managerMap.delete(this.tile.id!)
    }

    clearURL = () => {
        const router = this.rootStore.router
        router.goTo(views.diagnosticsScreen, router.params, this.rootStore, { tileView: true })
    }

    @action fetchErrorHistory = async () => {
        this.isFetching = true
        const historicalErrors = await errorLogRouter.fetchTileErrorHistory(this.tile).catch(err => {
            console.error(err)
        })
        runInAction('historyFinishedFetching', () => {
            this.isFetching = false
            this.historicalErrors = historicalErrors || undefined
        })
    }

    @computed get historicalErrorLogs(): DiagnosticsErrorLog[] {
        if (!this.historicalErrors) {
            return []
        }
        return this.historicalErrors.flatMap(error => error.logs || []).sort((a, b) => b.time.diff(a.time))
    }

    @action toggleCurrentErrors = () => {
        this.isCurrentErrorsOpen = !this.isCurrentErrorsOpen
    }

    get currentErrors(): DiagnosticsError[] {
        return this.tile.errors
    }
}
