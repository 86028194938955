import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'
import { Link } from 'mobx-router'

import i18n from 'src/i18n'
import views, { DiagnosticsGlobalTab } from 'src/config/views'

import { ScreenTabName } from '../stores/ScreenDiagnosticsUIStore'

import NavTabs, { NavTab } from 'src/common/components/navigation/NavTabs'
import { TabContent, TabPane, Button } from 'reactstrap'
import ScreenCurrentErrors from '../submodules/screen-current-errors/container'
import ScreenErrorHistory from '../submodules/screen-error-history/container'
import Loader from 'src/common/components/Loader'
import ErrorControls from '../submodules/screen-current-errors/components/ErrorControls'
import FilterToggle from 'src/common/components/FilterToggle'
import OverviewTags from 'src/common/components/OverviewTags'
import { Icon, Intent } from '@blueprintjs/core'
import _ from 'lodash'
import { RoleType } from 'src/common/models/Roles'

@inject('store')
@observer
class ScreenTabViews extends React.Component<{ store?: RootStore }> {
    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return null
        }

        const screenDiagnosticsUIStore = this.props.store!.screenDiagnosticsUIStore
        const screenErrorHistoryManager = screenDiagnosticsUIStore.screenErrorHistoryManager
        const currentScreen = screenDiagnosticsUIStore.currentScreen
        const currentScreenConfiguration = screenDiagnosticsUIStore.currentScreenConfiguration
        const isMobile = this.props.store!.appUIStore.isMobile

        if (!currentScreen || !currentScreen.id) {
            return (
                <div className='diagnostics-non-ideal-state diagnostics-no-selection'>
                    <h2>{i18n.t('diagnosticsPage.screensSummary')}</h2>
                    <OverviewTags
                        statuses={screenDiagnosticsUIStore.selectedOrg?.screenStatuses}
                        fullTags
                        showWarnings
                        showNonErrors
                    />
                    <br />
                    <br />
                    <h3>{i18n.t('diagnosticsPage.noScreensSelected')}</h3>
                    <h3>{i18n.t('diagnosticsPage.noScreensSelectedHelpText')}</h3>
                    <hr />
                    <Link
                        view={views.diagnosticsGlobal}
                        params={{ ...this.props.store!.router?.params, tab: DiagnosticsGlobalTab.digest }}
                        store={this.props.store!}
                    >
                        <Button className='custom-button-large' color='primary'>
                            {i18n.t('diagnosticsPage.goToGlobalDiagnostics')}
                        </Button>
                    </Link>
                </div>
            )
        } else if (screenDiagnosticsUIStore.isLoading) {
            return (
                <div className='diagnostics-non-ideal-state'>
                    <Loader />
                </div>
            )
        } else if (screenDiagnosticsUIStore.fetchComplete && !currentScreenConfiguration) {
            return (
                <div className='diagnostics-non-ideal-state'>
                    <h2>{i18n.t('diagnosticsPage.errorFetchingScreenConfiguration')}</h2>
                    <Button
                        onClick={screenDiagnosticsUIStore.populateScreenConfiguration.bind(this, currentScreen.id)}
                        className='custom-button-large'
                        color='primary'
                    >
                        {i18n.t('actions.tryAgain')}
                    </Button>
                </div>
            )
        }
        if (!screenDiagnosticsUIStore.hasDiagnosticsPermissions) {
            return (
                <div className='non-ideal-state'>
                    <Icon icon='warning-sign' iconSize={54} intent={Intent.DANGER} />
                    <h2>{i18n.t('diagnosticsPage.noOperator')}</h2>
                </div>
            )
        }

        const tabs: NavTab[] = screenDiagnosticsUIStore.screenTabNames.map(tabName => ({
            name: i18n.t('diagnosticsPage.screenTabs.' + tabName),
            view: views.diagnosticsScreen,
            params: { ...this.props.store!.router.params, screen: currentScreen?.id, tab: tabName },
            active: screenDiagnosticsUIStore.activeScreenTab === tabName,
        }))

        const contextualElements: JSX.Element[] = [
            ...(screenDiagnosticsUIStore.activeScreenTab === ScreenTabName.current ? [<ErrorControls key={0} />] : []),
            ...(isMobile && screenDiagnosticsUIStore.activeScreenTab === ScreenTabName.history
                ? [
                      <FilterToggle
                          key={1}
                          isOpen={screenErrorHistoryManager.areControlsOpen}
                          handleOnClick={screenErrorHistoryManager.toggleControls}
                      />,
                  ]
                : []),
        ]

        return (
            <React.Fragment>
                <NavTabs tabs={tabs} contextualElements={contextualElements} showDropdownOnMobile />
                <TabContent activeTab={screenDiagnosticsUIStore.activeScreenTab}>
                    <TabPane tabId={ScreenTabName.current}>
                        <ScreenCurrentErrors />
                    </TabPane>
                    <TabPane tabId={ScreenTabName.history}>
                        <ScreenErrorHistory />
                    </TabPane>
                </TabContent>
            </React.Fragment>
        )
    }
}

export default ScreenTabViews
