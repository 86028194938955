import { action, autorun, computed, observable, reaction, runInAction } from 'mobx'

import i18n from 'src/i18n'

import RootStore from 'src/common/RootStore'
import ScreenCurrentErrorsManager from './ScreenCurrentErrorsManager'
import ScreenErrorHistoryManager from './ScreenErrorHistoryManager'

import views, { RoutePath } from 'src/config/views'

import Organisation from 'src/common/models/Organisation'
import Screen from 'src/common/models/Screen'
import ScreenConfiguration from 'src/common/models/ScreenConfiguration'

import { ValueLabelPair } from 'src/common/components/SelectComponents'
import { Intent, Position, Toaster } from '@blueprintjs/core'

import screenRouter from 'src/api/screenRouter'

import { Avior, Output } from 'src/common/models/Avior'
import { ScreenState } from 'src/common/models/ScreenState'
import _ from 'lodash'
import { RoleType } from 'src/common/models/Roles'

const screenLostConnectionBanner = Toaster.create({
    className: 'screen-lost-connection-toaster',
    position: Position.BOTTOM,
})

const screenStoppedBanner = Toaster.create({
    className: 'screen-lost-connection-toaster',
    position: Position.BOTTOM,
})

export enum ScreenInfoType {
    brightness = 'brightness', // Default online
    status = 'status', // Default offline or stopped
    manufacturer = 'manufacturer',
    version = 'connectVersion',
    connectDaemon = 'connectDaemon',
    controllerVersion = 'controllerVersion',
    firmwareVersion = 'firmwareVersion',
    lednetDaemon = 'lednetDaemon',
    // uptime = 'lednetUptime',
    // restart = 'lednetRestart',
    // jitter = 'jitter',
    // vsyncDelays = 'vsyncDelays',
    // vsyncDrops = 'vsyncDrops',
    // hardwareVsync = 'hardwareVsync',
    // threadVsync = 'threadVsync',
    // threadEngine = 'threadEngine',
    // threadQueue = 'threadQueue',
}

export enum ScreenTabName {
    current = 'current',
    history = 'history',
    // digest = 'digest',
}

export default class ScreenDiagnosticsUIStore {
    @observable private setOrg: Organisation | undefined
    @observable currentScreen: Screen | undefined
    @observable currentScreenConfiguration: ScreenConfiguration | undefined

    @observable searchQueryValue?: string
    @observable screenInfoValue: string = ScreenInfoType.brightness

    @observable activeScreenTab: ScreenTabName | undefined

    @observable isScreenConfigurationPopulating = false
    @observable fetchComplete = false

    @observable isPowerManagementOpen: boolean = false
    @observable avior?: Avior = undefined

    rootStore: RootStore
    screenCurrentErrorsManager: ScreenCurrentErrorsManager
    screenErrorHistoryManager: ScreenErrorHistoryManager

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.screenCurrentErrorsManager = new ScreenCurrentErrorsManager(this)
        this.screenErrorHistoryManager = new ScreenErrorHistoryManager(this)

        // React to currentScreen isConnected and route changes
        autorun(r => {
            const route = this.rootStore.router?.currentView?.rootPath
            const subRoute = this.rootStore.router?.currentView?.path.split('/')[2]
            if (
                this.currentScreen &&
                route === RoutePath.diagnostics &&
                '/' + subRoute === RoutePath.diagnosticsScreen
            ) {
                if (!this.currentScreen.isConnected) {
                    // Show error banner if connection lost
                    screenLostConnectionBanner.show(
                        {
                            icon: 'satellite',
                            intent: Intent.DANGER,
                            message: this.currentScreen.name + ' ' + i18n.t('diagnosticsPage.lostConnection'),
                            timeout: 0,
                        },
                        'screenLostConnection'
                    )
                } else if (this.currentScreen.state !== ScreenState.started) {
                    screenStoppedBanner.show(
                        {
                            icon: 'warning-sign',
                            intent: Intent.DANGER,
                            message: this.currentScreen.name + ' ' + i18n.t('diagnosticsPage.isStopped'),
                            timeout: 0,
                        },
                        'screenStopped'
                    )
                } else {
                    // Dismiss lost connection toast
                    screenLostConnectionBanner.dismiss('screenLostConnection')
                    screenStoppedBanner.dismiss('screenStopped')
                }
            } else {
                screenLostConnectionBanner.dismiss('screenLostConnection')
                screenStoppedBanner.dismiss('screenStopped')
            }
        })
    }

    get screenTabNames(): ScreenTabName[] {
        return Object.values(ScreenTabName)
    }

    get infoOptions(): ValueLabelPair[] {
        const options = Object.values(ScreenInfoType).map(item => ({
            value: item,
            label: i18n.t('diagnosticsPage.screenInfo.' + item),
        })) as ValueLabelPair[]

        const labels = this.selectedOrg?.screenLabels && Object.entries(this.selectedOrg?.screenLabels)
        if (labels) {
            for (const label of labels.reverse()) {
                const [key, value] = label
                options.unshift({
                    value: key,
                    label: value,
                })
            }
        }

        return options
    }

    @computed get selectedOrg(): Organisation | undefined {
        const orgStore = this.rootStore.orgStore
        // If not multiple screen manager, set to own org
        return !orgStore.hasMultipleOpsOrganisations ? orgStore.myOrg : this.setOrg
    }

    @computed get isLoading(): boolean {
        return this.isScreenConfigurationPopulating || this.rootStore.diagnosticsStore.isErrorsFetching
    }

    @computed get screensForDiagnostics(): Screen[] | undefined {
        const diagnosticsStore = this.rootStore.diagnosticsStore
        if (!diagnosticsStore.errorsContainer || !diagnosticsStore.errorsContainer.diagnosticsErrors) {
            return undefined
        }
        const screens = this.rootStore.screenStore.items
            .filter(
                screen => screen.isEnabledAndReady && this.selectedOrg && this.selectedOrg.id === screen.organisationId
            )
            .sort((a, b) => {
                // Sort by connection status, then by state, then by max severity, then by error count, then by name.
                if (a.isConnected === b.isConnected) {
                    if (a.state !== ScreenState.started && b.state === ScreenState.started) {
                        return 1
                    } else if (a.state === ScreenState.started && b.state !== ScreenState.started) {
                        return -1
                    } else {
                        return (
                            a.maxUnassignedSeverity - b.maxUnassignedSeverity ||
                            a.unassignedErrorCount - b.unassignedErrorCount ||
                            b.name.localeCompare(a.name)
                        )
                    }
                } else {
                    return a.isConnected ? -1 : 1
                }
            })
        return screens
    }

    @computed get hasAvior(): boolean {
        return this.currentScreen?.hasAvior ?? false
    }

    @computed get aviorOutputs(): Output[] | undefined {
        if (!this.avior) {
            return undefined
        }
        const { out: outputs } = this.avior
        return Object.values(outputs).map((output, i) => ({
            ordinal: i + 1,
            mode: output.mode,
            name: output.name,
            status: output.status,
            polarity: output.polarity,
        }))
    }

    @computed get hasDiagnosticsPermissions(): boolean {
        return (
            this.rootStore.userStore.hasDiagnosticsPermissions &&
            _.some(this.selectedOrg?.roles, role => role === RoleType.operator || role === RoleType.opsLite)
        )
    }

    fetchErrors = async () => {
        if (this.selectedOrg) {
            await this.rootStore.diagnosticsStore.fetchErrors({
                organisations: [this.selectedOrg.id!],
            })
        }
    }

    @action initialiseSubscription = () => {
        if (this.rootStore.diagnosticsStore.errorsContainer) {
            return
        }

        reaction(
            () => this.rootStore.mqttStore.socketConnected,
            (isConnected: boolean) => {
                if (isConnected) {
                    this.fetchErrors()
                }
            },
            { fireImmediately: true }
        )
    }

    @action populateScreenConfiguration = (screenId: string) => {
        // Ignore if currentScreenConfiguration already set or already fetching
        if (
            (this.currentScreenConfiguration && this.currentScreenConfiguration.screenId === screenId) ||
            this.isScreenConfigurationPopulating
        ) {
            return
        }

        this.isScreenConfigurationPopulating = true
        this.fetchComplete = false
        // Clear current configuration to avoid stale data appearing from last selection
        this.currentScreenConfiguration = undefined

        this.rootStore.screenStore
            .populateScreenConfiguration(screenId)
            .then(screenConfiguration => {
                runInAction('updateCurrentScreenConfiguration', () => {
                    this.currentScreenConfiguration = screenConfiguration
                })
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                runInAction('screenConfigurationFinishedLoading', () => {
                    this.isScreenConfigurationPopulating = false
                    this.fetchComplete = true
                })
            })
    }

    @action updateParams = (
        params: { org?: string; screen?: string; tab?: string },
        queryParams?: Record<string, any>
    ) => {
        // Receives params from the router
        const { org, screen, tab } = params

        if (org) {
            const originalOrg = this.setOrg
            const orgChanged = org !== originalOrg?.id
            const screenChanged = screen !== this.currentScreen?.id
            this.setOrg = this.rootStore.orgStore.findItem(org)
            if (orgChanged) {
                this.fetchErrors()

                if (originalOrg && (!screen || !screenChanged)) {
                    // Clear params to avoid incorrect screen showing from last selection
                    this.updateParams({ org, screen: undefined, tab: undefined }, undefined)
                    this.rootStore.router.goTo(views.diagnosticsScreen, { org }, this.rootStore, undefined)
                    return
                }
            }
        }

        if (screen) {
            this.currentScreen = this.rootStore.screenStore.findItem(screen)
            if (!this.currentScreen) {
                this.updateParams({ screen: undefined, tab: undefined }, undefined)
                this.rootStore.router.goTo(
                    views.diagnosticsScreen,
                    { org, screen: undefined, tab: undefined },
                    this.rootStore,
                    undefined
                )
                return
            }

            this.populateScreenConfiguration(screen)
        } else {
            this.currentScreen = undefined
            this.currentScreenConfiguration = undefined
        }

        if (tab) {
            if (!screen) {
                this.updateParams({ screen: undefined, tab: undefined }, undefined)
                this.rootStore.router.goTo(
                    views.diagnosticsScreen,
                    { org, screen: undefined, tab: undefined },
                    this.rootStore,
                    undefined
                )
                return
            }
            if (!Object.values(ScreenTabName).includes(tab as ScreenTabName)) {
                this.updateParams({ tab: undefined }, undefined)
                this.rootStore.router.goTo(
                    views.diagnosticsScreen,
                    { org, screen, tab: ScreenTabName.current },
                    this.rootStore,
                    undefined
                )
                return
            }

            this.activeScreenTab = ScreenTabName[tab]
        }

        if (queryParams && queryParams['tileView']) {
            this.rootStore.tileViewUIStore.setTileView(queryParams['tileView'])
        } else {
            this.rootStore.tileViewUIStore.setTileView(false)
        }

        if (queryParams && queryParams['errorHistory']) {
            this.rootStore.errorHistoryStore.updateError(queryParams['errorHistory'])
        } else {
            this.rootStore.errorHistoryStore.clearOpenDrawer()
        }

        if (queryParams && queryParams['tileHistory']) {
            this.rootStore.tileHistoryStore.updateTile(queryParams['tileHistory'])
        } else {
            this.rootStore.tileHistoryStore.clearOpenDrawer()
        }
    }

    @action updateSearchQuery = (value: string) => {
        this.searchQueryValue = value
    }

    @action updateScreenInfoValue = (value: ScreenInfoType) => {
        this.screenInfoValue = value
    }

    @action setIsPowerManagementOpen = (open: boolean) => {
        this.isPowerManagementOpen = open
    }

    @action async fetchAviorStatus() {
        if (!this.currentScreen || !this.currentScreen.id) {
            return
        }

        try {
            const result = await screenRouter.avior(this.currentScreen.id, 'map')
            runInAction('updateAviorStatus', () => {
                this.avior = result
            })
            // Send state to update last seen timestamp
            this.handleForceSyncAvior()
        } catch (error) {
            console.error('Error fetching avior status:', error)
        }
    }

    @action async sendAviorCommand(command: string) {
        if (!this.currentScreen || !this.currentScreen.id) {
            return
        }
        try {
            const result = await screenRouter.avior(this.currentScreen.id, 'cmd', command)
            return result
        } catch (error) {
            console.error('Error sending avior command:', error)
        }
    }

    handleRebootAvior = () => {
        const cmd = 'AT#REBOOT'
        this.sendAviorCommand(cmd)
    }

    handleForceSyncAvior = () => {
        const cmd = 'AT#SYNC'
        this.sendAviorCommand(cmd)
    }
}
