import DisplayReport, { DisplayReportJSON, DisplayReportParameters } from '../common/models/DisplayReport'
import BaseRouter, { EmptySearchParams, RouterFlag } from './BaseRouter'
import onAPI from './onAPI'

class ReportRouter extends BaseRouter<DisplayReport, DisplayReportJSON, EmptySearchParams> {
    protected route = '/display-report'

    protected flags = RouterFlag.create

    async create(item: DisplayReportParameters): Promise<DisplayReport> {
        const returnedJSON: DisplayReportJSON = await onAPI.fetchJSON(this.route, item)
        return this.newItem(returnedJSON)
    }

    protected newItem(json: DisplayReportJSON): DisplayReport {
        return new DisplayReport(json)
    }
}

const reportRouter = new ReportRouter()
export default reportRouter
