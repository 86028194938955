import * as React from 'react'
import { inject, observer } from 'mobx-react'

import RootStore from 'src/common/RootStore'

import ScreenErrorHistoryScope from './components/ScreenErrorHistoryScope'
import ScreenErrorHistoryLogs from './components/ScreenErrorHistoryLogs'
import { Collapse } from '@blueprintjs/core'

@inject('store')
@observer
class ScreenErrorHistory extends React.Component<{ store?: RootStore }> {
    componentDidMount = () => {
        this.props.store!.screenDiagnosticsUIStore.screenErrorHistoryManager.getInitialHistory()
    }

    render() {
        const screenErrorHistoryManager = this.props.store!.screenDiagnosticsUIStore.screenErrorHistoryManager
        const errorCodes = screenErrorHistoryManager.searchParams?.errorCodes
        const isMobile = this.props.store!.appUIStore.isMobile
        const shortDateTimeFormat = this.props.store!.userStore.me?.shortDateTimeFormat

        return (
            <div className='screen-error-history'>
                <Collapse
                    className='history-controls-collapse'
                    isOpen={!isMobile || screenErrorHistoryManager.areControlsOpen}
                    keepChildrenMounted
                    transitionDuration={0}
                >
                    <ScreenErrorHistoryScope />
                </Collapse>
                {isMobile && !screenErrorHistoryManager.areControlsOpen && (
                    <div className='selected-search-params-tags'>
                        <span className='bp3-tag bp3-round'>
                            {screenErrorHistoryManager.startDate?.format(shortDateTimeFormat)}
                            {' - '}
                            {screenErrorHistoryManager.endDate?.format(shortDateTimeFormat)}
                        </span>
                        <span className='bp3-tag bp3-round'>
                            {errorCodes && errorCodes.length > 0 ? errorCodes.length : 'All'} codes
                        </span>
                    </div>
                )}
                <ScreenErrorHistoryLogs
                    history={screenErrorHistoryManager.logs}
                    hasNextPage={!screenErrorHistoryManager.isFetchComplete}
                    isNextPageLoading={screenErrorHistoryManager.isFetching}
                    errorFetching={screenErrorHistoryManager.errorFetching}
                />
            </div>
        )
    }
}

export default ScreenErrorHistory
