import moment from 'moment'
import 'moment-duration-format'

export const secondsDurationToString = (seconds: number): string =>
    moment.duration(seconds, 'seconds').format('d[d] h[h] m[m] s[s]')

export const millisecondsDiffToString = (start: moment.Moment, end: moment.Moment): string =>
    moment
        .duration(end.diff(start), 'ms')
        .format('w[w] d[d] H[h] m[m] s[s]')
        .split(' ')
        .slice(0, 2)
        .join(' ')

export const calculateTimeDiff = (time1: moment.Moment, time2: moment.Moment): number => {
    try {
        if (!time1.isValid() || !time2.isValid()) {
            throw new Error('Invalid time')
        }
        return Math.abs(time1.diff(time2, 'seconds'))
    } catch (error) {
        console.error('Error calculating time diff', error)
        return Infinity
    }
}
