import { action, computed, observable } from 'mobx'
import BaseModel from 'src/common/models/BaseModel'

import Organisation, { OrganisationPermission } from 'src/common/models/Organisation'
import User from 'src/common/models/User'
import Screen from 'src/common/models/Screen'
import { tileTypeName } from 'src/common/models/Tile'

export interface AdminTableColData {
    label: string
    width?: number
    minWidth?: number
    disableSort?: boolean
}

export interface AdminTableRowData {
    id: string
    values: Record<string, any>
}

type RowData = (item: BaseModel) => AdminTableRowData

export abstract class AdminTabData {
    @observable storeName: string
    @observable columns: AdminTableColData[]
    @observable data: AdminTableRowData[]
    @observable rowData: RowData

    constructor(storeName: string, columns: AdminTableColData[], rowData: RowData) {
        this.storeName = storeName
        this.columns = columns
        this.rowData = rowData
    }

    @computed get columnLabels(): string[] {
        return this.columns.map(column => column.label)
    }

    @action setRowData = (items: BaseModel[], showDeleted: boolean) => {
        this.data = items.filter(i => (!showDeleted ? i.deletedAt === null : true))?.map(i => this.rowData(i)) ?? []
    }
}

export class OrgAdminTabData extends AdminTabData {
    constructor() {
        const columns = [
            { label: 'name', minWidth: 200 },
            { label: 'country', width: 200 },
            { label: 'zipCode', width: 100 },
            { label: 'maxScreens', width: 150 },
            {
                label: 'associatedOrganisations',
                minWidth: 260,
                disableSort: true,
            },
        ]
        super(
            'org',
            columns,
            (org: Organisation): AdminTableRowData => ({
                id: org.id!,
                values: {
                    name: org.name,
                    country: org.country,
                    zipCode: org.zipCode,
                    maxScreens: org.maxScreens,
                    associatedOrganisations: org.associatedOrganisations
                        ?.map(
                            organisation =>
                                organisation.name +
                                (organisation.permissions === OrganisationPermission.read ? ' [R]' : '') +
                                (organisation.permissions === OrganisationPermission.write ? ' [W]' : '')
                        )
                        .join(', '),
                    canEdit: org.canEdit,
                    canDelete: org.canDelete,
                    id: org.id,
                    deletedAt: org.deletedAt,
                },
            })
        )
    }
}

export class UserAdminTabData extends AdminTabData {
    constructor() {
        const columns = [
            { label: 'firstName' },
            { label: 'lastName' },
            { label: 'email', minWidth: 240 },
            { label: 'phone', width: 140 },
        ]
        super(
            'user',
            columns,
            (user: User): AdminTableRowData => ({
                id: user.id!,
                values: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    email: user.email,
                    phone: user.phone,
                    access: user.roleString,
                    showConsoleIcon: user.isSuperUser || user.hasConsoleAccess,
                    canEdit: user.canEdit,
                    canDelete: user.canDelete,
                    id: user.id,
                    emailVerified: user.emailVerified,
                    deletedAt: user.deletedAt,
                },
            })
        )
    }
}

export class ScreenAdminTabData extends AdminTabData {
    constructor(screens: Screen[]) {
        const baseColumns = [
            { label: 'name' },
            { label: 'manufacturer', width: 130 },
            { label: 'tileType', width: 130 },
            { label: 'geometry', width: 120 },
            { label: 'resolution', width: 130 },
            { label: 'connectVersion', width: 90 },
        ]

        const allColumns = [...baseColumns]

        const labelKeysToAdd = new Set<string>()

        screens.forEach(screen => {
            // Collect label keys to add
            screen?.labelKeyValuePairs.forEach(label => {
                if (!allColumns.some(col => col.label === label.key)) {
                    labelKeysToAdd.add(label.key)
                }
            })
        })

        // Insert new label columns after the "name" column
        const insertIndex = allColumns.findIndex(col => col.label === 'name') + 1
        Array.from(labelKeysToAdd)
            .reverse()
            .forEach(key => {
                allColumns.splice(insertIndex, 0, { label: key })
            })

        super(
            'screen',
            allColumns,
            (screen: Screen): AdminTableRowData => ({
                id: screen.id!,
                values: {
                    name: screen.name,
                    // Conditionally insert new column data based on labels
                    ...screen.labelKeyValuePairs.reduce((acc, label) => {
                        acc[label.key] = label.value
                        return acc
                    }, {}),
                    manufacturer: screen.manufacturerName,
                    tileType: screen.tileType && tileTypeName(screen.tileType),
                    geometry: screen.geometryDisplay,
                    resolution: screen.resolution,
                    version: screen.comptroller?.connectVersion ?? '',
                    canEdit: screen.canEdit,
                    canDelete: screen.canDelete,
                    isConnected: screen.isConnected,
                    id: screen.id,
                    enabled: screen.enabled,
                    deletedAt: screen.deletedAt,
                },
            })
        )
    }
}
