import * as React from 'react'
import { observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'

import { Table } from 'reactstrap'
import backChevron from 'src/assets/images/backChevron.svg'
import playsIcon from 'src/assets/images/playsIcon.svg'
import timeIcon from 'src/assets/images/timeIcon.svg'
import viewsIcon from 'src/assets/images/viewsIcon.svg'
import imageBroken from 'src/assets/images/imageBroken.svg'
import moment from 'moment'

interface ImageStatsProps {
    store?: RootStore
}

function ImageStats(props: ImageStatsProps) {
    const { store } = props

    const isMobile = store!.appUIStore.isMobile

    const liveUIStore = store!.liveUIStore
    const liveImage = liveUIStore.liveImageForScreenSummary
    const liveImageStats = liveUIStore.liveImageStatsForScreenSummary

    const handleImageResponseError = (event: any) => {
        // Prevent infinite loop if fallback src invalid
        event.target.onerror = null
        event.target.src = imageBroken
    }

    if (!liveImage) {
        return null
    }

    const { screenId, URL: imageUrl, filename, unnamed } = liveImage

    // Convert total and average time into moment object
    const totalTimeSeconds = moment.duration(liveImageStats?.totalTime, 'seconds')
    const averageTimeSeconds = moment.duration(liveImageStats?.averageTime, 'seconds')

    const screen = store!.screenStore.findItem(screenId)

    if (!screen) {
        return null
    }

    return (
        <div className='p-4'>
            <div className='d-flex'>
                {isMobile && (
                    <div className='mr-3' onClick={() => liveUIStore.setIsStatsOpen(false)}>
                        <img className='diagnostics-back-arrow' src={backChevron} alt='Back' />
                    </div>
                )}
                <h1>Image Stats</h1>
            </div>
            <br />
            <h2>{screen.name}</h2>
            <br />

            <div className='d-flex justify-content-center'>
                <img
                    className='w-auto max-w-full max-h-350px'
                    src={imageUrl ? imageUrl : imageBroken}
                    alt={imageUrl ? (filename ? filename : '') : 'Image missing'}
                    onError={handleImageResponseError}
                />
            </div>

            <div className='mt-4'>
                <h3 className='modal-image-title align-top'>
                    {!unnamed ? filename : i18n.t('reportsPage.unnamedImage') + '(' + filename + ')'}
                </h3>

                <Table className='custom-summary-table mt-3' responsive>
                    <tbody>
                        <tr key={screenId} className='custom-summary-table-row'>
                            <td className='p-0'>
                                <table className='mt-3'>
                                    <tbody>
                                        <tr>
                                            <td className='align-middle'>
                                                <img className='mr-2' src={playsIcon} alt='Play count' height={10} />{' '}
                                                {liveImage.plays} plays
                                            </td>
                                            <td className='align-middle'>
                                                <img className='mr-2' src={timeIcon} alt='Play time' height={13} />
                                                <span className={!liveImageStats ? 'bp3-skeleton' : ''}>
                                                    Total time {totalTimeSeconds.format('H[h] m[m] s[s]')} /{' '}
                                                    {averageTimeSeconds.format('s[s]')} {i18n.t('common.average')}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='align-middle'>
                                                <img className='mr-2' src={viewsIcon} alt='First played' height={7.6} />
                                                <span>First played at</span>
                                                <span className={'ml-1' + (!liveImageStats ? ' bp3-skeleton' : '')}>
                                                    {moment(liveImageStats?.firstPlayed).format(
                                                        store!.userStore.me?.dateTimeFormat
                                                    )}
                                                </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='align-middle'>
                                                <img className='mr-2' src={viewsIcon} alt='Last played' height={7.6} />
                                                <span>Last played at </span>
                                                <span className={'ml-1' + (!liveImageStats ? ' bp3-skeleton' : '')}>
                                                    {moment(liveImageStats?.lastPlayed).format(
                                                        store!.userStore.me?.dateTimeFormat
                                                    )}
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    )
}

export default observer(ImageStats)
