import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

// Amplify
import { Hub } from 'aws-amplify'
// @ts-expect-error
import { HubPayload } from '@aws-amplify/core/lib/Hub'

import SignInForm from './components/SignInForm'
import ForgotPassword from './components/ForgotPassword'
import ResetPassword from './components/ResetPassword'
import RequireNewPassword from './components/RequireNewPassword'
import VerifyMFA from './components/VerifyMFA'
import LanguageSelector from '../../../../common/components/LanguageSelector'
import outWatchLogo from 'src/assets/images/outWatchLogo.svg'
import outWatchLogoFull from 'src/assets/images/outWatchLogoFull.svg'
import Loader from '../../../../common/components/Loader'
import { ProgressBar } from '@blueprintjs/core'
import Button from 'reactstrap/lib/Button'
import views from 'src/config/views'

@inject('store')
@observer
class SignIn extends React.Component<{ store?: RootStore } & WithNamespaces> {
    componentDidMount() {
        // Initialise Hub listener
        Hub.listen('auth', data => {
            const { payload } = data
            this.onAuthEvent(payload)
        })
    }

    onAuthEvent(payload: HubPayload) {
        switch (payload.event) {
            case 'signIn':
                this.props.store!.authStore.authenticate()
                break
        }
    }

    render() {
        return (
            <div className='auth-container'>
                {!this.props.store!.userStore.me && !this.props.store!.authStore.isFetching && (
                    <React.Fragment>
                        <div className='auth-language-selector d-none d-md-block'>
                            <LanguageSelector />
                        </div>
                        <div className='auth-sign-in'>
                            <div className='auth-mobile-header d-md-none'>
                                <img className='auth-mobile-logo' src={outWatchLogo} alt='OutWatch Logo' />
                                <div className='auth-language-selector-mobile'>
                                    <LanguageSelector />
                                </div>
                            </div>
                            <div className='auth-leftside-bar d-none d-md-flex'>
                                <div className='auth-leftside-bar-top'>
                                    <img src={outWatchLogo} alt='OutWatch Logo' />
                                    <div className='auth-leftside-bar-text'>
                                        <span>{i18n.t('authPage.authLead')}</span>
                                    </div>
                                </div>
                                <div className='auth-leftside-bar-bottom text-center'>
                                    <a href='https://outwatch.io' target='_blank' rel='noopener noreferrer'>
                                        <img src={outWatchLogoFull} alt='OutWatch Logo' />
                                    </a>
                                    <div className='auth-leftside-bar-smalltext'>
                                        © {new Date().getFullYear()} OutWatch PTY Limited. All rights reserved.
                                    </div>
                                </div>
                            </div>
                            <div className='auth-form-states'>
                                <div>
                                    <SignInForm />
                                    <ForgotPassword />
                                    <ResetPassword />
                                    <RequireNewPassword />
                                    <VerifyMFA />
                                    <hr />
                                    <div className='auth-links'>
                                        <Button
                                            className='custom-button-link'
                                            color='link'
                                            onClick={() => this.props.store!.router.goTo(views.privacyPolicy)}
                                        >
                                            <span>{i18n.t('authPage.privacyPolicy')}</span>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )}
                {this.props.store!.authStore.isFetching && (
                    <div className='auth-loading'>
                        <Loader />
                        <ProgressBar
                            className='auth-progress-bar bp3-no-stripes'
                            value={this.props.store!.authStore.loadingValue}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default withNamespaces()(SignIn)
