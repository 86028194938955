import * as React from 'react'
import * as ReactDOM from 'react-dom'
import * as dotenv from 'dotenv'
import * as Sentry from '@sentry/browser'
// import Amplify from 'aws-amplify'

// MobX
import { configure } from 'mobx'
import { Provider } from 'mobx-react'
import views from './config/views'
import { MobxRouter, startRouter } from 'mobx-router'

import { rootStore } from './common/RootStore'

// i18n
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

// Providers
import { OWLibProvider } from './providers/OutWatchLibProvider'

// UI imports
import ErrorBoundary from './common/components/ErrorBoundary'
import AppNavbar from './common/components/AppNavbar'
import PopoutWrapper from './common/components/PopoutWrapper'
import OrgSelectionWrapper from './common/components/OrgSelectionWrapper'
import UnsupportedBrowser from './common/components/UnsupportedBrowser'
import './assets/sass/styles.scss'

declare global {
    interface Document {
        documentMode?: any
    }
    interface Window {
        StyleMedia?: any
        fwSettings?: any
        FreshworksWidget?: any
    }
    interface Navigator {
        userLanguage?: string
    }
}

// Set document title
if (process.env.REACT_APP_ENV === 'development') {
    document.title = '💻 ' + document.title
} else if (process.env.REACT_APP_ENV === 'staging') {
    document.title = '🕵️ ' + document.title
}

// dotenv
dotenv.config()

// mobx options
configure({
    enforceActions: 'observed',
})

// Init Sentry if not development
if (process.env.REACT_APP_ENV !== 'development') {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: process.env.REACT_APP_CURRENT_GIT_SHA,
        environment: process.env.REACT_APP_ENV,
    })
}

// Start mobx-router
startRouter(views, rootStore)

// If a user is logged in, get current credentials
rootStore.authStore.authenticate()

// AWS Amplify debugging
// Amplify.Logger.LOG_LEVEL = 'DEBUG'

// Browser detection
// Internet Explorer 6-11
const isIE = /* @cc_on!@*/ false || !!document.documentMode
// Edge 20-44
const isEdge = !isIE && !!window.StyleMedia
// NOTE: Edge 79+ (Chromium based) is safe

if (isIE || isEdge) {
    ReactDOM.render(<UnsupportedBrowser />, document.getElementById('root'))
} else {
    ReactDOM.render(
        <Provider store={rootStore}>
            <OWLibProvider>
                <I18nextProvider i18n={i18n}>
                    <div className='application'>
                        <ErrorBoundary>
                            <AppNavbar />
                            <PopoutWrapper />
                            <div className='application-content'>
                                <OrgSelectionWrapper>
                                    <MobxRouter />
                                </OrgSelectionWrapper>
                            </div>
                        </ErrorBoundary>
                    </div>
                </I18nextProvider>
            </OWLibProvider>
        </Provider>,
        document.getElementById('root')
    )
}
