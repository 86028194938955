import * as React from 'react'
import { Button, Dialog, Classes } from '@blueprintjs/core'
import Select from 'react-select'
import { inject, observer } from 'mobx-react'
import { AdminBatchAction } from 'src/common/stores/AdminStore'
import { runInAction } from 'mobx'
import RootStore from 'src/common/RootStore'
import { Label, FormGroup, InputGroup } from 'reactstrap'
import Toggle from 'react-toggle'
import Input from 'reactstrap/lib/Input'

interface SoftwareUpdateModalProps {
    onSubmit: () => void
    onClose: () => void
    isOpen: boolean
}

@inject('store')
@observer
class SoftwareUpdateModal extends React.Component<SoftwareUpdateModalProps & { store?: RootStore }> {
    componentDidMount() {
        this.initializeDefaults()
    }

    componentDidUpdate(prevProps: SoftwareUpdateModalProps & { store?: RootStore }) {
        if (
            prevProps.store!.adminUIStore.softwareUpdateSelection !==
            this.props.store!.adminUIStore.softwareUpdateSelection
        ) {
            this.initializeDefaults()
        }
    }

    initializeDefaults = () => {
        const { store } = this.props
        const uiStore = store!.adminUIStore

        runInAction(() => {
            uiStore.softwareUpdateOptions.schedule = 'immediately'
        })
    }

    handleConfirm = () => {
        const { onSubmit, onClose } = this.props
        this.props.store!.adminUIStore.submitBatchAction()
        onClose()
    }

    getSoftwareComponentName = () => {
        const { store } = this.props
        const uiStore = store!.adminUIStore
        if (!uiStore?.softwareUpdateSelection || !uiStore?.currentSoftwareOptions) {
            return ''
        }
        return uiStore.currentSoftwareOptions.find(option => option.value === uiStore.softwareUpdateSelection)?.label
    }

    renderOptions = () => {
        const { store } = this.props
        const uiStore = store!.adminUIStore

        if (!uiStore?.softwareUpdateSelection) {
            return null
        }

        if (uiStore.softwareUpdateSelection === AdminBatchAction.connect && !uiStore.softwareUpdateOptions.version) {
            runInAction(() => {
                uiStore.softwareUpdateOptions.version = uiStore.connectVersionOptions[0]?.value || ''
            })
        } else if (uiStore.softwareUpdateSelection === AdminBatchAction.custom) {
            runInAction(() => {
                uiStore.softwareUpdateOptions.files = ['']
                uiStore.softwareUpdateOptions.downloadOnly = true
            })
        }

        switch (uiStore.softwareUpdateSelection) {
            case AdminBatchAction.connect:
                return (
                    <FormGroup label='Version'>
                        <Select
                            options={uiStore.connectVersionOptions.map(option => ({
                                label: option.label,
                                value: option.value,
                                isDisabled: option.isDisabled,
                            }))}
                            value={uiStore.connectVersionOptions.find(
                                option => option.value === uiStore.softwareUpdateOptions.version
                            )}
                            onChange={selectedOption =>
                                runInAction(() => {
                                    uiStore.softwareUpdateOptions.version = selectedOption ? selectedOption.value : ''
                                })
                            }
                            isOptionDisabled={option => option.isDisabled}
                        />
                    </FormGroup>
                )
            case AdminBatchAction.lednet:
                return (
                    <FormGroup>
                        <Label for='version' style={{ fontSize: '14px' }}>
                            Version
                        </Label>
                        <Input
                            onChange={e =>
                                runInAction(() => {
                                    uiStore.softwareUpdateOptions.version = e.target.value
                                })
                            }
                            type='text'
                            id='version'
                            autoComplete='off'
                            defaultValue={''}
                            placeholder='Leave blank for latest'
                        />
                    </FormGroup>
                )
            case AdminBatchAction.system:
                return (
                    <FormGroup label='Command'>
                        <Select
                            options={[
                                { label: 'Force Windows Update', value: 'forceWindowsUpdate' },
                                { label: 'Force Drivers Update', value: 'forceDriversUpdate' },
                                { label: 'Enable Windows Updates', value: 'enableWindowsUpdates' },
                                { label: 'Disable Windows Updates', value: 'disableWindowsUpdates' },
                            ]}
                            onChange={selectedOption =>
                                runInAction(() => {
                                    uiStore.softwareUpdateOptions.commands = selectedOption
                                        ? [{ command: selectedOption.value, args: [] }]
                                        : undefined
                                })
                            }
                        />
                    </FormGroup>
                )
            case AdminBatchAction.custom:
                return (
                    <FormGroup>
                        <Label for='url' style={{ fontSize: '14px' }}>
                            URL
                        </Label>
                        <Input
                            onChange={e =>
                                runInAction(() => {
                                    uiStore.softwareUpdateOptions.files = [e.target.value]
                                })
                            }
                            type='text'
                            id='url'
                            autoComplete='off'
                            defaultValue={''}
                            placeholder='URL of the file you want to install'
                        />
                    </FormGroup>
                )
            default:
                return null
        }
    }

    renderAdditionalOptions = () => {
        const { store } = this.props
        const uiStore = store!.adminUIStore
        let downloadOnlyFixed = false
        if (!uiStore) {
            return null
        }

        if (!uiStore.softwareUpdateOptions.schedule) {
            runInAction(() => {
                uiStore.softwareUpdateOptions.schedule = 'immediately'
            })
        }

        if (uiStore.batchActionValue === AdminBatchAction.custom) {
            downloadOnlyFixed = true
        }

        return (
            <>
                {uiStore.batchActionValue === AdminBatchAction.system && (
                    <FormGroup>
                        <label htmlFor='scheduleDropdown' style={{ marginBottom: '8px', fontSize: '14px' }}>
                            Schedule
                        </label>
                        <Select
                            id='scheduleDropdown'
                            options={[
                                { label: 'Immediately', value: 'immediately' },
                                { label: 'On Reboot', value: 'onReboot' },
                            ]}
                            value={[
                                { label: 'Immediately', value: 'immediately' },
                                { label: 'On Reboot', value: 'onReboot' },
                            ].find(option => option.value === uiStore.softwareUpdateOptions.schedule)}
                            onChange={selectedOption =>
                                runInAction(() => {
                                    uiStore.softwareUpdateOptions.schedule = selectedOption ? selectedOption.value : ''
                                })
                            }
                        />
                    </FormGroup>
                )}
                {uiStore.batchActionValue !== AdminBatchAction.system && (
                    <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Toggle
                                defaultChecked={uiStore.softwareUpdateOptions.downloadOnly || false}
                                className='custom-toggle'
                                icons={false}
                                disabled={downloadOnlyFixed}
                                onChange={e =>
                                    runInAction(() => {
                                        uiStore.softwareUpdateOptions.downloadOnly = e.target.checked
                                    })
                                }
                                id='downloadOnly'
                            />
                            <Label
                                for='downloadOnly'
                                style={{ marginLeft: '8px', fontSize: '14px', paddingTop: '4px' }}
                            >
                                Download Only
                            </Label>
                        </div>
                    </FormGroup>
                )}
                {uiStore.batchActionValue !== AdminBatchAction.system && !uiStore.softwareUpdateOptions.downloadOnly && (
                    <>
                        {(uiStore.softwareUpdateSelection === AdminBatchAction.connect ||
                            uiStore.softwareUpdateSelection === AdminBatchAction.lednet) && (
                            <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Toggle
                                        defaultChecked={uiStore.softwareUpdateOptions.force || false}
                                        className='custom-toggle'
                                        icons={false}
                                        onChange={e =>
                                            runInAction(() => {
                                                uiStore.softwareUpdateOptions.force = e.target.checked
                                            })
                                        }
                                        id='forceUpdate'
                                    />
                                    <Label
                                        for='forceUpdate'
                                        style={{ marginLeft: '8px', fontSize: '14px', paddingTop: '4px' }}
                                    >
                                        Force Update
                                    </Label>
                                </div>
                            </FormGroup>
                        )}
                        <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Toggle
                                    defaultChecked={uiStore.softwareUpdateOptions.restartConnect || false}
                                    className='custom-toggle'
                                    icons={false}
                                    onChange={e =>
                                        runInAction(() => {
                                            uiStore.softwareUpdateOptions.restartConnect = e.target.checked
                                        })
                                    }
                                    id='restartConnect'
                                />
                                <Label
                                    for='restartConnect'
                                    style={{ marginLeft: '8px', fontSize: '14px', paddingTop: '4px' }}
                                >
                                    Restart Connect
                                </Label>
                            </div>
                        </FormGroup>
                    </>
                )}
            </>
        )
    }

    render() {
        const { store } = this.props
        const uiStore = store!.adminUIStore
        const { isOpen, onClose } = this.props
        console.log('render', uiStore.softwareUpdateSelection, uiStore.batchActionValue)
        return (
            <Dialog isOpen={isOpen} onClose={onClose} title='Update Options'>
                <div className={Classes.DIALOG_BODY}>
                    <h4 style={{ color: 'black', fontSize: '1.5em', marginBottom: '1em' }}>
                        {this.getSoftwareComponentName()}
                    </h4>
                    {this.renderOptions()}
                    {this.renderAdditionalOptions()}
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button intent='primary' onClick={this.handleConfirm}>
                            Submit
                        </Button>
                    </div>
                </div>
            </Dialog>
        )
    }
}

export default SoftwareUpdateModal
