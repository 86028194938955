import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { reactI18nextModule } from 'react-i18next'

import translationEN from './locales/en/translation.json'
import translationZH_HANS from './locales/zh_HANS/translation.json'

export enum Translations {
    en = 'English',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    zh_HANS = '中文',
}

// Translations
const resources = {
    en: {
        translation: translationEN,
    },
    zh_HANS: {
        translation: translationZH_HANS,
    },
}

i18n.use(detector)
    .use(reactI18nextModule) // Passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en', // Use en if detected lng is not available

        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
