import * as React from 'react'
import { useCallback } from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { useDropzone } from 'react-dropzone'
import { AppToaster } from 'src/common/components/AppToaster'
import { Intent, ProgressBar } from '@blueprintjs/core'
import { FormText, Row, Col, Button, Form, FormGroup, Label, Input } from 'reactstrap'
import addIcon from 'src/assets/images/addIcon.svg'
import editIcon from 'src/assets/images/editIcon.svg'
import deleteIcon from 'src/assets/images/deleteIcon.svg'
import { Dialog } from '@blueprintjs/core'

function Dropzone(props: any) {
    const onDrop = useCallback(
        (acceptedFile: File[]) => {
            if (acceptedFile.length > 0) {
                props.handleUploadfile(acceptedFile)
            } else {
                AppToaster.show({
                    message: i18n.t('feedback.errors.fileNotAccepted'),
                    intent: Intent.DANGER,
                })
            }
        },
        [props]
    )
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        multiple: false,
        accept: ['image/jpeg', 'image/png', 'image/gif'],
        maxSize: 1048576,
    })

    return (
        <div {...getRootProps()} className='custom-dropzone'>
            <input {...getInputProps()} />
            {!props.isLogoUploading ? (
                <div className='text-center'>
                    <h6>{i18n.t('settingsPage.organisationLogoUploadHelpText')}</h6>
                    <h6>
                        <em>{i18n.t('settingsPage.organisationLogoUploadFileTypes')}</em>
                    </h6>
                </div>
            ) : (
                <ProgressBar />
            )}
        </div>
    )
}

@inject('store')
@observer
class OrgDetails extends React.Component<{ store?: RootStore } & WithNamespaces> {
    state = {
        currentLabelId: '',
        currentScreenLabel: '',
        labelToDelete: '',
    }

    handleUploadFile = (file: File[]) => {
        this.props.store!.orgStore.uploadNewLogo(file[0])
    }

    render() {
        const orgStore = this.props.store!.orgStore
        const me = this.props.store!.userStore.me
        const myOrg = orgStore.myOrg

        if (!me || !myOrg) {
            return null
        }

        return (
            <>
                <div>
                    <h2>{i18n.t('settingsPage.organisationDetails')}</h2>
                    <br />
                    <div>
                        <Row>
                            <Col lg={3} md={4} sm={6} xs={6}>
                                <h4>{i18n.t('settingsPage.organisationName')}</h4>
                                <h5 className='selectable-text'>{myOrg.name}</h5>
                                <h4>{i18n.t('settingsPage.organisationCountry')}</h4>
                                <h5 className='selectable-text'>{myOrg.country}</h5>
                            </Col>
                            <Col lg={3} md={4} sm={6} xs={6}>
                                <h4>{i18n.t('settingsPage.organisationZipCode')}</h4>
                                <h5 className='selectable-text'>{myOrg.zipCode}</h5>
                                <h4>{i18n.t('settingsPage.organisationID')}</h4>
                                <h5 className='selectable-text'>{myOrg.id}</h5>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={6} sm={12} xs={12}>
                                <h4>{i18n.t('settingsPage.associatedOrgs')}</h4>
                                <FormText>{i18n.t('settingsPage.associatedOrgsHelpText')}</FormText>
                                <br />
                                <h5 className='selectable-text'>
                                    {orgStore.associatedOrgsNames
                                        ? orgStore.associatedOrgsNames
                                        : i18n.t('common.none')}
                                </h5>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col md={6} sm={12} xs={12}>
                                <h4>{i18n.t('settingsPage.associatedByOrgs')}</h4>
                                <FormText>{i18n.t('settingsPage.associatedByOrgsHelpText')}</FormText>
                                <br />
                                <h5 className='selectable-text'>
                                    {orgStore.associatedByOrgsNames
                                        ? orgStore.associatedByOrgsNames
                                        : i18n.t('common.none')}
                                </h5>
                            </Col>
                        </Row>
                    </div>
                    <hr />
                    <h2>{i18n.t('settingsPage.organisationLogo')}</h2>
                    <br />
                    <h4>Upload organisation logo</h4>
                    <Row>
                        <Col md={6} sm={12} xs={12}>
                            <Dropzone
                                handleUploadfile={this.handleUploadFile}
                                isLogoUploading={orgStore.isLogoUploading}
                            />
                        </Col>
                    </Row>
                    {!myOrg.isAdvertiserOnly && (
                        <>
                            <br />
                            <hr />
                            <div className='d-flex'>
                                <h2>Screen labels</h2>
                                <Button
                                    className='custom-button-edit ml-3'
                                    onClick={orgStore.toggleEditLabelsDialog}
                                    color='primary'
                                    disabled={
                                        !this.state.currentLabelId &&
                                        myOrg.screenLabels &&
                                        Object.values(myOrg.screenLabels).length >= 3
                                    }
                                >
                                    <img src={addIcon} alt='Edit' height={12} />
                                </Button>
                            </div>
                            <FormText>
                                Up to 3 labels can be added to screens to store additional metadata about the screen.
                                For example, a label could be used to store an internal ID or site code.
                            </FormText>
                            <br />
                            <div className='label-grid'>
                                {myOrg.screenLabels ? (
                                    Object.entries(myOrg.screenLabels).map(([key, label]) => (
                                        <div key={key} className='label-row'>
                                            <h4>{label}</h4>
                                            <Button
                                                className='custom-button-edit ml-3'
                                                onClick={() => {
                                                    this.setState({
                                                        currentLabelId: key,
                                                        currentScreenLabel: label,
                                                    })
                                                    orgStore.toggleEditLabelsDialog()
                                                }}
                                                color='primary'
                                            >
                                                <img src={editIcon} alt='Edit' height={12} />
                                            </Button>
                                            <Button
                                                className='custom-button-edit ml-3'
                                                onClick={() => {
                                                    this.setState({ labelToDelete: key })
                                                    orgStore.toggleEditLabelsDialog()
                                                }}
                                                color='danger'
                                            >
                                                <img src={deleteIcon} alt='Edit' height={12} />
                                            </Button>
                                        </div>
                                    ))
                                ) : (
                                    <h4 className='text-black'>None set</h4>
                                )}
                            </div>
                        </>
                    )}
                </div>

                {/* Label management dialog */}
                <Dialog
                    isOpen={orgStore.isEditLabelsOpen}
                    className='custom-dialog'
                    canOutsideClickClose={false}
                    title='Manage labels'
                    onClose={() => {
                        orgStore.toggleEditLabelsDialog()
                        this.setState({
                            currentLabelId: '',
                            currentScreenLabel: '',
                            labelToDelete: '',
                        })
                    }}
                >
                    <div className='details-form'>
                        {this.state.labelToDelete ? (
                            <h5 className='text-danger mt-2'>
                                Warning: this will remove this label from all screens that have it set.
                            </h5>
                        ) : (
                            <Row>
                                <Col xs={12} md={6}>
                                    <Form className='auth-form mb-3' onSubmit={e => e.preventDefault()}>
                                        <FormGroup>
                                            <Label for='screenLabel'>Label name i.e. &quot;Site Code&quot;</Label>
                                            <Input
                                                autoFocus
                                                id='screenLabel'
                                                name='screenLabel'
                                                type='text'
                                                autoComplete='off'
                                                onChange={e => this.setState({ currentScreenLabel: e.target.value })}
                                                value={this.state.currentScreenLabel}
                                            />
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                        )}
                        <div className='dialog-actions'>
                            {this.state.labelToDelete ? (
                                <Button
                                    className='custom-button-large'
                                    onClick={async () => {
                                        await orgStore.deleteScreenLabel(this.state.labelToDelete)
                                        this.setState({ labelToDelete: '' })
                                    }}
                                    color='danger'
                                >
                                    Confirm delete
                                </Button>
                            ) : (
                                <Button
                                    className='custom-button-large'
                                    onClick={async () => {
                                        await orgStore.addScreenLabel(
                                            this.state.currentScreenLabel,
                                            this.state.currentLabelId
                                        )
                                        this.setState({
                                            currentLabelId: '',
                                            currentScreenLabel: '',
                                        })
                                    }}
                                    color='primary'
                                    disabled={!this.state.currentScreenLabel}
                                >
                                    {this.state.currentLabelId ? 'Update' : 'Add'}
                                </Button>
                            )}
                        </div>
                    </div>
                </Dialog>
            </>
        )
    }
}

export default withNamespaces()(OrgDetails)
