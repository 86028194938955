import * as React from 'react'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import BaseVirtualizedTable, {
    BaseVirtualizedTableProps,
    BaseVirtualizedTableCol,
} from './BaseVirtualizedTableDraggable'

import { eventPreventDefault } from 'src/common/utils/EventUtils'

import dragIcon from 'src/assets/images/dragIcon.svg'
import { popouts } from '../popout/popouts'

const DEFAULT_HEADER_ROW_HEIGHT = 20
const DEFAULT_ROW_HEIGHT = 50
const DEFAULT_COL_WIDTH = 100

const DragHandle = SortableHandle((props: any) => (
        <div className={'drag-handle' + (props.show === true ? ' show' : props.show === false ? ' hide' : '')}>
            <img src={dragIcon} alt='Drag handle' draggable={false} onDragStart={eventPreventDefault} />
        </div>
    ))

const DraggableTable = SortableContainer(BaseVirtualizedTable, { withRef: true })

const DraggableTableRowRenderer = SortableElement(({ index, style, className, columns, draggable }: any) => (
    <div style={style} className={className} role='row' key={index}>
        <DragHandle show={draggable} />
        {columns}
    </div>
))

export type DraggableVirtualizedCol = BaseVirtualizedTableCol

interface DraggableVirtualizedTableProps extends BaseVirtualizedTableProps {
    onSort: (oldIndex: any, newIndex: any) => void
    draggable?: boolean
    popoutId?: string
    hashes?: Set<string>
}

class DraggableVirtualizedTable extends React.Component<DraggableVirtualizedTableProps> {
    draggableTableRef: any

    componentDidUpdate(prevProps: DraggableVirtualizedTableProps) {
        // If new rows added, scroll to bottom
        if (this.props.hashes && prevProps.hashes) {
            if (prevProps.hashes.size !== this.props.hashes.size) {
                if (this.props.rows.length > prevProps.rows.length) {
                    this.draggableTableRef?.refs.wrappedInstance?.handleScrollToRow(this.props.rows.length - 1)
                }
            }
        }
    }

    onSortEnd = ({ oldIndex, newIndex }: any) => {
        this.props.onSort(oldIndex, newIndex)
    }

    rowRenderer = (props: any) => <DraggableTableRowRenderer {...props} draggable={this.props.draggable} />

    getScrollContainer = (): HTMLElement => {
        let targetDocument = document
        const popoutId = this.props.popoutId
        if (popoutId) {
            // Find scroll container in popout if popoutId provided
            Object.entries(popouts).forEach(entry => {
                if (entry[0] === popoutId && entry[1].child) {
                    targetDocument = entry[1].child.document
                }
            })
        }

        return targetDocument.getElementsByClassName('ReactVirtualized__Grid')[0] as HTMLElement
    }

    render() {
        const { data, cols, rows, width, height, draggable } = this.props

        const rowHeight = this.props.rowHeight || DEFAULT_ROW_HEIGHT

        let rowWidth = 0
        cols.forEach(col => {
            rowWidth += col.width || DEFAULT_COL_WIDTH
        })

        const tableCols = cols.map(col => ({
                dataKey: col.dataKey,
                label: col.label,
                width: col.width || DEFAULT_COL_WIDTH,
                minWidth: col.minWidth,
                maxWidth: col.maxWidth,
                flexGrow: col.flexGrow,
                className: col.className,
                disableSort: col.disableSort,
                cellElement: col.cellElement,
            }))

        return (
            <DraggableTable
                {...this.props}
                // react-sortable-hoc props
                ref={instance => (this.draggableTableRef = instance)}
                getContainer={this.getScrollContainer}
                useDragHandle
                lockAxis='y'
                lockToContainerEdges
                onSortEnd={this.onSortEnd}
                // BaseVirtualizedTableProps
                cols={tableCols}
                data={data}
                width={width || rowWidth}
                height={height || DEFAULT_HEADER_ROW_HEIGHT + rowHeight * rows.length}
                headerHeight={DEFAULT_HEADER_ROW_HEIGHT}
                rowCount={rows.length}
                rowRenderer={this.rowRenderer}
                className={draggable === false ? 'hide-drag-handle' : 'show-drag-handle'}
            />
        )
    }
}

export default DraggableVirtualizedTable
