import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { TransitionType } from 'src/common/models/MediaSettings'
import { eventPreventDefault, formFloatValidation } from 'src/common/utils/EventUtils'

import Screen from 'src/common/models/Screen'

import { Input, Form, Button } from 'reactstrap'
import Select from 'react-select'
import { DropdownIndicator, Option, ValueLabelPair } from 'src/common/components/SelectComponents'
import editIcon from 'src/assets/images/editIcon.svg'

interface PlayerSettingsProps {
    screen: Screen
    isUpdating: boolean
    playerDiv?: HTMLElement
}

@inject('store')
@observer
class PlayerSettings extends React.Component<{ store?: RootStore } & PlayerSettingsProps & WithNamespaces> {
    private mediaManager = this.props.store!.mediaStore.getMediaManager(this.props.screen.id!)
    private playerManager = this.props.store!.playerStore.getManager(this.props.screen.id!)

    handlePlayerSettingsChange = (event: React.FocusEvent<HTMLInputElement>) => {
        this.mediaManager.updatePlayerSettings(event.target.id, event.target.value)
    }

    handleTransitionOptionChange = (selectedOption: ValueLabelPair) => {
        this.mediaManager.updatePlayerSettings('transitionType', selectedOption.value)
        this.mediaManager.setSettingFocused('transitionType', false)
    }

    handleSaveSettings = () => {
        this.mediaManager.saveSettingsChanges()
        this.playerManager.toggleEditSettings()
    }

    handleCancelSettings = () => {
        this.mediaManager.clearSettingsChanges()
        this.playerManager.toggleEditSettings()
    }

    handleInputFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        this.mediaManager.setSettingFocused(event.target.id, true)
    }

    handleInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        this.mediaManager.setSettingFocused(event.target.id, false)
    }

    handleOnKeyDown = (event: any) => {
        formFloatValidation(event)

        switch (event.key) {
            case 'Enter':
                event.preventDefault()
                // Trigger blur
                event.target.blur()
                break
            default:
                return
        }
    }

    render() {
        const mediaManager = this.mediaManager
        const playerManager = this.playerManager
        if (!this.props.screen || !mediaManager.mediaContainer || !playerManager) {
            return null
        }

        const existingSettings = mediaManager.mediaContainer.settings
        if (!existingSettings) {
            return null
        }

        const updateTransitionType = mediaManager.updatePlayerSettingsMap.get('transitionType')

        let transitionTypeOptionValue: TransitionType
        if (updateTransitionType !== undefined) {
            transitionTypeOptionValue = TransitionType[updateTransitionType]
        } else if (mediaManager.pendingScreenSettings) {
            transitionTypeOptionValue = mediaManager.pendingScreenSettings.transitionType
        } else {
            transitionTypeOptionValue = existingSettings.transitionType
        }

        const selectedTransitionTypeOption = {
            value: transitionTypeOptionValue,
            label: i18n.t('livePage.player.settings.' + transitionTypeOptionValue),
        }

        const transitionOptions = []
        for (const key of Object.keys(TransitionType)) {
            const value = key
            const label = i18n.t('livePage.player.settings.' + TransitionType[key])
            transitionOptions.push({ value, label })
        }

        const dwellTimeValue =
            mediaManager.updatePlayerSettingsMap.get('dwellTime') !== undefined
                ? mediaManager.updatePlayerSettingsMap.get('dwellTime')
                : (mediaManager.pendingScreenSettings && mediaManager.pendingScreenSettings.dwellTime) ||
                  String(existingSettings.dwellTime)

        const transitionTimeValue =
            mediaManager.updatePlayerSettingsMap.get('transitionTime') !== undefined
                ? mediaManager.updatePlayerSettingsMap.get('transitionTime')
                : (mediaManager.pendingScreenSettings && mediaManager.pendingScreenSettings.transitionTime) ||
                  String(existingSettings.transitionTime)

        return (
            <Form className='player-settings' onSubmit={eventPreventDefault}>
                <div className='settings-group'>
                    <h5>Global dwell time</h5>
                    <Input
                        id='dwellTime'
                        type='text'
                        className={
                            'custom-text-input' + (!playerManager.isSettingsEditable ? ' custom-input-ghost' : '')
                        }
                        value={dwellTimeValue}
                        onChange={this.handlePlayerSettingsChange}
                        onFocus={this.handleInputFocus}
                        onBlur={this.handleInputBlur}
                        onKeyDown={this.handleOnKeyDown}
                        disabled={!playerManager.isSettingsEditable}
                    />
                </div>
                <div className='settings-group'>
                    <h5>Transition type</h5>
                    <Select
                        className={
                            'custom-select-wrapper' + (!playerManager.isSettingsEditable ? ' custom-select-ghost' : '')
                        }
                        classNamePrefix='custom-select'
                        isSearchable={false}
                        isDisabled={!playerManager.isSettingsEditable}
                        blurInputOnSelect
                        onChange={this.handleTransitionOptionChange}
                        options={transitionOptions}
                        value={selectedTransitionTypeOption}
                        components={{ DropdownIndicator, Option }}
                        menuPortalTarget={this.props.playerDiv}
                    />
                </div>
                {transitionTypeOptionValue === TransitionType.fade && (
                    <div className='settings-group'>
                        <h5>Transition time</h5>
                        <Input
                            id='transitionTime'
                            type='text'
                            className={
                                'custom-text-input' + (!playerManager.isSettingsEditable ? ' custom-input-ghost' : '')
                            }
                            value={transitionTimeValue}
                            onChange={this.handlePlayerSettingsChange}
                            onFocus={this.handleInputFocus}
                            onBlur={this.handleInputBlur}
                            onKeyDown={this.handleOnKeyDown}
                            disabled={!playerManager.isSettingsEditable}
                        />
                    </div>
                )}
                <div className={'settings-group' + (!this.props.screen.canEdit ? ' invisible' : '')}>
                    {!playerManager.isSettingsEditable ? (
                        <Button
                            className='custom-button-edit'
                            onClick={playerManager.toggleEditSettings}
                            color='primary'
                        >
                            <img src={editIcon} alt='Edit' height={12} />
                        </Button>
                    ) : (
                        <React.Fragment>
                            <Button
                                onClick={this.handleSaveSettings}
                                color='primary'
                                disabled={
                                    this.props.isUpdating ||
                                    !(mediaManager.pendingScreenSettings && mediaManager.pendingScreenSettings.isDirty)
                                }
                            >
                                {i18n.t('actions.save')}
                            </Button>
                            <Button
                                className='custom-button-link custom-button-link-sm'
                                onClick={this.handleCancelSettings}
                                color='link'
                            >
                                <span>{i18n.t('actions.cancel')}</span>
                            </Button>
                        </React.Fragment>
                    )}
                </div>
            </Form>
        )
    }
}

export default withNamespaces()(PlayerSettings)
