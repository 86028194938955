import { observable, action, computed } from 'mobx'

import i18n from 'src/i18n'

import PopoutManager from './PopoutManager'

export default class PlayerManager extends PopoutManager {
    @observable isSettingsEditable: boolean = false
    @observable isCloseAlertOpen: boolean = false
    @observable isRefreshAlertOpen: boolean = false
    @observable hasConfirmedClose: boolean = false
    @observable isDragging: boolean = false
    @observable searchQuery: { value: string; dataKeys: string[] } | undefined

    @computed get title(): string {
        const screen = this.rootStore.screenStore.findItem(this.objectId)
        return this.rootStore.appUIStore.title + ' | ' + (screen?.name ?? i18n.t('placeholders.missingData')) + ' Adhoc'
    }

    // NOTE: This is always triggered before the window closes
    handleBeforeUnload = (event: BeforeUnloadEvent): string | undefined => {
        const mediaManager = this.rootStore.mediaStore.screenMediaManagerMap.get(this.objectId)
        if (!mediaManager) {
            return undefined
        }
        // Only ask for confimation if changes are pending
        // Prevents using browser confirmation dialog when user has acknowledged destructive action
        // NOTE: Newer browsers will ignore the returned string and display it's own message
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload#Browser_compatibility
        const confirmationMessage = 'Your changes will be discarded, are you sure you want to continue?'
        if (mediaManager.hasScreenUnsavedChanges && !this.hasConfirmedClose) {
            ;(event || window.event).returnValue = confirmationMessage
            return confirmationMessage
        } else {
            // NOTE: Internet Explorer does not respect the null return value and will display this
            // to users as "null" text. You have to use undefined to skip the prompt
            return undefined
        }
    }

    // Unimplemented for now
    handleFocusPopout = () => {}

    @action removeManager = () => {
        // Clear media manager
        this.rootStore.mediaStore.screenMediaManagerMap.delete(this.objectId)

        // Clear player manager
        this.rootStore.playerStore.managerMap.delete(this.objectId)
    }

    // Unimplemented for now
    clearURL = () => {}

    @action refreshPlayerMedia = () => {
        const mediaManager = this.rootStore.mediaStore.screenMediaManagerMap.get(this.objectId)
        if (!mediaManager) {
            return
        }
        mediaManager.fetchPlayerMedia()

        if (this.isRefreshAlertOpen) {
            this.isRefreshAlertOpen = false
        }
    }

    @action confirmClosePlayer = () => {
        this.hasConfirmedClose = true

        this.togglePopout()
    }

    @action toggleEditSettings = () => {
        this.isSettingsEditable = !this.isSettingsEditable
    }

    @action toggleCloseAlert = () => {
        this.isCloseAlertOpen = !this.isCloseAlertOpen
    }

    @action toggleRefreshAlert = () => {
        this.isRefreshAlertOpen = !this.isRefreshAlertOpen
    }

    @action setIsDragging = (isDragging: boolean) => {
        this.isDragging = isDragging
    }

    @action updateSearchQuery = (value: string, dataKeys: string[]) => {
        this.searchQuery = { value, dataKeys }
    }
}
