export const millisecondsToString = (float: number, dp: number = 2): string => {
    if (typeof float !== 'number') {
        // TODO: Implement logging to a service for bad data
        console.error('Bad data', float)
        return '?'
    }
    return float.toFixed(dp) + 'ms'
}

export const millisecondsToSecondsString = (float: number): string => {
    if (typeof float !== 'number') {
        // TODO: Implement logging to a service for bad data
        console.error('Bad data', float)
        return '?'
    }
    return (float / 1000).toFixed(0) + 's'
}

export const getTemperatureCelsius = (float: number): string => {
    if (typeof float !== 'number') {
        // TODO: Implement logging to a service for bad data
        console.error('Bad data', float)
        return '?'
    }
    return float.toFixed(1) + '°C'
}

export const getPercentage = (float: number): string => {
    if (typeof float !== 'number') {
        // TODO: Implement logging to a service for bad data
        console.error('Bad data', float)
        return '?'
    }
    return float.toFixed(0) + '%'
}

export const getHexString = (value: number): string => {
    if (typeof value !== 'number') {
        // TODO: Implement logging to a service for bad data
        console.error('Bad data', value)
        return '?'
    }
    return '0x' + value.toString(16)
}

export const getHexStringDecimal = (value: string): number => {
    if (typeof value !== 'string') {
        // TODO: Implement logging to a service for bad data
        console.error('Bad data', value)
        return 0
    }
    return parseInt(value, 16)
}

export const formatCommas = (value: number): string => String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
