import { action } from 'mobx'
import views from 'src/config/views'

import ErrorHistoryManager from '../managers/ErrorHistoryManager'
import DiagnosticsError from '../models/DiagnosticsError'
import PopoutStore from './PopoutStore'

export default class ErrorHistoryStore extends PopoutStore<ErrorHistoryManager, DiagnosticsError> {
    @action updateError = (errorHash: string) => {
        let manager = this.managerMap.get(errorHash)
        if (manager && manager.isPopout) {
            manager.focusPopout()
            return
        }

        const currentScreen = this.rootStore.screenDiagnosticsUIStore.currentScreen
        if (!currentScreen || !currentScreen.errorGroup) {
            // Bad data, reset query params
            this.rootStore.resetRouterQueryParams(views.diagnosticsScreen)
            return
        }

        // Set current drawer
        this.currentDrawerId = errorHash

        // Get the error object from the error hash, as this may be requested directly from the URL
        const diagnosticsError = currentScreen.errorGroup.getErrorFromHash(errorHash)
        if (!diagnosticsError) {
            return
        }

        manager = this.getManager(diagnosticsError.hash, diagnosticsError)
        manager.toggleDrawer()
        manager.fetchErrorHistory()
    }

    newManager = (diagnosticsError: DiagnosticsError): ErrorHistoryManager => new ErrorHistoryManager(this.rootStore, diagnosticsError)
}
