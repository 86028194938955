import { observable, computed } from 'mobx'
import { Omit } from 'react-router'
import moment from 'moment'

export default abstract class BaseModel {
    @observable readonly id?: string
    // TODO: massage this into a date
    @observable readonly updatedAt?: moment.Moment
    @observable readonly createdAt?: moment.Moment
    @observable deletedAt?: moment.Moment | null

    constructor(json: BaseModelJSON) {
        this.id = json.id
        this.updatedAt = moment(json.updatedAt)
        this.createdAt = moment(json.createdAt)
        this.deletedAt = json.deletedAt ? moment(json.deletedAt) : null
    }

    @computed get isValid(): boolean {
        return !!this.id
    }

    get isOwned(): boolean {
        return false
    }

    get canEdit(): boolean {
        return false
    }

    get canDelete(): boolean {
        return this.canEdit
    }

    toJSON(includeDates?: boolean): BaseModelJSON {
        return includeDates ? {
                id: this.id,
                createdAt: this.createdAt,
                updatedAt: this.updatedAt,
                deletedAt: this.deletedAt,
            } : {
                id: this.id,
            };
    }
}

type BaseModelJSON = Omit<BaseModel, 'toJSON' | 'isValid' | 'isOwned' | 'canEdit' | 'canDelete'>

export interface INamedBaseModel extends BaseModel {
    id?: string
    name: string
}
