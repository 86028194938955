import * as React from 'react'

import { Button } from 'reactstrap'
import filterIcon from '../../assets/images/filterIcon.svg'
import chevronDown from '../../assets/images/chevronDown.svg'

interface FilterToggleProps {
    handleOnClick: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) &
        ((event: React.MouseEvent<any, MouseEvent>) => void) &
        (() => void)
    isOpen: boolean
}

class FilterToggle extends React.Component<FilterToggleProps> {
    render() {
        return (
            <Button className='custom-dropdown-toggle' onClick={this.props.handleOnClick}>
                <img src={filterIcon} height={20} alt='Filter' />
                <div className='custom-dropdown-chevron'>
                    <img
                        style={{ transform: this.props.isOpen ? 'rotate(180deg)' : '' }}
                        src={chevronDown}
                        alt='Toggle'
                    />
                </div>
            </Button>
        )
    }
}

export default FilterToggle
