import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import DiagnosticsErrorGroup from 'src/common/models/DiagnosticsErrorGroup'

import ErrorStatusIcon from '../../../../components/ErrorStatusIcon'

interface ErrorStatusCellProps {
    errorGroup: DiagnosticsErrorGroup
}

@inject('store')
@observer
class ErrorStatusCell extends React.Component<{ store?: RootStore } & ErrorStatusCellProps> {
    render() {
        const errorGroup: DiagnosticsErrorGroup = this.props.errorGroup

        if (!errorGroup) {
            return null
        }

        return (
            <div className='status-cell'>
                <ErrorStatusIcon errorGroup={this.props.errorGroup} />
                <div
                    className={
                        'custom-badge fade-background-colour' +
                        (errorGroup.totalAssignedErrors === errorGroup.totalErrors ? ' custom-badge-alt' : '')
                    }
                >
                    {errorGroup.totalErrors}
                </div>
            </div>
        )
    }
}

export default ErrorStatusCell
