export enum RenewCommand {
    renewLiveSubscription = 'renewLiveSubscription',
    renewDiagnosticsSubscription = 'renewDiagnosticsSubscription',
    renewOrganisationsSubscription = 'renewOrganisationsSubscription',
}

export enum MqttError {
    ok = 0,
    connectionTimeout = 1,
    subscribeTimeout = 2,
    unsubscribeTimeout = 3,
    pingTimeout = 4,
    internalError = 5,
    connackReturnCode = 6,
    socketError = 7,
    socketClose = 8,
    malformedUtf = 9,
    unsupported = 10,
    invalidState = 11,
    invalidType = 12,
    invalidArgument = 13,
    unsupportedOperation = 14,
    invalidStoredData = 15,
    invalidMqttMessageType = 16,
    malformedUnicode = 17,
    bufferFull = 18,
}
