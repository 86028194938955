import RootStore from 'src/common/RootStore'
import { observable, action, computed, runInAction } from 'mobx'
import views from 'src/config/views'

import i18n from 'src/i18n'

import PopoutManager from './PopoutManager'
import DiagnosticsError from '../models/DiagnosticsError'
import DiagnosticsErrorLog from '../models/DiagnosticsErrorLog'

export default class ErrorHistoryManager extends PopoutManager {
    @observable history?: DiagnosticsErrorLog[]
    diagnosticsError: DiagnosticsError

    constructor(rootStore: RootStore, diagnosticsError: DiagnosticsError) {
        super(rootStore, diagnosticsError)
        this.diagnosticsError = diagnosticsError
        this.objectId = this.diagnosticsError.hash
    }

    @computed get title(): string {
        return (
            (this.diagnosticsError.errorLabel ?? i18n.t('placeholders.missingData')) +
            ' | ' +
            i18n.t('diagnosticsPage.errorNames.' + this.diagnosticsError.group!.error) +
            ' | History'
        )
    }

    // Unimplemented for now
    handleFocusPopout = () => {}

    @action removeManager = () => {
        this.rootStore.errorHistoryStore.managerMap.delete(this.diagnosticsError.hash)
    }

    clearURL = () => {
        this.rootStore.resetRouterQueryParams(views.diagnosticsScreen)
    }

    @action fetchErrorHistory = async () => {
        this.isFetching = true
        const history = await this.rootStore.diagnosticsStore.fetchErrorHistory(this.diagnosticsError).catch(err => {
            console.error(err)
        })
        runInAction('historyFinishedFetching', () => {
            this.isFetching = false
            this.history = history || undefined
        })
    }
}
