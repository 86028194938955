import RootStore from 'src/common/RootStore'
import screenRouter from 'src/api/screenRouter'
import { action, runInAction } from 'mobx'

import i18n from 'src/i18n'

import DomainStore from './DomainStore'
import Screen, { ScreenStats } from '../models/Screen'
import { LogoBoxLocation } from '../models/LogoBoxLocation'
import ScreenConfiguration from '../models/ScreenConfiguration'

import { AppToaster } from '../components/AppToaster'
import { Intent } from '@blueprintjs/core'
import { ScreenState } from '../models/ScreenState'
import { PlayerSyncState } from '../models/PlayerSyncState'
import { ComptrollerData } from '../models/Screen'

interface ScreenStatePayload {
    state: ScreenState
    isConnected: boolean
    brightnessMap?: Record<string, any>
    comptrollerData?: ComptrollerData
    stats?: ScreenStats
    playerName?: string
    playerSyncState?: PlayerSyncState
    minFirmware?: string
    maxFirmware?: string
}

export default class ScreenStore extends DomainStore<Screen> {
    constructor(rootStore: RootStore) {
        super(rootStore)
        this.router = screenRouter
        this.storeName = 'Screen'
    }

    resolutionsForScreens = (screens?: Screen[]): string[] => {
        const screenSet = new Set<string>()

        if (!screens) {
            screens = this.items
        }

        for (const screen of screens) {
            screenSet.add(screen.resolution!)
        }

        // NOTE: Sorts resolutions by highest pixel value and then X value
        return Array.from(screenSet.values()).sort((a, b): number => {
            const aSplit = a.split('x', 2)
            const bSplit = b.split('x', 2)
            const aX = Number(aSplit[0])
            const aY = Number(aSplit[1])
            const bX = Number(bSplit[0])
            const bY = Number(bSplit[1])
            const aPix = aX * aY
            const bPix = bX * bY
            if (aPix !== bPix) {
                return aPix - bPix
            }
            return aX - bX
        })
    }

    async populateScreenConfiguration(screenId: string): Promise<ScreenConfiguration> {
        const json = await screenRouter.populateScreenConfiguration(screenId)
        return new ScreenConfiguration(json)
    }

    setLogoBoxLocation = (screenId: string, location: LogoBoxLocation | null) => {
        screenRouter
            .setLogoBoxLocation(screenId, location)
            .then(() => {
                runInAction('updateLogoBoxLocation', () => {
                    // Update current screen config
                    this.rootStore.screenDiagnosticsUIStore.currentScreen!.logoBoxLocation = location
                })

                AppToaster.show({
                    message: i18n.t('feedback.successes.successfullySetLogoBoxLocation'),
                    intent: Intent.SUCCESS,
                })
            })
            .catch(err => {
                console.error(err)

                AppToaster.show({
                    message: i18n.t('feedback.errors.errorSettingLogoBoxLocation'),
                    intent: Intent.DANGER,
                })
            })
    }

    @action updateScreensState(screenId: string, screenStatePayload: ScreenStatePayload) {
        const screen = this.findItem(screenId)
        if (!screen) {
            console.error(`Screen: ${screenId} not found`)
            return
        }
        const {
            state,
            isConnected,
            brightnessMap,
            comptrollerData,
            stats,
            minFirmware,
            maxFirmware,
            playerName,
            playerSyncState,
        } = screenStatePayload
        if (screen) {
            screen!.state = state
            if (screen.errorGroup && brightnessMap && brightnessMap[screen.id!]) {
                screen.errorGroup.brightness = brightnessMap[screen.id!]
            }
        }

        screen.isConnected = isConnected

        if (screen.comptroller && comptrollerData) {
            screen.comptroller.lednetDaemon = comptrollerData.lednetDaemon
            screen.comptroller.lednetRestart = comptrollerData.lednetRestart
            screen.comptroller.lednetUptime = comptrollerData.lednetUptime
            screen.comptroller.lednetVersion = comptrollerData.lednetVersion
            screen.comptroller.lednetDaemonVersion = comptrollerData.lednetDaemonVersion
            screen.comptroller.connectVersion = comptrollerData.connectVersion
            screen.comptroller.connectDaemon = comptrollerData.connectDaemon
        }

        if (stats) {
            screen.stats = stats
        }
        if (minFirmware) {
            screen.minFirmware = minFirmware
            screen.maxFirmware = maxFirmware!
        }

        if (playerName && playerSyncState) {
            screen.playerName = playerName
            screen.playerSyncState = playerSyncState
        }
    }
}
