import RootStore from 'src/common/RootStore'

import { observable } from 'mobx'

import mediaRouter from 'src/api/mediaRouter'
import MediaManager from '../managers/MediaManager'

export default class MediaStore {
    @observable screenMediaManagerMap = new Map<string, MediaManager>()

    rootStore: RootStore
    router = mediaRouter

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    getMediaManager = (screenId: string) => {
        let manager = this.screenMediaManagerMap.get(screenId)
        if (!manager) {
            manager = new MediaManager(this.rootStore, screenId)
            this.screenMediaManagerMap.set(screenId, manager)
        }
        return manager
    }
}
