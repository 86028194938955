import { action, computed, observable, reaction, runInAction } from 'mobx'

import RootStore from 'src/common/RootStore'
import { browserStorageManager, StorageKey } from 'src/common/managers/BrowserStorageManager'

import LiveImage from 'src/common/models/LiveImage'
import LiveImageStats from 'src/common/models/LiveImageStats'
import { ValueLabelPair } from 'src/common/components/SelectComponents'
import _ from 'lodash'
import Organisation from 'src/common/models/Organisation'
import Screen from 'src/common/models/Screen'
import { AppToaster } from 'src/common/components/AppToaster'
import { Intent } from '@blueprintjs/core'
import { LiveSnapshots } from 'src/common/stores/LiveStore'

export enum ZoomLevel {
    one = 205,
    two = 252,
    three = 322,
    four = 440,
}

export const startZoomLevel = ZoomLevel.one

interface LiveFilters {
    screens?: string[]
    resolutions?: string[]
}

export enum CameraViewValue {
    enhanced = 1,
    raw = 0,
}

export default class LiveUIStore {
    PAGE_SCROLL_THRESHOLD = 135 // px

    @observable zoomLevel = ZoomLevel.three

    @observable liveFiltersOpen = false
    @observable filters?: LiveFilters
    @observable rememberFilters: boolean

    @observable snapshotsMode?: LiveSnapshots
    @observable snapshotsCamera: number = 0
    @observable snapshotsView: number = 1

    @observable screenSummaryDrawerOpen = false
    @observable liveImageForScreenSummary: LiveImage
    @observable liveImageStatsForScreenSummary?: LiveImageStats

    @observable screenHovered?: string
    @observable pageScrolled: boolean = false

    @observable private setOrg?: Organisation

    rootStore: RootStore

    @observable private displayedScreenIds: string[] = []
    @observable private displayedResolutions: string[] = []

    @observable isStatsOpen: boolean = false
    @observable isCameraOpen: boolean = false
    @observable proofScreenId?: string
    @observable adhoc: boolean = false
    @observable screenTouched?: string

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        // React to screen updates
        reaction(
            () => this.selectedOrg?.screens,
            screens => {
                if (screens) {
                    // If filter screens are no longer in the selected org, remove the screenId from the filter
                    if (this.filters?.screens) {
                        const filteredScreens = this.filters.screens.filter(screenId =>
                            screens.map(s => s.id!).includes(screenId)
                        )
                        if (filteredScreens.length !== this.filters.screens.length) {
                            this.filters.screens = filteredScreens
                            if (this.rememberFilters && !this.adhoc) {
                                browserStorageManager.updateLocalStorageItem(StorageKey.liveFilters, this.filters)
                            }
                            this.updateLiveSubscription()
                        }
                    }
                }
            }
        )
    }

    @action setScreenTouched = (screenId?: string) => {
        this.screenTouched = screenId
    }

    @computed get zoomDetail() {
        return this.zoomLevel > ZoomLevel.two
    }

    @computed get selectedScreens(): string[] {
        if (this.filters?.screens && this.filters.screens.length > 0) {
            return this.filters.screens
        }

        return this.selectedOrg?.enabledScreens.map(s => s.id!) ?? []
    }

    @computed get availableResolutions(): string[] | undefined {
        const screens = this.selectedScreens
            .map(screenId => this.rootStore.screenStore.findItem(screenId))
            .filter(s => !!s) as Screen[]
        return this.rootStore.screenStore.resolutionsForScreens(screens)
    }

    @computed get selectionMatchesDisplayedImages(): boolean {
        return (
            _.isEqual(this.displayedScreenIds, this.selectedScreens) &&
            _.isEqual(this.displayedResolutions, this.filters?.resolutions)
        )
    }

    @computed get selectedOrg(): Organisation | undefined {
        const orgStore = this.rootStore.orgStore
        // If not multiple screen manager, set to own org
        return !orgStore.hasMultipleOpsOrganisations ? orgStore.myOrg : this.setOrg
    }

    @computed get screenOptionsGrouped(): Array<{ options: ValueLabelPair[] }> | undefined {
        if (!this.selectedOrg) {
            return undefined
        }
        return [
            {
                options: this.selectedOrg.enabledScreens
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(screen => ({ value: screen.id!, label: screen.name })),
            },
        ]
    }

    get resolutionOptions(): ValueLabelPair[] | undefined {
        if (!this.availableResolutions) {
            return undefined
        }
        return this.availableResolutions.map(resolution => ({
            value: resolution,
            label: resolution,
        }))
    }

    @action setPageScrolled = (pageScrolled: boolean) => {
        this.pageScrolled = pageScrolled
    }

    @action updateParams = (params: { org: string }) => {
        const { org } = params

        if (org) {
            this.setOrg = this.rootStore.orgStore.findItem(org)
        }

        const orgScreens = this.selectedOrg?.screens.map(screen => screen.id!)
        const savedFilters = this.getSavedFilters()
        if (savedFilters) {
            this.rememberFilters = true
            if (savedFilters.screens?.every((screenId: string) => orgScreens?.includes(screenId))) {
                this.filters = savedFilters
            } else {
                // Invalid saved screens, does not match selected org
                // TODO: Support saving selection for each org?
                this.toggleRememberFilters()
            }
        } else {
            // Clear any set screens and resolutions
            this.filters = {
                screens: [],
                resolutions: [],
            }
        }

        // Update subscription immediately
        this.updateLiveSubscription()
    }

    @action getSavedFilters() {
        const sessionFilters = browserStorageManager.readSessionStorageObject(StorageKey.liveFilters) as any
        if (sessionFilters) {
            this.adhoc = true
            browserStorageManager.removeSessionStorageItem(StorageKey.liveFilters)
            return { ...sessionFilters, resolutions: [] }
        }
        this.adhoc = false
        return browserStorageManager.readLocalStorageObject(StorageKey.liveFilters) as any
    }

    @action toggleLiveFilters = () => {
        this.liveFiltersOpen = !this.liveFiltersOpen
    }

    @action changeZoomLevel = (value: ZoomLevel) => {
        this.zoomLevel = value
    }

    @action updateFilters = (filter: string, selectedOption: ValueLabelPair | ValueLabelPair[]) => {
        if (!selectedOption) {
            return
        }

        if (!this.filters) {
            this.filters = {}
        }

        switch (filter) {
            case 'screen':
                if (!Array.isArray(selectedOption)) {
                    return
                }
                this.filters.screens = selectedOption.map((screen: ValueLabelPair) => screen.value)
                if (this.adhoc && this.filters.screens.length === 0) {
                    this.updateParams({ org: this.selectedOrg!.id! })
                    return
                }
                // Clear any set resolutions
                this.filters.resolutions = []
                break
            case 'resolution':
                if (!Array.isArray(selectedOption)) {
                    return
                }
                this.filters.resolutions = selectedOption.map((resolution: ValueLabelPair) => resolution.value)
                break
        }
        this.adhoc = false
        if (this.rememberFilters) {
            browserStorageManager.updateLocalStorageItem(StorageKey.liveFilters, this.filters)
        }
    }

    initialiseSubscription = () => {
        // if (!this.rootStore.liveStore.sub) {
        this.updateLiveSubscription()
        // }
    }

    @action unsubscribe = () => {
        this.rootStore.liveStore.clearLiveSubscription()
        this.displayedScreenIds = []
    }

    @action updateLiveSubscription = (force = false) => {
        if (!force && this.selectionMatchesDisplayedImages) {
            // Ignore selection if nothing has changed
            return
        }

        // Fetch live subscription
        if (this.selectedScreens.length > 0) {
            this.rootStore.liveStore.getLiveSubscription(
                this.selectedScreens,
                this.filters?.resolutions ?? [],
                this.snapshotsMode,
                force
            )
            this.displayedScreenIds = this.selectedScreens
            this.displayedResolutions = this.filters?.resolutions ?? []
        }
    }

    @action toggleRememberFilters = () => {
        this.rememberFilters = !this.rememberFilters

        if (this.rememberFilters && this.filters) {
            browserStorageManager.updateLocalStorageItem(StorageKey.liveFilters, this.filters)
        } else {
            browserStorageManager.removeLocalStorageItem(StorageKey.liveFilters)
        }
    }

    @action newScreenSummary = async (liveImage: LiveImage) => {
        this.liveImageForScreenSummary = liveImage

        try {
            if (!liveImage.displayImageId) {
                throw new Error('No display image id')
            }
            const stats = await this.rootStore.liveStore.fetchLiveImageStats(
                liveImage.screenId,
                liveImage.displayImageId
            )
            runInAction('updateLiveImageStatsForScreenSummary', () => {
                this.liveImageStatsForScreenSummary = stats
            })
            this.setIsStatsOpen(true)
        } catch (error) {
            console.error(error)
            AppToaster.show({
                message: 'Failed to fetch live image stats',
                intent: Intent.DANGER,
            })
        }
    }

    @action setScreenHovered = (screenId?: string) => {
        this.screenHovered = screenId
    }

    @action setIsStatsOpen = (open: boolean) => {
        this.isStatsOpen = open
    }

    @action setIsCameraOpen = (open: boolean) => {
        this.isCameraOpen = open
        if (!open) {
            this.proofScreenId = undefined
            this.rootStore.cameraStore.close()
        }
    }

    @action openCamera = (screenId: string) => {
        this.setIsCameraOpen(true)
        this.proofScreenId = screenId
        this.rootStore.cameraStore.startCameraFeed(screenId)
    }

    @action toggleCameraSnapshots = () => {
        if (this.snapshotsMode) {
            this.snapshotsMode = undefined
        } else {
            this.snapshotsMode = { camera: this.snapshotsCamera, view: this.snapshotsView }
        }
        this.rootStore.liveStore.refreshLoadingImages()
        this.updateLiveSubscription(true)
    }

    @action updateCameraView = (value: CameraViewValue) => {
        this.snapshotsView = value
        if (this.snapshotsMode) {
            this.snapshotsMode = { camera: this.snapshotsCamera, view: this.snapshotsView }
        }
        this.updateLiveSubscription(true)
    }
}
