import { action, observable, runInAction } from 'mobx'
import RootStore from '../RootStore'

export default class AppStore {
    @observable listsComplete = false

    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action async populateLists() {
        try {
            await this.rootStore.populateLists()
            runInAction('listComplete', () => {
                this.listsComplete = true
            })
        } catch (error) {}
    }
}
