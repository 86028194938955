import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import Screen from 'src/common/models/Screen'
import { ScreenState } from 'src/common/models/ScreenState'

import { Transition } from 'react-spring/renderprops'
import offlineIcon from 'src/assets/images/offlineIcon.svg'
import stopIcon from 'src/assets/images/stopIcon.svg'
import playIcon from 'src/assets/images/playIcon.svg'

interface ScreenStatusCellProps {
    sortIndex: number
    screen: Screen
}

@inject('store')
@observer
class ScreenStatusCell extends React.Component<{ store?: RootStore } & ScreenStatusCellProps> {
    state: { cellScreenStatus: string } = {
        cellScreenStatus: '',
    }

    constructor(props: ScreenStatusCellProps) {
        super(props)
        this.state = {
            cellScreenStatus: this.props.screen.isConnected + this.props.screen.state,
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps: ScreenStatusCellProps) => {
        // Update state if sort index changes
        if (nextProps.sortIndex !== this.props.sortIndex) {
            this.setState({ cellScreenStatus: nextProps.screen.isConnected + nextProps.screen.state })
        }
    }

    render() {
        const screen = this.props.screen
        if (!screen) {
            return null
        }

        const ErrorGroupList = screen.errorGroup

        const transitionItems = []

        let statusIconSrc: any
        let statusIconHeight = ''
        if (screen.isConnected) {
            switch (screen.state) {
                case ScreenState.stopped:
                case ScreenState.enumerated:
                    statusIconSrc = stopIcon
                    statusIconHeight = '8px'
                    break
                case ScreenState.started:
                    statusIconSrc = playIcon
                    statusIconHeight = '10.7px'
                    break
                default:
                    statusIconSrc = offlineIcon
                    statusIconHeight = '16px'
                    break
            }
        } else {
            statusIconSrc = offlineIcon
            statusIconHeight = '16px'
        }

        transitionItems.push({
            statusIconSrc,
            statusIconHeight,
        })

        return (
            <div className='status-cell'>
                <div className='error-status-icon' style={{ backgroundColor: screen.severityColour }}>
                    <Transition
                        items={transitionItems}
                        keys={
                            screen.isConnected + screen.state !== this.state.cellScreenStatus ? screen.state : undefined
                        }
                        initial={{ position: 'absolute' } as React.CSSProperties}
                        from={{ position: 'absolute', opacity: 0 } as React.CSSProperties}
                        enter={{ opacity: 1 }}
                        leave={{ opacity: 0 }}
                        config={{ duration: 1000 }}
                    >
                        {/* eslint-disable-next-line react/display-name */}
                        {item => props => (
                            <img
                                src={item.statusIconSrc}
                                alt='Status'
                                style={{ ...props, height: item.statusIconHeight }}
                            />
                        )}
                    </Transition>
                </div>
                {!this.props.store!.diagnosticsStore.isErrorsFetching &&
                    ErrorGroupList &&
                    ErrorGroupList.totalErrors > 0 &&
                    screen.unassignedErrorCount !== 0 && (
                        <div className='custom-badge'>{ErrorGroupList.totalUnassignedErrors}</div>
                    )}
            </div>
        )
    }
}

export default ScreenStatusCell
