import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import i18n from 'src/i18n'

import { getDataString } from 'src/common/helpers/ErrorDataParser'
import { Tooltip, Position } from '@blueprintjs/core'

interface ErrorDataCellProps {
    dataKey: string
    data: any
}

@inject('store')
@observer
class ErrorDataCell extends React.Component<{ store?: RootStore } & ErrorDataCellProps> {
    render() {
        const key = this.props.dataKey
        const data = this.props.data

        const content = getDataString(key, data)

        if (Array.isArray(data)) {
            // Display data as comma separated if it is an array
            return data.length !== 0 ? (
                <Tooltip
                    boundary={'viewport'}
                    content={content}
                    hoverOpenDelay={300}
                    disabled={data.length <= 1}
                    position={Position.TOP}
                >
                    {content}
                </Tooltip>
            ) : (
                i18n.t('placeholders.missingData')
            )
        } else {
            return content
        }
    }
}

export default ErrorDataCell
