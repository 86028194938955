export type Shortcut = { header: string } | { command: string; label: string; danger?: boolean }

export const basicShortcuts = [
    {
        header: 'Quick commands',
    },
    {
        label: 'Start',
        command: 'autoStart()',
    },
    {
        label: 'Stop',
        command: 'stop()',
    },
    {
        label: 'Enumerate',
        command: 'enumerate()',
    },
    {
        label: 'Force start',
        command: 'start()',
        danger: true,
    },
]

export const infoShortcuts = [
    {
        header: 'Show info',
    },
    {
        label: 'Status',
        command: '?',
    },
    {
        label: 'Tile',
        command: 'tiles()',
    },
    {
        label: 'Section',
        command: 'sections()',
    },
    {
        label: 'Screen',
        command: 'screens()',
    },
    {
        label: 'Adapter',
        command: 'adapters()',
    },
    {
        label: 'Error',
        command: 'errors()',
    },
]

export const inputShortcuts = [
    {
        header: 'Change input',
    },
    {
        label: 'Display',
        command: 'screen.input(1, enum.input.display)',
    },
    {
        label: 'Window',
        command: 'screen.input(1, enum.input.window)',
    },
    {
        label: 'Pattern black',
        command: 'screen.input(1, enum.input.pattern) screen.pattern(1, enum.pattern.black)',
    },
    {
        label: 'Pattern white',
        command: 'screen.input(1, enum.input.pattern) screen.pattern(1, enum.pattern.white)',
    },
    {
        label: 'Pattern TNL',
        command: 'screen.input(1, enum.input.pattern) screen.pattern(1, enum.pattern.id)',
    },
]

export const resetShortcuts = [
    {
        header: 'Reset',
    },
    {
        label: 'Reset stats',
        command: 'adapter.resetStats()',
    },
    {
        label: 'Soft reset',
        command: `stop() thread.wait(5000) print(string.rep('*', 64)) print('<b style="color:pink;">Resetting... please wait for startup result!</b>') print(string.rep('*', 64)) adapter.resetTiles() thread.wait(10000) autoStart()`,
        danger: true,
    },
    {
        label: 'Reset tiles',
        command: 'adapter.resetTiles()',
        danger: true,
    },
]
