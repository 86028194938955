import { observable, runInAction, action, computed } from 'mobx'
import { INamedBaseModel } from 'src/common/models/BaseModel'
import DomainStore from 'src/common/stores/DomainStore'
import _ from 'lodash'

export default abstract class AdminStoreItem<T extends INamedBaseModel> {
    @observable formUpdated: boolean = false
    @observable createItemFormValid: boolean = false

    @observable item?: T

    store: DomainStore<T>

    @computed get hasChanges(): boolean {
        // Take a copy of the existing item
        const existingItem = { ...(this.item && this.item.id && this.store.findItem(this.item.id)) }
        // Check if both the form has been updated and deep compare the items
        // The create form generates a "blank" item, so if we don't check formUpdated,
        // this function could return true for a form with no changes
        return this.formUpdated && !_.isEqual(this.item, existingItem)
    }

    isFormValid(isNew: boolean): boolean {
        return isNew ? this.createItemFormValid : !!this.item?.isValid
    }

    createItem = async (): Promise<T> => {
        if (!this.item) {
            return Promise.reject()
        }
        const newItem = await this.store.createItem(this.item)
        runInAction(() => {
            this.item = newItem
        })
        return newItem
    }

    updateItem = async (): Promise<T> => {
        if (!this.item) {
            return Promise.reject()
        }
        const updatedItem = await this.store.updateItem(this.item)
        runInAction(() => {
            this.item = updatedItem
        })
        return updatedItem
    }

    deleteItem = async (silenceToast = false): Promise<void> => {
        if (!this.item) {
            throw new Error('No item to delete')
        }
        try {
            await this.store.deleteItem(this.item, silenceToast)
        } catch (error) {
            console.error('Error deleting item:', error)
        }
    }

    restoreItem = async (silenceToast = false): Promise<void> => {
        if (!this.item) {
            throw new Error('No item to restore')
        }
        try {
            await this.store.restoreItem(this.item, silenceToast)
            const updatedItem = await this.store.fetchById(this.item.id!)
            runInAction('update' + this.store.storeName, () => {
                const index = this.store.items.findIndex(c => c.id === updatedItem.id)
                if (index !== -1) {
                    this.store.items[index] = updatedItem
                }
            })
        } catch (error) {
            console.error('Error restoring item:', error)
        }
    }

    permanentlyDeleteItem = async (silenceToast = false): Promise<void> => {
        if (!this.item) {
            return Promise.reject()
        }
        return this.store.permanentlyDeleteItem(this.item, silenceToast)
    }

    @action setItem = () => {
        // Clear observables
        this.formUpdated = false
        this.createItemFormValid = false

        this.setNewItem()
    }

    @action clearItem() {
        this.item = undefined
    }

    abstract setNewItem(): void

    abstract setItemToUpdate(item: T): void
}
