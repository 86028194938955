import * as React from 'react'

import { Button } from 'reactstrap'
import chromeIcon from 'src/assets/images/browserIcons/chromeIcon.png'
import firefoxIcon from 'src/assets/images/browserIcons/firefoxIcon.png'
import safariIcon from 'src/assets/images/browserIcons/safariIcon.png'
import edgeIcon from 'src/assets/images/browserIcons/edgeIcon.png'

class UnsupportedBrowser extends React.Component {
    render() {
        return (
            <div className='unsupported-browser'>
                <div>
                    <h1>Your browser is not currently supported.</h1>
                    <h2>Please try upgrading to the latest version of one of the following browsers:</h2>
                    <div className='browser-links'>
                        <a
                            href='https://www.google.com/chrome'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='browser-link'
                        >
                            <img src={chromeIcon} alt='Chrome' />
                            <Button className='custom-button-large m-3' color='primary'>
                                Chrome
                            </Button>
                        </a>
                        <a
                            href='https://www.mozilla.org/firefox/'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='browser-link'
                        >
                            <img src={firefoxIcon} alt='Firefox' />
                            <Button className='custom-button-large m-3' color='primary'>
                                Firefox
                            </Button>
                        </a>
                        <a
                            href='https://www.apple.com/safari/'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='browser-link'
                        >
                            <img src={safariIcon} alt='Safari' />
                            <Button className='custom-button-large m-3' color='primary'>
                                Safari
                            </Button>
                        </a>
                        <a
                            href='https://www.microsoft.com/edge'
                            target='_blank'
                            rel='noopener noreferrer'
                            className='browser-link'
                        >
                            <img src={edgeIcon} alt='Edge' />
                            <Button className='custom-button-large m-3' color='primary'>
                                Edge
                            </Button>
                        </a>
                    </div>
                </div>
            </div>
        )
    }
}

export default UnsupportedBrowser
