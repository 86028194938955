import * as React from 'react'
import { observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { Output } from 'src/common/models/Avior'

import { Button } from 'reactstrap'
import { Dialog, Icon } from '@blueprintjs/core'
import clsx from 'clsx'

const CYCLE_TIME = 5

interface OutputControlProps {
    store?: RootStore
    output?: Output
    onClose: () => void
}

function OutputControl(props: OutputControlProps) {
    const { store, output, onClose } = props

    const [throttledOutputs, setThrottledOutputs] = React.useState<Set<number>>(new Set())

    const screenDiagnosticsUIStore = store!.screenDiagnosticsUIStore

    const handlePowerCycleOutput = async () => {
        if (!output) {
            return
        }
        const cmd = `${output.name}=${output.polarity ? `1,${CYCLE_TIME}` : `0 at#wait=${CYCLE_TIME} ${output.name}=1`}`
        try {
            await screenDiagnosticsUIStore.sendAviorCommand(cmd)
            // Lock the button for the duration that the output will take to power cycle
            setThrottledOutputs(prev => new Set(prev.add(output.ordinal)))
            setTimeout(() => {
                setThrottledOutputs(prev => new Set([...prev].filter(x => x !== output.ordinal)))
            }, CYCLE_TIME * 1000)
        } catch (error) {
            console.error('Error power cycling output', error)
        }
    }

    const getOutputValue = (value: boolean) => (value ? '1' : '0')

    const handleSetOutput = async (value: boolean) => {
        if (!output) {
            return
        }
        const cmd = `${output.name}=${getOutputValue(output.polarity ? !value : value)}`
        try {
            await screenDiagnosticsUIStore.sendAviorCommand(cmd)
        } catch (error) {
            console.error('Error setting output', error)
        }
    }

    if (!output) {
        return null
    }

    const isPowerCycling = throttledOutputs.has(output.ordinal)

    return (
        <Dialog className='custom-dialog' title='Control Output' onClose={onClose} isOpen>
            <div className='dialog-content p-4'>
                <div className='output-control'>
                    <div className='node-icon-header'>
                        <Icon icon='switch' size={22} color='#60a5fa' />
                        <h3 className='text-black node-header-badge'>{output.name}</h3>
                    </div>

                    <div className='node-row mt-4'>
                        <div>
                            <h4 className='text-black'>Power Cycle</h4>
                            <Button
                                onClick={() => handlePowerCycleOutput()}
                                className='custom-button-small text-blue-500'
                                outline
                                // disabled={!aviorOnline}
                                disabled={isPowerCycling}
                                color='primary'
                            >
                                <Icon
                                    className={clsx('mr-2', isPowerCycling && 'rotate-center')}
                                    icon='refresh'
                                    size={15}
                                />
                                Cycle
                            </Button>
                        </div>
                        <div>
                            <h4 className='text-black'>Set Relay</h4>
                            <div className='d-flex'>
                                <Button
                                    onClick={() => handleSetOutput(true)}
                                    className='custom-button-small text-green-500 mr-3'
                                    outline
                                    color='success'
                                >
                                    <Icon className='mr-2' icon='power' size={15} />
                                    Set On
                                </Button>
                                <Button
                                    onClick={() => handleSetOutput(false)}
                                    className='custom-button-small text-red-500'
                                    outline
                                    color='danger'
                                >
                                    <Icon className='mr-2' icon='power' size={15} />
                                    Set Off
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='dialog-actions'>
                    <Button className='custom-button custom-button-large' onClick={() => onClose()} color='primary'>
                        Done
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default observer(OutputControl)
