import * as React from 'react'
import { observer, inject } from 'mobx-react'
import RootStore from 'src/common/RootStore'
import { Link } from 'mobx-router'

import { Nav, NavItem } from 'reactstrap'
import { Tooltip, Position } from '@blueprintjs/core'

export interface NavIcon {
    name: string
    icon: JSX.Element
    view: any
    params?: Record<string, any>
    active: boolean
}

interface NavSidebarParams {
    icons: NavIcon[]
    className?: string
}

@inject('store')
@observer
export default class NavSidebar extends React.Component<NavSidebarParams & { store?: RootStore }> {
    render() {
        const isMobile = this.props.store!.appUIStore.isMobile
        if (isMobile) {
            return null
        }

        const { icons, className } = this.props

        return (
            <div className={'nav-sidebar-container' + (className ? ' ' + className : '')}>
                <Nav vertical>
                    {icons.map(icon => (
                        <NavItem key={icon.name}>
                            <Tooltip
                                boundary={'viewport'}
                                content={icon.name}
                                hoverOpenDelay={300}
                                position={Position.RIGHT}
                            >
                                <Link view={icon.view} store={this.props.store!} params={icon.params}>
                                    <div className={'nav-link' + (icon.active ? ' active' : '')}>{icon.icon}</div>
                                </Link>
                            </Tooltip>
                        </NavItem>
                    ))}
                </Nav>
            </div>
        )
    }
}
