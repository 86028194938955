import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { Tooltip, Position } from '@blueprintjs/core'
import Checkbox from '../../Checkbox'

interface SelectCellProps {
    rowData: any
    isSelected: boolean
    isDisabled: boolean
    rowDisabledMessage?: string
    onSelect: (itemId: string, isChecked: boolean) => void
}

@inject('store')
@observer
class SelectCell extends React.Component<{ store?: RootStore } & SelectCellProps> {
    handleRowSelect = () => {
        // Since disabled prop doesn't work inside of tooltip target, we have to manually check if action is allowed
        // Allow unselecting if it was disabled after being selected
        if (this.props.isDisabled && !this.props.isSelected) {
            return
        }

        this.props.onSelect(this.props.rowData.id, !this.props.isSelected)
    }

    render() {
        const { isSelected, isDisabled, rowDisabledMessage } = this.props

        return (
            <Tooltip
                content={rowDisabledMessage}
                hoverOpenDelay={300}
                disabled={rowDisabledMessage !== undefined && !isDisabled}
                position={Position.TOP}
                boundary='viewport'
                targetClassName='row-select-disabled'
            >
                <Checkbox
                    handleOnClick={this.handleRowSelect}
                    isChecked={isSelected}
                    className={isDisabled ? (!isSelected ? 'disabled' : 'disabled-selected') : ''}
                />
            </Tooltip>
        )
    }
}

export default SelectCell
