import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'

import { Icon, Boundary, Tag, Popover, Position, PopoverInteractionKind } from '@blueprintjs/core'
import { DateRangePicker, DateRange, IDateRangeShortcut } from '@blueprintjs/datetime'
import { Button } from 'reactstrap'
import { Colour } from '../utils/Colour'
import { eventStopPropagation } from '../utils/EventUtils'
import moment from 'moment'

interface DatePickerProps {
    handleChange: (newDateRange: [moment.Moment | undefined, moment.Moment | undefined]) => void
    handleBoundaryChange?: (boundary: Boundary | undefined) => void
    handleTagFocus?: (boundary: Boundary | undefined) => void
    startDate?: moment.Moment
    endDate?: moment.Moment
    minDate?: moment.Moment
    maxDate?: moment.Moment
    disabled?: boolean
    startOptional?: boolean
    endOptional?: boolean
    beforeSelectStartText?: string
    beforeSelectEndText?: string
    startColour?: Colour
    endColour?: Colour
    portalContainer?: HTMLElement
    singleMonthOnly?: boolean
    singleTimeOnly?: boolean
    disableAutoBoundary?: boolean
    tagsInverted?: boolean
    usePopover?: boolean
    shortcuts?: boolean | IDateRangeShortcut[]
}

const ICON_SIZE = 16

@inject('store')
@observer
class DatePicker extends React.Component<DatePickerProps & { store?: RootStore }> {
    state = { isOpen: false, boundary: undefined }

    handleChange = (dateRange: [Date | undefined | null, Date | undefined | null]) => {
        // ignore mouse events in the date-range picker if the popover is animating closed
        if (!this.state.isOpen) {
            return
        }

        let newStartDate = dateRange[0] ? moment(dateRange[0]) : undefined
        if (newStartDate) {
            // Start date updated, change focus to end date
            this.updateBoundary(Boundary.END)
        }

        let newEndDate = dateRange[1] ? moment(dateRange[1]) : undefined
        if (newEndDate) {
            // Check that the new end date isn't after the max date if set
            newEndDate = this.preventAfterMaxDate(newEndDate)

            if (!newStartDate) {
                // No start date selected, change focus to start date
                this.updateBoundary(Boundary.START)
            }

            if (newStartDate && this.props.endDate && newStartDate.isAfter(this.props.endDate)) {
                // If new start date selection is after the selected end date, end date must be cleared
                newEndDate = undefined
                this.updateBoundary(undefined)
            } else if (this.props.startDate && newEndDate.isBefore(this.props.startDate)) {
                if (this.props.disableAutoBoundary) {
                    // Ignore if automatically focusing input is disabled
                    return
                }
                // If new end date selection is before the selected start date, selection must become new start date
                newStartDate = moment(dateRange[1]!)
                newEndDate = undefined
                this.updateBoundary(Boundary.END)
            }
        }

        // Send back updated range as the requesting component manages state
        this.props.handleChange([newStartDate, newEndDate])
    }

    updateBoundary = (boundary: Boundary | undefined) => {
        if (!this.props.disableAutoBoundary) {
            // Ignore if automatically focusing input is disabled
            this.setState({ boundary })
        }

        if (this.props.handleBoundaryChange) {
            this.props.handleBoundaryChange(boundary)
        }
    }

    handleTagFocus = (boundary: Boundary | undefined, e: React.MouseEvent<HTMLSpanElement>) => {
        // Prevent closing popover
        e.stopPropagation()

        this.setState({ isOpen: true, boundary })
        // Pass through update event
        this.updateBoundary(boundary)
        if (this.props.handleTagFocus) {
            this.props.handleTagFocus(boundary)
        }
    }

    preventAfterMaxDate = (date: moment.Moment): moment.Moment => {
        const maxDate = this.props.maxDate
        if (maxDate && date.isAfter(maxDate)) {
            // If date is after max date, return now
            return moment()
        }
        return date
    }

    handlePopoverClose = () => {
        this.setState({ isOpen: false, boundary: undefined })
    }

    handleClearInput = (boundary: Boundary, event: any) => {
        eventStopPropagation(event)

        switch (boundary) {
            case Boundary.START:
                this.props.handleChange([undefined, this.props.endDate])
                if (this.state.boundary === Boundary.START) {
                    this.setState({ boundary: undefined })
                }
                break
            case Boundary.END:
                this.props.handleChange([this.props.startDate, undefined])
                if (this.state.boundary === Boundary.END) {
                    this.setState({ boundary: undefined })
                }
                break
            default:
                break
        }
    }

    render() {
        const {
            startDate,
            endDate,
            minDate,
            maxDate,
            startColour,
            endColour,
            singleMonthOnly,
            singleTimeOnly,
            tagsInverted,
            usePopover,
            shortcuts,
        } = this.props

        const startDateValid = startDate ? startDate.isValid() : false
        const endDateValid = endDate ? endDate.isValid() : false

        const appUIStore = this.props.store!.appUIStore
        const isMobile = appUIStore.isMobile
        const dateTimeFormat = this.props.store!.userStore.me?.mobileAdjustedTimeFormat
        // Must pass [null, null] to show empty values in controlled component
        const range: DateRange = [
            // @ts-expect-error
            startDateValid ? startDate!.toDate() : undefined,
            // @ts-expect-error
            endDateValid ? endDate!.toDate() : undefined,
        ]

        const datepickerTags = (
            <div className='custom-datepicker-input'>
                <div className={'datepicker-tags' + (tagsInverted ? ' datepicker-tags-inverted' : '')}>
                    <Tag
                        className={
                            'datepicker-tag' +
                            (startDateValid ? ' input-valid' : ' input-invalid') +
                            (this.props.disabled ? ' input-disabled' : '') +
                            (this.props.startOptional ? ' input-clearable' : '')
                        }
                        style={{ backgroundColor: startDateValid ? startColour : undefined }}
                        large
                        icon={
                            !this.props.disabled && this.state.boundary === Boundary.START ? 'chevron-right' : undefined
                        }
                        interactive={!this.props.disabled}
                        onClick={this.handleTagFocus.bind(this, Boundary.START)}
                        onRemove={
                            this.props.startOptional &&
                            (startDateValid || this.state.boundary === Boundary.START) &&
                            this.handleClearInput.bind(this, Boundary.START)
                        }
                    >
                        {startDateValid
                            ? startDate!.format(dateTimeFormat)
                            : this.state.boundary === Boundary.START
                            ? i18n.t('common.dates.selectStart')
                            : this.props.beforeSelectStartText
                            ? this.props.beforeSelectStartText
                            : i18n.t('common.dates.selectStart')}
                    </Tag>
                    <Icon className='datepicker-arrow' icon='arrow-right' iconSize={ICON_SIZE} />
                    <Tag
                        className={
                            'datepicker-tag' +
                            (endDateValid ? ' input-valid' : ' input-invalid') +
                            (this.props.disabled ? ' input-disabled' : '') +
                            (this.props.endOptional ? ' input-clearable' : '')
                        }
                        style={{ backgroundColor: endDateValid ? endColour : undefined }}
                        large
                        icon={
                            !this.props.disabled && this.state.boundary === Boundary.END ? 'chevron-right' : undefined
                        }
                        interactive={!this.props.disabled}
                        onClick={this.handleTagFocus.bind(this, Boundary.END)}
                        onRemove={
                            this.props.endOptional &&
                            (endDateValid || this.state.boundary === Boundary.END) &&
                            this.handleClearInput.bind(this, Boundary.END)
                        }
                    >
                        {endDateValid
                            ? endDate!.format(dateTimeFormat)
                            : this.state.boundary === Boundary.END
                            ? i18n.t('common.dates.selectEnd')
                            : this.props.beforeSelectEndText
                            ? this.props.beforeSelectEndText
                            : i18n.t('common.dates.selectEnd')}
                    </Tag>
                </div>
            </div>
        )

        const datepickerCalendar = (
            <div className='datepicker-calendar'>
                {usePopover === false && this.state.boundary === undefined && (
                    <div className='datepicker-disabled-overlay' />
                )}
                <DateRangePicker
                    boundaryToModify={this.state.boundary}
                    allowSingleDayRange
                    className={
                        'custom-datepicker-input' +
                        (singleTimeOnly
                            ? (this.state.boundary === Boundary.START ? ' disable-end-time' : '') +
                              (this.state.boundary === Boundary.END ? ' disable-start-time' : '')
                            : '')
                    }
                    contiguousCalendarMonths={false}
                    dayPickerProps={{
                        firstDayOfWeek: 1,
                    }}
                    minDate={minDate ? minDate.toDate() : undefined}
                    maxDate={maxDate ? maxDate.toDate() : undefined}
                    onChange={this.handleChange}
                    shortcuts={shortcuts ?? false}
                    timePickerProps={{
                        useAmPm: true,
                        showArrowButtons: true,
                    }}
                    timePrecision='second'
                    // This component is controlled as some of the logic above modifies values outside of the DateRangePicker component logic
                    value={range}
                    singleMonthOnly={isMobile || singleMonthOnly}
                />
                {usePopover !== false && (
                    <Button
                        onClick={this.handlePopoverClose}
                        className='close-datepicker-button custom-button-large d-md-none text-center'
                        color='primary'
                    >
                        {i18n.t('actions.close')}
                    </Button>
                )}
            </div>
        )

        if (usePopover !== false) {
            return (
                <Popover
                    isOpen={this.state.isOpen}
                    portalContainer={this.props.portalContainer}
                    interactionKind={PopoverInteractionKind.HOVER}
                    position={Position.BOTTOM}
                    autoFocus={false}
                    target={datepickerTags}
                    content={datepickerCalendar}
                    modifiers={{ flip: { enabled: false } }}
                    enforceFocus={false}
                    onClose={this.handlePopoverClose}
                    disabled={this.props.disabled}
                    popoverClassName='datepicker-popover'
                />
            )
        } else {
            return (
                <React.Fragment>
                    {datepickerTags}
                    {datepickerCalendar}
                </React.Fragment>
            )
        }
    }
}

export default DatePicker
