import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { SettingsTabName, SettingsPageName } from './stores/SettingsUIStore'
import views from 'src/config/views'

import { TabContent, TabPane } from 'reactstrap'
import DetailsTab from './components/my-profile/DetailsTab'
import PreferencesTab from './components/my-profile/PreferencesTab'
import NotificationsTab from './components/my-profile/NotificationsTab'
import OrgDetails from './components/organisation-profile/OrgDetails'
import NavTabs, { NavTab } from 'src/common/components/navigation/NavTabs'

@inject('store')
@observer
class Settings extends React.Component<{ store?: RootStore } & WithNamespaces> {
    render() {
        const settingsUIStore = this.props.store!.settingsUIStore

        let pageTitle = ''
        switch (settingsUIStore.activeSettingsPage) {
            case SettingsPageName.myProfile:
                pageTitle = i18n.t('titles.MyProfile')
                break
            case SettingsPageName.organisationProfile:
                pageTitle = i18n.t('titles.OrganisationProfile')
                break
            default:
                break
        }

        const tabs: NavTab[] = settingsUIStore.settingsTabNames.map(tabName => ({
                name: i18n.t('settingsPage.settingsTabs.' + tabName),
                view: views.settings,
                params: {
                    page: SettingsPageName.myProfile,
                    tab: tabName,
                },
                active: settingsUIStore.activeSettingsTab === tabName,
            }))

        return (
            <div className='grey-container'>
                <div className='settings-container custom-container-fluid'>
                    <h1 className='settings-title'>{pageTitle}</h1>
                    {settingsUIStore.activeSettingsPage === SettingsPageName.myProfile && (
                        <React.Fragment>
                            <NavTabs tabs={tabs} className='settings-tabs' />
                            <div className='custom-container'>
                                <TabContent activeTab={settingsUIStore.activeSettingsTab}>
                                    <TabPane tabId={SettingsTabName.details}>
                                        <DetailsTab />
                                    </TabPane>
                                    <TabPane tabId={SettingsTabName.preferences}>
                                        <PreferencesTab />
                                    </TabPane>
                                    <TabPane tabId={SettingsTabName.notifications}>
                                        <NotificationsTab />
                                    </TabPane>
                                </TabContent>
                            </div>
                        </React.Fragment>
                    )}
                    {settingsUIStore.activeSettingsPage === SettingsPageName.organisationProfile && (
                        <div className='custom-container organisation-details'>
                            <OrgDetails />
                        </div>
                    )}
                </div>
            </div>
        )
    }
}

export default withNamespaces()(Settings)
