import { action } from 'mobx'
import views from 'src/config/views'

import TileHistoryManager from '../managers/TileHistoryManager'
import Tile from '../models/Tile'
import PopoutStore from './PopoutStore'

export default class TileHistoryStore extends PopoutStore<TileHistoryManager, Tile> {
    @action updateTile = (tileId: string) => {
        let manager = this.managerMap.get(tileId)
        if (manager && manager.isPopout) {
            manager.focusPopout()
            return
        }

        const currentScreenConfiguration = this.rootStore.screenDiagnosticsUIStore.currentScreenConfiguration
        if (!currentScreenConfiguration) {
            // Bad data, reset query params
            this.rootStore.resetRouterQueryParams(views.diagnosticsScreen)
            return
        }

        // Set current drawer
        this.currentDrawerId = tileId

        // Get the error object from the error hash, as this may be requested directly from the URL
        const tile = currentScreenConfiguration.tiles.find(item => item.id === tileId)
        if (!tile) {
            return
        }

        manager = this.getManager(tile.id!, tile)
        manager.toggleDrawer()
        manager.fetchErrorHistory()
    }

    newManager = (tile: Tile): TileHistoryManager => new TileHistoryManager(this.rootStore, tile)
}
