import { observable } from 'mobx'
import DisplayImageStats from './DisplayImageStats'

class DisplayImageSummary {
    static newDisplayImageSummary(): DisplayImageSummary {
        return new DisplayImageSummary({
            totals: DisplayImageStats.newDisplayImageStats(),
            screens: [],
        })
    }

    @observable totals: DisplayImageStats
    @observable screens: DisplayImageStats[]

    constructor(json: DisplayImageSummaryJSON) {
        this.totals = new DisplayImageStats(json.totals)
        this.screens = []
        for (const screen of json.screens) {
            this.screens.push(new DisplayImageStats(screen))
        }
    }
}

export type DisplayImageSummaryJSON = DisplayImageSummary

export default DisplayImageSummary
