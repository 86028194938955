import { StorageKey, browserStorageManager } from '../managers/BrowserStorageManager'
import { UserPreferences } from '../models/UserSettings'
import moment from 'moment'
import 'moment-timezone'

// Seed data
const DEFAULT_LANGUAGE = 'en'
const DEFAULT_TIME_ZONE = moment.tz.guess()
const DEFAULT_TIME_FORMAT = 'h:mm:ss a'
const DEFAULT_DATE_FORMAT = 'DD/MM/YYYY'

export let migration1UserPreferences: UserPreferences

export const migrations = [
    () => {
        browserStorageManager.clearNonProtectedKeys()

        // Update seed data in local storage
        browserStorageManager.updateLocalStorageItem(StorageKey.i18nLang, DEFAULT_LANGUAGE)
        browserStorageManager.updateLocalStorageItem(StorageKey.timeZone, DEFAULT_TIME_ZONE)
        browserStorageManager.updateLocalStorageItem(StorageKey.timeFormat, DEFAULT_TIME_FORMAT)
        browserStorageManager.updateLocalStorageItem(StorageKey.dateFormat, DEFAULT_DATE_FORMAT)
    },
    () => {
        // Fetch any user preferences stored in local storage
        const language = browserStorageManager.readLocalStorageString(StorageKey.i18nLang)
        const timeZone = browserStorageManager.readLocalStorageString(StorageKey.timeZone)
        const timeFormat = browserStorageManager.readLocalStorageString(StorageKey.timeFormat)
        const dateFormat = browserStorageManager.readLocalStorageString(StorageKey.dateFormat)

        // Only store values if they differ from the defaults
        const preferences = new UserPreferences({
            language: language !== DEFAULT_LANGUAGE ? language! : null,
            timeZone: timeZone !== DEFAULT_TIME_ZONE ? timeZone! : null,
            timeFormat: timeFormat !== DEFAULT_TIME_FORMAT ? timeFormat! : null,
            dateFormat: dateFormat !== DEFAULT_DATE_FORMAT ? dateFormat! : null,
        })

        // Remove user preference values from local storage if they exist
        for (const key of [StorageKey.i18nLang, StorageKey.timeZone, StorageKey.timeFormat, StorageKey.dateFormat]) {
            browserStorageManager.removeLocalStorageItem(key)
        }

        // Check for any retreived preferences
        if (Object.values(preferences).filter(v => v !== null).length !== 0) {
            // Ensure preferences has timezone and language set
            if (!preferences.timeZone) {
                preferences.timeZone = DEFAULT_TIME_ZONE
            }
            if (!preferences.language) {
                preferences.language = DEFAULT_LANGUAGE
            }
            migration1UserPreferences = new UserPreferences(preferences)
        }
    },
]
