import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { AlertMethod, UserNotificationType } from 'src/common/models/UserSettings'

import { Row, Col, Button, Container } from 'reactstrap'
import { Tooltip, Position } from '@blueprintjs/core'
import Checkbox from 'src/common/components/Checkbox'

@inject('store')
@observer
class NotificationsTab extends React.Component<{ store?: RootStore } & WithNamespaces> {
    isCheckboxAllowed = (userNotificationType: UserNotificationType, alertMethod: AlertMethod): boolean => {
        const me = this.props.store!.userStore.me
        if (!me) {
            return false
        }
        if (alertMethod === AlertMethod.sms) {
            // Only SuperUser can toggle SMS checkbox
            return me.isSuperUser
        }
        if (userNotificationType === UserNotificationType.digest) {
            // Prevent subscribing to digest if no timezone set
            return !!me.preferences?.timeZone
        }
        return true
    }

    handleNotificationCheckbox = (userNotificationType: UserNotificationType, alertMethod: AlertMethod) => {
        // Since disabled prop doesn't work inside of tooltip target, we have to manually check if action is allowed
        if (!this.isCheckboxAllowed(userNotificationType, alertMethod)) {
            return
        }

        const settingsUIStore = this.props.store!.settingsUIStore
        const enableMethod = !settingsUIStore.isNotificationMethodEnabled(userNotificationType, alertMethod)
        settingsUIStore.updateUserNotifications(userNotificationType, alertMethod, enableMethod)
    }

    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return null
        }

        const settingsUIStore = this.props.store!.settingsUIStore

        return (
            <div className='notifications-tab'>
                <h2>{i18n.t('settingsPage.alerts')}</h2>
                <h4>{i18n.t('settingsPage.alertsInfo')}</h4>
                <br />
                <Container fluid className='settings-toggles'>
                    <Row>
                        <Col md={6} xs={8} />
                        {settingsUIStore.alertMethods.map(alertMethod => (
                            <Col key={alertMethod} xs={2} className='checkbox-col'>
                                <h4>{i18n.t('settingsPage.alertMethods.' + alertMethod)}</h4>
                            </Col>
                        ))}
                    </Row>
                    <br />
                    {settingsUIStore.userNotifications.map(notification => (
                        <Row key={notification.type} className='type-row'>
                            <Col md={6} xs={8}>
                                <h4>{i18n.t('settingsPage.userNotificationTypes.' + notification.type)}</h4>
                            </Col>
                            {notification.methods.map(method => (
                                <Col key={notification.type + '-' + method} xs={2} className='checkbox-col'>
                                    <Tooltip
                                        content={
                                            notification.type === UserNotificationType.digest
                                                ? i18n.t('settingsPage.digestHelpText')
                                                : method === AlertMethod.sms
                                                ? i18n.t('settingsPage.smsHelpText')
                                                : undefined
                                        }
                                        disabled={this.isCheckboxAllowed(notification.type, method)}
                                        position={Position.TOP}
                                        targetClassName='checkbox-help'
                                    >
                                        <Checkbox
                                            handleOnClick={this.handleNotificationCheckbox.bind(
                                                this,
                                                notification.type,
                                                method
                                            )}
                                            isChecked={settingsUIStore.isNotificationMethodEnabled(
                                                notification.type,
                                                method
                                            )}
                                            className={
                                                !this.isCheckboxAllowed(notification.type, method) ? 'disabled' : ''
                                            }
                                        />
                                    </Tooltip>
                                </Col>
                            ))}
                        </Row>
                    ))}
                </Container>
                <br />
                <Button
                    onClick={settingsUIStore.saveUserNotifications}
                    color='primary'
                    disabled={this.props.store!.userStore.isUpdating || !settingsUIStore.hasUnsavedUserNotifications}
                >
                    {i18n.t('actions.save')}
                </Button>
            </div>
        )
    }
}

export default withNamespaces()(NotificationsTab)
