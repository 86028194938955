import RootStore from '../RootStore'

import { AWSIoTProvider } from '@aws-amplify/pubsub'

import { MqttError } from '../models/MqttEnums'
// @ts-expect-error
import { MqttProvidertOptions } from '@aws-amplify/pubsub/lib/Providers/MqttOverWSProvider'
import uuid from 'uuid/v4'
import { runInAction, action } from 'mobx'

const maxRetryTime = 8000 // ms
const startRetryTime = 1000 // ms

export default class CustomIoTProvider extends AWSIoTProvider {
    retryTimeout = 15
    retryToastTimers: Array<{ key: string; timeout: NodeJS.Timeout }> = []
    reconnecting: boolean = false
    rootStore: RootStore

    constructor(options: MqttProvidertOptions, rootStore: RootStore) {
        super(options)
        if (!options) {
            return
        }
        this.rootStore = rootStore
    }

    // NOTE: Leaving for debugging - BGN

    // newClient(options: MqttProvidertOptions): Promise<any> {
    //     return super.newClient(options)
    // }

    // connect(clientId: string, options?: MqttProvidertOptions): Promise<any> {
    //     return super.connect(clientId, options)
    // }

    @action reconnect(retryTime = 0) {
        this.reconnecting = true
        this.rootStore.mqttStore.isSocketConnected = false
        setTimeout(async () => {
            delete this.options.clientId
            const url = await this.endpoint
            this.connect(uuid(), { url })
                .then(() => {
                    runInAction(() => {
                        this.rootStore.mqttStore.isSocketConnected = true
                        this.reconnecting = false
                        this.rootStore.mqttStore.refreshAll()
                    })
                })
                .catch(error => {
                    if (retryTime === 0) {
                        retryTime = startRetryTime
                    } else if (retryTime < maxRetryTime) {
                        retryTime *= 2
                    }
                    this.reconnect(retryTime)
                })
        }, retryTime)
    }

    onDisconnect({ clientId, errorCode, ...args }: { [x: string]: any; clientId: string; errorCode: number }): void {
        // Only attempt reconnect on error
        // NOTE: Disconnect also fires on graceful socket close
        if (errorCode !== MqttError.ok) {
            if (errorCode === MqttError.socketClose && !this.reconnecting) {
                this.reconnect(startRetryTime)
            }
            this.disconnect(clientId)
        }
    }
    // TODO: Needs to prompt refreshing the page if retry fails too many times
}
