export const normaliseData = (data: number[], floor?: number, ceil?: number): number[] => 
    // Normalise data with a threshold
    // If no threshold provided, normalise between min and max of data values
     data.map(value => {
        // If no floor provided, use 0
        const floorValue = floor ? floor : 0
        // If no ceil provided, use avg x 2
        const ceilValue = ceil ? ceil : (data.reduce((prev, curr) => prev + curr) / data.length) * 2

        if (value >= ceilValue) {
            return 1
        } else if (value <= floorValue) {
            return 0
        } else {
            return (value - floorValue) / (ceilValue - floorValue)
        }
    })

