import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { eventPreventDefault } from 'src/common/utils/EventUtils'

import { Dialog, Alert, Intent } from '@blueprintjs/core'
import { Button, Form } from 'reactstrap'
import Select from 'react-select'
import { ClearIndicator, DropdownIndicator, Option } from 'src/common/components/SelectComponents'

@inject('store')
@observer
class LogoBoxDialog extends React.Component<{ store?: RootStore } & WithNamespaces> {
    handleNoLogoBox = () => {
        this.props.store!.tileViewUIStore.setLogoBoxDialog(false)
        this.props.store!.tileViewUIStore.toggleLogoBoxAlert()
    }

    render() {
        if (!this.props.store!.screenDiagnosticsUIStore.currentScreen) {
            return null
        }

        const logoBoxPositionOptions = [
            { value: 'topLeft', label: i18n.t('diagnosticsPage.logoBoxLocations.topLeft') },
            { value: 'topCentre', label: i18n.t('diagnosticsPage.logoBoxLocations.topCentre') },
            { value: 'topRight', label: i18n.t('diagnosticsPage.logoBoxLocations.topRight') },
            { value: 'bottomLeft', label: i18n.t('diagnosticsPage.logoBoxLocations.bottomLeft') },
            { value: 'bottomCentre', label: i18n.t('diagnosticsPage.logoBoxLocations.bottomCentre') },
            { value: 'bottomRight', label: i18n.t('diagnosticsPage.logoBoxLocations.bottomRight') },
        ]

        const logoBoxLocation = this.props.store!.screenDiagnosticsUIStore.currentScreen.logoBoxLocation
        const defaultLogoBoxPosition = logoBoxLocation
            ? { value: logoBoxLocation, label: i18n.t('diagnosticsPage.logoBoxLocations.' + logoBoxLocation) }
            : logoBoxPositionOptions[0]
        const tileViewUIStore = this.props.store!.tileViewUIStore

        return (
            <React.Fragment>
                <Dialog
                    isOpen={tileViewUIStore.logoBoxDialogOpen}
                    className='custom-dialog logo-box-dialog'
                    canOutsideClickClose={false}
                    isCloseButtonShown={false}
                    title={i18n.t('diagnosticsPage.logoBox')}
                >
                    <div className='logo-box-dialog-content'>
                        {/* Check if screen configuration has a Logo Box location set */}
                        <span className='mt-3'>
                            {!logoBoxLocation
                                ? i18n.t('diagnosticsPage.newLogoBoxModalText')
                                : i18n.t('diagnosticsPage.updateLogoBoxModalText')}
                        </span>
                        <br />
                        <Form className='mb-3' onSubmit={eventPreventDefault}>
                            <Select
                                className='custom-select-wrapper custom-select-inverted'
                                classNamePrefix='custom-select'
                                isSearchable={false}
                                isClearable={false}
                                blurInputOnSelect
                                onChange={tileViewUIStore.setLogoBoxLocation}
                                options={logoBoxPositionOptions}
                                defaultValue={defaultLogoBoxPosition}
                                components={{ ClearIndicator, DropdownIndicator, Option }}
                            />
                        </Form>
                        <div className='dialog-actions dialog-actions-space-between'>
                            <Button
                                className='custom-button-link custom-button-link-black'
                                onClick={this.handleNoLogoBox}
                                color='link'
                            >
                                <span>No logo box</span>
                            </Button>
                            <Button
                                className='custom-button custom-button-large'
                                onClick={tileViewUIStore.saveSetLogoBoxLocation}
                                color='primary'
                            >
                                <span>{i18n.t('actions.save')}</span>
                            </Button>
                        </div>
                    </div>
                </Dialog>
                {/* No logo box alert */}
                <Alert
                    className='custom-alert'
                    confirmButtonText='Okay'
                    icon='warning-sign'
                    intent={Intent.WARNING}
                    isOpen={tileViewUIStore.isLogoBoxAlertOpen}
                    onConfirm={tileViewUIStore.toggleLogoBoxAlert}
                >
                    <p>
                        If this screen does not have a logo box, please ensure that the logo box option is toggled off
                        for each section in the LedNet Controller.
                    </p>
                </Alert>
            </React.Fragment>
        )
    }
}

export default withNamespaces()(LogoBoxDialog)
