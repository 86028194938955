import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { FormGroup, Input } from 'reactstrap'
import Select from 'react-select'
import { Icon, Intent } from '@blueprintjs/core'
import { DropdownIndicator, Option, NoOptionsMessage, ValueLabelPair } from 'src/common/components/SelectComponents'
import OverviewTags from 'src/common/components/OverviewTags'
import searchIcon from 'src/assets/images/searchIcon.svg'

@inject('store')
@observer
class DiagnosticsOverview extends React.Component<{ store?: RootStore } & WithNamespaces> {
    handleSearchQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.store!.screenDiagnosticsUIStore.updateSearchQuery(event.target.value)
    }

    handleChangeInfo = (selectedOption: ValueLabelPair) => {
        this.props.store!.screenDiagnosticsUIStore.updateScreenInfoValue(selectedOption.value)
    }

    render() {
        const allErrors = this.props.store!.diagnosticsStore.errorsContainer
        const totalUnassignedErrors = allErrors ? allErrors.totalUnassignedErrors : 0

        const screenDiagnosticsUIStore = this.props.store!.screenDiagnosticsUIStore
        const infoOptions = screenDiagnosticsUIStore.infoOptions
        const defaultInfoOption = infoOptions.find(option => option.value === screenDiagnosticsUIStore.screenInfoValue)

        const searchQueryValue = screenDiagnosticsUIStore.searchQueryValue
        const isErrorsFetching = this.props.store!.diagnosticsStore.isErrorsFetching

        return (
            <>
                <div className='diagnostics-overview'>
                    <h3 className={'diagnostics-overview-title' + (isErrorsFetching ? ' bp3-skeleton' : '')}>
                        {totalUnassignedErrors > 0 ? (
                            <>
                                {totalUnassignedErrors} {i18n.t('diagnosticsPage.statusUnassigned')}
                            </>
                        ) : (
                            <>
                                <Icon className='mr-2' icon='tick-circle' iconSize={20} intent={Intent.SUCCESS} />
                                {i18n.t('diagnosticsPage.statusNoUnassigned')}
                            </>
                        )}
                    </h3>
                    <div className={isErrorsFetching ? 'bp3-skeleton' : ''}>
                        <OverviewTags
                            statuses={screenDiagnosticsUIStore.selectedOrg?.screenStatuses}
                            showWarnings
                            showNonErrors
                        />
                    </div>
                </div>
                <div className='diagnostics-overview-filter'>
                    <FormGroup className={'custom-search' + (isErrorsFetching ? ' bp3-skeleton' : '')}>
                        <img src={searchIcon} alt='Search' className='custom-search-icon' />
                        <Input
                            className='diagnostics-search'
                            onChange={this.handleSearchQueryChange}
                            type='search'
                            placeholder={i18n.t('actions.search')}
                            autoComplete='off'
                            defaultValue={searchQueryValue}
                        />
                        <Select
                            className='custom-select-wrapper'
                            classNamePrefix='custom-select'
                            onChange={this.handleChangeInfo}
                            isSearchable={false}
                            isClearable={false}
                            options={infoOptions}
                            defaultValue={defaultInfoOption}
                            components={{
                                DropdownIndicator,
                                Option,
                                NoOptionsMessage,
                            }}
                            menuPlacement='auto'
                        />
                    </FormGroup>
                </div>
            </>
        )
    }
}

export default withNamespaces()(DiagnosticsOverview)
