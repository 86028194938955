import AdminStoreItem from './AdminStoreItem'
import Screen from 'src/common/models/Screen'
import { action, computed } from 'mobx'
import RootStore from 'src/common/RootStore'
import { TileType } from 'src/common/models/Tile'

export default class AdminStoreScreen extends AdminStoreItem<Screen> {
    orgId?: string

    constructor(rootStore: RootStore) {
        super()
        this.store = rootStore.screenStore
        this.orgId = rootStore.adminUIStore.selectedOrg?.id
    }

    @computed get isTileTypeUnknown(): boolean {
        return this.item?.tileType === TileType.unknown
    }

    @action setItemToUpdate = (screen: Screen) => {
        this.item = new Screen(screen)
    }

    @action setNewItem = () => {
        this.item = Screen.newScreen()
        this.item!.organisationId = this.orgId || ''
    }

    @action updateItemForm = (key: string, value: any) => {
        // Form was updated
        this.formUpdated = true
        switch (key) {
            case 'name':
                this.item!.name = value
                break
            case 'tilesWide':
                this.item!.tilesWide = Number(value)
                break
            case 'tilesHigh':
                this.item!.tilesHigh = Number(value)
                break
            case 'manufacturer':
                this.item!.manufacturer = value
                break
            case 'tileWidth':
                this.item!.tileWidth = Number(value)
                break
            case 'tileHeight':
                this.item!.tileHeight = Number(value)
                break
            case 'tileType':
                this.item!.tileType = value
                if (value === undefined) {
                    // Invalidate tile dimensions if select cleared
                    this.item!.tileWidth = 0
                    this.item!.tileHeight = 0
                }
                break
            case 'contentOwner':
                this.item!.contentOwnerId = value
                break
            case 'operationsManager':
                this.item!.operationsManagerId = value
                break
            case 'enabled':
                this.item!.enabled = value
                break
            case 'isTest':
                this.item!.isTest = value
                break
            case 'aviorId':
                this.item!.aviorId = value
                break
            case 'aviorPassword':
                this.item!.aviorPassword = value
                break
            case 'hasCamera':
                this.item!.hasCamera = value
                break
            case 'labels':
                this.item!.labels = value
                break
            default:
                return
        }

        const originalItemAviorId = this.item!.id && this.store.findItem(this.item!.id)?.aviorId
        const aviorFormValid =
            this.item!.aviorId && this.item!.aviorId !== originalItemAviorId
                ? !!this.item!.aviorPassword && this.item!.aviorPassword !== ''
                : true

        // If all form fields are filled, form can be submitted
        this.createItemFormValid =
            this.item!.hasCompletedDataFields && this.item!.hasCompletedDataFields && aviorFormValid
    }
}
