import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { Dialog } from '@blueprintjs/core'
import { Button } from 'reactstrap'

@inject('store')
@observer
class ExportReportDialog extends React.Component<{ store?: RootStore } & WithNamespaces> {
    componentDidUpdate = () => {
        if (this.props.store!.reportsUIStore.reportSignedURL) {
            // Modal will close after 5 minutes
            setTimeout(
                this.props.store!.reportsUIStore.closeExportReportDialog,
                this.props.store!.reportsUIStore.exportReportLinkTimeout
            )
        }
    }

    toggleExportReportDialog = () => {
        this.props.store!.reportsUIStore.toggleExportReportDialog()
    }

    handleReportDownload = () => {
        window.open(this.props.store!.reportsUIStore.reportSignedURL)

        this.props.store!.reportsUIStore.closeExportReportDialog()
    }

    render() {
        return (
            <Dialog
                className='custom-dialog export-report-dialog'
                title='Export report'
                isOpen={this.props.store!.reportsUIStore.exportReportDialogOpen}
                onClose={this.toggleExportReportDialog}
            >
                <div className='dialog-content'>
                    {!this.props.store!.reportsUIStore.reportSignedURL ? (
                        <Button className='custom-button-small m-3' disabled>
                            Generating report
                        </Button>
                    ) : (
                        <div>
                            <h3>
                                {`Report "${this.props.store!.reportsUIStore.selectedExportName}" ${i18n.t(
                                    'reportsPage.reportReady'
                                )}`}
                            </h3>
                            <div className='dialog-actions dialog-actions-center'>
                                <Button
                                    className='custom-button custom-button-large'
                                    onClick={this.handleReportDownload}
                                    color='primary'
                                >
                                    {i18n.t('actions.download')}
                                </Button>
                                <Button
                                    className='custom-button-link custom-button-link-black'
                                    onClick={this.toggleExportReportDialog}
                                    color='link'
                                >
                                    <span>{i18n.t('actions.cancel')}</span>
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </Dialog>
        )
    }
}

export default withNamespaces()(ExportReportDialog)
