import { RoutePath } from 'src/config/views'
import { observable, computed } from 'mobx'
import _ from 'lodash'

export enum RoleType {
    operator = 'Operator',
    opsLite = 'OpsLite',
    contentUser = 'ContentUser',
    audit = 'Audit',
    admin = 'Admin',
    player = 'Player',
    advertiser = 'Advertiser',
    superUser = 'SuperUser',
    none = 'None',
}

export class UserRole {
    @observable types: RoleType[]
    @observable allowedPaths: RoutePath[] = []

    constructor(types: RoleType[]) {
        this.types = types
        if (types.length === 0 || types[0] === RoleType.none) {
            return
        } else if (types.includes(RoleType.superUser)) {
            this.allowedPaths = Object.keys(RoutePath).map(key => RoutePath[key])
        } else {
            for (const type of types) {
                switch (type) {
                    case RoleType.admin:
                        this.allowedPaths.push(RoutePath.admin)
                        break
                    case RoleType.operator:
                    case RoleType.opsLite:
                        this.allowedPaths.push(RoutePath.diagnostics)
                        this.allowedPaths.push(RoutePath.diagnosticsGlobal)
                        this.allowedPaths.push(RoutePath.diagnosticsScreen)
                        break
                    case RoleType.contentUser:
                        this.allowedPaths.push(RoutePath.live)
                        break
                    case RoleType.audit:
                        this.allowedPaths.push(RoutePath.reports)
                        break
                    case RoleType.player:
                        this.allowedPaths.push(RoutePath.player)
                        break
                    case RoleType.advertiser:
                        this.allowedPaths.push(RoutePath.creatives)
                        break
                }
            }
        }
        this.allowedPaths.push(RoutePath.settings)
    }

    @computed get startPath(): RoutePath {
        console.log('types', this.types)
        if (_.isEmpty(this.types) || this.types[0] === RoleType.none) {
            return RoutePath.auth
        }
        if (this.types.includes(RoleType.superUser)) {
            return RoutePath.missionControl
        }
        if (_.some(this.types, type => type === RoleType.operator || type === RoleType.opsLite)) {
            return RoutePath.diagnostics
        }
        if (_.some(this.types, type => type === RoleType.contentUser)) {
            return RoutePath.live
        }
        if (_.some(this.types, type => type === RoleType.audit)) {
            return RoutePath.reports
        }
        if (_.some(this.types, type => type === RoleType.player)) {
            return RoutePath.player
        }
        if (_.some(this.types, type => type === RoleType.advertiser)) {
            return RoutePath.creatives
        }
        if (_.some(this.types, type => type === RoleType.admin)) {
            return RoutePath.admin
        }
        return RoutePath.auth
    }

    @computed get startParams() {
        switch (this.startPath) {
            case RoutePath.missionControl:
            case RoutePath.diagnostics:
                return { org: undefined, screen: undefined }
            case RoutePath.live:
            case RoutePath.reports:
            case RoutePath.player:
            case RoutePath.creatives:
            case RoutePath.admin:
                return { org: undefined }
            default:
                return {}
        }
    }
}
