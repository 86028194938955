import onAPI from './onAPI'
import { ControllerStatType } from 'src/common/models/ControllerStat'

class TileViewRouter {
    protected route = '/screen-stat'

    async subscribeToControllerStat(
        screenId: string,
        controllerStatType: ControllerStatType,
        subscriptionKey?: string
    ) {
        const json: any = await onAPI.fetchJSON(this.route + 's/' + screenId + '/subscribe', {
            stat: controllerStatType,
            subscriptionKey,
        })
        return json
    }
}

const tileViewRouter = new TileViewRouter()
export default tileViewRouter
