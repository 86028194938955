import { observable, computed, action } from 'mobx'
import { Omit } from 'react-router'

import { ErrorCode, ErrorSeverity, ErrorSource } from './DiagnosticErrorEnums'
import DiagnosticsError from './DiagnosticsError'

class DiagnosticsErrorGroup {
    @observable error: ErrorCode
    @observable priority: number
    @observable severity: ErrorSeverity
    @observable source: ErrorSource
    @observable errorList: DiagnosticsError[]

    @computed get totalErrors(): number {
        return this.errorList.length
    }

    @computed get totalAssignedErrors(): number {
        return this.errorList.filter(error => error.isAssigned).length
    }

    @computed get errorListForDiagnostics(): DiagnosticsError[] {
        // Sort error list by timestamp, newest first
        return this.errorList.slice().sort((a, b) => b.time.diff(a.time))
    }

    constructor(json: DiagnosticsErrorGroupJSON) {
        this.errorList = []
        this.error = json.error
        this.priority = json.priority
        this.severity = json.severity
        this.source = json.source
        for (const error of json.errorList) {
            this.errorList.push(new DiagnosticsError(error, this))
        }
    }

    @action removeHash = (hash: string) => {
        this.errorList = this.errorList.filter(error => error.hash !== hash)
    }
}

export type DiagnosticsErrorGroupJSON = Omit<
    DiagnosticsErrorGroup,
    'totalErrors' | 'totalAssignedErrors' | 'removeHash'
>

export default DiagnosticsErrorGroup
