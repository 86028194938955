import * as React from 'react'

// Mobx
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import i18n, { Translations } from 'src/i18n'

import { eventPreventDefault } from '../utils/EventUtils'

import { Form, Label, InputGroup } from 'reactstrap'
import Select from 'react-select'
import { DropdownIndicator, Option, ValueLabelPair } from '../../common/components/SelectComponents'
import globeIcon from '../../assets/images/globeIcon.svg'
import { UserPreferenceType } from '../models/UserSettings'

interface LanguageSelectorProps {
    updateUserPreferences?: boolean
}

@inject('store')
@observer
class LanguageSelector extends React.Component<LanguageSelectorProps & { store?: RootStore }> {
    handleChangeLanguage = (selectedOption: ValueLabelPair) => {
        i18n.changeLanguage(selectedOption.value)

        if (this.props.updateUserPreferences) {
            this.props.store!.settingsUIStore.updateUserPreferences(UserPreferenceType.language, selectedOption.value)
        }
    }

    render() {
        const languageOptions: ValueLabelPair[] = Object.keys(Translations).map(key => ({
                value: key,
                label: Translations[key],
            }))

        const userLanguage = this.props.store!.userStore.me?.preferences?.language
        const defaultLanguageOption = userLanguage
            ? {
                  value: userLanguage,
                  label: Translations[userLanguage],
              }
            : languageOptions[0]

        return (
            <Form className='language-selector' inline onSubmit={eventPreventDefault}>
                <InputGroup className='custom-input-group'>
                    <Label className='custom-label custom-label-inline'>
                        <img className='globe-icon' src={globeIcon} alt='Select language' />
                    </Label>
                    <Select
                        className='custom-select-wrapper'
                        classNamePrefix='custom-select'
                        isSearchable={false}
                        blurInputOnSelect
                        onChange={this.handleChangeLanguage}
                        options={languageOptions}
                        defaultValue={defaultLanguageOption}
                        components={{ DropdownIndicator, Option }}
                    />
                </InputGroup>
            </Form>
        )
    }
}

export default LanguageSelector
