import onAPI from './onAPI'
import { LednetVersion } from 'src/common/stores/AdminStore'

class LednetVersionRouter {
    private lednetRoute = '/lednet-update'

    async getLednetVersions(): Promise<LednetVersion[]> {
        const json: LednetVersion[] = await onAPI.fetchJSON(this.lednetRoute + 's')
        return json
    }

    async updateLednetVersions(comptrollerIds: string[], version: string): Promise<void> {
        return onAPI.fetchJSON(this.lednetRoute, {
            comptrollerIds,
            version,
        })
    }
}

const lednetVersionRouter = new LednetVersionRouter()
export default lednetVersionRouter
