import { observable } from 'mobx'
import LiveImage from './LiveImage'
import { LiveSnapshots } from '../stores/LiveStore'

class LiveSnapshot {
    static newLiveSnapshot(): LiveSnapshot {
        return new LiveSnapshot({
            liveScreenItems: [],
            subscriptionKey: '',
            subscriptionTTL: 0,
        })
    }

    @observable liveScreenItems: LiveImage[]
    @observable subscriptionKey: string
    @observable subscriptionTTL: number

    constructor(json: LiveSnapshotJSON) {
        // super(json)
        if (!json) {
            return
        }
        this.liveScreenItems = json.liveScreenItems
        this.subscriptionKey = json.subscriptionKey
        this.subscriptionTTL = json.subscriptionTTL
    }

    // toJSON(): LiveSnapshotJSON {
    // NOTE: Not implemented at this point
    // return super.toJSON() as LiveSnapshotJSON
    // }
}

export type LiveSnapshotJSON = LiveSnapshot

export default LiveSnapshot

export interface LiveSnapshotParameters {
    screenIds: string[]
    resolutions: string[]
    subscribe: boolean
    subscriptionKey?: string
    snapshots?: LiveSnapshots
}
