import { observable, action } from 'mobx'

import ScreenDiagnosticsUIStore from './ScreenDiagnosticsUIStore'

import DiagnosticsErrorGroup from 'src/common/models/DiagnosticsErrorGroup'
import { browserStorageManager, StorageKey } from 'src/common/managers/BrowserStorageManager'

export default class ScreenCurrentErrorsManager {
    @observable isCompactView = false
    @observable openErrorGroups?: Set<DiagnosticsErrorGroup>

    screenDiagnosticsUIStore: ScreenDiagnosticsUIStore

    constructor(screenDiagnosticsUIStore: ScreenDiagnosticsUIStore) {
        this.screenDiagnosticsUIStore = screenDiagnosticsUIStore

        const diagnosticsCompactView = browserStorageManager.readLocalStorageString(StorageKey.diagnosticsCompactView)
        if (diagnosticsCompactView) {
            this.isCompactView = diagnosticsCompactView === 'true'
        }
    }

    @action toggleCompactView = () => {
        if (!this.isCompactView) {
            // Reset openErrorGroups
            this.collapseAllErrorGroups()
        }

        this.isCompactView = !this.isCompactView

        browserStorageManager.updateLocalStorageItem(StorageKey.diagnosticsCompactView, this.isCompactView.toString())
    }

    @action toggleCurrentScreenErrorGroups = (errorGroup: DiagnosticsErrorGroup) => {
        if (!this.openErrorGroups) {
            this.openErrorGroups = new Set<DiagnosticsErrorGroup>()
        }

        if (this.openErrorGroups.has(errorGroup)) {
            this.openErrorGroups.delete(errorGroup)
        } else {
            this.openErrorGroups.add(errorGroup)
        }
    }

    @action collapseAllErrorGroups = () => {
        this.openErrorGroups?.clear()
    }
}
