import * as React from 'react'

// i18n
import i18n from 'src/i18n'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { Label, Tooltip, Position } from '@blueprintjs/core'
import helpLightIcon from 'src/assets/images/helpLightIcon.svg'

interface HelpLabelProps {
    label: string
}

@inject('store')
@observer
class HelpLabel extends React.Component<HelpLabelProps & { store?: RootStore }> {
    render() {
        return (
            <Label className='help-label custom-label'>
                <h6>{this.props.label}</h6>
                <Tooltip content={i18n.t('common.help.timezoneHelp')} position={Position.TOP}>
                    <img src={helpLightIcon} alt='Help' className='help-label-icon' />
                </Tooltip>
            </Label>
        )
    }
}

export default HelpLabel
