import RootStore from 'src/common/RootStore'
import AdminStoreItem from './AdminStoreItem'
import AdminStoreOrganisation from './AdminStoreOrg'
import AdminStoreScreen from './AdminStoreScreen'
import AdminStoreUser from './AdminStoreUser'
import { INamedBaseModel } from 'src/common/models/BaseModel'

class AssetData {
    rootStore: RootStore
    org: AdminStoreOrganisation
    screen: AdminStoreScreen
    user: AdminStoreUser
    hasMultipleOrganisations: boolean
    isSuperUser: boolean
    hasMultipleWriteOrgs: boolean

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.org = new AdminStoreOrganisation(rootStore)
        this.user = new AdminStoreUser(rootStore)
        this.screen = new AdminStoreScreen(rootStore)
        this.hasMultipleOrganisations = rootStore.orgStore.hasMultipleWriteOrgs
        this.isSuperUser = rootStore.userStore.me!.isSuperUser
        this.hasMultipleWriteOrgs = rootStore.orgStore.hasMultipleWriteOrgs
    }

    get data() {
        let item: AdminStoreItem<INamedBaseModel> | undefined
        if (this.org.item) {
            item = this.org
        }
        if (this.user.item) {
            item = this.user
        }
        if (this.screen.item) {
            item = this.screen
        }
        return item
    }

    get hasUnsavedChanges(): boolean {
        return !!this.data && this.data.hasChanges
    }
}

export default AssetData
