import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'
import i18n from 'src/i18n'

import LiveImage from 'src/common/models/LiveImage'
import { ScreenState } from 'src/common/models/ScreenState'

import { Transition } from 'react-spring/renderprops'
import { Button } from 'reactstrap'
import { Icon, Position, Tooltip } from '@blueprintjs/core'
import playsIcon from '../../../assets/images/playsIcon.svg'
import resolutionIcon from '../../../assets/images/resolutionIcon.svg'
import imageBroken from '../../../assets/images/imageBroken.svg'
import LockedOverlay from 'src/common/components/LockedOverlay'
import SyncState from 'src/common/components/SyncState'
import Tags from 'src/common/components/Tags'
import { AlertReasonShortDescriptions, OutageReason, StoppageReason } from 'src/common/models/AlertReason'
import views from 'src/config/views'
import { ScreenTabName } from 'src/modules/diagnostics/screen/stores/ScreenDiagnosticsUIStore'
import { PlayerName } from 'src/common/models/PlayerSyncState'

interface LiveImageCardProps {
    loadingImage: LiveImage
    loadedImage?: LiveImage
}

@inject('store')
@observer
class LiveImageCard extends React.Component<{ store?: RootStore } & LiveImageCardProps> {
    handleMouseHover = (screenId?: string) => {
        this.props.store!.liveUIStore.setScreenHovered(screenId)
    }

    handleTouchStart = (screenId?: string) => {
        this.props.store!.liveUIStore.setScreenTouched(screenId)
    }

    handleTouchEnd = () => {
        this.props.store!.liveUIStore.setScreenTouched(undefined)
    }

    handleOpenPlayer = (screenId: string) => {
        this.props.store!.playerStore.launchWindow(screenId)
    }

    render() {
        const loadingImages = this.props.store!.liveStore.loadingImagesForGrid
        if (!loadingImages) {
            return null
        }

        const loadingImage = this.props.loadingImage
        const screen = this.props.store!.screenStore.findItem(loadingImage.screenId)
        const userStore = this.props.store!.userStore
        const router = this.props.store!.router

        if (!screen) {
            return null
        }

        let liveImage = this.props.loadedImage
        if (!liveImage) {
            liveImage = new LiveImage({
                screenId: screen.id!,
                // Prevent broken image for initial load
                URL: loadingImage && !loadingImage.URL ? imageBroken : '',
                filename: '',
            })
        }

        const zoomLevel = this.props.store!.liveUIStore.zoomLevel
        const screenPlaying = screen.state === ScreenState.started
        const screenConnected = screen.isConnected
        const reason = !screenConnected
            ? screen.outageReason || OutageReason.network
            : !screenPlaying
            ? screen.stoppageReason || StoppageReason.controllerExplicit
            : undefined
        const liveImageTransitionQueue: LiveImage[] = []
        liveImageTransitionQueue.push(liveImage)
        const isOutWatchPlayer = screen.playerName === PlayerName.outwatch || screen.playerName === PlayerName.candelic
        const isMobile = this.props.store!.appUIStore.isMobile

        return (
            <div className='screen-card' style={{ width: zoomLevel + 'px', maxHeight: zoomLevel + 'px' }}>
                <div className='screen-card-name'>
                    <span>{screen.name}</span>
                </div>
                {screen.labelKeyValuePairs && (
                    <Tags tags={screen.labelKeyValuePairs.filter(label => label.value !== '')} />
                )}
                <div className='screen-card-data'>
                    <div>
                        <img src={resolutionIcon} alt='Resolution' height={15} width={15} />
                        <span className='screen-card-resolution'>{screen.resolution}</span>
                        {screen.hasCamera && (
                            <>
                                <Icon className='ml-4 text-primary' icon='camera' iconSize={16} />
                                {/* <span className='ml-2'>Camera</span> */}
                            </>
                        )}
                    </div>
                    <div className='screen-card-player-name'>
                        <SyncState size={10} screen={screen} />
                        <span>{i18n.t('livePage.playerName.' + screen.playerName)}</span>
                    </div>
                </div>
                <div
                    className='screen-card-image-container'
                    onMouseEnter={this.handleMouseHover.bind(this, screen.id)}
                    onMouseLeave={this.handleMouseHover.bind(this, undefined)}
                    onTouchStart={this.handleTouchStart.bind(this, screen.id)}
                    onTouchEnd={this.handleTouchEnd.bind(this)}
                    onClick={() => isMobile && liveImage && this.props.store!.liveUIStore.newScreenSummary(liveImage)}
                >
                    {!(screenPlaying && screenConnected) && (
                        <LockedOverlay style={{ borderRadius: '5px' }}>
                            <div className='screen-not-playing'>
                                <h6>{AlertReasonShortDescriptions[reason!]}</h6>
                            </div>
                        </LockedOverlay>
                    )}
                    {/* NOTE: Tallest div will decide height for row */}
                    {((this.props.store!.liveUIStore.screenHovered === screen!.id && !isMobile) ||
                        (isMobile &&
                            (this.props.store!.liveUIStore.screenTouched === screen!.id ||
                                this.props.store!.liveUIStore.screenHovered === screen!.id))) && (
                        <div
                            className='hover-overlay d-none d-md-flex'
                            style={{
                                '@media (maxWidth: 767px)': {
                                    display: 'flex !important',
                                },
                            }}
                        >
                            <div className='left-buttons'>
                                <Tooltip
                                    boundary='viewport'
                                    position={Position.RIGHT}
                                    hoverOpenDelay={500}
                                    content='Image Stats'
                                >
                                    <Button
                                        color='primary'
                                        disabled={!liveImage || !liveImage.displayImageId}
                                        onClick={() =>
                                            liveImage && this.props.store!.liveUIStore.newScreenSummary(liveImage)
                                        }
                                    >
                                        <Icon icon='timeline-line-chart' iconSize={20} />
                                    </Button>
                                </Tooltip>
                                {isOutWatchPlayer && (
                                    <Tooltip
                                        boundary='viewport'
                                        position={Position.RIGHT}
                                        hoverOpenDelay={500}
                                        content='OutWatch Player'
                                    >
                                        <Button
                                            className='mt-2'
                                            color='primary'
                                            onClick={this.handleOpenPlayer.bind(this, screen.id)}
                                        >
                                            <Icon icon='play' iconSize={20} />
                                        </Button>
                                    </Tooltip>
                                )}
                                {screen.hasCamera && (
                                    <Tooltip
                                        boundary='viewport'
                                        position={Position.RIGHT}
                                        hoverOpenDelay={500}
                                        content='Camera'
                                    >
                                        <Button
                                            className='mt-2'
                                            color='primary'
                                            onClick={() =>
                                                screen.id && this.props.store!.liveUIStore.openCamera(screen.id)
                                            }
                                        >
                                            <Icon icon='camera' iconSize={20} />
                                        </Button>
                                    </Tooltip>
                                )}
                            </div>
                            {userStore.hasDiagnosticsPermissions && (
                                <div className='right-buttons'>
                                    <Tooltip
                                        boundary='viewport'
                                        position={Position.LEFT}
                                        hoverOpenDelay={500}
                                        content='Operations'
                                    >
                                        <Button
                                            className='mt-2'
                                            color='primary'
                                            onClick={() =>
                                                router.goTo(
                                                    views.diagnosticsScreen,
                                                    {
                                                        ...router.params,
                                                        screen: screen.id,
                                                        tab: ScreenTabName.current,
                                                    },
                                                    this.props.store!
                                                )
                                            }
                                        >
                                            <Icon icon='wrench' iconSize={20} />
                                        </Button>
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    )}
                    <div className='screen-card-image' style={{ height: zoomLevel / screen.aspectRatio + 'px' }}>
                        <Transition
                            items={liveImageTransitionQueue}
                            keys={item => item.displayableFilename}
                            from={{ position: 'absolute', opacity: 0 } as React.CSSProperties}
                            enter={{ opacity: 1 }}
                            leave={{ opacity: 0 }}
                            config={{ duration: 2000 }}
                        >
                            {/* eslint-disable-next-line react/display-name */}
                            {item => props => (
                                <div style={props} className='live-crossfade-container'>
                                    <img
                                        src={item.displayURL(!!this.props.store!.liveUIStore.snapshotsMode)}
                                        alt={item.displayableFilename}
                                    />
                                </div>
                            )}
                        </Transition>
                    </div>
                </div>
                <div className='screen-card-data'>
                    <Transition
                        items={liveImageTransitionQueue}
                        keys={item => item.displayableFilename}
                        initial={{ position: 'absolute' } as React.CSSProperties}
                        from={{ position: 'absolute', opacity: 0 } as React.CSSProperties}
                        enter={{ opacity: 1 }}
                        leave={{ opacity: 0 }}
                        config={{ duration: 2000 }}
                    >
                        {/* eslint-disable-next-line react/display-name */}
                        {item => props => (
                            <div style={props} className='screen-card-liveimage-info'>
                                <img src={playsIcon} alt='Play count' height={10} width={9} />
                                <span>{item.plays || 'No play data'}</span>
                                {this.props.store!.liveUIStore.zoomDetail && item.filename !== '' && (
                                    <span className='screen-card-image-name'>{item.displayableFilename}</span>
                                )}
                            </div>
                        )}
                    </Transition>
                </div>
            </div>
        )
    }
}

export default LiveImageCard
