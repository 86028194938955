class TextLine {
    level: number = 0
    text: string

    constructor(json: any) {
        // Check if 'remoteCommand' or 'localCommand' key exists for styling
        if (json.hasOwnProperty('remoteCommand')) {
            // Only print command
            this.text = '> ' + json['remoteCommand']
        } else if (json.hasOwnProperty('localCommand')) {
            // Only print command
            this.text = '> ' + json['localCommand']
            // Check if log
        } else if (json.hasOwnProperty('log')) {
            // Check if log has text and level keys
            if (json['log'].hasOwnProperty('text') && json['log'].hasOwnProperty('level')) {
                this.level = json['log']['level']
                this.text = json['log']['text']
            } else {
                console.error('Invalid message. Type: log')
            }
        } else {
            // If not using valid keys, ignore and throw error
            console.error('Invalid message')
        }
    }

    // Returns a classname based on log level
    className() {
        const baseString = 'list-item-text '
        let className: string
        switch (this.level) {
            case 1:
                // Bold: normal alternative (light cyan, bold)
                className = 'text-info font-weight-bold'
                break
            case 2:
                // Command: command message (dark green, italic)
                className = 'text-success font-weight-bold font-italic'
                break
            case 3:
                // Critical: critical error (red)
                className = 'text-danger'
                break
            case 4:
                // Warning: warning (orange)
                className = 'text-warning font-weight-bold'
                break
            case 5:
                // MinorWarning: warning (light orange)
                className = 'text-warning'
                break
            case 6:
                // Good: (green)
                className = 'text-success'
                break
            case 7:
                // Status: general status (yellow)
                className = 'text-yellow'
                break
            case 8:
                // Heading: cyan)
                className = 'text-cyan'
                break
            case 9:
                // Section: (blue)
                className = 'text-blue'
                break
            case 10:
                // Light: (grey)
                className = 'text-muted'
                break
            case 11:
                // Status2: (purple)
                className = 'text-purple'
                break
            default:
                // Normal: normal log (white)
                className = 'text-white'
                break
        }
        return baseString + className
    }
}

export default TextLine
