import { observable, action, computed, runInAction } from 'mobx'
import RootStore from 'src/common/RootStore'

import { SignInStatus } from 'src/common/models/SignInStatus'
import { AWSErrorHandler } from 'src/common/models/AWSErrorHandler'
import { checkEmail } from 'src/common/utils/Regex'

import { Auth } from 'aws-amplify'

export default class SignInUIStore {
    @observable AWSUser: any
    @observable authAWSError: string | undefined
    @observable isLoading = false

    // User credentials entered in signin form
    @observable username: string | undefined
    @observable password: string | undefined

    @observable verificationCode: string | undefined
    @observable newPassword: string | undefined
    @observable verifyNewPassword: string | undefined

    @observable mfaCode: string | undefined
    @observable rememberDevice: boolean = false

    // Status for CustomSignIn screen - we display different vies for each status
    @observable signinStatus: SignInStatus = SignInStatus.signIn

    // Set the inital focused state to true to prevent showing an error on first load
    @observable signInEmailFocused = true
    @observable signInPasswordFocused = true
    @observable forgotPasswordEmailFocused = true
    @observable verificationCodeFocused = true

    @observable emailValid = false

    authCodeLength = 6
    minimumPasswordLength = 8

    errorHandler: AWSErrorHandler
    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
        this.errorHandler = new AWSErrorHandler()
    }

    @computed get isAuthAWSError(): boolean {
        return this.authAWSError ? true : false;
    }

    @computed get isAuthEmailValid(): boolean {
        return this.emailValid && this.username !== undefined
    }

    @computed get isAuthPasswordValid(): boolean {
        return this.password !== undefined && this.password.length >= this.minimumPasswordLength
    }

    @computed get isAuthVerifyPasswordValid(): boolean {
        return (
            this.newPassword !== undefined &&
            this.verifyNewPassword !== undefined &&
            this.newPassword === this.verifyNewPassword
        )
    }

    @computed get isSignInEmailInputValid(): boolean {
        return this.signInEmailFocused || this.isAuthEmailValid
    }

    @computed get isSignInPasswordValid(): boolean {
        return this.signInPasswordFocused || this.isAuthPasswordValid
    }

    @computed get isSignInFormValid(): boolean {
        return this.isAuthEmailValid && this.isAuthPasswordValid && !this.isLoading
    }

    @computed get isForgotPasswordEmailValid(): boolean {
        return this.forgotPasswordEmailFocused || this.isAuthEmailValid
    }

    @computed get isNewPasswordValid(): boolean {
        return this.newPassword !== undefined && this.newPassword.length > this.minimumPasswordLength
    }

    @computed get isNewPasswordFormValid(): boolean {
        return this.isNewPasswordValid && this.emailValid && this.isAuthVerifyPasswordValid
    }

    @computed get isVerificationCodeValid(): boolean {
        return this.verificationCode !== undefined && this.verificationCode.length === this.authCodeLength
    }

    @computed get isVerificationCodeInputValid(): boolean {
        return this.verificationCodeFocused || this.isVerificationCodeValid
    }

    @computed get isResetPasswordFormValid(): boolean {
        return this.isVerificationCodeValid && this.isAuthVerifyPasswordValid
    }

    @computed get isConfirmSignInFormValid(): boolean {
        return this.mfaCode !== undefined && this.mfaCode.length === this.authCodeLength && !this.isLoading
    }

    @action setSignInEmailFocused = (value: boolean) => {
        if (value === true) {
            this.authAWSError = undefined
        }

        this.signInEmailFocused = value
    }

    @action setSignInPasswordFocused = (value: boolean) => {
        if (value === true) {
            this.authAWSError = undefined
        }

        this.signInPasswordFocused = value
    }

    @action setForgotPasswordEmailFocused = (value: boolean) => {
        this.forgotPasswordEmailFocused = value
    }

    @action setVerificationCodeFocused = (value: boolean) => {
        this.verificationCodeFocused = value
    }

    @action handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const id = event.target.id
        const value = event.target.value !== '' ? event.target.value : undefined

        switch (id) {
            case 'email':
                this.username = value
                this.emailValid = !!(value && checkEmail(value))
                break
            case 'password':
                this.password = value
                break
            case 'code':
                this.verificationCode = value
                break
            case 'newPassword':
                this.newPassword = value
                break
            case 'verifyNewPassword':
                this.verifyNewPassword = value
                break
            case 'mfaCode':
                this.mfaCode = value
                break
        }
    }

    @action resetUserCredentials(resetUserName: boolean = true) {
        if (resetUserName) {
            this.username = undefined
        }
        this.password = undefined
        this.verificationCode = undefined
        this.newPassword = undefined
        this.verifyNewPassword = undefined
        this.mfaCode = undefined
    }

    @action setSignInStatus(status: SignInStatus) {
        if (this.signinStatus !== status) {
            this.signinStatus = status
            this.authAWSError = undefined
        }
    }

    // SignIn Form Methods
    async signInUser() {
        if (this.username) {
            try {
                runInAction('setSignInLoading', () => {
                    this.isLoading = true
                })
                await Auth.signIn(this.username, this.password).then((user: any) => {
                    runInAction('setAWSUser', () => {
                        this.AWSUser = user
                    })

                    switch (user.challengeName) {
                        case 'NEW_PASSWORD_REQUIRED':
                            this.setSignInStatus(SignInStatus.requireNewPassword)
                            break
                        case 'SOFTWARE_TOKEN_MFA':
                            this.setSignInStatus(SignInStatus.requireMFA)
                            break
                        default:
                            break
                    }
                })

                // No errors, reset errorMessage
                runInAction('clearAWSError', () => {
                    this.authAWSError = undefined
                })
            } catch (err) {
                runInAction('setAWSError', () => {
                    this.authAWSError = err.code
                })
            } finally {
                runInAction('setSignInLoading', () => {
                    this.isLoading = false
                })
            }
        }
    }

    // User clicked Forgot Password
    @action forgotPassword = () => {
        // Clear any error message
        this.authAWSError = undefined

        if (this.username) {
            Auth.forgotPassword(this.username)
                .then(data => {
                    // Display info that e-mail was send to user with code
                    // And show window with code
                    this.resetUserCredentials(false)
                    this.setSignInStatus(SignInStatus.submitCode)
                })
                .catch(err => {
                    runInAction('setAWSError', () => {
                        this.authAWSError = err.code
                    })
                })
        }
    }

    // Collect confirmation code and new password, then
    submitForgotPassword = () => {
        if (this.username && this.verificationCode && this.newPassword) {
            Auth.forgotPasswordSubmit(this.username, this.verificationCode, this.newPassword)
                .then(data => {
                    this.setSignInStatus(SignInStatus.signIn)
                })
                .catch(err => {
                    runInAction('setAWSError', () => {
                        this.authAWSError = err.code
                    })
                })
        }
    }

    @action submitNewPassword = () => {
        // Clear any error message
        this.authAWSError = undefined

        if (this.newPassword) {
            Auth.completeNewPassword(this.AWSUser, this.newPassword, '').catch(err => {
                runInAction('setAWSError', () => {
                    this.authAWSError = err.code
                })
            })
        }
    }

    // Looks like we have only one common method so far...
    changeStatus = (status: SignInStatus) => {
        this.setSignInStatus(status)
    }

    handleBackToSignIn = () => {
        // this.resetUserCredentials()
        this.changeStatus(SignInStatus.signIn)
    }

    @action handleRememberDevice = () => {
        this.rememberDevice = !this.rememberDevice
    }

    @action handleConfirmSignIn = async () => {
        if (!this.mfaCode) {
            return
        }

        // Clear any error message
        this.authAWSError = undefined

        try {
            runInAction('setSignInLoading', () => {
                this.isLoading = true
            })
            await Auth.confirmSignIn(this.AWSUser, this.mfaCode, 'SOFTWARE_TOKEN_MFA')
            if (this.rememberDevice) {
                await this.rootStore.authStore.rememberDevice()
            }
        } catch (error) {
            runInAction('setAWSError', () => {
                this.authAWSError = error.code
            })
        } finally {
            runInAction('setSignInLoading', () => {
                this.isLoading = false
            })
        }
    }
}
