import { observable, computed } from 'mobx'
import moment, { Moment } from 'moment'
import { Omit } from 'react-router'

export enum CameraCommand {
    ping = 'pingCamera',
    cameraFeed = 'cameraFeed',
    stopCameraFeed = 'stopCameraFeed',
    cameraLive = 'cameraLive',
    cameraHistoryRequest = 'cameraHistoryRequest',
    cameraHistoryResponse = 'cameraHistoryResponse',
}

export class CameraLive {
    static newCameraLive(): CameraLive {
        return new CameraLive({
            hash: '',
            url: '',
            startTime: moment(),
            cameraId: '0',
        })
    }

    @observable hash: string
    @observable url: string
    @observable startTime: Moment
    @observable cameraId: string

    constructor(json: CameraLiveJSON) {
        this.hash = json.hash
        this.url = json.url
        this.startTime = moment(json.startTime)
        this.cameraId = json.cameraId
    }

    @computed get width(): number {
        return 0
    }

    @computed get height(): number {
        return 0
    }
}

export type CameraLiveJSON = Omit<CameraLive, 'width' | 'height'>
