import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { ZoomLevel, startZoomLevel } from '../stores/TileViewUIStore'
import ZoomSlider from 'src/common/components/ZoomSlider'

@inject('store')
@observer
class TileViewZoom extends React.Component<{ store?: RootStore }> {
    handleZoomLevelChange = (value: number) => {
        let zoom = startZoomLevel

        switch (value) {
            case 1:
                zoom = ZoomLevel.one
                break
            case 2:
                zoom = ZoomLevel.two
                break
            case 3:
                zoom = ZoomLevel.three
                break
            case 4:
                zoom = ZoomLevel.four
                break
            case 5:
                zoom = ZoomLevel.five
                break
            default:
                return
        }

        this.props.store!.tileViewUIStore.changeZoomLevel(zoom)
    }

    render() {
        return (
            <ZoomSlider
                handleZoomLevelChange={this.handleZoomLevelChange}
                defaultZoomLevel={3}
                minZoom={1}
                maxZoom={5}
                className='tile-view-zoom'
            />
        )
    }
}

export default TileViewZoom
