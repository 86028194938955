import * as React from 'react'

// i18n
import i18n from 'src/i18n'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import Tile from 'src/common/models/Tile'
import { ControllerStatType, BasicDiagnosticsStatType } from 'src/common/models/ControllerStat'

import { severityColour } from 'src/common/utils/Colour'

import ProofCanvas from './ProofCanvas'
import chainBreakIcon from 'src/assets/images/chainBreakIcon.svg'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { ErrorSeverity } from 'src/common/models/DiagnosticErrorEnums'
import { Tooltip, Position } from '@blueprintjs/core'
import { ZoomLevel } from '../stores/TileViewUIStore'

interface SectionTileProps {
    handleTileClick?: (tile: Tile) => void
    width: number
    height: number
    index?: number
    tile?: Tile
    isShowingHistory?: boolean
    className?: string
}

const fontMap: Record<ZoomLevel, number> = {
    [ZoomLevel.one]: 10,
    [ZoomLevel.two]: 15,
    [ZoomLevel.three]: 18,
    [ZoomLevel.four]: 20,
    [ZoomLevel.five]: 22,
}

@inject('store')
@observer
class SectionTile extends React.Component<SectionTileProps & { store?: RootStore }> {
    private tile = this.props.tile

    renderProofStat = (size: number, tileContent: string) => {
        if (!tileContent || isNaN(parseInt(tileContent, 10))) {
            return null
        }

        const value = BigInt.asUintN(64, BigInt(tileContent))

        const data: boolean[] = []
        let bitNo = 1n
        for (let i = 0; i < 64; i++) {
            data.push(Boolean(value & BigInt(bitNo)))
            bitNo = bitNo << 1n
        }

        return (
            <div className='proof-stat'>
                <ProofCanvas height={size} width={size} data={data} />
            </div>
        )
    }

    render() {
        const tileViewUIStore = this.props.store!.tileViewUIStore
        const tileViewStore = this.props.store!.tileViewStore
        const heatmapColourMap = tileViewUIStore.heatmapColourMap
        const strandColourMap = tileViewUIStore.strandColourMap
        const sizeColourMap = tileViewUIStore.sizeColourMap

        let tileBackgroundColour: string | undefined
        if (this.tile) {
            if (tileViewUIStore.currentStatValue === BasicDiagnosticsStatType.strands) {
                tileBackgroundColour = strandColourMap?.get(this.tile.tnl)
            } else if (tileViewUIStore.currentStatValue === BasicDiagnosticsStatType.size) {
                tileBackgroundColour = sizeColourMap?.get(this.tile.section.sizeString)
            } else if (tileViewUIStore.showHeatmap) {
                tileBackgroundColour = heatmapColourMap?.get(this.tile.tnl)
            } else {
                // Default to error severity
                tileBackgroundColour = severityColour(this.tile?.maxSeverity || ErrorSeverity.noError)
            }
        } else {
            // Grey
            tileBackgroundColour = undefined
        }

        let tileContent: string
        if (this.props.isShowingHistory) {
            tileContent = String(this.tile?.errorCount)
        } else {
            switch (tileViewUIStore.currentStatValue) {
                case BasicDiagnosticsStatType.tnl:
                case BasicDiagnosticsStatType.strands:
                    tileContent = String(this.tile?.tnl)
                    break
                case BasicDiagnosticsStatType.size:
                    tileContent = this.tile?.section.sizeString || ''
                    break
                case BasicDiagnosticsStatType.errors:
                    tileContent = String(this.tile?.errorCount)
                    break
                case BasicDiagnosticsStatType.model:
                    tileContent = String(this.tile?.type)
                    break
                case ControllerStatType[tileViewUIStore.currentStatValue]:
                    const stale = this.tile?.cannotCommunicate ? '*' : ''
                    tileContent = this.tile ? String(tileViewStore.stat?.statValues.get(this.tile.tnl)) + stale : ''
                    break
                default:
                    tileContent = ''
            }
        }

        const width = this.props.width
        const height = this.props.height
        const index = this.props.index
        const stale = this.tile?.cannotCommunicate ? ' *stale' : ''
        return (
            <Tooltip
                key={this.tile?.id || index}
                content={
                    i18n.t('diagnosticsPage.tileTnl') +
                    ' ' +
                    (this.tile?.tnl + stale || i18n.t('diagnosticsPage.tileTnlMissing'))
                }
                disabled={this.props.isShowingHistory}
                modifiers={{ offset: { offset: '0, -40%' }, flip: { enabled: false } }}
                portalClassName='tnl-tooltip-portal'
                position={Position.TOP}
            >
                <div
                    key={this.tile?.id || index}
                    onClick={this.props.handleTileClick ? this.props.handleTileClick.bind(this, this.tile) : undefined}
                    className={
                        'section-tile fade-background-colour' + (this.props.className ? ' ' + this.props.className : '')
                    }
                    style={{
                        width,
                        height,
                        backgroundColor: tileBackgroundColour,
                    }}
                >
                    {this.tile ? (
                        tileViewUIStore.currentStatValue === ControllerStatType.proof ? (
                            this.renderProofStat(height, tileContent)
                        ) : (
                            <TransitionGroup>
                                <CSSTransition key={tileContent} classNames='fade' timeout={1000} exit={false}>
                                    <h6
                                        style={{
                                            fontSize: fontMap[tileViewUIStore.zoomLevel] + 'px',
                                            letterSpacing: 1.8 + 'px',
                                        }}
                                    >
                                        {tileContent}
                                    </h6>
                                </CSSTransition>
                            </TransitionGroup>
                        )
                    ) : (
                        <img
                            style={{ height: 6 + 10 * (height / 40) + 'px' }}
                            src={chainBreakIcon}
                            alt='Cannot communicate'
                        />
                    )}
                </div>
            </Tooltip>
        )
    }
}

export default SectionTile
