import * as React from 'react'
import { observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { Button } from 'reactstrap'
import SyncState from 'src/common/components/SyncState'

interface PlayerProps {
    store?: RootStore
}

function Player(props: PlayerProps) {
    const { store } = props
    const uiStore = store!.playerUIStore

    const { screens } = uiStore

    const me = store!.userStore.me
    if (!me) {
        return null
    }

    const openPlayer = (screenId: string) => {
        store!.playerStore.launchWindow(screenId)
    }

    return (
        <div className='grey-container'>
            <div className='custom-container-fluid player-container'>
                <main>
                    {screens.length === 0 ? (
                        <div className='no-screens'>
                            <h2>No screens available</h2>
                            <p className='text-white'>Please contact support to add screens to your account.</p>
                        </div>
                    ) : (
                        screens.map(screen => (
                            <div key={screen.id} className='screen-card'>
                                <div className='screen-card-header'>
                                    <h2 className='ellipsis'>{screen.name}</h2>
                                    <SyncState size={20} screen={screen} />
                                </div>
                                <p className='text-white'>{screen.resolution}</p>
                                <Button
                                    className='custom-button'
                                    color='primary'
                                    onClick={() => openPlayer(screen.id!)}
                                >
                                    Open Player
                                </Button>
                            </div>
                        ))
                    )}
                </main>
            </div>
        </div>
    )
}

export default observer(Player)
