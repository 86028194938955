import { observable } from 'mobx'

import Media from './Media'
import MediaSettings from './MediaSettings'

class MediaContainer {
    static newMediaContainer(): MediaContainer {
        return new MediaContainer({
            files: new Array<Media>(),
        })
    }

    @observable files: Media[]
    @observable settings?: MediaSettings

    constructor(json: MediaContainerJSON, screenId?: string) {
        this.files = json.files.map((file, i) => {
            const media = new Media(file)
            media.index = i
            media.screenId = screenId
            return media
        })
        if (json.settings) {
            this.settings = new MediaSettings(json.settings)
        }
    }
}

export type MediaContainerJSON = MediaContainer

export default MediaContainer
