import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { PopoutProps } from './PopoutProps'
import { generateWindowFeaturesString } from './generateWindowFeaturesString'
import { popouts } from './popouts'
import { crossBrowserCloneNode } from './crossBrowserCloneNode'
import * as globalContext from './globalContext'
import './childWindowMonitor'

export class Popout extends React.Component<PopoutProps> {
    styleElement: HTMLStyleElement | null | undefined

    child: Window | null | undefined

    private id: string | undefined

    private container: HTMLElement | null | undefined

    private setupAttempts = 0

    componentDidUpdate() {
        this.renderChildWindow()
    }

    componentDidMount() {
        this.renderChildWindow()
    }

    componentWillUnmount() {
        this.closeChildWindowIfOpened()
    }

    render() {
        return null
    }

    bringChildWindowToFrontIfOpened = () => {
        if (isChildWindowOpened(this.child!)) {
            this.child!.focus()
        }
    }

    private setupOnCloseHandler(id: string, child: Window) {
        // For Edge, IE browsers, the document.head might not exist here yet. We will just simply attempt again when RAF is called
        // For Firefox, on the setTimeout, the child window might actually be set to null after the first attempt if there is a popup blocker
        if (this.setupAttempts >= 5) {
            return
        }

        if (child && child.document && child.document.head) {
            const unloadScriptContainer = child.document.createElement('script')
            const onBeforeUnloadLogic = `
            window.onbeforeunload = function(e) {
                var result = window.opener.${globalContext.id}.onBeforeUnload.call(window, '${id}', e);

                if (result) {
                    window.opener.${globalContext.id}.startMonitor.call(window.opener, '${id}');

                    e.returnValue = result;
                    return result;
                } else {
                    window.opener.${globalContext.id}.onChildClose.call(window.opener, '${id}');
                }
            }`

            // Use onload for most URL scenarios to allow time for the page to load first
            // Safari 11.1 is aggressive, so it will call onbeforeunload prior to the page being created.
            unloadScriptContainer.innerHTML = `
            window.onload = function(e) {
                ${onBeforeUnloadLogic}
            };
            `

            // For edge and IE, they don't actually execute the onload logic, so we just want the onBeforeUnload logic.
            // If this isn't a URL scenario, we have to bind onBeforeUnload directly too.
            if (isBrowserIEOrEdge() || !this.props.url) {
                unloadScriptContainer.innerHTML = onBeforeUnloadLogic
            }

            child.document.head.appendChild(unloadScriptContainer)

            this.setupCleanupCallbacks()
        } else {
            this.setupAttempts++
            setTimeout(() => this.setupOnCloseHandler(id, child), 50)
        }
    }

    private setupCleanupCallbacks() {
        window.addEventListener('unload', this.handleUnload)

        // NOTE: onBeforeUnload must be called before onChildClose
        globalContext.set('onBeforeUnload', (id: string, evt: BeforeUnloadEvent) => {
            if (popouts[id].props.onBeforeUnload) {
                return popouts[id].props.onBeforeUnload!(evt)
            } else {
                return undefined
            }
        })

        globalContext.set('onChildClose', (id: string) => {
            if (popouts[id].props.onClose) {
                popouts[id].props.onClose!()
            }
        })
    }

    private handleUnload(evt: any) {
        // If the window has been unloaded already, get out
        if (!this.props) {
            return
        }
        // Close the popout if parent window is closed and persistOnOrphan is false
        // Will be called regardless if the parent window unloads
        if (!this.props.persistOnOrphan && this.container) {
            this.closeChildWindowIfOpened()
        }
    }

    // Note: copyStyles repaces this function, because it wasn't sufficent for Chrome/Firefox
    // Keeping it here because copyStyles doesn't work on Edge
    // private setupStyleElement(child: Window) {
    //     this.styleElement = child.document.createElement('style')
    //     this.styleElement.setAttribute('data-this-styles', 'true')
    //     this.styleElement.type = 'text/css'

    //     child.document.head.appendChild(this.styleElement)
    // }

    private copyStyles(sourceDoc: Document, targetDoc: Document) {
        Array.from(sourceDoc.styleSheets).forEach((styleSheet: CSSStyleSheet) => {
            let cssRules
            try {
                // In Chrome, a stylesheet from a different domain will not have cssRules, so it is safe to test sheet.cssRules
                // In Firefox, accessing this property will result in a SecurityError and break the remote scripts
                cssRules = styleSheet.cssRules
            } catch (err) {
                console.error(err)
            }
            if (cssRules) {
                // True for inline styles
                const newStyleEl = sourceDoc.createElement('style')

                Array.from(styleSheet.cssRules).forEach(cssRule => {
                    newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText))
                })

                targetDoc.head.appendChild(newStyleEl)
            } else if (styleSheet.href) {
                // True for stylesheets loaded from a URL
                const newLinkEl = sourceDoc.createElement('link')

                newLinkEl.rel = 'stylesheet'
                newLinkEl.href = styleSheet.href
                targetDoc.head.appendChild(newLinkEl)
            }
        })

        // Lock down browser viewport especially for mobile
        const metaViewport = sourceDoc.createElement('meta')
        metaViewport.name = 'viewport'
        metaViewport.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'

        // Set full-screen mode for Safari on iOS
        const metaApple = sourceDoc.createElement('meta')
        metaApple.name = 'apple-mobile-web-app-capable'
        metaApple.content = 'yes'

        // Set full-screen mode for Android
        const metaAndroid = sourceDoc.createElement('meta')
        metaAndroid.name = 'mobile-web-app-capable'
        metaAndroid.content = 'yes'

        targetDoc.head.appendChild(metaViewport)
        targetDoc.head.appendChild(metaApple)
        targetDoc.head.appendChild(metaAndroid)

        // Leaving here for dynamic style injection testing
        // Array.from(sourceDoc.querySelectorAll("[data-emotion]")).forEach((element: any) => {
        //     const cssRules = element.sheet.cssRules
        //     console.log(cssRules)
        //     if (cssRules) {
        //         Array.from(cssRules).forEach((cssRule: CSSRule) => {
        //             console.log(cssRule)
        //         })
        //     }
        // })
    }

    private injectHtml(id: string, child: Window) {
        let container: HTMLDivElement

        if (this.props.html) {
            child.document.write(this.props.html)
            const head = child.document.head

            let cssText = ''
            let rules = null

            for (let i = window.document.styleSheets.length - 1; i >= 0; i--) {
                const styleSheet = window.document.styleSheets[i] as CSSStyleSheet
                try {
                    rules = styleSheet.cssRules
                } catch {
                    // We're primarily looking for a security exception here.
                    // See https://bugs.chromium.org/p/chromium/issues/detail?id=775525
                    // Try to just embed the style element instead.
                    const styleElement = child.document.createElement('link')
                    styleElement.type = styleSheet.type
                    styleElement.rel = 'stylesheet'
                    if (styleSheet.href) {
                        styleElement.href = styleSheet.href
                    }
                    head.appendChild(styleElement)
                } finally {
                    if (rules) {
                        // rules does not have a Symbol.iterator method
                        // eslint-disable-next-line @typescript-eslint/prefer-for-of
                        for (let j = 0; j < rules.length; j++) {
                            try {
                                cssText += rules[j].cssText
                            } catch {
                                // IE11 will throw a security exception sometimes when accessing cssText.
                                // There's no good way to detect this, so we capture the exception instead.
                            }
                        }
                    }
                }

                rules = null
            }

            const style = child.document.createElement('style')
            style.innerHTML = cssText

            head.appendChild(style)
            container = child.document.createElement('div')
            container.id = id
            child.document.body.appendChild(container)
        } else {
            let childHtml = '<!DOCTYPE html><html><head>'
            for (let i = window.document.styleSheets.length - 1; i >= 0; i--) {
                const styleSheet = window.document.styleSheets[i] as CSSStyleSheet
                try {
                    // @ts-expect-error
                    const cssText = styleSheet.cssText
                    childHtml += `<style>${cssText}</style>`
                } catch {
                    // IE11 will throw a security exception sometimes when accessing cssText.
                    // There's no good way to detect this, so we capture the exception instead.
                }
            }
            childHtml += `</head><body><div id="${id}" class="popout"></div></body></html>`
            child.document.write(childHtml)
            container = child.document.getElementById(id)! as HTMLDivElement
        }

        // Create a document with the styles of the parent window first
        // this.setupStyleElement(child)
        this.copyStyles(document, child.document)

        // Set title
        child.document.title = this.props.title || getWindowName(this.props.name!)

        return container
    }

    private setupStyleObserver(child: Window) {
        // Add style observer for legacy style node additions
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'childList') {
                    forEachStyleElement(mutation.addedNodes, element => {
                        child.document.head.appendChild(crossBrowserCloneNode(element, child.document))
                    })
                }
            })
        })

        const config = { childList: true }

        observer.observe(document.head, config)
    }

    private initializeChildWindow(id: string, child: Window) {
        popouts[id] = this

        if (!this.props.url) {
            const container: HTMLDivElement = this.injectHtml(id, child)
            this.setupStyleObserver(child)
            this.setupOnCloseHandler(id, child)
            return container
        } else {
            this.setupOnCloseHandler(id, child)

            return null
        }
    }

    private openChildWindow = () => {
        const options = generateWindowFeaturesString(this.props.options || {})

        const name = getWindowName(this.props.name!)

        this.child = validatePopupBlocker(window.open(this.props.url || 'about:blank', name, options)!)

        if (!this.child) {
            if (this.props.onBlocked) {
                this.props.onBlocked()
            }
            this.container = null
        } else {
            // Sets popout id to supplied name
            this.id = name
            this.container = this.initializeChildWindow(this.id, this.child!)
        }
    }

    private closeChildWindowIfOpened = () => {
        if (isChildWindowOpened(this.child!)) {
            this.child!.close()

            this.child = null
            if (this.props.onClose) {
                this.props.onClose()
            }
        }
    }

    private renderChildWindow() {
        validateUrl(this.props.url!)

        if (!this.props.hidden) {
            if (!isChildWindowOpened(this.child!)) {
                this.openChildWindow()
            }

            if (!this.props.url && this.container) {
                ReactDOM.render(this.props.children, this.container)
            }
        } else {
            this.closeChildWindowIfOpened()
        }
    }
}

function validateUrl(url: string) {
    if (!url) {
        return
    }

    const parser = document.createElement('a')
    parser.href = url

    const current = window.location

    if (
        (parser.hostname && current.hostname !== parser.hostname) ||
        (parser.protocol && current.protocol !== parser.protocol)
    ) {
        throw new Error(
            `react-popup-component error: cross origin URLs are not supported (window=${current.protocol}//${current.hostname}; popout=${parser.protocol}//${parser.hostname})`
        )
    }
}

function validatePopupBlocker(child: Window) {
    if (!child || child.closed || typeof child === 'undefined' || typeof child.closed === 'undefined') {
        return null
    }

    return child
}

function isChildWindowOpened(child: Window | null) {
    return child && !child.closed
}

function getWindowName(name: string) {
    return (
        name ||
        Math.random()
            .toString(12)
            .slice(2)
    )
}

function forEachStyleElement(
    nodeList: NodeList,
    callback: (element: HTMLElement, index?: number) => void,
    scope?: any
) {
    let element: HTMLElement

    for (let i = 0; i < nodeList.length; i++) {
        element = nodeList[i] as HTMLElement
        if (element.tagName === 'STYLE') {
            callback.call(scope, element, i)
        }
    }
}

function isBrowserIEOrEdge() {
    const userAgent = typeof navigator !== 'undefined' && navigator.userAgent ? navigator.userAgent : ''
    return /Edge/.test(userAgent) || /Trident/.test(userAgent)
}
