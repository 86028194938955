import React, { useRef, useEffect } from 'react'

interface ProofCanvasProps
    extends React.DetailedHTMLProps<React.CanvasHTMLAttributes<HTMLCanvasElement>, HTMLCanvasElement> {
    data: boolean[]
}

const ProofCanvas = (props: ProofCanvasProps) => {
    const { data } = props
    const canvasRef = useRef<HTMLCanvasElement>(null)

    const draw = (ctx: CanvasRenderingContext2D | null, arr: ProofCanvasProps['data']) => {
        if (!ctx) {
            return
        }
        const w = ctx.canvas.width
        const h = ctx.canvas.height
        const rows = 8
        const cols = 8

        ctx.clearRect(0, 0, w, h)
        ctx.fillStyle = '#333333'
        ctx.fillRect(0, 0, w, h)
        for (let y = 0; y < rows; y++) {
            for (let x = 0; x < cols; x++) {
                if (arr[y * rows + x]) {
                    ctx.fillStyle = '#ce2e5b'
                    ctx.fillRect(
                        Math.floor(x * (w / cols)),
                        Math.floor(y * (h / rows)),
                        Math.ceil(w / cols),
                        Math.ceil(h / rows)
                    )
                }
            }
        }
    }

    const canvas = canvasRef.current

    useEffect(() => {
        if (canvas) {
            const context = canvas.getContext('2d')
            draw(context, data)
        }
    }, [canvas, data])

    return <canvas ref={canvasRef} {...props} />
}

export default ProofCanvas
