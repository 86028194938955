import * as React from 'react'

declare global {
    interface Window {
        createOWLib?: () => Promise<unknown>
    }
}

export interface ScreenSize {
    screenSize: number[]
    tileSizes: number[][]
}

interface OWLibContextInterface {
    calculateBufferProofHashAsync: (buffer: ArrayBuffer, sizes: ScreenSize[]) => Promise<string[] | void>
    calculateLuminance: (
        imageWidth: number,
        imageHeight: number,
        bitmap: Uint8Array | Uint8ClampedArray
    ) =>
        | {
              L: number
              R: number
              G: number
              B: number
          }
        | undefined
    isLoading: boolean
}

const OWLibContext = React.createContext<OWLibContextInterface>({
    calculateBufferProofHashAsync: async () => undefined,
    calculateLuminance: () => undefined,
    isLoading: false,
})

function OWLibProvider(props: any) {
    const [hasScript, setHasScript] = React.useState(false)
    const [isLoading, setIsLoading] = React.useState(true)
    const [owLib, setOWLib] = React.useState<any>(null)

    React.useEffect(() => {
        if (window.createOWLib) {
            console.info('owLib module already loaded')
            setHasScript(true)
            return
        }

        const script = document.createElement('script')
        script.src = process.env.PUBLIC_URL + '/scripts/owLib.js'
        script.async = true
        script.onload = () => {
            setHasScript(true)
        }
        document.head.appendChild(script)
    }, [])

    React.useEffect(() => {
        if (hasScript && !owLib) {
            const init = async () => {
                try {
                    if (!window.createOWLib) {
                        throw new Error('owLib module not ready!')
                    }
                    setOWLib(await window.createOWLib())
                } catch (error) {
                    console.error('Error loading owLib module:', error)
                } finally {
                    setIsLoading(false)
                }
            }
            init()
        }
    }, [hasScript, owLib])

    React.useEffect(() => {
        if (owLib) {
            owLib.init()
            console.info('owLib module ready')
            setIsLoading(false)
        }
    }, [owLib])

    /**
     * Returns an array of proof hashes for a given raw image buffer
     *
     * @param buffer A raw image buffer
     * @param sizes An array of objects containing screen size values to calculate a hash for
     * @returns An array of hashes
     */
    const calculateBufferProofHashAsync = async (
        buffer: ArrayBuffer,
        sizes: ScreenSize[]
    ): Promise<string[] | undefined> => {
        if (isLoading) {
            console.error('owLib module not ready!')
            return
        }
        if (!owLib) {
            console.error('No owLib module!')
            return
        }

        console.log('buffer to lib', buffer)
        console.log('sizes to lib', sizes)
        const hashes = await owLib.proofPerceptual(buffer, sizes)
        console.log('hashes from lib', hashes)
        return hashes
    }

    /**
     * Returns luminance data for a given image
     *
     * @param imageWidth Width of the source image in px
     * @param imageHeight Height of the source image in px
     * @param bitmap A Uint8Array of the raw bitmap data
     * @returns An object containing L, R, G, B
     */
    const calculateLuminance = (
        imageWidth: number,
        imageHeight: number,
        bitmap: Uint8Array | Uint8ClampedArray
    ): { L: number; R: number; G: number; B: number } | undefined => {
        if (isLoading) {
            console.error('owLib module not ready!')
            return
        }
        if (!owLib) {
            console.error('No owLib module!')
            return
        }

        const { L, R, G, B } = owLib.luminance(imageWidth, imageHeight, bitmap)
        return { L, R, G, B }
    }

    return (
        <OWLibContext.Provider
            value={{
                calculateBufferProofHashAsync,
                calculateLuminance,
                isLoading: !hasScript || isLoading,
            }}
            {...props}
        />
    )
}

export { OWLibContext, OWLibProvider }
