import { RouterFlag } from './BaseRouter'
import onAPI, { HTTPMethod } from './onAPI'

import { runInAction } from 'mobx'

import MediaContainer from 'src/common/models/MediaContainer'
import Media, { MediaJSON } from 'src/common/models/Media'
import MediaSettings from 'src/common/models/MediaSettings'

class MediaRouter {
    protected route = '/media/adhoc'

    protected flags = RouterFlag.create | RouterFlag.read | RouterFlag.update | RouterFlag.delete | RouterFlag.list

    async getMedia(screenId: string): Promise<MediaContainer> {
        const json: any = await onAPI.fetchJSON(this.route + '/' + screenId)
        return new MediaContainer(json, screenId)
    }

    async updateSettings(screenId: string, settings: MediaSettings): Promise<void> {
        return onAPI.fetchJSON(this.route + '/settings/' + screenId, settings)
    }

    async getFileUploadURLs(screenId: string, filesToAdd: Media[]): Promise<Media[]> {
        const result: { filesToAdd: Record<string, any> } = await onAPI.fetchJSON(
            this.route + '/uploadFiles/' + screenId,
            {
                filesToAdd: filesToAdd.map(media => media.fileData()),
            }
        )

        runInAction('assignSignedURLs', () => {
            filesToAdd.forEach(file => {
                file.signedGetURL = result.filesToAdd[file.name]
            })
        })
        return filesToAdd
    }

    async updateContent(screenId: string, media: Media[], filesToDelete: Media[]): Promise<Media[]> {
        return onAPI.fetchJSON(this.route + '/content/' + screenId, {
            media: media.map(mediaItem => mediaItem.toJSON()),
            filesToDelete: filesToDelete.map(mediaItem => mediaItem.fileData()),
        })
    }

    async updateMedia(
        screenId: string,
        filesToAdd: Media[],
        filesToDelete: Media[],
        settings: MediaSettings | null
    ): Promise<Media[]> {
        const toAdd = filesToAdd.map(media => media.fileData())
        const toDelete = filesToDelete.map(media => media.fileData())
        const result: { filesToAdd: Record<string, any> } = await onAPI.fetchJSON(this.route + '/' + screenId, {
            filesToAdd: toAdd,
            filesToDelete: toDelete,
            settings,
        })

        runInAction('assignSignedURLs', () => {
            filesToAdd.forEach(file => {
                file.signedGetURL = result.filesToAdd[file.name]
            })
        })
        return filesToAdd
    }

    async uploadMedia(filesToAdd: Media[]): Promise<void> {
        return Promise.all(
            filesToAdd.map(
                async (media: Media): Promise<Response> => {
                    try {
                        const response = await fetch(media.signedGetURL, {
                            headers: {
                                'Content-Type': 'image',
                            },
                            method: HTTPMethod.put,
                            body: media.file,
                        })
                        if (!response.ok) {
                            return Promise.reject(response.statusText)
                        }
                        return response
                    } catch (err) {
                        return Promise.reject(err)
                    }
                }
            )
        )
            .then(() => console.info('Everything uploaded'))
            .catch(err => console.error(err))
    }

    protected newItem(json: MediaJSON): Media {
        return new Media(json)
    }
}

const mediaRouter = new MediaRouter()
export default mediaRouter
