import { action, computed, observable } from 'mobx'

import { PubSub } from 'aws-amplify'

import { StatCommand } from './ControllerStat'
import { RenewCommand } from './MqttEnums'
import { ConsoleCommand } from './ConsoleEnums'
import { CameraCommand } from './Camera'
import moment from 'moment'

const BASE_TOPIC = process.env.REACT_APP_IOT_BASE_TOPIC

const TTL_INTERVAL = 0.1 // Frequency to send TTL

export type KeepAliveCommand = RenewCommand | StatCommand | ConsoleCommand | CameraCommand

export default class MqttSubscription {
    @observable private subscription?: ZenObservable.Subscription

    @observable inboundTopic: string
    @observable outboundTopic?: string
    @observable subscriptionKey: string
    @observable private ttl?: number
    @observable private keepAliveCommand?: KeepAliveCommand
    @observable private keepAlivePayload?: any
    @observable private expiresAt?: number

    @observable private callback: (payload: any) => void
    @observable onStale?: () => void
    @observable private onClose?: () => void
    private staleTimer?: NodeJS.Timeout
    private lastStaleTimestamp: number = 0
    @observable subscribed = false

    constructor({
        topics,
        subscriptionKey,
        callback,
    }: {
        topics: [string, string?]
        subscriptionKey: string
        callback: (payload: any) => void
    }) {
        this.inboundTopic = topics[0]
        if (topics[1]) {
            this.outboundTopic = topics[1]
        }
        this.subscriptionKey = subscriptionKey
        this.callback = callback
    }

    @action setTTL = (ttl: number) => {
        this.ttl = ttl * 1000 // ms
        return this
    }

    @action setKeepAliveCommand = (keepAliveCommand: KeepAliveCommand) => {
        this.keepAliveCommand = keepAliveCommand
        return this
    }

    @action setKeepAlivePayload = (keepAlivePayload: any) => {
        this.keepAlivePayload = keepAlivePayload
        return this
    }

    @action setOnStale = (onStale: () => void) => {
        // this closure will make it so multiple calls to onStale will only trigger once
        this.onStale = () => {
            if (!this.isStale || this.lastStaleTimestamp === this.expiresAt) {
                return
            }
            this.lastStaleTimestamp = this.expiresAt || 0
            onStale()
        }
        return this
    }

    @action setOnClose = (onClose: () => void) => {
        this.onClose = onClose
        return this
    }

    @action subscribe = (publish: (topic: string, message: any) => void) => {
        const subscriptionKey = this.subscriptionKey
        this.subscribed = true

        if (this.ttl) {
            this.expiresAt = Date.now() + this.ttl
        }

        this.subscription = PubSub.subscribe(BASE_TOPIC + this.inboundTopic, {
            subscriptionKey,
        }).subscribe({
            next: (data: any) => {
                this.callback(data)
            },
            error: (errorValue: any) => {
                this.unsubscribe()
                console.error('Subscribe error', errorValue.errorCode)
            },
        })

        if (this.shouldKeepAlive) {
            this.handleKeepAlive(publish)
        }
    }

    @action private handleKeepAlive = (publish: (topic: string, message: any) => void) => {
        if (!this.outboundTopic || !this.keepAliveCommand || this.ttl === undefined) {
            console.error('Error renewing subscription', this.subscriptionKey)
            return
        }

        // check if stale fairly frequenly, but only send ping if we are about to expire
        const intervalId = setInterval(() => {
            // Check if the subscription has expired
            if (this.isStale) {
                if (this.onStale) {
                    console.log(
                        'subscription is stale',
                        moment(Date.now()).format('YYYY-MM-DD HH:mm:ss'),
                        moment(this.expiresAt).format('YYYY-MM-DD HH:mm:ss')
                    )
                    // Refresh stale data if we have a callback
                    this.onStale()
                } else {
                    // Otherwise, unsubscribe as the server will no longer send data for this subscription
                    this.unsubscribe()
                }
                return
            } else {
                // check if the subscription will expire before the next keep alive
                console.log('Checking keep alive', this.expiresAt, Date.now() + this.ttl! * 0.2)
                if (this.expiresAt && this.expiresAt < Date.now() + this.ttl! * 0.2) {
                    console.log('Sending keep alive', this.subscriptionKey)
                    publish(this.outboundTopic!, {
                        command: this.keepAliveCommand,
                        subscriptionKey: this.subscriptionKey,
                        payload: this.keepAlivePayload,
                    })
                    this.expiresAt = Date.now() + this.ttl!
                    console.log('New expiry', moment(this.expiresAt).format('YYYY-MM-DD HH:mm:ss'))
                }
            }
        }, Math.max(this.ttl * TTL_INTERVAL, 15000))

        this.staleTimer = intervalId
    }

    @action unsubscribe = () => {
        this.subscribed = false
        if (this.staleTimer) {
            clearInterval(this.staleTimer)
        }
        if (this.onClose) {
            this.onClose()
        }
        this.subscription?.unsubscribe()
    }

    @computed get isStale(): boolean {
        if (!this.expiresAt) {
            return false
        }
        return this.expiresAt < Date.now()
    }

    @computed private get shouldKeepAlive(): boolean {
        return !!this.ttl && this.ttl > 0 && !!this.keepAliveCommand && !!this.outboundTopic
    }

    @computed get subscriptionData(): { inboundTopic: string; outboundTopic?: string; subscriptionKey: string } {
        return {
            inboundTopic: this.inboundTopic,
            outboundTopic: this.outboundTopic,
            subscriptionKey: this.subscriptionKey,
        }
    }
}
