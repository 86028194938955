import * as React from 'react'
import { observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { Output } from 'src/common/models/Avior'

import { Button, Form, FormFeedback, FormGroup, FormText, Input, InputGroup, Label } from 'reactstrap'
import { Dialog } from '@blueprintjs/core'
import Toggle from 'react-toggle'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

interface OutputSettingsProps {
    store?: RootStore
    output?: Output
    onClose: () => void
}

function OutputSettings(props: OutputSettingsProps) {
    const { store, output, onClose } = props

    const [enabled, setEnabled] = React.useState(true)
    const [name, setName] = React.useState('')
    const [polarity, setPolarity] = React.useState(false)

    React.useEffect(() => {
        if (output) {
            setEnabled(output.mode === 1)
            setName(output.name)
            setPolarity(output.polarity)
        }
    }, [output])

    const screenDiagnosticsUIStore = store!.screenDiagnosticsUIStore

    const handleUpdateOutputMode = (value: boolean) => {
        const cmd = `O${output?.ordinal}0=${value ? 1 : 0}`
        screenDiagnosticsUIStore.sendAviorCommand(cmd)
    }

    const handleRenameOutput = (value: string) => {
        const cmd = `O${output?.ordinal}1=${value}`
        screenDiagnosticsUIStore.sendAviorCommand(cmd)
    }

    const handleSetPolarity = (value: boolean) => {
        const cmd = `M${output?.ordinal}1=${value ? 1 : 0}`
        screenDiagnosticsUIStore.sendAviorCommand(cmd)
    }

    const handleSave = () => {
        if (enabled !== (output?.mode === 1 ? true : false)) {
            handleUpdateOutputMode(enabled)
        }
        if (name !== output?.name) {
            handleRenameOutput(name)
        }
        if (polarity !== output?.polarity) {
            handleSetPolarity(polarity)
        }
        onClose()
    }

    if (!output) {
        return null
    }

    const formValid = name.length >= 4 && name.length <= 15

    return (
        <Dialog className='custom-dialog' title='Output Settings' onClose={onClose} isOpen canOutsideClickClose={false}>
            <div className='dialog-content p-4'>
                <Form className='admin-form' onSubmit={eventPreventDefault}>
                    <FormGroup>
                        <Label for='aviorId'>Name</Label>
                        <Input
                            value={name}
                            onChange={e => setName(e.target.value.replace(/\s/g, ''))}
                            maxLength={15}
                            invalid={name.length < 4}
                        />
                        <FormFeedback>Please enter at least 4 characters.</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for='enabled'>Enabled</Label>
                        <InputGroup>
                            <Toggle
                                checked={enabled}
                                className='custom-toggle'
                                icons={false}
                                onChange={e => setEnabled(e.target.checked)}
                                id='enabled'
                            />
                        </InputGroup>
                        <FormText>Disabling the output will prevent controlling this relay.</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for='polarity'>Polarity Reversed</Label>
                        <InputGroup>
                            <Toggle
                                checked={polarity}
                                className='custom-toggle'
                                icons={false}
                                onChange={e => setPolarity(e.target.checked)}
                                id='polarity'
                            />
                        </InputGroup>
                        <FormText>
                            The relays used in the distribution system can either be set to match the Avior output
                            relays or have the opposite polarity.
                        </FormText>
                    </FormGroup>
                </Form>
                <div className='dialog-actions'>
                    <Button
                        className='custom-button custom-button-large'
                        onClick={() => handleSave()}
                        color='primary'
                        disabled={!formValid}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default observer(OutputSettings)
