import RootStore from 'src/common/RootStore'

import { observable, action, computed } from 'mobx'

import BaseModel from '../models/BaseModel'

import { Popout } from '../components/popout'
import { AppToaster } from '../components/AppToaster'
import { popouts } from '../components/popout/popouts'
import { Intent } from '@blueprintjs/core'
import MqttSubscription from '../models/MqttSubscription'

export default abstract class PopoutManager {
    @observable isDrawerOpen: boolean = false
    @observable isPopout: boolean = false
    @observable isClosing: boolean = false

    @observable isFetching: boolean = false
    @observable isProblemFetching: boolean = false
    @observable subscription?: MqttSubscription

    abstract get title(): string
    objectId: string
    rootStore: RootStore

    constructor(rootStore: RootStore, object: string | BaseModel) {
        this.rootStore = rootStore
        if (typeof object === 'string') {
            this.objectId = object
        }
    }

    @computed get popout(): Popout | undefined {
        const p = Object.entries(popouts).find(entry => entry[0] === this.objectId && entry[1].child)
        return p?.[1]
    }

    @action toggleDrawer = () => {
        if (this.isDrawerOpen) {
            // Clean up manager from closed drawer
            this.removeManager()
            this.clearURL()
        }
        this.isDrawerOpen = !this.isDrawerOpen
    }

    @action togglePopout = () => {
        if (this.isPopout) {
            // Clean up manager from closed popout
            this.removeManager()
        } else {
            // Close the drawer and replace with popout
            this.isDrawerOpen = false
            this.isPopout = true
            this.clearURL()
        }
    }

    @action handlePopoutBlocked = () => {
        // Show a toast on the main window
        AppToaster.show({
            icon: 'duplicate',
            message: 'Popout was blocked. Please check your browser settings and try again',
            intent: Intent.DANGER,
        })

        // Clean up unsuccessful popout
        this.removeManager()
    }

    @action beforeUnload = (event: BeforeUnloadEvent): string | undefined => {
        this.isClosing = true
        if (this.subscription) {
            this.rootStore.mqttStore.removeSubscription(this.subscription)
        }
        return this.handleBeforeUnload(event)
    }

    handleBeforeUnload = (event: BeforeUnloadEvent): string | undefined => undefined

    focusPopout = () => {
        if (!this.popout) {
            return
        }
        this.popout.bringChildWindowToFrontIfOpened()
        this.handleFocusPopout()
    }

    handleFocusPopout = () => {}

    removeManager = () => {}

    clearURL = () => {}
}
