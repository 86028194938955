import LiveSnapshot, { LiveSnapshotJSON, LiveSnapshotParameters } from '../common/models/LiveSnapshot'
import LiveImageStats, { LiveImageStatsJSON, LiveImageStatsParams } from 'src/common/models/LiveImageStats'
import onAPI from './onAPI'

class LiveRouter {
    protected route = '/live'

    protected flags = 0

    async subscribe(item: LiveSnapshotParameters): Promise<LiveSnapshot> {
        const json: LiveSnapshotJSON = await onAPI.fetchJSON(this.route, item)
        return this.newItem(json)
    }

    async fetchLiveImageStats(liveImageStatsParams: LiveImageStatsParams): Promise<LiveImageStats> {
        const json: LiveImageStatsJSON = await onAPI.fetchJSONWithQueryString(
            this.route + '-stats',
            liveImageStatsParams
        )
        return new LiveImageStats(json)
    }

    protected newItem(json: LiveSnapshotJSON): LiveSnapshot {
        return new LiveSnapshot(json)
    }
}

const liveRouter = new LiveRouter()
export default liveRouter
