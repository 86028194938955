import RootStore from 'src/common/RootStore'

import { action, runInAction, observable, computed } from 'mobx'
import { createViewModel, IViewModel } from 'mobx-utils'

import { checkSpaces, checkAmpersand } from '../utils/Regex'
import isValidFilename from 'valid-filename'

import Media from '../models/Media'
import mediaRouter from 'src/api/mediaRouter'
import MediaContainer from '../models/MediaContainer'
import MediaSettings, { TransitionType } from '../models/MediaSettings'
import Screen from '../models/Screen'
import { PlayerSyncState } from '../models/PlayerSyncState'

import { popouts } from '../components/popout/popouts'
import { ExternalToaster } from '../components/AppToaster'
import { Intent } from '@blueprintjs/core'
import moment from 'moment'
import _ from 'lodash'

const { REACT_APP_PLAYER_GROUPS_ORG_IDS } = process.env
const PLAYER_GROUPS_ORG_IDS = REACT_APP_PLAYER_GROUPS_ORG_IDS?.split(' ') ?? []

export default class MediaManager {
    @observable mediaContainer?: MediaContainer

    @observable addScreenFilesSet = new Set<Media>()
    @observable removeScreenFilesSet = new Set<Media>()
    @observable screenFilenameErrorsList: string[] = []

    @observable settingFocusedSet = new Set<string>()
    @observable updatePlayerSettingsMap = new Map<string, string>()
    @observable pendingScreenSettings?: MediaSettings & IViewModel<MediaSettings>

    @observable customDwellTimeFocusedSet = new Set<Media>()
    @observable customDwellTimeMap = new Map<Media, string>()

    @observable openScheduleDialog?: Media

    @observable isFetching = false
    @observable isUpdating = false

    @observable immutableMediaList?: Media[]

    @observable mediaGroupCount: number = 0
    @observable activeMediaGroup: number = 0

    rootStore: RootStore
    router = mediaRouter
    screenId: string

    mediaGroupsEnabled: boolean

    private playerDiv?: HTMLElement

    constructor(rootStore: RootStore, screenId: string) {
        this.rootStore = rootStore
        this.screenId = screenId

        // Find player div in popouts
        Object.entries(popouts).forEach(entry => {
            if (entry[0] === screenId && entry[1].child) {
                this.playerDiv = entry[1].child.document.body
            }
        })

        const me = rootStore.userStore.me
        if (!me) {
            return
        }
        this.mediaGroupsEnabled = PLAYER_GROUPS_ORG_IDS.includes(me.organisationId)
    }

    @computed get currentScreen(): Screen | undefined {
        return this.rootStore.screenStore.findItem(this.screenId)
    }

    @computed get hasMediaGroups(): boolean {
        return this.mediaGroupCount > 0
    }

    @computed get combinedMediaList(): Media[] {
        if (!this.mediaContainer) {
            return []
        }
        return [...this.mediaContainer.files, ...this.addScreenFilesSet].sort((a, b) => a.index - b.index)
    }

    @computed get displayMediaList(): Media[] {
        return this.hasMediaGroups
            ? this.combinedMediaList.filter(media => media.group === this.activeMediaGroup)
            : this.combinedMediaList
    }

    @computed get currentGroupMediaList(): Media[] | undefined {
        if (!this.hasMediaGroups) {
            return
        }
        return this.displayMediaList.filter(
            media =>
                !media.isPendingDelete &&
                (media.startDate ? media.startDate.isBefore(moment.now()) : true) &&
                (media.stopDate ? media.stopDate.isAfter(moment.now()) : true)
        )
    }

    @computed get currentGroupMediaTime(): number | undefined {
        if (!this.hasMediaGroups) {
            return
        }
        return this.currentGroupMediaList?.reduce(
            (sum, media) =>
                sum +
                (media.dwellTime ||
                    (this.mediaContainer && this.mediaContainer.settings ? this.mediaContainer.settings.dwellTime : 0)),
            0
        )
    }

    @computed get hasScreenMediaChanges(): boolean {
        if (this.addScreenFilesSet.size !== 0) {
            return true
        }
        if (this.removeScreenFilesSet.size !== 0) {
            return true
        }
        return this.combinedMediaList.length > 0 && !_.isEqual(this.combinedMediaList, this.immutableMediaList)
    }

    @computed get hasScreenUnsavedChanges(): boolean {
        return !!(this.hasScreenMediaChanges || (this.pendingScreenSettings && this.pendingScreenSettings.isDirty))
    }

    @computed get hashSet(): Set<string> {
        const hashSet = new Set<string>()
        for (const media of this.combinedMediaList) {
            hashSet.add(media.hash)
        }
        return hashSet
    }

    @computed get currentMediaList(): Media[] {
        return this.combinedMediaList.filter(
            media =>
                !media.isPendingDelete &&
                (media.startDate ? media.startDate.isBefore(moment.now()) : true) &&
                (media.stopDate ? media.stopDate.isAfter(moment.now()) : true)
        )
    }

    @computed get currentMediaTime(): number {
        return this.currentMediaList.reduce(
            (sum, media) =>
                sum +
                (media.dwellTime ||
                    (this.mediaContainer && this.mediaContainer.settings ? this.mediaContainer.settings.dwellTime : 0)),
            0
        )
    }

    @action fetchPlayerMedia = () => {
        // Ignore if screen is already fetching
        if (this.isFetching) {
            return
        }

        this.isFetching = true

        // Clear any lingering unsaved data
        this.clearMediaQueues()
        if (this.pendingScreenSettings) {
            this.pendingScreenSettings.reset()
        }

        this.router
            .getMedia(this.screenId)
            .then(mediaContainer => {
                runInAction('updateMediaContainerMap', () => {
                    this.mediaContainer = mediaContainer
                    this.immutableMediaList = _.cloneDeep(this.mediaContainer.files)

                    if (!this.mediaContainer || !this.mediaContainer.settings) {
                        return
                    }

                    const existingGroupCount = this.mediaContainer.files
                        .sort((a, b) => (a.group ?? 0) - (b.group ?? 0))
                        .slice(-1)[0]?.group
                    if (existingGroupCount && existingGroupCount > 0) {
                        this.mediaGroupCount = existingGroupCount
                        this.activeMediaGroup = 1
                    }
                })
            })
            .catch(err => {
                console.error(err)

                const toaster = ExternalToaster(this.playerDiv || document.body)

                toaster.show({
                    message: 'Error fetching media container',
                    intent: Intent.DANGER,
                })
            })
            .finally(() => {
                runInAction('isMediaFetchingFinished', () => {
                    this.isFetching = false
                })
            })
    }

    @action updatePlayerMediaAfterSave = () => {
        if (!this.mediaContainer) {
            return
        }

        // If existing file was deleted, immediately remove from existing media container
        if (this.removeScreenFilesSet.size !== 0) {
            this.mediaContainer.files = this.mediaContainer.files.filter(media => !media.isPendingDelete)
            this.removeScreenFilesSet.clear()
        }

        // Copy the ordered combined media list and make it our new mediaFiles list
        this.mediaContainer.files = this.combinedMediaList.slice()
        this.addScreenFilesSet.clear()

        this.reIndexMedia()
        this.immutableMediaList = _.cloneDeep(this.mediaContainer.files)
    }

    @action reIndexMedia = () => {
        // Re-index after items have been deleted and left holes
        for (let i = 0; i < this.combinedMediaList.length; i++) {
            this.combinedMediaList[i].index = i
        }
    }

    @action updateMediaOrder = (oldIndex: number, newIndex: number) => {
        if (oldIndex === newIndex) {
            return
        }
        // Create a copy of the computed array here so we don't get recomputes on the fly
        const allItems = this.combinedMediaList.slice()
        if (oldIndex < newIndex) {
            for (let i = newIndex; i > oldIndex; i--) {
                allItems[i].index -= 1
            }
        } else {
            for (let i = newIndex; i < oldIndex; i++) {
                allItems[i].index += 1
            }
        }
        allItems[oldIndex].index = newIndex
    }

    @action addFiles = async (fileList: File[]) => {
        if (!this.currentScreen) {
            return
        }
        const startingIndex =
            this.hasMediaGroups && this.displayMediaList.length > 0
                ? // If adding media to a group, set starting index to 1 after the last item in the current group
                  this.displayMediaList.slice(-1)[0].index + 1
                : this.combinedMediaList.length
        const mediaList = await Promise.all(
            fileList.map((file, i) => Media.newMediaFromFile(file, startingIndex + i, this.screenId))
        )
        for (const media of mediaList) {
            const toaster = ExternalToaster(this.playerDiv || document.body)
            const maxWidth = this.currentScreen.width * 2
            const maxHeight = this.currentScreen.height * 2

            // Check for name collision or unsupported filenames
            if (!!this.combinedMediaList.find(item => item.name.toLowerCase() === media.name.toLowerCase())) {
                this.screenFilenameErrorsList.push(media.name)

                toaster.show({
                    icon: 'warning-sign',
                    message:
                        'Filename collision: ' +
                        media.name +
                        '. Please remove the existing file first if you want to use the same filename',
                    intent: Intent.DANGER,
                    timeout: 0,
                })
            } else if (checkSpaces(media.name)) {
                toaster.show({
                    icon: 'warning-sign',
                    message:
                        'Filename issue: ' +
                        media.name +
                        '. Please remove leading spaces or places in the filename that have more than one consecutive space.',
                    intent: Intent.DANGER,
                    timeout: 0,
                })
            } else if (checkAmpersand(media.name)) {
                toaster.show({
                    icon: 'warning-sign',
                    message:
                        'Filename issue: ' + media.name + ". Please remove '&' characters as they are not supported.",
                    intent: Intent.DANGER,
                    timeout: 0,
                })
            } else if (!isValidFilename(media.name)) {
                toaster.show({
                    icon: 'warning-sign',
                    message: 'Filename issue: ' + media.name + '. Please check this filename for errors.',
                    intent: Intent.DANGER,
                    timeout: 0,
                })
            } else if (media.width > maxWidth || media.height > maxHeight) {
                toaster.show({
                    icon: 'error',
                    message:
                        'File resolution too large: ' +
                        media.name +
                        '. Please use a file that is smaller than ' +
                        maxWidth +
                        'x' +
                        maxHeight +
                        '.',
                    intent: Intent.DANGER,
                    timeout: 0,
                })
            } else {
                runInAction('addMedia', () => {
                    if (this.hasMediaGroups) {
                        media.group = this.activeMediaGroup
                    }

                    this.addScreenFilesSet.add(media)
                })

                // Update order to ensure correct index, prevents appending to end of global list
                this.updateMediaOrder(
                    this.combinedMediaList.findIndex(item => item.name === media.name),
                    media.index
                )
                this.reIndexMedia()
            }
        }
    }

    @action addDuplicate = (media: Media) => {
        const duplicateIndex = media.index + 1
        media.index = this.combinedMediaList.length
        this.addScreenFilesSet.add(media)
        this.updateMediaOrder(this.combinedMediaList.length - 1, duplicateIndex)
    }

    @action removeMedia = (media: Media) => {
        // Ignore files already pending delete
        if (this.removeScreenFilesSet.has(media)) {
            return
        }
        this.removeScreenFilesSet.add(media)
    }

    @action undoRemoveMedia = (media: Media) => {
        this.removeScreenFilesSet.delete(media)
    }

    @action cancelUpload = (media: Media) => {
        this.addScreenFilesSet.delete(media)
        this.reIndexMedia()
    }

    @action saveMediaChanges = async () => {
        if (!this.currentScreen) {
            return
        }
        this.isUpdating = true

        try {
            if (this.addScreenFilesSet.size !== 0) {
                const addFilesHashSet = new Set<string>()
                const filesToAdd = new Array<Media>()
                if (this.mediaContainer) {
                    for (const media of this.mediaContainer.files) {
                        addFilesHashSet.add(media.hash)
                    }
                }
                for (const media of this.addScreenFilesSet) {
                    if (!addFilesHashSet.has(media.hash)) {
                        filesToAdd.push(media)
                        addFilesHashSet.add(media.hash)
                    }
                }
                const uploadList = await this.router.getFileUploadURLs(this.screenId, filesToAdd)
                if (uploadList.length !== 0) {
                    await this.router.uploadMedia(uploadList)
                }
            }

            const mediaList = this.combinedMediaList.filter(media => !media.isPendingDelete)
            const mediaListFilesHashSet = new Set<string>()
            for (const media of mediaList) {
                mediaListFilesHashSet.add(media.hash)
            }
            const filesToRemove = new Array<Media>()
            for (const media of this.removeScreenFilesSet) {
                if (!mediaListFilesHashSet.has(media.hash)) {
                    filesToRemove.push(media)
                }
            }

            await this.router.updateContent(this.screenId, mediaList, filesToRemove)
            this.updatePlayerMediaAfterSave()
            this.clearMediaQueues()
        } catch (err) {
            // TODO: Make sure we rollback to our pre-save state in the case of an error
            console.error(err)

            const toaster = ExternalToaster(this.playerDiv || document.body)

            toaster.show({
                message: 'Error saving media changes',
                intent: Intent.DANGER,
            })
        } finally {
            runInAction('finishedSavingMedia', () => {
                this.currentScreen!.playerSyncState = PlayerSyncState.syncing
                this.isUpdating = false
            })
        }
    }

    @action clearMediaQueues = () => {
        this.addScreenFilesSet.clear()
        this.removeScreenFilesSet.clear()
        this.screenFilenameErrorsList = []
    }

    @action updateCustomDwellTime = (media: Media, value: string) => {
        this.customDwellTimeMap.set(media, value)
    }

    @action setDwellTimeFocused = (media: Media, isFocused: boolean) => {
        if (isFocused) {
            this.customDwellTimeFocusedSet.add(media)
        } else {
            this.customDwellTimeFocusedSet.delete(media)
        }

        if (!isFocused) {
            // Set new dwell time on blur
            if (!this.mediaContainer || !this.mediaContainer.settings) {
                return
            }

            // Empty value ('') will set dwell time back to global
            // Undefined value will be ignored
            const getNewDwellTime = this.customDwellTimeMap.get(media)
            const minDwellTime = this.mediaContainer.settings.minDwellTime
            let newDwellTime: number | undefined
            if (getNewDwellTime && getNewDwellTime !== '') {
                newDwellTime = Number(parseFloat(getNewDwellTime).toFixed(2))
            }

            if (
                getNewDwellTime === undefined ||
                (newDwellTime && newDwellTime === media.dwellTime && newDwellTime > minDwellTime)
            ) {
                // No change or same valid custom dwell time entered, ignore
                return
            } else {
                if (getNewDwellTime !== undefined && (!newDwellTime || newDwellTime < minDwellTime)) {
                    if (getNewDwellTime === '') {
                        // Handle clear dwell time
                        media.dwellTime = undefined
                    } else if (newDwellTime && newDwellTime < minDwellTime) {
                        // If invalid dwell time
                        const toaster = ExternalToaster(this.playerDiv || document.body)

                        toaster.show({
                            icon: 'warning-sign',
                            message:
                                this.pendingScreenSettings &&
                                this.pendingScreenSettings.transitionType === TransitionType.snap
                                    ? 'Dwell time must be at least 3 seconds'
                                    : 'Dwell time must be at least 3 seconds greater than the transition time',
                            intent: Intent.WARNING,
                        })
                    } else {
                        // Use saved global dwell time
                        media.dwellTime = this.mediaContainer.settings.dwellTime
                    }
                } else {
                    // New dwell time valid, update media with new value
                    media.dwellTime = newDwellTime
                }
            }

            // Clear the dwell time value
            this.customDwellTimeMap.delete(media)
        }
    }

    @action toggleScheduleDialog = (media: Media, close?: boolean) => {
        if (close) {
            this.openScheduleDialog = undefined
            return
        }
        this.openScheduleDialog = media
    }

    @action saveScheduledDates = (
        media: Media,
        scheduledDates: [moment.Moment | undefined, moment.Moment | undefined]
    ) => {
        media.startDate = scheduledDates[0]
        media.stopDate = scheduledDates[1]

        this.toggleScheduleDialog(media, true)
    }

    @action updatePlayerSettings = (setting: string, value: string) => {
        if (!this.mediaContainer || !this.mediaContainer.settings) {
            return
        }

        this.updatePlayerSettingsMap.set(setting, value)
    }

    @action setSettingFocused = (setting: string, isFocused: boolean) => {
        if (isFocused) {
            this.settingFocusedSet.add(setting)
        } else {
            this.settingFocusedSet.delete(setting)
        }

        if (!isFocused) {
            const value = this.updatePlayerSettingsMap.get(setting)

            if (value === undefined || !this.mediaContainer || !this.mediaContainer.settings) {
                return
            }

            // If no existing new settings view model, create a new one from media container settings
            const newSettingsViewModel = this.pendingScreenSettings || createViewModel(this.mediaContainer.settings)

            switch (setting) {
                case 'dwellTime':
                    if (value === '') {
                        // Handle clear dwell time
                        newSettingsViewModel.resetProperty('dwellTime')
                    } else if (Number(value) < newSettingsViewModel.minDwellTime) {
                        // If invalid dwell time
                        const toaster = ExternalToaster(this.playerDiv || document.body)

                        toaster.show({
                            icon: 'warning-sign',
                            message:
                                newSettingsViewModel.transitionType === TransitionType.snap
                                    ? 'Dwell time must be at least 3 seconds'
                                    : 'Dwell time must be at least 3 seconds greater than the transition time',
                            intent: Intent.WARNING,
                        })
                        // Reset dwell time
                        newSettingsViewModel.resetProperty('dwellTime')
                    } else {
                        newSettingsViewModel.dwellTime = Number(parseFloat(value).toFixed(2))
                    }
                    break
                case 'transitionType':
                    if (TransitionType[value] === TransitionType.snap) {
                        // Set snap transition time to 0
                        newSettingsViewModel.transitionTime = 0
                    } else if (
                        TransitionType[value] === TransitionType.fade &&
                        newSettingsViewModel.transitionTime === 0
                    ) {
                        // Reset transition time back to last good value if it was 0
                        newSettingsViewModel.resetProperty('transitionTime')
                    }

                    newSettingsViewModel.transitionType = TransitionType[value]
                    break
                case 'transitionTime':
                    if (value === '') {
                        // Handle clear transition time
                        newSettingsViewModel.resetProperty('transitionTime')
                    } else if (Number(value) > newSettingsViewModel.maxTransitionTime) {
                        // If invalid transition time
                        const toaster = ExternalToaster(this.playerDiv || document.body)

                        toaster.show({
                            icon: 'warning-sign',
                            message: 'Transition time must be at least 3 seconds less than the dwell time',
                            intent: Intent.WARNING,
                        })
                        // Reset transition time
                        newSettingsViewModel.resetProperty('transitionTime')
                    } else {
                        newSettingsViewModel.transitionTime = Number(parseFloat(value).toFixed(2))
                    }
                    break
                default:
                    return
            }

            // Store pending settings
            this.pendingScreenSettings = newSettingsViewModel

            // Clear the setting value
            this.updatePlayerSettingsMap.delete(setting)
        }
    }

    @action saveSettingsChanges = () => {
        if (!this.pendingScreenSettings) {
            return
        }
        this.isUpdating = true

        this.router
            .updateSettings(this.screenId, this.pendingScreenSettings)
            .then(() => {
                this.pendingScreenSettings!.submit()
            })
            .catch(err => {
                console.error(err)

                const toaster = ExternalToaster(this.playerDiv || document.body)

                toaster.show({
                    message: 'Error saving player settings',
                    intent: Intent.DANGER,
                })
            })
            .finally(() => {
                runInAction('screenSettingChangesFinished', () => {
                    this.isUpdating = false
                })
            })
    }

    @action clearSettingsChanges = () => {
        if (!this.pendingScreenSettings) {
            return
        }
        this.pendingScreenSettings.reset()
    }

    @action addMediaGroup = () => {
        if (!this.hasMediaGroups && this.mediaContainer) {
            // Convert all media to group 1
            for (const media of this.mediaContainer.files) {
                media.group = 1
            }
            this.addScreenFilesSet = new Set(
                Array.from(this.addScreenFilesSet, media => {
                    media.group = 1
                    return media
                })
            )
        }
        this.mediaGroupCount++
        this.setActiveGroup(this.mediaGroupCount)
    }

    // Remove media group, but move all media in group to group 1
    // If removing the last group, remove all media groups
    @action removeMediaGroup = (group: number) => {
        if (this.mediaGroupCount === 1) {
            // Remove all media groups
            for (const media of this.mediaContainer!.files) {
                media.group = undefined
            }

            this.addScreenFilesSet = new Set(
                Array.from(this.addScreenFilesSet, media => {
                    media.group = undefined
                    return media
                })
            )

            this.mediaGroupCount = 0
            this.setActiveGroup(0)
            return
        }

        if (this.mediaContainer) {
            // Remove all media in group
            for (const media of this.mediaContainer.files) {
                if (!media.group) {
                    continue
                }
                if (media.group === group) {
                    media.group = 1
                } else if (media.group > group) {
                    media.group--
                }
            }
        }

        this.addScreenFilesSet = new Set(
            Array.from(this.addScreenFilesSet, media => {
                if (!media.group) {
                    return media
                }
                if (media.group === group) {
                    media.group = 1
                } else if (media.group > group) {
                    media.group--
                }
                return media
            })
        )

        this.mediaGroupCount--
        this.setActiveGroup(this.mediaGroupCount)
    }

    @action setActiveGroup = (group: number) => {
        this.activeMediaGroup = group
    }
}
