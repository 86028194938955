import * as React from 'react'

import i18n from 'src/i18n'

import { sortByKey } from 'src/common/utils/SortByKey'

import {
    Table,
    Column,
    Index,
    RowMouseEventHandlerParams,
    SortDirection,
    SortDirectionType,
    TableProps,
    ColumnProps,
    TableCellDataGetterParams,
    TableHeaderProps,
    TableCellProps,
} from 'react-virtualized'
import 'react-virtualized/styles.css'
import sortArrowUpActive from 'src/assets/images/sortArrowUpActive.svg'
import sortArrowDownActive from 'src/assets/images/sortArrowDownActive.svg'
import _ from 'lodash'

const DEFAULT_HEADER_ROW_HEIGHT = 20
const DEFAULT_ROW_HEIGHT = 50
const DEFAULT_COL_WIDTH = 100

export interface BaseVirtualizedTableCol extends Partial<ColumnProps> {
    dataKey: string // Must match a key in row data
    label: string // Used as table header label text
    width?: number // Optional using Partial<T>
    disableSort?: boolean // Prevents sorting on a per column basis
    cellElement?: (cellData?: any, rowData?: any) => JSX.Element | string | null // Provides a custom cell element
}

export interface BaseVirtualizedTableProps extends Partial<TableProps> {
    cols: BaseVirtualizedTableCol[]
    data?: any // Signals to the table that something has changed when deleting or marking a row as added
    rowHeight?: number // Optional using Partial<T>
    isLoading?: boolean
    isSearching?: boolean
    rowClassName?: (rowData?: any) => string // Forces type to function
}

class BaseVirtualizedTable extends React.Component<BaseVirtualizedTableProps> {
    baseTableRef: any

    state: { sortDirection?: SortDirectionType; sortBy?: string; sortedList: any[] } = {
        sortedList: [],
    }

    constructor(props: BaseVirtualizedTableProps) {
        super(props)
        this.state = {
            sortDirection: this.props.sortDirection,
            sortBy: this.props.sortBy,
            sortedList: this.props.rows,
        }
    }

    componentDidMount = () => {
        const { sortDirection, sortBy } = this.state

        // Sort on inital mount if sortDirection and sortBy provided
        // This resets sorting when switching screens
        if (sortDirection && sortBy) {
            this.handleSort({
                sortDirection,
                sortBy,
            })
        }
    }

    componentDidUpdate(prevProps: BaseVirtualizedTableProps) {
        const { sortBy, sortDirection } = this.state
        const { rows } = this.props

        // Update state if data prop changes
        if (this.props.data !== prevProps.data && sortBy && sortDirection) {
            this.setState({
                // Sort using params from state when new props received
                sortedList: this.sortList(rows, {
                    sortBy,
                    sortDirection,
                }),
            })
        }
    }

    handleScrollToRow = (index: number) => {
        this.baseTableRef?.scrollToRow(index)
    }

    renderHeader(
        label: string,
        disableSort: boolean,
        { dataKey, sortBy, sortDirection }: TableHeaderProps
    ): JSX.Element {
        let sortIcon
        switch (sortDirection) {
            case SortDirection.ASC:
                sortIcon = sortArrowUpActive
                break
            case SortDirection.DESC:
                sortIcon = sortArrowDownActive
                break
        }

        return (
            <React.Fragment>
                {label}
                {!disableSort && sortBy === dataKey && (
                    <img className='sortable-header-icon' src={sortIcon} alt='Toggle sort' />
                )}
            </React.Fragment>
        )
    }

    getRowData = ({ index }: Index): any => this.state.sortedList[index]

    getRowClassname = ({ index }: Index): string => {
        if (index < 0) {
            // Ignore header which has index of -1
            return ''
        }
        return this.props.isLoading
            ? 'bp3-skeleton vtr-loading'
            : this.props.rowClassName
            ? this.props.rowClassName(this.getRowData({ index }))
            : ''
    }

    getRowStyle = ({ index }: Index): React.CSSProperties => {
        if (index < 0) {
            // Ignore header which has index of -1
            return {}
        }
        return this.props.isLoading
            ? {
                  width: 'calc(100% - 40px)',
                  height: (this.props.rowHeight || DEFAULT_ROW_HEIGHT) - 8,
                  margin: '4px 20px',
              }
            : {}
    }

    getCellData = ({ dataKey, rowData }: TableCellDataGetterParams): any => 
        // NOTE: rowData can be undefined
         rowData && rowData[dataKey]
    

    renderCell = (
        cellElement: (cellData: any, rowData: any) => JSX.Element | string | null,
        { cellData, rowData }: TableCellProps
    ) => {
        if (_.isEmpty(rowData)) {
            // Rowdata is an empty object while loading so ignore
            return
        }
        if (cellElement) {
            return cellElement(cellData, rowData)
        }
        // Return cellData as string if no cellElement defined
        // Only show placeholder if cellData is null or undefined, empty strings are acceptable
        return (cellData && String(cellData)) ?? i18n.t('placeholders.missingData')
    }

    handleRowClick = (index: RowMouseEventHandlerParams) => {
        // Update inner grid component before passing along to props
        this.baseTableRef?.forceUpdateGrid()
        return this.props.onRowClick ? this.props.onRowClick(index) : undefined
    }

    handleSort = (info: { sortBy: string; sortDirection: SortDirectionType }) => {
        const { sortedList } = this.state
        const { sortable, isSearching, onSort } = this.props
        if (!sortedList || sortable === false || isSearching) {
            return
        }
        const newlySortedList = this.sortList(sortedList, info)
        // Update inner grid component before passing along to props
        this.setState({ sortDirection: info.sortDirection, sortBy: info.sortBy, sortedList: newlySortedList })

        this.baseTableRef?.forceUpdateGrid()
        return onSort && onSort(newlySortedList)
    }

    sortList = (listToSort: any[], info: { sortBy: string; sortDirection: SortDirectionType }): any[] => {
        if (this.props.isSearching) {
            return listToSort
        }
        let list = listToSort
        list = sortByKey(list, info.sortBy)
        return info.sortDirection === SortDirection.DESC ? list.reverse() : list
    }

    render() {
        const { cols, rows, data, width, height, className, isLoading, rowGetter, noRowsRenderer } = this.props

        const headerHeight = this.props.headerHeight || DEFAULT_HEADER_ROW_HEIGHT
        const rowHeight = this.props.rowHeight || DEFAULT_ROW_HEIGHT

        let rowWidth = 0
        cols.forEach(col => {
            rowWidth += col.width || DEFAULT_COL_WIDTH
        })

        return (
            <Table
                {...this.props}
                ref={instance => (this.baseTableRef = instance)}
                data={data} // Pass through data prop to trigger render if data changes
                width={width || rowWidth}
                height={height || DEFAULT_HEADER_ROW_HEIGHT + rowHeight * rows.length}
                headerHeight={headerHeight}
                rowHeight={rowHeight}
                rowCount={rows.length}
                rowClassName={this.getRowClassname}
                rowStyle={this.getRowStyle}
                className={'bvt-table custom-scrollbars' + (className ? ' ' + className : '')}
                headerClassName={isLoading ? ' bp3-skeleton' : ''}
                sort={this.handleSort}
                sortBy={this.state.sortBy}
                sortDirection={this.state.sortDirection}
                onRowClick={this.handleRowClick}
                rowGetter={rowGetter || this.getRowData}
                noRowsRenderer={noRowsRenderer}
            >
                {cols.map(col => (
                    <Column
                        {...col}
                        key={col.dataKey}
                        width={col.width || DEFAULT_COL_WIDTH}
                        minWidth={col.minWidth}
                        maxWidth={col.maxWidth}
                        flexGrow={col.flexGrow}
                        flexShrink={col.flexShrink}
                        headerRenderer={this.renderHeader.bind(this, col.label, col.disableSort)}
                        cellDataGetter={col.cellDataGetter || this.getCellData}
                        cellRenderer={this.renderCell.bind(this, col.cellElement)}
                        className={col.className}
                    />
                ))}
            </Table>
        )
    }
}

export default BaseVirtualizedTable
