import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// Mobx
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { SignInStatus } from 'src/common/models/SignInStatus'

import { Form, FormGroup, Label, Input, Button, InputGroup, FormFeedback, FormText } from 'reactstrap'
import padlockIcon from 'src/assets/images/padlockIcon.svg'

@inject('store')
@observer
class RequireNewPassword extends React.Component<{ store?: RootStore } & WithNamespaces> {
    // User clicked Forgot Password
    handleRequireNewPassword = () => {
        this.props.store!.signInUIStore.submitNewPassword()
    }

    handleOnKeyPress = (event: any) => {
        switch (event.key) {
            case 'Enter':
                event.preventDefault()
                // Handle "enter" or "return" key press
                this.props.store!.signInUIStore.submitNewPassword()
                break
            default:
                return
        }
    }

    render() {
        if (this.props.store!.signInUIStore.signinStatus !== SignInStatus.requireNewPassword) {
            return null
        }

        return (
            <div className='auth-form-container'>
                <Form onKeyPress={this.handleOnKeyPress} className='auth-form'>
                    <div className='auth-form-header text-center'>
                        <h1>{i18n.t('authPage.authHeaders.requireNewPassword')}</h1>
                    </div>
                    <div className='auth-form-body'>
                        <FormGroup className='custom-form-group'>
                            <Label className='auth-form-label'>
                                <h6>{i18n.t('authPage.newPassword')}</h6>
                            </Label>
                            <InputGroup className='custom-input custom-input-prepend-icon'>
                                <img className='custom-input-icon-left' src={padlockIcon} alt='Locked' />
                                <Input
                                    autoFocus
                                    id='newPassword'
                                    name='newPassword'
                                    type='password'
                                    onChange={this.props.store!.signInUIStore.handleInputChange}
                                    invalid={this.props.store!.signInUIStore.isAuthAWSError}
                                />
                                <FormFeedback>
                                    {i18n.t('awsErrors.' + this.props.store!.signInUIStore.authAWSError)}
                                </FormFeedback>
                                <FormText>{i18n.t('authPage.newPasswordHelpText')}</FormText>
                            </InputGroup>
                        </FormGroup>
                        <FormGroup className='custom-form-group'>
                            <Label className='auth-form-label'>
                                <h6>{i18n.t('authPage.verifyNewPassword')}</h6>
                            </Label>
                            <InputGroup className='custom-input custom-input-prepend-icon'>
                                <img className='custom-input-icon-left' src={padlockIcon} alt='Locked' />
                                <Input
                                    id='verifyNewPassword'
                                    name='verifyNewPassword'
                                    type='password'
                                    onChange={this.props.store!.signInUIStore.handleInputChange}
                                    invalid={this.props.store!.signInUIStore.isAuthAWSError}
                                />
                                <FormFeedback>
                                    {i18n.t('awsErrors.' + this.props.store!.signInUIStore.authAWSError)}
                                </FormFeedback>
                            </InputGroup>
                        </FormGroup>
                        <Button
                            className='auth-form-body-button'
                            color='primary'
                            disabled={!this.props.store!.signInUIStore.isNewPasswordFormValid}
                            onClick={this.handleRequireNewPassword}
                        >
                            <h6>{i18n.t('actions.submit')}</h6>
                        </Button>
                    </div>
                </Form>
                <Button
                    className='custom-button-link'
                    color='link'
                    onClick={this.props.store!.signInUIStore.handleBackToSignIn}
                >
                    <span>{i18n.t('authPage.backToSignIn')}</span>
                </Button>
            </div>
        )
    }
}

export default withNamespaces()(RequireNewPassword)
