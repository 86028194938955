import { action, computed, observable, reaction, runInAction } from 'mobx'
import RootStore from 'src/common/RootStore'

import i18n from 'src/i18n'

import views, { RoutePath } from 'src/config/views'

import DisplayImage, { DisplayImageSearchParams } from 'src/common/models/DisplayImage'
import DisplayImageSummary from 'src/common/models/DisplayImageSummary'
import DisplayReport, { DisplayReportParameters, ReportFormat, ReportType } from 'src/common/models/DisplayReport'
import { AppToaster } from 'src/common/components/AppToaster'
import { Intent } from '@blueprintjs/core'
import { ValueLabelPair } from 'src/common/components/SelectComponents'
import moment from 'moment'
import Organisation from 'src/common/models/Organisation'

export default class ReportsUIStore {
    @observable reportScopeSet: boolean = false
    @observable reportFiltersOpen: boolean = true

    // TODO: Building params to send in payload could be cleaner
    @observable newSearchParams: DisplayImageSearchParams = {}

    // TODO: Select dropdown state management could be better
    @observable resolutions: string[]
    @observable selectedResolutions: ValueLabelPair[] = []

    @observable selectedUnnamedOption?: ValueLabelPair
    @observable selectedUnnamedOptionLabel: string
    @observable selectedScreenNames: ValueLabelPair[] = []

    @observable viewSelectDropdownOpen: boolean = false
    @observable contentPageScrolled: boolean = false
    @observable isAtBottom: boolean = false

    @observable expandImageDialogOpen: boolean = false
    @observable expandedDisplayImage?: DisplayImage

    @observable mobileSelectedImagesOpen: boolean = false
    @observable displayImagesSelected: DisplayImage[] = []
    @observable summaryReportIds?: string[]

    @observable imageSummaryDrawerOpen: boolean = false
    @observable displayImageReportSummary?: DisplayImageSummary
    @observable isSingleImageSummary: boolean

    @observable selectedExportName?: string
    @observable selectedExportFormat?: ReportFormat
    @observable selectedExportDataType?: ReportType
    @observable reportSignedURL?: string

    @observable exportReportDialogOpen: boolean = false

    @observable private setOrg?: Organisation

    exportReportLinkTimeout = 300000 // ms
    pageScrollThreshold = 135 // px

    exportOptions: ValueLabelPair[] = [
        { value: 'candelicCsv', label: 'CSV' },
        { value: 'talonCsv', label: 'Talon CSV' },
    ]
    @observable selectedExportOption: ValueLabelPair = this.exportOptions[0]

    rootStore: RootStore

    toggleBottomControls = reaction(
        () =>
            this.displayImagesSelected.length > 0 &&
            this.rootStore.router.currentView &&
            this.rootStore.router.currentView.rootPath === RoutePath.reports,
        displayImagesSelected => {
            document.documentElement.style.setProperty(
                '--freshworks-frame-offset',
                `${displayImagesSelected ? 82 : 0}px`
            ) // Set CSS variable
        }
    )

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @computed get isRangeValid(): boolean {
        const startDate = this.newSearchParams.startDate
        const stopDate = this.newSearchParams.stopDate

        if (!startDate || !stopDate) {
            return false
        }

        return (
            moment(startDate, moment.ISO_8601, true).isValid() &&
            moment(stopDate, moment.ISO_8601, true).isValid() &&
            startDate.isBefore(stopDate)
        )
    }

    @computed get filteredItems(): DisplayImage[] {
        let items: DisplayImage[] = this.rootStore.displayImageStore.items
        if (this.newSearchParams.searchTerm && this.newSearchParams.searchTerm !== '') {
            const matcher = new RegExp(this.newSearchParams.searchTerm, 'i')
            items = items.filter(item => matcher.test(item.fileName))
        }
        if (this.selectedResolutions && this.selectedResolutions.length > 0) {
            const resolutions: string[] = this.selectedResolutions.map((option: ValueLabelPair) => option.value)
            items = items.filter(item => resolutions.includes(item.resolution))
        }
        if (this.selectedUnnamedOption?.value !== undefined) {
            const unnamed: boolean = this.selectedUnnamedOption.value
            items = items.filter(item => item.unnamed === unnamed)
        }
        return items
    }

    @computed get allowedScreenIds(): Set<string> | undefined {
        const selectedScreenIds = this.newSearchParams.screenIds
        if (!selectedScreenIds || selectedScreenIds.length === 0) {
            return undefined
        }

        const allowedScreenIds = new Set<string>()
        this.rootStore.screenStore.findItem(selectedScreenIds[0])?.organisation?.screens.forEach(screen => {
            allowedScreenIds.add(screen.id!)
        })

        return allowedScreenIds
    }

    @computed get selectedOrg(): Organisation | undefined {
        const orgStore = this.rootStore.orgStore
        // If not multiple screen manager, set to own org
        return !orgStore.hasMultipleOpsOrganisations ? orgStore.myOrg : this.setOrg
    }

    get resolutionOptions(): ValueLabelPair[] | undefined {
        if (!this.resolutions) {
            return undefined
        }
        return this.resolutions.map((resolution: string) => ({
            value: resolution,
            label: resolution,
        }))
    }

    get nameTypesOptions(): ValueLabelPair[] {
        return [
            { value: undefined, label: i18n.t('reportsPage.allNames') },
            { value: true, label: i18n.t('reportsPage.unnamedOnly') },
            { value: false, label: i18n.t('reportsPage.namedOnly') },
        ]
    }

    isScreenOptionDisabled = (option: ValueLabelPair): boolean => {
        // Only allow screens within same organisation group
        if (!this.allowedScreenIds) {
            return false
        }

        return !this.allowedScreenIds.has(option.value)
    }

    checkIfImageSelected = (displayImage: DisplayImage): boolean =>
        !!this.displayImagesSelected.find(image => image.id === displayImage.id)

    @action updateParams = (params: { org: string; encodedParams?: string }) => {
        const { org, encodedParams: encoded } = params

        if (org) {
            this.setOrg = this.rootStore.orgStore.findItem(org)
            // Clear any set scope
            this.clearScope()
        }

        if (encoded) {
            const { startDate, stopDate, unnamed } = JSON.parse(atob(encoded))
            const dateRange: [moment.Moment | undefined, moment.Moment | undefined] = [undefined, undefined]
            if (startDate) {
                dateRange[0] = moment(startDate)
            }
            if (stopDate) {
                dateRange[1] = moment(stopDate)
            }
            this.updateScopeDateRange(dateRange)

            // NOTE: Not supporting screenIds yet, but this parsing works for the following request:
            // /reports?screenIds=["1234","5678"]
            // if (queryParams.screenIds) {
            //     const screenIds = JSON.parse(queryParams.screenIds).map((id: string) => {
            //         return String(id)
            //     })
            //     this.newSearchParams.screenIds = screenIds
            // }

            if (unnamed !== null) {
                const nameTypesOption = this.nameTypesOptions.find(option => option.value === unnamed)
                if (nameTypesOption) {
                    this.updateFiltersSelectParams('unnamed', nameTypesOption)
                }
            }

            this.rootStore.reportsUIStore.requestDisplayImages(true)

            // Clear params from URL, prevents caching if page refreshed
            const router = this.rootStore.router
            const { encodedParams, ...rest } = router.params
            router.goTo(views.reports, { ...rest }, this.rootStore)
        }
    }

    @action updateScopeDateRange = (dateRange: [moment.Moment | undefined, moment.Moment | undefined]) => {
        this.newSearchParams.startDate = dateRange[0]
        this.newSearchParams.stopDate = dateRange[1]
    }

    @action toggleReportFilters = () => {
        this.reportFiltersOpen = !this.reportFiltersOpen
    }

    @action toggleViewSelect = () => {
        this.viewSelectDropdownOpen = !this.viewSelectDropdownOpen
    }

    @action toggleContentPageScrolled = (value: boolean) => {
        this.contentPageScrolled = value
    }

    @action toggleIsAtBottom = () => {
        this.isAtBottom = !this.isAtBottom
    }

    @action toggleMobileSelectedImagesOpen = () => {
        this.mobileSelectedImagesOpen = !this.mobileSelectedImagesOpen
    }

    @action closeMobileSelectedImages = () => {
        this.mobileSelectedImagesOpen = false
    }

    @action toggleExpandImageDialog = (displayImage?: DisplayImage) => {
        this.expandedDisplayImage = displayImage
        this.expandImageDialogOpen = !this.expandImageDialogOpen
    }

    @action toggleImageSummaryDrawer = () => {
        if (this.imageSummaryDrawerOpen) {
            // Clear summary upon modal close
            this.displayImageReportSummary = undefined
        }

        this.imageSummaryDrawerOpen = !this.imageSummaryDrawerOpen
    }

    @action toggleExportReportDialog = () => {
        if (this.exportReportDialogOpen) {
            // Clear report URL option upon modal close
            this.reportSignedURL = undefined
        }

        this.exportReportDialogOpen = !this.exportReportDialogOpen
    }

    @action closeExportReportDialog = () => {
        this.exportReportDialogOpen = false

        // Clear report URL option upon modal close
        this.reportSignedURL = undefined
    }

    @action updateSearchTerm = (value: string) => {
        this.newSearchParams.searchTerm = value
    }

    @action updateFiltersSelectParams = (filter: string, selectedOption: ValueLabelPair | ValueLabelPair[]) => {
        switch (filter) {
            case 'unnamed':
                if (Array.isArray(selectedOption)) {
                    return
                }
                this.newSearchParams.unnamed = selectedOption.value
                this.selectedUnnamedOptionLabel = selectedOption.label
                this.selectedUnnamedOption = selectedOption
                break
            case 'resolutions':
                if (!Array.isArray(selectedOption)) {
                    return
                }
                this.newSearchParams.resolutions = selectedOption.map((option: ValueLabelPair) => option.value)
                this.selectedResolutions = selectedOption
                break
            case 'screenIds':
                if (!Array.isArray(selectedOption)) {
                    return
                }
                this.newSearchParams.screenIds = selectedOption.map((option: ValueLabelPair) => option.value)
                this.selectedScreenNames = selectedOption
                break
        }
    }

    @action requestDisplayImages(setScope?: boolean) {
        if (!this.selectedOrg) {
            return
        }

        this.rootStore.displayImageStore.populateDisplayImageList({
            ...this.newSearchParams,
            organisationId: this.selectedOrg.id,
        })

        // If setScope is true, lock the scope in the UI
        if (setScope) {
            this.reportScopeSet = true
        }

        // Update available resolutions
        this.rootStore.displayImageStore
            .fetchResolutions({
                screenIds: this.newSearchParams.screenIds,
                organisationId: this.selectedOrg.id,
            })
            .then(resolutions => {
                runInAction('updateResolutions', () => {
                    this.resolutions = resolutions
                })
            })
            .catch(err => console.error(err))
    }

    @action clearScope = () => {
        this.newSearchParams = {}
        this.selectedScreenNames = []

        this.clearFilters()
    }

    @action clearFilters = () => {
        this.newSearchParams.resolutions = undefined
        this.selectedResolutions = []
        this.resolutions = []

        this.newSearchParams.unnamed = undefined
        this.selectedUnnamedOption = undefined
        this.selectedUnnamedOptionLabel = ''

        this.newSearchParams.searchTerm = undefined

        this.reportScopeSet = false

        this.clearSelectedImages()
    }

    @action addImageToSelectedArray = (displayImage: DisplayImage) => {
        this.displayImagesSelected.push(displayImage)
    }

    @action removeImageFromSelectedArray = (displayImage: DisplayImage) => {
        this.displayImagesSelected = this.displayImagesSelected.filter(image => image.id !== displayImage.id)
    }

    @action clearSelectedImages = () => {
        this.displayImagesSelected = []
    }

    @action requestNewReport = (singleSummaryImageID?: string) => {
        this.toggleImageSummaryDrawer()

        if (singleSummaryImageID) {
            // Single image summary
            this.summaryReportIds = [singleSummaryImageID]
            this.isSingleImageSummary = true
        } else {
            // Multiple image summary
            this.summaryReportIds = this.displayImagesSelected.map((displayImage: DisplayImage) => displayImage.id!)
            this.isSingleImageSummary = false
        }

        this.rootStore.displayImageStore
            .fetchSummary({
                startDate: this.newSearchParams.startDate,
                stopDate: this.newSearchParams.stopDate,
                screenIds: this.newSearchParams.screenIds,
                displayImageIds: this.summaryReportIds,
            })
            .then((summary: DisplayImageSummary) => {
                runInAction('setDisplayImageReportSummary', () => {
                    this.displayImageReportSummary = summary
                })
            })
            .catch(() => {
                AppToaster.show({
                    message: i18n.t('feedback.errors.errorGeneratingReport'),
                    intent: Intent.DANGER,
                })
            })
    }

    @action updateExportName = (value: string) => {
        this.selectedExportName = value
    }

    @action updateExportOption = (selectedOption: ValueLabelPair) => {
        this.selectedExportOption = selectedOption
    }

    @action requestSummaryReport = () => {
        // Break selected option into two keys for reports download API
        // TODO: Handle this using two separate dropdowns in the future
        if (!this.selectedExportOption) {
            return
        }

        switch (this.selectedExportOption.value) {
            case 'candelicCsv':
                this.selectedExportFormat = ReportFormat.csv
                this.selectedExportDataType = ReportType.candelic
                break
            case 'talonCsv':
                this.selectedExportFormat = ReportFormat.csv
                this.selectedExportDataType = ReportType.candelic
                break
        }

        const params: DisplayReportParameters = {
            name: this.selectedExportName!,
            organisationId: this.rootStore.userStore.me!.organisationId,
            startDate: this.newSearchParams.startDate,
            stopDate: this.newSearchParams.stopDate,
            screenIds: this.newSearchParams.screenIds,
            displayImageIds: this.summaryReportIds,
            format: this.selectedExportFormat!,
            type: this.selectedExportDataType!,
        }

        this.rootStore.displayImageStore
            .downloadReport(params)
            .then((report: DisplayReport) => {
                runInAction('setReportURL', () => {
                    this.reportSignedURL = report.signedURL
                })
            })
            .catch(() => {
                AppToaster.show({
                    message: i18n.t('feedback.errors.errorGeneratingReport'),
                    intent: Intent.DANGER,
                })
            })
    }
}
