import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import DisplayImage from 'src/common/models/DisplayImage'

import { Button } from 'reactstrap'
import { Drawer, Position } from '@blueprintjs/core'
import imageBroken from '../../../assets/images/imageBroken.svg'
import clearIcon from '../../../assets/images/clearIcon.svg'
import chevronDown from '../../../assets/images/chevronDown.svg'

@inject('store')
@observer
class SelectedImages extends React.Component<{ store?: RootStore } & WithNamespaces> {
    componentDidUpdate = () => {
        // Close mobile selected images drawer if array empty
        if (this.props.store!.reportsUIStore.displayImagesSelected.length === 0) {
            this.props.store!.reportsUIStore.closeMobileSelectedImages()
        }
    }

    toggleExpandImageDialog = (displayImage: DisplayImage) => {
        this.props.store!.reportsUIStore.toggleExpandImageDialog(displayImage)
    }

    handleImageResponseError = (event: any) => {
        // Prevent infinte loop if fallback src invalid
        event.target.onerror = null
        event.target.src = imageBroken
    }

    displayImageCarousel = (displayImage: DisplayImage) => {
        if (!displayImage) {
            return null
        } else {
            return (
                <div key={displayImage.id} className='selected-images-carousel-item'>
                    {displayImage.thumbnailURL ? (
                        <img
                            className='selected-images-carousel-image'
                            onClick={this.toggleExpandImageDialog.bind(this, displayImage)}
                            src={displayImage.thumbnailURL}
                            alt={displayImage.displayableFilename}
                            onError={this.handleImageResponseError}
                        />
                    ) : (
                        <div
                            className='display-image-missing-container-selected'
                            onClick={this.toggleExpandImageDialog.bind(this, displayImage)}
                        >
                            <img className='display-image-missing' src={imageBroken} alt='Broken' />
                        </div>
                    )}
                    <img
                        className='selected-images-clear'
                        onClick={this.props.store!.reportsUIStore.removeImageFromSelectedArray.bind(this, displayImage)}
                        src={clearIcon}
                        alt='Clear'
                    />
                </div>
            )
        }
    }

    handleReportSubmit = () => {
        this.props.store!.reportsUIStore.requestNewReport()
    }

    render() {
        const displayImagesSelected = this.props.store!.reportsUIStore.displayImagesSelected
        if (!(displayImagesSelected && displayImagesSelected.length > 0)) {
            return null
        }

        const isMobile = this.props.store!.appUIStore.isMobile

        return (
            <React.Fragment>
                <div className='selected-images-container'>
                    {!isMobile ? (
                        <React.Fragment>
                            <div className='selected-images-carousel horizontal-scroll-shadows hide-scrollbars'>
                                {displayImagesSelected.map((displayImage: DisplayImage) =>
                                    this.displayImageCarousel(displayImage)
                                )}
                            </div>
                            <div className='selected-images-controls'>
                                <div className='selected-images-count'>
                                    <span>
                                        {displayImagesSelected.length} {i18n.t('reportsPage.adsSelected')}
                                    </span>
                                </div>
                                <Button
                                    className='selected-images-clear-all'
                                    color='link'
                                    onClick={this.props.store!.reportsUIStore.clearSelectedImages}
                                >
                                    <span>{i18n.t('reportsPage.clearSelected')}</span>
                                </Button>
                                <Button
                                    className='selected-images-report-submit'
                                    onClick={this.handleReportSubmit}
                                    color='primary'
                                >
                                    {i18n.t('actions.report')}
                                </Button>
                            </div>
                        </React.Fragment>
                    ) : (
                        <div className='selected-images-controls'>
                            <div
                                onClick={this.props.store!.reportsUIStore.toggleMobileSelectedImagesOpen}
                                className='selected-images-count'
                            >
                                <span>x {displayImagesSelected.length}</span>
                                <img
                                    className='custom-dropdown-chevron'
                                    style={{
                                        transform: this.props.store!.reportsUIStore.mobileSelectedImagesOpen
                                            ? 'rotate(180deg)'
                                            : '',
                                    }}
                                    src={chevronDown}
                                    alt='Toggle'
                                />
                            </div>
                            <Button
                                className='selected-images-clear-all'
                                color='link'
                                onClick={this.props.store!.reportsUIStore.clearSelectedImages}
                            >
                                <span>{i18n.t('reportsPage.clearSelected')}</span>
                            </Button>
                            <Button
                                className='selected-images-report-submit'
                                onClick={this.handleReportSubmit}
                                color='primary'
                            >
                                {i18n.t('actions.report')}
                            </Button>
                        </div>
                    )}
                </div>
                {/* Show mobile selected drawer if open */}
                <Drawer
                    isOpen={isMobile && this.props.store!.reportsUIStore.mobileSelectedImagesOpen}
                    onClose={this.props.store!.tileViewUIStore.toggleMobileControls}
                    position={Position.BOTTOM}
                    size='100%'
                    portalClassName='custom-drawer mobile-selected-images-drawer'
                >
                    <div className='selected-images-carousel'>
                        {displayImagesSelected.map((displayImage: DisplayImage) =>
                            this.displayImageCarousel(displayImage)
                        )}
                    </div>
                </Drawer>
            </React.Fragment>
        )
    }
}

export default withNamespaces()(SelectedImages)
