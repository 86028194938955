import clsx from 'clsx'
import React from 'react'
import outWatchLogo from 'src/assets/images/outWatchLogo.svg'

const Loader = ({ fullscreen }: { fullscreen?: boolean }) => (
    <div className={clsx('loading-state', fullscreen && 'loading-state-fullscreen')}>
        <img src={outWatchLogo} alt='Loading' height={58} />
    </div>
)

export default Loader
