import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { AdminTabName } from '../../container'
import { AdminTabData } from '../../models/AdminTabData'

import consoleIcon from 'src/assets/images/consoleIcon.svg'
import consoleIconAlt from 'src/assets/images/consoleIconAlt.svg'
import { Tooltip, Position } from '@blueprintjs/core'

interface AccessCellProps {
    className?: string
    tabName: AdminTabName
    tabData: AdminTabData
    rowData: any
    cellData: any
    deleted: boolean
}

@inject('store')
@observer
class AccessCell extends React.Component<{ store?: RootStore } & AccessCellProps> {
    render() {
        const { className, rowData, deleted } = this.props

        return (
            <div className={'access-cell' + (className ? ' ' + className : '')}>
                {rowData.access}
                {rowData.showConsoleIcon && (
                    <Tooltip
                        boundary='viewport'
                        content='Has console access'
                        hoverOpenDelay={300}
                        position={Position.TOP}
                        targetClassName='console-icon-wrapper'
                    >
                        {!deleted ? (
                            <img src={consoleIcon} alt='Console' height={12} />
                        ) : (
                            <img src={consoleIconAlt} alt='Console' height={12} />
                        )}
                    </Tooltip>
                )}
            </div>
        )
    }
}

export default AccessCell
