import DisplayImage, {
    DisplayImageHashParams,
    DisplayImagePerceptualHashParams,
    DisplayImageJSON,
    DisplayImageSearchParams,
    DisplayImageSummaryParams,
} from '../common/models/DisplayImage'
import DisplayRecord, { DisplayRecordJSON, RandomParams, TimestampParams } from '../common/models/DisplayRecord'
import DisplayImageSummary, { DisplayImageSummaryJSON } from 'src/common/models/DisplayImageSummary'
import BaseRouter, { RouterFlag } from './BaseRouter'
import onAPI from './onAPI'
import moment from 'moment'

export class DisplayImageRouter extends BaseRouter<DisplayImage, DisplayImageJSON, DisplayImageSearchParams> {
    protected route = '/display-image'

    protected flags = RouterFlag.list | RouterFlag.search

    async summary(summaryParams: DisplayImageSummaryParams): Promise<DisplayImageSummary> {
        const json: DisplayImageSummaryJSON = await onAPI.fetchJSON(this.route + 's/summary', summaryParams)
        return new DisplayImageSummary(json)
    }

    async hash(hashParams: DisplayImageHashParams): Promise<DisplayImage[]> {
        const json: DisplayImageJSON[] = await onAPI.fetchJSON(this.route + 's/hash', hashParams)
        return json.map(j => new DisplayImage(j))
    }

    async perceptualHash(hashParams: DisplayImagePerceptualHashParams): Promise<DisplayImage[]> {
        const json: DisplayImageJSON[] = await onAPI.fetchJSON(this.route + 's/perceptual-hash', hashParams)
        return json.map(j => new DisplayImage(j))
    }

    async randomByImage(displayImageId: string, randomParams: RandomParams): Promise<DisplayRecord[]> {
        const json: DisplayRecordJSON[] = await onAPI.fetchJSON(
            this.route + 's/random/display-image/' + displayImageId,
            randomParams
        )
        return json.map(j => new DisplayRecord(j))
    }

    async randomByScreen(screenId: string, randomParams: RandomParams): Promise<DisplayRecord[]> {
        const json: DisplayRecordJSON[] = await onAPI.fetchJSON(
            this.route + 's/random/screen/' + screenId,
            randomParams
        )
        return json.map(j => new DisplayRecord(j))
    }

    async random(randomParams: RandomParams): Promise<DisplayRecord[]> {
        const json: DisplayRecordJSON[] = await onAPI.fetchJSON(this.route + 's/random', randomParams)
        return json.map(j => new DisplayRecord(j))
    }

    async randomZip(randomParams: RandomParams): Promise<string> {
        interface UrlResponse {
            url: string
        }
        const json: UrlResponse = await onAPI.fetchJSON(this.route + 's/random-zip', randomParams)
        return json.url
    }

    async timestamp(screenId: string, timestampParams: TimestampParams): Promise<DisplayRecord> {
        const json: DisplayRecordJSON = await onAPI.fetchJSON(
            this.route + 's/timestamp/screen/' + screenId,
            // add 1 millisecond to timestamp to avoid rounding errors
            { ...timestampParams, timestamp: moment(timestampParams.timestamp).add(1, 'millisecond') }
        )
        return new DisplayRecord(json)
    }

    protected newItem(json: DisplayImageJSON): DisplayImage {
        return new DisplayImage(json)
    }
}

const displayImageRouter = new DisplayImageRouter()
export default displayImageRouter
