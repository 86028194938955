import { observable } from 'mobx'
import { Omit } from 'react-router'
import User from './User'

export enum UserPreferenceType {
    language = 'language',
    timeZone = 'timeZone',
    timeFormat = 'timeFormat',
    dateFormat = 'dateFormat',
}

export enum UserNotificationType {
    outage = 'outage',
    fault = 'fault',
    // critical = 'critical',
    digest = 'digest',
}

export enum AlertMethod {
    email = 'email',
    sms = 'sms',
    // push = 'push',
}

export class UserPreferences {
    static newUserPreferences(): UserPreferences {
        return new UserPreferences({
            language: null,
            timeZone: null,
            timeFormat: null,
            dateFormat: null,
        })
    }

    @observable language: string | null
    @observable timeZone: string | null
    @observable timeFormat: string | null
    @observable dateFormat: string | null

    constructor(json: UserPreferencesJSON) {
        this.language = json.language
        this.timeZone = json.timeZone
        this.timeFormat = json.timeFormat
        this.dateFormat = json.dateFormat
    }

    toJSON(): UserPreferencesJSON {
        return {
            language: this.language,
            timeZone: this.timeZone,
            timeFormat: this.timeFormat,
            dateFormat: this.dateFormat,
        } as UserPreferencesJSON
    }

    addSeed(me: User): UserPreferences {
        return new UserPreferences({
            language: this.language ?? me.language,
            timeZone: this.timeZone ?? me.timeZone,
            timeFormat: this.timeFormat ?? me.timeFormat,
            dateFormat: this.dateFormat ?? me.dateFormat,
        })
    }
}

export type UserPreferencesJSON = Omit<UserPreferences, 'toJSON' | 'addSeed'>

export class UserNotification {
    static newUserNotification(): UserNotification {
        return new UserNotification({
            type: null,
            methods: null,
        })
    }

    @observable type: UserNotificationType | null
    @observable methods: AlertMethod[] | null

    constructor(json: UserNotificationJSON) {
        this.type = json.type
        this.methods = json.methods
    }
}

export type UserNotificationJSON = UserNotification
