import React, { useState } from 'react'
import { observer } from 'mobx-react'

import { Shortcut } from 'src/fixtures/console-shortcuts'

import { Button, DropdownItem } from 'reactstrap'

interface CommandShortcutProps {
    shortcut: Shortcut
    handleCommand: (command: string) => void
}

function CommandShortcut(props: CommandShortcutProps) {
    const [awaitingConfirmation, setAwaitingConfirmation] = useState(false)

    const { shortcut, handleCommand } = props

    if ('header' in shortcut) {
        return <DropdownItem header>{shortcut.header}</DropdownItem>
    }
    if ('command' in shortcut && 'label' in shortcut) {
        if (shortcut.danger && !awaitingConfirmation) {
            return (
                <Button
                    className='dropdown-item button-requires-confirmation'
                    onClick={() => setAwaitingConfirmation(true)}
                >
                    <span className='text-danger'>{shortcut.label}</span>
                </Button>
            )
        } else {
            return (
                <DropdownItem onClick={() => handleCommand(shortcut.command)}>
                    <span className={shortcut.danger ? 'text-danger' : ''}>
                        {awaitingConfirmation ? 'Are you sure?' : shortcut.label}
                    </span>
                </DropdownItem>
            )
        }
    }
    return null
}

export default observer(CommandShortcut)
