/* eslint-disable @typescript-eslint/naming-convention */
import { observable, computed } from 'mobx'
import { Omit } from 'react-router'

import BaseModel from './BaseModel'
import Section from './Section'
import DiagnosticsError from './DiagnosticsError'
import { ErrorCode, ErrorSeverity, ErrorSource } from './DiagnosticErrorEnums'

export enum TileType {
    cd_6_500v1 = 'cd_6_500v1',
    cd10_500v1 = 'cd_10_500v1',
    cd10_400v4 = 'cd_10_400v4',
    av10_500 = 'av10_500',
    cd10v3 = 'cd_10v3',
    sd10v2 = 'sd_10v2',
    axxion10 = 'axxion_10',
    blade12 = 'blade_12',
    blade16 = 'blade_16',
    blade19 = 'blade_19',
    bladeII12 = 'blade_ii_12',
    bladeII16 = 'blade_ii_16',
    bladeII19 = 'blade_ii_19',
    bladeM = 'blade_m',
    oceania10 = 'oceania_10',
    showWall10 = 'showwall_10',
    solo15 = 'solo_15',
    unknown = 'unknown',
}

export const tileTypeName = (tileType: TileType): string => {
    switch (tileType) {
        case TileType.cd_6_500v1:
            return 'CD-6 500'
        case TileType.cd10_500v1:
            return 'CD-10 500'
        case TileType.cd10_400v4:
            return 'CD-10v4'
        case TileType.av10_500:
            return 'AV-10 500'
        case TileType.cd10v3:
            return 'CD-10v3'
        case TileType.sd10v2:
            return 'SD-10v2'
        case TileType.axxion10:
            return 'Axxion-10'
        case TileType.blade12:
            return 'Blade-12'
        case TileType.blade16:
            return 'Blade-16'
        case TileType.blade19:
            return 'Blade-19'
        case TileType.bladeII12:
            return 'Blade-II-12'
        case TileType.bladeII16:
            return 'Blade-II-16'
        case TileType.bladeII19:
            return 'Blade-II-19'
        case TileType.bladeM:
            return 'Blade-M'
        case TileType.oceania10:
            return 'Oceania-10'
        case TileType.showWall10:
            return 'ShowWall-10'
        case TileType.solo15:
            return 'Solo-15'
        case TileType.unknown:
        default:
            return 'Unknown'
    }
}

export enum TileSupport {
    supported = 'Supported',
    legacy = 'Legacy',
    custom = 'Custom',
}

export const tileTypeSupport = (tileType: TileType): TileSupport | undefined => {
    switch (tileType) {
        case TileType.cd_6_500v1:
        case TileType.cd10_500v1:
        case TileType.cd10_400v4:
        case TileType.av10_500:
        case TileType.cd10v3:
        case TileType.sd10v2:
            return TileSupport.supported
        case TileType.axxion10:
        case TileType.blade12:
        case TileType.blade16:
        case TileType.blade19:
        case TileType.bladeII12:
        case TileType.bladeII16:
        case TileType.bladeII19:
        case TileType.bladeM:
        case TileType.oceania10:
        case TileType.showWall10:
        case TileType.solo15:
            return TileSupport.legacy
        case TileType.unknown:
            return TileSupport.custom
        default:
            return
    }
}

class Tile extends BaseModel {
    @observable id: string
    @observable tileX: number
    @observable tileY: number
    @observable tnl: string
    @observable section: Section
    serial?: string
    firmware?: string

    constructor(json: TileJSON, section: Section) {
        super(json)

        this.id = json.id
        this.tileX = json.tileX
        this.tileY = json.tileY
        this.tnl = json.tnl
        this.section = section
    }

    @computed get printableId(): string {
        // eslint-disable-next-line
        return '0x' + this.id.substring(8).replace(/\-/g, '')
    }

    @computed get type(): string {
        return this.section.tileType
    }

    @computed get errors(): DiagnosticsError[] {
        const screen = this.section.screenConfiguration.screen
        if (screen && screen.errorGroup) {
            return Array.from(screen.errorGroup.errors.values())
                .reduce<DiagnosticsError[]>((errors, errorGroup) => {
                    if (errorGroup.source === ErrorSource.tile) {
                        for (const error of errorGroup.errorList) {
                            if (error.tileId && error.tileId === this.id && !error.isAssigned) {
                                errors.push(error)
                            }
                        }
                    }
                    return errors
                }, [])
                .sort((a, b) => {
                    const severityDifference = b.group!.severity - a.group!.severity
                    if (severityDifference === 0) {
                        return b.group!.priority - a.group!.priority
                    }
                    return severityDifference
                })
        }
        return []
    }

    @computed get cannotCommunicate(): boolean {
        return this.errors.some(error => error.group!.error === ErrorCode.tileCannotCommunicate)
    }

    @computed get errorCount(): number {
        return this.errors.length
    }

    @computed get maxSeverity(): ErrorSeverity {
        return this.errors.length === 0 ? ErrorSeverity.noError : this.errors[0].group!.severity
    }

    @computed get displayableId(): string {
        if (this.id) {
            return this.id
                .split(/-(.+)/)[1]
                .replace(/-/g, '')
                .match(/.{1,8}/g)!
                .join('-')
        }
        return ''
    }
}

export type TileJSON = Omit<Tile, 'toJSON' | 'isValid' | 'errorCount' | 'maxSeverity' | 'displayableId'>

export default Tile
