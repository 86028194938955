import { observable, action, computed, autorun, reaction } from 'mobx'

import RootStore from 'src/common/RootStore'

import { LogoBoxLocation } from 'src/common/models/LogoBoxLocation'
import { heatmapColours, getCurrentFirmwareVersionIndexes } from 'src/common/utils/Heatmap'
import { normaliseData } from 'src/common/utils/Normalise'

import { ControllerStatType, TileMapStatType, BasicDiagnosticsStatType } from 'src/common/models/ControllerStat'
import TileViewStore from 'src/common/stores/TileViewStore'
import Color from 'color'
import { uniq } from 'lodash'

export enum ZoomLevel {
    one = 0.8,
    two = 1,
    three = 1.25,
    four = 1.5,
    five = 1.75,
}

export const startZoomLevel = ZoomLevel.three

export default class TileViewUIStore {
    @observable zoomLevel = startZoomLevel

    @observable tileViewOpen = false
    @observable currentStat?: { value: TileMapStatType; label: string }
    @observable normaliseMinMax = true

    @observable logoBoxDialogOpen = false
    @observable selectedLogoBoxLocation?: { value: LogoBoxLocation; label: string }

    @observable isLogoBoxAlertOpen = false
    @observable sectionGaps = false

    @observable mobileControlsOpen: boolean = false

    heatmapGradient = [
        [108, 109, 155], // lavender
        [254, 34, 96], // red
    ]
    heatmapGradientReversed = this.heatmapGradient.slice().reverse()
    omitStatsFromHeatmap: TileMapStatType[] = [
        BasicDiagnosticsStatType.tnl,
        BasicDiagnosticsStatType.strands,
        BasicDiagnosticsStatType.errors,
        BasicDiagnosticsStatType.model,
        ControllerStatType.serial,
        ControllerStatType.inventory,
    ]

    sectionColours = [
        [136, 14, 79], // purple
        [49, 27, 146], // blue
        [13, 71, 161], // cobalt
        [0, 96, 100], // teal
        [27, 94, 32], // green
        [130, 119, 23], // beige
        [255, 111, 0], // orange
        [191, 54, 12], // rust
    ]

    rootStore: RootStore

    toggleBottomControls = reaction(
        () =>
            this.tileViewOpen &&
            this.rootStore.router.queryParams &&
            this.rootStore.router.queryParams['tileView'] &&
            !this.rootStore.router.queryParams['tileHistory'], // Prevent applying styling when in title history drawer
        tileViewOpen => {
            document.documentElement.style.setProperty('--freshworks-frame-offset', `${tileViewOpen ? 82 : 0}px`) // Set CSS variable
        }
    )

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore

        autorun(r => {
            const currentScreenConfig = this.rootStore.screenDiagnosticsUIStore.currentScreenConfiguration
            if (currentScreenConfig && this.rootStore.tileViewUIStore.tileViewOpen) {
                this.rootStore.tileViewStore.setScreenConfig(currentScreenConfig)
            }
        })
    }

    @computed get currentStatValue(): TileMapStatType {
        return this.currentStat ? this.currentStat.value : TileViewStore.firstStat
    }

    @computed get isWaitingForStat(): boolean {
        const currentStat = ControllerStatType[this.currentStatValue]
        const { stat } = this.rootStore.tileViewStore

        if (!currentStat) {
            return false
        }

        return stat?.values.length === 0 || currentStat !== stat?.stat
    }

    @computed get allTnls(): string[] | undefined {
        if (this.rootStore.tileViewStore.stat && this.rootStore.tileViewStore.stat.statValues) {
            const tnls = [...this.rootStore.tileViewStore.stat.statValues.keys()].filter(Boolean)
            return tnls.length > 0 ? tnls : undefined
        } else {
            return undefined
        }
    }

    // Matches section colours assigned in LEDNet
    @computed get strandColourMap(): Map<string, string> | undefined {
        if (!this.allTnls) {
            return undefined
        }

        const data = new Map(this.allTnls.map(tnl => [tnl, tnl.split(/(\d)/)[0]]))

        const indexes = uniq([...data.values()].sort())

        const colours = new Map(
            indexes.map((s, i) => [s, Color(this.sectionColours[i % this.sectionColours.length]).hex()])
        )

        return new Map(this.allTnls.map(tnl => [tnl, colours.get(data.get(tnl) ?? '') ?? '']))
    }

    @computed get sizeColourMap(): Map<string, string> | undefined {
        const currentConfig = this.rootStore.screenDiagnosticsUIStore.currentScreenConfiguration
        if (!currentConfig) {
            return undefined
        }
        const sizes = uniq(currentConfig.sections.map(section => section.sizeString))
        return new Map(sizes.map((size, i) => [size, Color(this.sectionColours[i % this.sectionColours.length]).hex()]))
    }

    // Assign colour based on heatmap data
    @computed get heatmapColourMap(): Map<string, string> | undefined {
        if (!this.allTnls || !this.rootStore.tileViewStore.stat || !this.rootStore.tileViewStore.stat.values) {
            return undefined
        }

        let values = this.rootStore.tileViewStore.stat.values.map(value => Object.values(value)[0])
        if (
            [ControllerStatType.firmware, ControllerStatType.factory].includes(this.rootStore.tileViewStore.stat.stat)
        ) {
            values = getCurrentFirmwareVersionIndexes(values)
        }

        // Only get heatmap for arrays of number types
        if (!values.every(v => typeof v === 'number')) {
            return undefined
        }

        // Heatmap normalise params
        // These values may be overridden by the heatmap threshold values per stat
        let floor = this.normaliseMinMax ? Math.min(...values) : undefined
        let ceil = this.normaliseMinMax ? Math.max(...values) : undefined

        let gradient = this.heatmapGradient
        // Adjust calculation if specific thresholds have been defined
        if (this.rootStore.tileViewStore.stat && this.rootStore.tileViewStore.stat.heatmapThreshold) {
            floor = this.rootStore.tileViewStore.stat.heatmapThreshold.floor
            ceil = this.rootStore.tileViewStore.stat.heatmapThreshold.ceil
            if (this.rootStore.tileViewStore.stat.heatmapThreshold.reverseGradient) {
                gradient = this.heatmapGradientReversed
            }
        }

        const data = normaliseData(values, floor, ceil)
        // Prevent showing heatmap as red when all values are the same
        const colours =
            Math.min(...data) === Math.max(...data)
                ? Array(data.length).fill(Color(this.heatmapGradient[0]).hex())
                : heatmapColours(gradient, data)
        const heatmapColourMap = new Map<string, string>()
        this.allTnls.forEach((tnl, i) => {
            heatmapColourMap.set(tnl, colours[i])
        })
        return heatmapColourMap
    }

    @computed get showHeatmap(): boolean {
        return !this.omitStatsFromHeatmap.includes(this.currentStatValue)
    }

    @action changeZoomLevel = (value: ZoomLevel) => {
        this.zoomLevel = value
    }

    @action handleUpdateStat = (selectedOption: { value: TileMapStatType; label: string }) => {
        if (!this.rootStore.screenDiagnosticsUIStore.currentScreen) {
            return
        }

        this.currentStat = selectedOption

        // Check if selected stat is a controller stat
        if (ControllerStatType[selectedOption.value]) {
            this.rootStore.tileViewStore.sendStatChangeCommand(ControllerStatType[selectedOption.value])
        }
    }

    @action toggleNormaliseMinMax = () => {
        this.normaliseMinMax = !this.normaliseMinMax
    }

    @action setLogoBoxLocation = (selectedOption: { value: LogoBoxLocation; label: string }) => {
        this.selectedLogoBoxLocation = selectedOption
    }

    @action saveSetLogoBoxLocation = () => {
        if (
            !this.rootStore.screenDiagnosticsUIStore.currentScreen ||
            !this.rootStore.screenDiagnosticsUIStore.currentScreenConfiguration
        ) {
            return
        }

        if (this.selectedLogoBoxLocation) {
            // Send new Logo Box location to API
            this.rootStore.screenStore.setLogoBoxLocation(
                this.rootStore.screenDiagnosticsUIStore.currentScreen.id!,
                this.selectedLogoBoxLocation.value
            )
        }

        // Close Logo Box location modal after saving
        this.setLogoBoxDialog(false)

        // Clear selected value
        this.selectedLogoBoxLocation = undefined
    }

    @action setTileView = (isOpen: boolean) => {
        if (!isOpen) {
            // Clean up tile view
            this.sectionGaps = false
            this.zoomLevel = startZoomLevel
            this.rootStore.tileViewStore.sendStopStatFeedCommand()
            this.currentStat = undefined
        }

        this.tileViewOpen = isOpen
    }

    @action toggleSectionGaps = () => {
        this.sectionGaps = !this.sectionGaps
    }

    @action setLogoBoxDialog = (isOpen: boolean) => {
        if (isOpen) {
            this.selectedLogoBoxLocation = undefined
        }
        this.logoBoxDialogOpen = isOpen
    }

    @action toggleLogoBoxAlert = () => {
        const currentScreen = this.rootStore.screenDiagnosticsUIStore.currentScreen
        const currentScreenConfiguration = this.rootStore.screenDiagnosticsUIStore.currentScreenConfiguration
        if (!currentScreen || !currentScreenConfiguration) {
            return
        }
        if (!this.isLogoBoxAlertOpen && currentScreen.logoBoxLocation !== null) {
            this.rootStore.screenStore.setLogoBoxLocation(currentScreen.id!, null)
            // Set all section logo box flags to false
            for (const section of currentScreenConfiguration.sections) {
                section.isLogoBox = false
            }
        }
        this.isLogoBoxAlertOpen = !this.isLogoBoxAlertOpen
    }

    @action toggleMobileControls = () => {
        this.mobileControlsOpen = !this.mobileControlsOpen
    }

    @computed get sectionGapSize(): number {
        return this.sectionGaps ? 5 : 0
    }
}
