import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { eventPreventDefault } from 'src/common/utils/EventUtils'

import { Form, FormGroup, Label, Input } from 'reactstrap'
import Select from 'react-select'
import {
    ValueContainer,
    ClearIndicator,
    DropdownIndicator,
    Option,
    NoOptionsMessage,
    ValueLabelPair,
} from '../../../common/components/SelectComponents'
import searchIcon from '../../../assets/images/searchIcon.svg'

@inject('store')
@observer
class ReportFilters extends React.Component<{ store?: RootStore } & WithNamespaces> {
    handleFilterChange = (filter: string, value: ValueLabelPair) => {
        this.props.store!.reportsUIStore.updateFiltersSelectParams(filter, value)
    }

    handleSearchQueryChange = (event: any) => {
        this.props.store!.reportsUIStore.updateSearchTerm(event.target.value)
    }

    handleReportFiltersSubmit = () => {
        this.props.store!.reportsUIStore.requestDisplayImages()
    }

    render() {
        const reportsUIStore = this.props.store!.reportsUIStore
        const resolutionOptions = reportsUIStore.resolutionOptions
        const nameTypesOptions = reportsUIStore.nameTypesOptions

        return (
            <React.Fragment>
                <div className='refine-results'>
                    <Form inline onSubmit={eventPreventDefault}>
                        <FormGroup className='reports-filter-group reports-filter-group-inverted'>
                            <Label className='custom-label'>
                                <h6>{i18n.t('reportsPage.resolution')}</h6>
                            </Label>
                            <Select
                                className='custom-select-wrapper'
                                classNamePrefix='custom-select'
                                isMulti
                                hideSelectedOptions={false}
                                closeMenuOnSelect={false}
                                blurInputOnSelect={false}
                                onChange={this.handleFilterChange.bind(this, 'resolutions')}
                                options={resolutionOptions}
                                placeholder={i18n.t('common.all')}
                                value={this.props.store!.reportsUIStore.selectedResolutions}
                                components={{
                                    ValueContainer,
                                    ClearIndicator,
                                    DropdownIndicator,
                                    Option,
                                    NoOptionsMessage,
                                }}
                            />
                        </FormGroup>
                        <FormGroup className='reports-filter-group reports-filter-group-inverted'>
                            <Label className='custom-label'>
                                <h6>{i18n.t('reportsPage.nameTypes')}</h6>
                            </Label>
                            <Select
                                className='custom-select-wrapper'
                                classNamePrefix='custom-select'
                                isSearchable={false}
                                blurInputOnSelect
                                onChange={this.handleFilterChange.bind(this, 'unnamed')}
                                options={nameTypesOptions}
                                defaultValue={nameTypesOptions[0]}
                                value={this.props.store!.reportsUIStore.selectedUnnamedOption}
                                components={{ ClearIndicator, DropdownIndicator, Option }}
                            />
                        </FormGroup>
                        <div className='search-filter-button'>
                            <FormGroup className='custom-form-group custom-form-group-inline custom-search custom-search-large reports-search'>
                                <img src={searchIcon} alt='Search' className='custom-search-icon' />
                                <Input
                                    onChange={this.handleSearchQueryChange}
                                    type='search'
                                    placeholder={i18n.t('actions.search')}
                                    autoComplete='off'
                                />
                            </FormGroup>
                        </div>
                    </Form>
                </div>
            </React.Fragment>
        )
    }
}

export default withNamespaces()(ReportFilters)
