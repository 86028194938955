import { observable, computed } from 'mobx'
import { Omit } from 'react-router'

import BaseModel from './BaseModel'
import Tile from './Tile'
import ScreenConfiguration from './ScreenConfiguration'

class Section extends BaseModel {
    @observable tileList: Tile[] = []
    @observable width: number
    @observable height: number
    @observable tileType: string
    @observable adapter: string
    @observable adapterFriendlyName: string
    @observable position: number
    @observable wiring: string
    @observable detectHoles: boolean
    @observable frameRate: string
    @observable scrapeX: number
    @observable scrapeY: number
    @observable name: string
    @observable mapFile: string
    @observable isLogoBox: boolean
    @observable screenConfiguration: ScreenConfiguration
    @observable tileWidth: number
    @observable tileHeight: number

    constructor(json: SectionJSON, config: ScreenConfiguration) {
        super(json)

        this.screenConfiguration = config
        for (const tileJson of json.tileList) {
            this.tileList.push(new Tile(tileJson, this))
        }
        this.width = json.width
        this.height = json.height
        this.tileType = json.tileType
        this.adapter = json.adapter
        this.adapterFriendlyName = json.adapterFriendlyName
        this.position = json.position
        this.wiring = json.wiring
        this.detectHoles = json.detectHoles
        this.frameRate = json.frameRate
        this.scrapeX = json.scrapeX
        this.scrapeY = json.scrapeY
        this.name = json.name
        this.mapFile = json.mapFile
        this.isLogoBox = json.isLogoBox
        this.tileWidth = json.tileWidth
        this.tileHeight = json.tileHeight
    }

    @computed get size(): number {
        return this.width * this.height
    }

    @computed get sizeString(): string {
        return `${this.tileWidth}x${this.tileHeight}`
    }

    @computed get minScrapeX(): number {
        if (this.isLogoBox) {
            return this.screenConfiguration.logoMinScrapeX
        }
        return this.screenConfiguration.minScrapeX
    }

    @computed get minScrapeY(): number {
        if (this.isLogoBox) {
            return this.screenConfiguration.logoMinScrapeY
        }
        return this.screenConfiguration.minScrapeY
    }

    get tileListForRender(): Array<Tile | undefined> {
        let tileList: Array<Tile | undefined> = this.tileList
        const tileListLength = tileList.length
        if (tileListLength < this.size) {
            tileList = []
            let i = 0
            let currentX = 0
            let currentY = 0
            while (currentY !== this.height) {
                if (i < tileListLength && this.tileList[i].tileX === currentX && this.tileList[i].tileY === currentY) {
                    tileList.push(this.tileList[i])
                    i++
                } else {
                    tileList.push(undefined)
                }
                currentX++
                if (currentX === this.width) {
                    currentX = 0
                    currentY++
                }
            }
        }
        return tileList
    }
}

export type SectionJSON = Omit<Section, 'toJSON' | 'isValid'>

export default Section
