import { observable, computed } from 'mobx'
import { Omit } from 'react-router'

import { rootStore } from '../RootStore'

import moment from 'moment'
import * as math from 'mathjs'

export interface MediaFileData {
    name: string
    width: number
    height: number
}

export enum VideoFormat {
    mp4 = 'MP4',
    mov = 'MOV',
    wmv = 'WMV',
    avi = 'AVI',
    mkv = 'MKV',
}

class Media {
    static newMedia(): Media {
        return new Media({
            name: '',
            size: 0,
            lastModified: moment(),
            signedGetURL: '',
            width: 0,
            height: 0,
            index: 0,
        })
    }

    static async newMediaFromFile(file: File, index: number, screenId: string): Promise<Media> {
        function getResolution(file: File): Promise<{ width: number; height: number }> {
            return new Promise((resolve, reject) => {
                let width = 0
                let height = 0
                const isImageFile = file.type.startsWith('image/')
                const isVideoFile = file.type.startsWith('video/')

                if (isImageFile) {
                    const img = document.createElement('img')
                    img.onload = () => {
                        window.URL.revokeObjectURL(img.src)
                        width = img.width
                        height = img.height
                        resolve({ width, height })
                    }
                    img.src = URL.createObjectURL(file)
                } else if (isVideoFile) {
                    const video = document.createElement('video')
                    video.src = URL.createObjectURL(file)

                    // We can only get the video resolution if the browser can play the video
                    if (video.canPlayType(file.type) !== '') {
                        video.onloadedmetadata = () => {
                            window.URL.revokeObjectURL(video.src)
                            width = video.videoWidth
                            height = video.videoHeight
                            resolve({ width, height })
                        }
                    } else {
                        resolve({ width, height })
                    }
                } else {
                    reject(new Error('Unsupported media file type'))
                }
            })
        }

        const { width, height } = await getResolution(file)

        const media = new Media({
            name: file.name,
            size: file.size,
            lastModified: moment(),
            signedGetURL: '',
            width,
            height,
            index,
            file,
        })
        media.screenId = screenId

        return media
    }

    @observable name: string
    @observable size: number
    @observable lastModified: moment.Moment
    @observable signedGetURL: string
    @observable width: number
    @observable height: number
    @observable index: number
    @observable dwellTime?: number
    @observable group?: number
    @observable file?: File
    @observable screenId?: string
    @observable startDate?: moment.Moment
    @observable stopDate?: moment.Moment

    constructor(json: MediaJSON) {
        this.name = json.name
        this.size = json.size || 0
        this.lastModified = json.lastModified
        this.signedGetURL = json.signedGetURL || ''
        this.width = json.width || 0
        this.height = json.height || 0
        this.index = json.index
        this.dwellTime = json.dwellTime || undefined
        this.group = json.group || undefined
        this.file = json.file
        this.startDate = json.startDate ? moment(json.startDate) : undefined
        this.stopDate = json.stopDate ? moment(json.stopDate) : undefined
    }

    @computed get isPendingDelete(): boolean {
        return rootStore.mediaStore.getMediaManager(this.screenId!).removeScreenFilesSet.has(this)
    }

    @computed get isPendingUpload(): boolean {
        return rootStore.mediaStore.getMediaManager(this.screenId!).addScreenFilesSet.has(this)
    }

    @computed get hash(): string {
        return this.name + ':' + this.size
    }

    @computed get dimensions(): string {
        if (this.width === 0 || this.height === 0) {
            return ''
        }
        return this.width + 'x' + this.height
    }

    @computed get aspectRatio(): number {
        return this.width / this.height
    }

    @computed get aspectRatioString(): string {
        const divisor = math.gcd(this.width, this.height)
        return this.width / divisor + 'x' + this.height / divisor
    }

    @computed get isVideoFile(): boolean {
        const fileExtension = this.name
            ?.split('.')
            .pop()
            ?.toLowerCase()

        return Object.keys(VideoFormat).includes(fileExtension as VideoFormat)
    }

    fileData(): MediaFileData {
        return {
            name: this.name,
            width: this.width,
            height: this.height,
        }
    }

    toJSON(): MediaJSON {
        return {
            name: this.name,
            width: this.width,
            height: this.height,
            dwellTime: this.dwellTime,
            group: this.group,
            startDate: this.startDate,
            stopDate: this.stopDate,
        } as MediaJSON
    }
}

export type MediaJSON = Omit<
    Media,
    | 'fileData'
    | 'hash'
    | 'toJSON'
    | 'isPendingDelete'
    | 'isPendingUpload'
    | 'dimensions'
    | 'aspectRatio'
    | 'aspectRatioString'
    | 'isVideoFile'
>

export default Media
