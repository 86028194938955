import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { Colour } from 'src/common/utils/Colour'
import { copyToClipboard } from 'src/common/utils/Clipboard'

import { Tooltip, Position, Icon } from '@blueprintjs/core'
import { Button } from 'reactstrap'
import clipboardIcon from 'src/assets/images/clipboardIcon.svg'

interface CopyToClipboardProps {
    text: string
    label: string
}

@inject('store')
@observer
class CopyToClipboard extends React.Component<{ store?: RootStore } & CopyToClipboardProps> {
    state = { isCopied: false }

    handleCopy = (event: any) => {
        event.stopPropagation()
        copyToClipboard(this.props.text)
        this.setState({ isCopied: true })
    }

    handleTooltipClose = () => {
        this.setState({ isCopied: false })
    }

    render() {
        return (
            <Tooltip
                boundary='viewport'
                content={
                    <div>
                        {!this.state.isCopied ? (
                            <h4>Copy {this.props.label} to clipboard</h4>
                        ) : (
                            <div className='id-copied-success'>
                                <h4>
                                    <Icon className='mr-1' icon='tick' iconSize={14} color={Colour.white} />
                                </h4>
                                <h4>Copied to clipboard</h4>
                            </div>
                        )}
                        <h5 className='mb-0'>{this.props.text}</h5>
                    </div>
                }
                position={Position.TOP}
                onClosed={this.handleTooltipClose}
            >
                <Button className='custom-button-secondary table-action' onClick={this.handleCopy}>
                    <img src={clipboardIcon} alt='Copy to clipboard' height={12} />
                </Button>
            </Tooltip>
        )
    }
}

export default CopyToClipboard
