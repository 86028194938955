import RootStore from 'src/common/RootStore'

import { observable, action, computed } from 'mobx'

import PopoutManager from '../managers/PopoutManager'
import BaseModel from '../models/BaseModel'

export default abstract class PopoutStore<T extends PopoutManager, U extends BaseModel | undefined> {
    @observable managerMap = new Map<string, T>()
    @observable currentDrawerId?: string

    rootStore: RootStore

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @computed get currentDrawerManager(): T | undefined {
        if (!this.currentDrawerId) {
            return undefined
        }
        return this.managerMap.get(this.currentDrawerId)
    }

    @computed get openPopoutMap(): Map<string, T> {
        const openPopouts = new Map<string, T>()
        this.managerMap.forEach((v, k) => {
            if (v.isPopout) {
                openPopouts.set(k, v)
            }
        })
        return openPopouts
    }

    @action getManager = (key: string, object?: U) => {
        let manager = this.managerMap.get(key)
        if (!manager) {
            const item = object || key
            manager = this.newManager(item)
            this.managerMap.set(key, manager)
        }
        return manager
    }

    @action launchWindow = (managerId: string, forceMobileDrawer: boolean = false) => {
        const manager = this.getManager(managerId)

        if (forceMobileDrawer && this.rootStore.appUIStore.isMobile) {
            this.currentDrawerId = managerId
            manager.toggleDrawer()
        } else {
            if (manager.isPopout) {
                // Focus popout if already opened
                manager.focusPopout()
            } else {
                this.currentDrawerId = undefined
                // Open popout
                manager.togglePopout()
            }
        }
    }

    @action clearOpenDrawer = (): void | undefined => {
        this.currentDrawerManager?.removeManager()
    }

    protected abstract newManager(object: U | string): T
}
