import * as React from 'react'

import { inject, observer } from 'mobx-react'

import RootStore from '../../../common/RootStore'

import i18n from 'src/i18n'

import { AdminTabName } from '../container'
import { AdminTableRowData } from '../models/AdminTabData'

import matchSorter from 'match-sorter'
import { AutoSizer, SortDirection } from 'react-virtualized'
import ActionCell from './tableComponents/ActionCell'
import BaseVirtualizedMultiGrid, {
    BaseVirtualizedMultiGridCol,
} from 'src/common/components/virtualized/BaseVirtualizedMultiGrid'
import SelectCell from 'src/common/components/virtualized/components/SelectCell'
import AccessCell from './tableComponents/AccessCell'
import { Manufacturer } from 'src/common/models/Screen'
import { AdminBatchAction } from 'src/common/stores/AdminStore'
import { lt } from 'semver'

interface AdminTableProps {
    tabName: AdminTabName
}

const DEFAULT_ROW_HEIGHT = 40
const DEFAULT_HEADER_ROW_HEIGHT = 26

@inject('store')
@observer
class AdminTable extends React.Component<AdminTableProps & { store?: RootStore }> {
    adminTableRef: any

    componentDidUpdate(prevProps: AdminTableProps) {
        if (this.props.tabName !== prevProps.tabName) {
            // Reset scroll position on tab change
            this.adminTableRef?.handleScrollToRow(0)
            this.adminTableRef?.handleScrollToColumn(0)

            // Reset sort on tab change
            this.adminTableRef?.handleResetSort()

            // Clear selected rows on tab change
            this.props.store!.adminUIStore.clearSelectedRowIds()
        }
    }

    handleRowSelect = (itemId: string, isChecked: boolean) => {
        const selectedRowIds = new Set(this.props.store!.adminUIStore.selectedItemIds)

        if (isChecked) {
            selectedRowIds.add(itemId)
        } else {
            selectedRowIds.delete(itemId)
        }

        this.props.store!.adminUIStore.updateSelectedRowIds(selectedRowIds)
    }

    getIsRowSelected = (rowData: any): boolean => {
        if (!rowData || !rowData.id) {
            return false
        }

        const selectedRowIds = this.props.store!.adminUIStore.selectedItemIds
        if (!selectedRowIds) {
            return false
        }

        return selectedRowIds.has(rowData.id)
    }

    getIsRowDeleted = (rowData: any): boolean => {
        if (!rowData) {
            return false
        }
        return rowData.deletedAt !== null
    }

    getIsRowDisabled = (rowData: any): boolean => {
        if (!rowData) {
            return false
        }

        switch (this.props.tabName) {
            case AdminTabName.screens:
                const screen = this.props.store!.screenStore.findItem(rowData.id)
                return !screen?.connectAvailable
            default:
                return false
        }
    }

    getIsRowSelectDisabled = (rowData: any): boolean => {
        if (!rowData) {
            return false
        }

        switch (this.props.tabName) {
            case AdminTabName.screens:
                const screen = this.props.store!.screenStore.findItem(rowData.id)
                let wrongManufacturer = false
                let oldVersion = false
                if (
                    this.props.store!.adminUIStore.batchActionValue &&
                    this.props.store!.adminUIStore.batchActionValue !== AdminBatchAction.connect &&
                    (!screen?.comptroller?.connectVersion || lt(screen.comptroller.connectVersion, '2.0.4'))
                ) {
                    oldVersion = true
                }
                if (
                    screen?.manufacturer === Manufacturer.novaStar &&
                    this.props.store!.adminUIStore.batchActionValue === AdminBatchAction.lednet
                ) {
                    wrongManufacturer = true
                } else if (
                    screen?.manufacturer === Manufacturer.candelic &&
                    this.props.store!.adminUIStore.batchActionValue === AdminBatchAction.pcap
                ) {
                    wrongManufacturer = true
                }
                return !screen?.updaterAvailable || wrongManufacturer || oldVersion
            default:
                return false
        }
    }

    getRowSelectDisabledMessage = (rowData: any): string | undefined => {
        if (!rowData) {
            return
        }

        switch (this.props.tabName) {
            case AdminTabName.screens:
                const screen = this.props.store!.screenStore.findItem(rowData.id)
                const screenMessage = screen?.updaterNotAvailableMessage
                if (screenMessage) {
                    return screenMessage
                } else if (
                    this.props.store!.adminUIStore.batchActionValue &&
                    this.props.store!.adminUIStore.batchActionValue !== AdminBatchAction.connect &&
                    (!screen?.comptroller?.connectVersion || lt(screen.comptroller.connectVersion, '2.0.4'))
                ) {
                    return 'Connect version must be 2.0.4 or higher to update this software'
                } else if (
                    screen?.manufacturer === Manufacturer.novaStar &&
                    this.props.store!.adminUIStore.batchActionValue === AdminBatchAction.lednet
                ) {
                    return 'NovaStar screens do not support LEDNet updates'
                } else if (
                    screen?.manufacturer === Manufacturer.candelic &&
                    this.props.store!.adminUIStore.batchActionValue === AdminBatchAction.pcap
                ) {
                    return 'Candelic screens do not support USBPcap updates'
                }
                return undefined
            default:
                return undefined
        }
    }

    noContentRenderer = (): JSX.Element => (
        <div className='bvt-no-results'>
            <h2>No results</h2>
        </div>
    )

    render() {
        const me = this.props.store!.userStore.me
        const adminUIStore = this.props.store!.adminUIStore

        const tabName = this.props.tabName
        const tabData = adminUIStore.tabData
        if (!me || !tabData) {
            return null
        }

        let adminList: AdminTableRowData[] | Array<Record<string, any>> = tabData.data.map(row => row.values)
        const searchQueryValue = adminUIStore.searchQueryValue
        if (searchQueryValue) {
            // Use match sorter to filter against dataKeys
            adminList = matchSorter(adminList, searchQueryValue, {
                keys: [
                    {
                        threshold: matchSorter.rankings.EQUAL,
                        key: 'id',
                    },
                    ...tabData.columnLabels,
                    {
                        threshold: matchSorter.rankings.STARTS_WITH,
                        key: (item: { labels: Array<{ key: string; value: string }> }) => {
                            if (item.labels) {
                                return item.labels.map((l: { key: string; value: string }) => l.value)
                            }
                            return []
                        },
                    },
                ],
            })
        }

        const isAdminLoading = !this.props.store!.appStore.listsComplete

        let screenStates: boolean[] = []
        switch (tabName) {
            case AdminTabName.screens:
                screenStates = this.props.store!.screenStore.items.map(screen => screen.connectAvailable)
                break
            default:
                break
        }

        let enableBatchActions = false
        if (me.isSuperUser) {
            // SuperUser only until fully tested
            enableBatchActions = tabName === AdminTabName.screens
        }
        const selectedRowIds = adminUIStore.selectedItemIds

        const data = {
            adminList,
            screenStates,
            selectedRowIds: Array.from(selectedRowIds),
        }

        const cols: BaseVirtualizedMultiGridCol[] = tabData.columns.map(column => ({
            dataKey: column.label,
            label: i18n.t('adminPage.tableHeaders.' + column.label, {
                defaultValue: column.label,
            }),
            width: column.width,
            minWidth: column.minWidth,
            disableSort: column.disableSort,
            cellElement: (cellData: any, rowData: any) => (
                <div
                    className={
                        this.getIsRowDeleted(rowData)
                            ? 'deleted-cell'
                            : this.getIsRowDisabled(rowData)
                            ? 'disabled-cell'
                            : ''
                    }
                >
                    {String(cellData)}
                </div>
            ),
        }))

        if (tabName === AdminTabName.users) {
            cols.push({
                dataKey: 'access',
                label: 'Access',
                cellElement: (cellData: any, rowData: any) => {
                    const deleted = this.getIsRowDeleted(rowData)
                    return (
                        <AccessCell
                            className={deleted ? 'deleted-cell' : this.getIsRowDisabled(rowData) ? 'disabled-cell' : ''}
                            tabName={tabName}
                            tabData={tabData}
                            cellData={cellData}
                            rowData={rowData}
                            deleted={deleted}
                        />
                    )
                },
                width: 180,
                disableSort: true,
                disableTitle: true,
            })
        }

        cols.push({
            dataKey: 'action',
            label: 'Action',
            cellElement: (cellData: any, rowData: any) => (
                <ActionCell tabName={tabName} tabData={tabData} cellData={cellData} rowData={rowData} />
            ),
            width: tabName === AdminTabName.screens ? 140 : 180,
            disableSort: true,
            disableTitle: true,
        })

        if (enableBatchActions) {
            cols.unshift({
                dataKey: 'select',
                label: '',
                cellElement: (cellData: any, rowData: any) => (
                    <SelectCell
                        rowData={rowData}
                        isSelected={this.getIsRowSelected(rowData)}
                        isDisabled={this.getIsRowSelectDisabled(rowData) || this.getIsRowDeleted(rowData)}
                        rowDisabledMessage={this.getRowSelectDisabledMessage(rowData)}
                        onSelect={this.handleRowSelect}
                    />
                ),
                width: 50,
                disableSort: true,
                disableTitle: true,
            })
        }

        let rows: AdminTableRowData[] | Array<Record<string, any>>
        if (!isAdminLoading && adminList) {
            rows = adminList
        } else {
            // Generate some blank rows while data is fetching
            const blankRowAmount = Math.floor((window.innerHeight - 280) / DEFAULT_ROW_HEIGHT)
            rows =
                blankRowAmount >= 1
                    ? new Array(blankRowAmount)
                          // Use column names as loading placeholder
                          .fill(cols.map(col => ({ col: String(col) })))
                    : []
        }

        return (
            <div className='admin-table'>
                <AutoSizer>
                    {({ width, height }) => (
                        <BaseVirtualizedMultiGrid
                            ref={instance => (this.adminTableRef = instance)}
                            cols={cols}
                            rows={rows}
                            width={width}
                            height={height}
                            rowHeight={DEFAULT_ROW_HEIGHT}
                            headerHeight={DEFAULT_HEADER_ROW_HEIGHT}
                            overscanColumnCount={cols.length}
                            data={data}
                            includeHeaders
                            enableBatchActions={enableBatchActions}
                            isLoading={isAdminLoading}
                            isSearching={!!searchQueryValue}
                            sortBy={cols.find(col => col.dataKey === 'name') ? 'name' : 'firstName'}
                            sortDirection={SortDirection.ASC}
                            noContentRenderer={this.noContentRenderer}
                        />
                    )}
                </AutoSizer>
            </div>
        )
    }
}

export default AdminTable
