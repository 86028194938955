import { observable } from 'mobx'
import moment from 'moment'
import { CameraHistoryMode } from '../stores/CameraStore'

class DisplayRecord {
    static newDisplayRecord(): DisplayRecord {
        return new DisplayRecord({
            id: '',
            displayHashId: '',
            displayImageId: '',
            screenId: '',
            startTime: moment(),
            stopTime: moment(),
            gapTime: 0,
            hash: '',
            hashErrors: 0,
            missingTiles: 0,
            hammingDistance: 0,
            base64Image: '',
            url: '',
            filename: '',
        })
    }

    @observable id: string
    @observable displayHashId: string
    @observable displayImageId: string
    @observable screenId: string
    @observable startTime: moment.Moment
    @observable stopTime: moment.Moment
    @observable gapTime: number
    @observable hash: string
    @observable hashErrors: number
    @observable missingTiles: number
    @observable hammingDistance: number
    @observable base64Image?: string
    @observable url?: string
    @observable filename?: string

    constructor(json: DisplayRecordJSON) {
        this.id = json.id
        this.displayHashId = json.displayHashId
        this.displayImageId = json.displayImageId
        this.screenId = json.screenId
        this.startTime = moment(json.startTime)
        this.stopTime = moment(json.stopTime)
        this.gapTime = json.gapTime
        this.hash = json.hash
        this.hashErrors = json.hashErrors
        this.missingTiles = json.missingTiles
        this.hammingDistance = json.hammingDistance
        this.base64Image = json.base64Image
        this.url = json.base64Image ? 'data:image/jpeg;base64,' + json.base64Image : ''
        this.filename = json.filename
    }
}

export type DisplayRecordJSON = DisplayRecord

export interface RandomParams {
    organisationId?: string
    startDate?: moment.Moment
    stopDate?: moment.Moment
    limit?: number
    displayImageIds?: string[]
}

export interface TimestampParams {
    organisationId?: string
    timestamp?: moment.Moment
    mode?: CameraHistoryMode
}

export default DisplayRecord
