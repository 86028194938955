import onAPI from './onAPI'

class CameraRouter {
    protected route = '/camera'

    async subscribeToCameraFeed(screenId: string, subscriptionKey?: string) {
        const json: any = await onAPI.fetchJSON('/screen/' + screenId + this.route + '/subscribe', {
            subscriptionKey,
        })
        return json
    }

    async frameSelectMulti(frames: number[]) {
        const json: any = await onAPI.fetchJSONWithQueryString(this.route + '/frame-select-multi', { frames })
        return json
    }
}

const cameraRouter = new CameraRouter()
export default cameraRouter
