import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import Media from 'src/common/models/Media'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

import duplicateIcon from 'src/assets/images/duplicateIcon.svg'
import { Button } from 'reactstrap'
import { Tooltip, Position } from '@blueprintjs/core'
import _ from 'lodash'

interface DuplicateCellProps {
    media: Media
    screenId: string
    playerDiv?: HTMLElement
}

@inject('store')
@observer
class DuplicateCell extends React.Component<{ store?: RootStore } & DuplicateCellProps> {
    handleDuplicate = (media: Media) => {
        if (!this.props.screenId) {
            return
        }

        this.props.store!.mediaStore.getMediaManager(this.props.screenId).addDuplicate(_.cloneDeep(media))
    }

    render() {
        const media = this.props.media

        if (!media) {
            return null
        }

        return (
            <Tooltip
                boundary='viewport'
                content='Duplicate'
                hoverOpenDelay={300}
                position={Position.TOP}
                portalContainer={this.props.playerDiv}
            >
                <Button
                    className='table-action-lg custom-button-secondary'
                    onClick={this.handleDuplicate.bind(this, media)}
                >
                    <img
                        src={duplicateIcon}
                        alt='Duplicate'
                        height={14}
                        draggable={false}
                        onDragStart={eventPreventDefault}
                    />
                </Button>
            </Tooltip>
        )
    }
}

export default DuplicateCell
