import * as React from 'react'
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { AdminTabName } from '../container'
import OrgForm from './panelForms/OrgForm'
import UserForm from './panelForms/UserForm'
import ScreenForm from './panelForms/ScreenForm'

export enum AdminPanelName {
    organisationDetails = 'organisationDetails',
    userDetails = 'userDetails',
    screenDetails = 'screenDetails',
}

interface AdminPanelFormProps {
    activeTab: AdminTabName
}

@inject('store')
@observer
export default class AdminPanelForm extends React.Component<AdminPanelFormProps & { store?: RootStore }> {
    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return
        }

        let form
        switch (this.props.activeTab) {
            case AdminTabName.organisations:
                form = <OrgForm />
                break
            case AdminTabName.users:
                form = <UserForm />
                break
            case AdminTabName.screens:
                form = <ScreenForm />
                break
        }

        return (
            <div className='custom-panel'>
                {this.props.store!.orgStore.hasMultipleOpsOrganisations &&
                    this.props.activeTab !== AdminTabName.organisations && (
                        <div className='custom-panel-selected-org'>
                            <h2>{this.props.store!.adminUIStore.selectedOrg?.name}</h2>
                        </div>
                    )}
                {form}
            </div>
        )
    }
}
