import * as React from 'react'

import i18n from 'src/i18n'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { Dialog, Drawer, Icon, Intent } from '@blueprintjs/core'
import LiveFilters from './components/LiveFilters'
import LiveGrid from './components/LiveGrid'
import ImageStats from './submodules/image-stats/container'
import CameraFeed from './submodules/camera-feed/container'
import { RoleType } from 'src/common/models/Roles'

@inject('store')
@observer
class Live extends React.Component<{ store?: RootStore }> {
    componentWillUnmount() {
        this.props.store!.liveUIStore.unsubscribe()
    }

    render() {
        const liveUIStore = this.props.store!.liveUIStore
        const isMobile = this.props.store!.appUIStore.isMobile
        const userStore = this.props.store!.userStore

        if (!userStore.me?.roles?.includes(RoleType.contentUser)) {
            return (
                <div className='non-ideal-state'>
                    <Icon icon='warning-sign' iconSize={54} intent={Intent.DANGER} />
                    <h2>{i18n.t('livePage.noLive')}</h2>
                </div>
            )
        }

        return (
            <React.Fragment>
                <LiveFilters />
                <LiveGrid />
                {/* Image stats */}
                <Drawer
                    isOpen={liveUIStore.isStatsOpen}
                    size={!isMobile ? '800px' : '100%'}
                    portalClassName='custom-drawer custom-drawer-with-nav'
                    onClose={() => liveUIStore.setIsStatsOpen(false)}
                >
                    <ImageStats store={this.props.store} />
                </Drawer>
                {/* Camera feed */}
                <Dialog
                    className='custom-dialog'
                    portalClassName='custom-dialog-xl'
                    title='Camera'
                    isOpen={liveUIStore.isCameraOpen}
                    onClose={() => {
                        liveUIStore.setIsCameraOpen(false)
                    }}
                >
                    <CameraFeed store={this.props.store} />
                </Dialog>
            </React.Fragment>
        )
    }
}

export default Live
