import * as React from 'react'
import { observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import imageBroken from 'src/assets/images/imageBroken.svg'
import { Col, Nav, NavItem, Row, TabContent, TabPane, Button, Form, InputGroup, Label, Alert } from 'reactstrap'
import LoadingSpinner from 'src/common/components/LoadingSpinner'
import Timer from 'src/common/components/Timer'
import DateInput from 'src/common/components/DateInput'

import { eventPreventDefault } from 'src/common/utils/EventUtils'
import moment from 'moment'
import { CameraHistoryMode } from 'src/common/stores/CameraStore'
import DisplayRecord from 'src/common/models/DisplayRecord'

interface CameraFeedProps {
    store?: RootStore
}

function CameraFeed(props: CameraFeedProps) {
    const { store } = props

    const [currentTab, setCurrentTab] = React.useState('live')
    const [cameraHistoryRecord, setCameraHistoryRecord] = React.useState<DisplayRecord>()
    const [requestedNearest, setRequestedNearest] = React.useState(false)
    const liveStore = store!.liveStore
    const liveUIStore = store!.liveUIStore
    const cameraStore = store!.cameraStore
    const feedExpired = cameraStore.feedExpired
    const screenId = liveUIStore.proofScreenId
    const me = store!.userStore.me

    // Clear camera history record when timestamp changes
    React.useEffect(() => {
        setCameraHistoryRecord(undefined)
    }, [cameraStore.timestamp])

    const handleImageResponseError = (event: any) => {
        event.target.onerror = null
        event.target.src = imageBroken
    }

    // Currently, there's only one queue of camera live images, no support for multiple screens
    const cameraLiveImage = cameraStore.cameraLiveImages.length > 0 && cameraStore.cameraLiveImages.slice(-1)[0]

    const loadedImages = liveStore.loadingImagesForGrid
    const latestProof =
        loadedImages.length > 0 ? loadedImages.find(image => image && image.screenId === screenId) : undefined

    const tabs = [
        {
            key: 'live',
            title: 'Live',
        },
        {
            key: 'history',
            title: 'History',
        },
    ]

    if (!screenId) {
        return null
    }

    const screen = store!.screenStore.findItem(screenId)

    if (!me) {
        return null
    }

    const requestHistory = async (mode: CameraHistoryMode) => {
        try {
            if (mode === CameraHistoryMode.nearest) {
                setRequestedNearest(true)
                if (!cameraStore.timestamp) {
                    return
                }
                setCameraHistoryRecord(await cameraStore.requestCameraTimestamp(screenId, cameraStore.timestamp, mode))
            } else {
                setRequestedNearest(false)
                if (!cameraHistoryRecord) {
                    return
                }
                setCameraHistoryRecord(
                    await cameraStore.requestCameraTimestamp(screenId, cameraHistoryRecord.startTime, mode)
                )
            }
        } catch (error) {
            console.error('Error requesting camera history', error)
        }
    }

    return (
        <div className='custom-dialog-content'>
            <div className='nav-tabs-container flex flex-col'>
                <Nav className='nav-tabs nav-tabs-light px-4 mb-0 w-full border-bottom' tabs>
                    {tabs.map(tab => (
                        <NavItem key={tab.key} onClick={() => setCurrentTab(tab.key)}>
                            <h3 className={'nav-link' + (currentTab === tab.key ? ' active' : '')}>
                                <span>{tab.title}</span>
                            </h3>
                        </NavItem>
                    ))}
                </Nav>
                <div className='nav-content-container w-full'>
                    <TabContent activeTab={currentTab}>
                        {/* Camera */}
                        <TabPane tabId='live'>
                            <Row className='w-full mr-0 ml-0'>
                                <Col xs={12} md={6} className='p-4 border-right'>
                                    <h3 className='text-black'>Live camera</h3>

                                    {screen?.connectAvailable ? (
                                        <>
                                            <div className='d-flex mt-2 justify-content-center'>
                                                {!feedExpired ? (
                                                    !cameraLiveImage ? (
                                                        <div className='mb-4'>
                                                            <LoadingSpinner dark />
                                                            <h4 className='text-black'>Waiting for camera feed...</h4>
                                                        </div>
                                                    ) : (
                                                        <img
                                                            className='w-full max-w-full h-auto camera-image'
                                                            src={cameraLiveImage ? cameraLiveImage.url : imageBroken}
                                                            onError={handleImageResponseError}
                                                            style={{
                                                                maxWidth: '100%',
                                                                maxHeight: '350px', // Ensure this matches the proof result max height
                                                                objectFit: 'contain',
                                                            }}
                                                        />
                                                    )
                                                ) : (
                                                    <div className='w-full'>
                                                        <Alert color='info'>
                                                            <h4 className='alert-heading'>Camera feed expired</h4>
                                                            <p>
                                                                Please refresh the feed to start receiving new images.
                                                            </p>
                                                        </Alert>
                                                        <Button
                                                            color='primary'
                                                            onClick={() => cameraStore.refreshCameraFeed()}
                                                        >
                                                            Refresh
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                            {!feedExpired && cameraStore.expiry && (
                                                <div className='d-flex align-middle mt-4'>
                                                    <Button color='primary' onClick={() => cameraStore.startTimer()}>
                                                        Reset timer
                                                    </Button>
                                                    <div className='ml-2'>
                                                        <Timer expiry={cameraStore.expiry} warning={30} />
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className='w-full'>
                                            <Alert color='warning'>
                                                <h4 className='alert-heading'>Screen not connected</h4>
                                                <p>
                                                    Please ensure the screen is connected to start receiving new images.
                                                </p>
                                            </Alert>
                                        </div>
                                    )}
                                </Col>

                                <Col xs={12} md={6} className='p-4'>
                                    <h3 className='text-black'>Proof Result</h3>

                                    <div className='d-flex mt-2 justify-content-center'>
                                        <img
                                            className='w-auto h-full max-w-full max-h-350px camera-image'
                                            src={latestProof ? latestProof.URL : imageBroken}
                                            onError={handleImageResponseError}
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '350px', // Ensure this matches the live camera max height
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </div>
                                    <span className='d-flex mt-4'>
                                        Filename: {latestProof ? latestProof.filename : 'Unknown'}
                                    </span>
                                </Col>
                            </Row>{' '}
                        </TabPane>

                        {/* History */}
                        <TabPane tabId='history'>
                            <div className='p-4 pb-5'>
                                <Form inline className='admin-form text-white' onSubmit={eventPreventDefault}>
                                    <InputGroup className='custom-input-group custom-input-group-inline custom-input-group-alt'>
                                        <Label className='custom-label custom-label-inline align-middle'>
                                            <h6 className='text-black'>Timestamp</h6>
                                        </Label>
                                        <DateInput
                                            handleChange={date => cameraStore.setTimestamp(moment(date))}
                                            maxDate={moment().endOf('day')}
                                        />
                                        <Button
                                            className='ml-2'
                                            color='primary'
                                            onClick={() => {
                                                requestHistory(CameraHistoryMode.nearest)
                                            }}
                                            disabled={!cameraStore.timestamp}
                                        >
                                            Request Image
                                        </Button>
                                    </InputGroup>
                                    <div className='custom-input-group custom-input-group-inline custom-input-group-alt'>
                                        <div className='custom-input-group custom-input-group-inline custom-input-group-alt'>
                                            <Button
                                                color='secondary'
                                                onClick={() => {
                                                    requestHistory(CameraHistoryMode.previousEqual)
                                                }}
                                                disabled={!cameraHistoryRecord}
                                            >
                                                Previous Match
                                            </Button>
                                            <Button
                                                color='primary'
                                                className='ml-2'
                                                onClick={() => {
                                                    requestHistory(CameraHistoryMode.previous)
                                                }}
                                                disabled={!cameraHistoryRecord}
                                            >
                                                Previous
                                            </Button>
                                            <Button
                                                color='primary'
                                                className='ml-4'
                                                onClick={() => {
                                                    requestHistory(CameraHistoryMode.next)
                                                }}
                                                disabled={!cameraHistoryRecord}
                                            >
                                                Next
                                            </Button>
                                            <Button
                                                color='secondary'
                                                className='ml-2'
                                                onClick={() => {
                                                    requestHistory(CameraHistoryMode.nextEqual)
                                                }}
                                                disabled={!cameraHistoryRecord}
                                            >
                                                Next Match
                                            </Button>
                                        </div>
                                    </div>
                                </Form>

                                <div className='d-flex m-5 justify-content-center' style={{ height: '350px' }}>
                                    {cameraHistoryRecord ? (
                                        cameraHistoryRecord.url ? (
                                            <img
                                                className='w-auto h-full max-w-full max-h-350px'
                                                src={cameraHistoryRecord.url}
                                                onError={handleImageResponseError}
                                            />
                                        ) : (
                                            <div className='w-full'>
                                                <Alert color='warning'>
                                                    <h4 className='alert-heading'>
                                                        No image available for this record
                                                    </h4>
                                                    <pre>{cameraHistoryRecord.hash}</pre>
                                                </Alert>
                                            </div>
                                        )
                                    ) : (
                                        <div>
                                            <h4 className='text-black'>
                                                Please select a timestamp and click Request Image
                                            </h4>
                                        </div>
                                    )}
                                </div>

                                {cameraHistoryRecord?.startTime && (
                                    <span className='d-flex mt-4'>
                                        {moment(cameraHistoryRecord.startTime).format(me.dateTimeFormat)}{' '}
                                        {moment.tz(me.timeZone).format('z')}
                                        {requestedNearest &&
                                            moment(cameraHistoryRecord.startTime).isAfter(cameraStore.timestamp) && (
                                                <span className='text-danger ml-2'>
                                                    This image is the closest image after the requested time
                                                </span>
                                            )}
                                    </span>
                                )}
                            </div>
                        </TabPane>
                    </TabContent>
                </div>
            </div>
        </div>
    )
}

export default observer(CameraFeed)
