import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import Screen from 'src/common/models/Screen'

import consoleIcon from 'src/assets/images/consoleIcon.svg'
import { Button } from 'reactstrap'

interface ConsoleButtonProps {
    currentScreen: Screen
    isMobile?: boolean
}

@inject('store')
@observer
class ConsoleButton extends React.Component<{ store?: RootStore } & ConsoleButtonProps> {
    render() {
        const { store, currentScreen, isMobile } = this.props

        const me = store!.userStore.me

        // Only show console button if user is superuser or has console access
        if (!me || (!me.isSuperUser && !me.hasConsoleAccess) || !currentScreen.id) {
            return null
        }

        const goToConsole = () => {
            store!.consoleStore.launchWindow(currentScreen.id!, true)
        }

        return (
            <Button
                onClick={goToConsole}
                className='custom-button-small mr-3 text-white'
                outline
                color='primary'
                disabled={!currentScreen.connectAvailable}
            >
                {!isMobile ? 'Console' : <img src={consoleIcon} alt='Console' height={14} />}
            </Button>
        )
    }
}

export default ConsoleButton
