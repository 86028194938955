import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import views from 'src/config/views'

import Section from 'src/common/models/Section'
import Tile from 'src/common/models/Tile'
import { LogoBoxLocation } from 'src/common/models/LogoBoxLocation'

import SectionTile from './components/SectionTile'
import TileViewControls from './components/TileViewControls'
import LogoBoxDialog from './components/LogoBoxDialog'
import DragScroll from 'react-dragscroll'
import Loader from 'src/common/components/Loader'
import { GridDimensions } from 'src/common/models/ScreenConfiguration'
import { ceil } from 'lodash'

@inject('store')
@observer
class TileView extends React.Component<{ store?: RootStore }> {
    componentDidMount() {
        const currentScreen = this.props.store!.screenDiagnosticsUIStore.currentScreen
        const screenConfiguration = this.props.store!.screenDiagnosticsUIStore.currentScreenConfiguration
        if (!currentScreen || !screenConfiguration) {
            return
        }

        // Check if screen configuration has a Logo Box, but doesn't have a Logo Box location set
        this.props.store!.tileViewUIStore.setLogoBoxDialog(
            screenConfiguration.hasLogoBox && !currentScreen.logoBoxLocation
        )
    }

    screenSection = (section: Section, zoomLevel: number, sectionIndex: number): JSX.Element | null => {
        const screenConfiguration = section.screenConfiguration
        const gridDimensions = screenConfiguration.gridDimensions(screenConfiguration.standardSections)
        const minScrapeX = screenConfiguration.minScrapeX
        const minScrapeY = screenConfiguration.minScrapeY

        if (!gridDimensions) {
            return null
        }
        const sections = screenConfiguration.standardSections
        const tileGap = gridDimensions.gap
        const sectionGap = this.props.store!.tileViewUIStore.sectionGapSize

        // Calculate the number of sections to the left and directly above the current section
        let sectionsToLeft = 0
        let sectionsAbove = 0
        let tilesToLeft = 0
        let tilesAbove = 0
        let maxScrapeY = 0
        let maxScrapeX = 0

        sections.forEach((otherSection, i) => {
            if (i < sectionIndex) {
                // Check if otherSection is directly to the left of the current section
                if (
                    otherSection.scrapeY < section.scrapeY + section.height &&
                    otherSection.scrapeY + otherSection.height > section.scrapeY
                ) {
                    if (otherSection.scrapeX + otherSection.width <= section.scrapeX) {
                        if (otherSection.scrapeX >= maxScrapeX) {
                            tilesToLeft += otherSection.width
                            sectionsToLeft += 1
                            maxScrapeX = otherSection.scrapeX + otherSection.width
                        }
                    }
                }
                // Check if otherSection is directly above the current section
                if (
                    otherSection.scrapeX < section.scrapeX + section.width &&
                    otherSection.scrapeX + otherSection.width > section.scrapeX
                ) {
                    if (otherSection.scrapeY + otherSection.height <= section.scrapeY) {
                        if (otherSection.scrapeY >= maxScrapeY) {
                            tilesAbove += otherSection.height
                            sectionsAbove += 1
                            maxScrapeY = otherSection.scrapeY + otherSection.height
                        }
                    }
                }
            }
        })

        const top = (section.scrapeY - minScrapeY) * zoomLevel + tilesAbove * tileGap + sectionsAbove * sectionGap
        const left = (section.scrapeX - minScrapeX) * zoomLevel + tilesToLeft * tileGap + sectionsToLeft * sectionGap
        return (
            <div
                key={section.id}
                className='section-grid'
                style={{
                    display: 'grid',
                    gridTemplateRows: `repeat(${section.height}, ${section.tileHeight * zoomLevel}px)`,
                    gridTemplateColumns: `repeat(${section.width}, ${section.tileWidth * zoomLevel}px)`,
                    gap: `${tileGap}px`,
                    position: 'absolute',
                    top: `${top}px`,
                    left: `${left}px`,
                }}
            >
                {section.tileListForRender.map((tile, i) => (
                    <SectionTile
                        key={i}
                        index={i}
                        tile={tile}
                        width={section.tileWidth * zoomLevel}
                        height={section.tileHeight * zoomLevel}
                        handleTileClick={this.handleTileClick}
                        className={this.props.store!.tileViewUIStore.isWaitingForStat ? 'bp3-skeleton' : undefined}
                    />
                ))}
            </div>
        )
    }

    logoSection = (section: Section, zoomLevel: number, sectionIndex: number): JSX.Element | null => {
        const screenConfiguration = section.screenConfiguration
        const gridDimensions = screenConfiguration.gridDimensions(screenConfiguration.logoBoxSections)

        if (!gridDimensions) {
            return null
        }
        const tileGap = gridDimensions.gap

        return (
            <div
                key={section.id}
                className='section-grid'
                style={{
                    gridTemplateRows: 'repeat(' + section.height + ', auto)',
                    gridTemplateColumns: 'repeat(' + section.width + ', auto)',
                    gap: tileGap + 'px',
                    gridArea: gridDimensions.gridArea.flat()[sectionIndex],
                }}
            >
                {section.tileListForRender.map((tile, i) => (
                    <SectionTile
                        key={i}
                        index={i}
                        tile={tile}
                        width={section.tileWidth * zoomLevel}
                        height={section.tileHeight * zoomLevel}
                        handleTileClick={this.handleTileClick}
                        className={this.props.store!.tileViewUIStore.isWaitingForStat ? ' bp3-skeleton' : undefined}
                    />
                ))}
            </div>
        )
    }

    handleTileClick = (tile: Tile) => {
        const router = this.props.store!.router
        router.goTo(views.diagnosticsScreen, router.params, this.props.store!, {
            tileView: true,
            tileHistory: tile?.id,
        })
    }

    maxWidthAndHeight = (
        dimensions: GridDimensions,
        sections: Section[],
        zoomLevel: number,
        sectionsWide: number,
        sectionsHigh: number,
        sectionGap: number
    ): [number, number] => {
        let maxWidth = 0
        let maxHeight = 0
        let calculatedWidth = 0
        let calculatedHeight = 0
        sections.forEach(section => {
            const sectionRight = section.scrapeX - section.minScrapeX + section.width * section.tileWidth
            const sectionBottom = section.scrapeY - section.minScrapeY + section.height * section.tileHeight
            if (sectionRight > calculatedWidth) {
                calculatedWidth = sectionRight
                maxWidth +=
                    section.width * ceil(section.tileWidth * zoomLevel) + section.width /* - 1 */ * dimensions.gap
            }
            if (sectionBottom > calculatedHeight) {
                calculatedHeight = sectionBottom
                maxHeight +=
                    section.height * ceil(section.tileHeight * zoomLevel) + section.height /* - 1 */ * dimensions.gap
            }
        })
        maxWidth += (sectionsWide - 1) * sectionGap
        maxHeight += (sectionsHigh - 1) * sectionGap
        return [maxWidth, maxHeight]
    }

    render() {
        const currentScreen = this.props.store!.screenDiagnosticsUIStore.currentScreen
        const screenConfiguration = this.props.store!.screenDiagnosticsUIStore.currentScreenConfiguration
        if (!currentScreen || !screenConfiguration) {
            return <Loader fullscreen />
        }

        const standardSectionsGrid = screenConfiguration.gridDimensions(screenConfiguration.standardSections)
        if (!standardSectionsGrid) {
            return <h2>No screen sections found.</h2>
        }
        const logoSectionGrid = screenConfiguration.gridDimensions(screenConfiguration.logoBoxSections)

        const zoomLevel = this.props.store!.tileViewUIStore.zoomLevel
        const logoBoxLocation = currentScreen.logoBoxLocation
        const sectionGap = this.props.store!.tileViewUIStore.sectionGapSize
        const [screenWidth, screenHeight] = this.maxWidthAndHeight(
            standardSectionsGrid,
            screenConfiguration.standardSections,
            zoomLevel,
            screenConfiguration.sectionsWide,
            screenConfiguration.sectionsHigh,
            sectionGap
        )
        const [logoWidth, logoHeight] = logoSectionGrid
            ? this.maxWidthAndHeight(
                  logoSectionGrid,
                  screenConfiguration.logoBoxSections,
                  zoomLevel,
                  screenConfiguration.logoBoxSectionsWide,
                  screenConfiguration.logoBoxSectionsHigh,
                  0
              )
            : [0, 0]
        return (
            <React.Fragment>
                <div className='tile-view custom-scrollbars'>
                    <TileViewControls />
                    {/* Draggable area */}
                    <DragScroll height='100%' width='100%'>
                        {/* Keeps the screen wrapper centered when zooming */}
                        <div
                            className='centre-zoom-wrapper'
                            style={{
                                width: screenWidth + 'px',
                            }}
                        >
                            {/* Use standard sections grid dimensions to display logo box relative to main part of screen */}
                            <div
                                className='screen-wrapper'
                                style={{
                                    flexDirection:
                                        logoBoxLocation === LogoBoxLocation.topLeft ||
                                        logoBoxLocation === LogoBoxLocation.topCentre ||
                                        logoBoxLocation === LogoBoxLocation.topRight
                                            ? 'column-reverse'
                                            : 'column',
                                    width: screenWidth + 'px',
                                }}
                            >
                                <div
                                    className='screen-grid'
                                    style={{
                                        width: screenWidth,
                                        height: screenHeight,
                                        position: 'relative',
                                        margin: 'auto',
                                    }}
                                >
                                    {screenConfiguration.standardSections.map((section, i) =>
                                        this.screenSection(section, zoomLevel, i)
                                    )}
                                </div>
                                {logoSectionGrid && (
                                    <div
                                        className='screen-grid logo-box-grid'
                                        style={{
                                            columnGap: Math.max(sectionGap, 1) + 'px',
                                            width: logoWidth,
                                            height: logoHeight,
                                            marginLeft:
                                                logoBoxLocation === LogoBoxLocation.topLeft ||
                                                logoBoxLocation === LogoBoxLocation.bottomLeft
                                                    ? 0
                                                    : 'auto',
                                            marginRight:
                                                logoBoxLocation === LogoBoxLocation.topRight ||
                                                logoBoxLocation === LogoBoxLocation.bottomRight
                                                    ? 0
                                                    : 'auto',
                                        }}
                                    >
                                        {screenConfiguration.logoBoxSections.map((section, i) =>
                                            this.logoSection(section, zoomLevel, i)
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </DragScroll>
                </div>
                <LogoBoxDialog />
            </React.Fragment>
        )
    }
}

export default TileView
