import * as React from 'react'

import { DateInput as BP3DateInput } from '@blueprintjs/datetime'
import moment from 'moment'

interface DateInputProps {
    handleChange: (date: Date) => void
    minDate?: moment.Moment
    maxDate?: moment.Moment
}

const format = 'DD/MM/YYYY, HH:mm:ss a'

class DateInput extends React.Component<DateInputProps> {
    render() {
        const { handleChange, minDate, maxDate } = this.props

        return (
            <BP3DateInput
                formatDate={date => moment(date).format(format)}
                parseDate={str => moment(str, format).toDate()}
                placeholder={format}
                timePickerProps={{
                    useAmPm: true,
                    showArrowButtons: true,
                }}
                timePrecision='second'
                onChange={handleChange}
                minDate={minDate ? minDate.toDate() : undefined}
                maxDate={maxDate ? maxDate.toDate() : undefined}
            />
        )
    }
}

export default DateInput
