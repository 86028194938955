import BaseModel from 'src/common/models/BaseModel'
import onAPI, { HTTPMethod } from './onAPI'

export enum RouterFlag {
    create = 1 << 0,
    read = 1 << 1,
    update = 1 << 2,
    delete = 1 << 3,
    list = 1 << 4,
    search = 1 << 5,
    restore = 1 << 6,
    permanentlyDelete = 1 << 7,
}

export interface EmptySearchParams {}

export interface LimitOffsetParams {
    limit?: number
    offset?: number
}

export default abstract class BaseRouter<T extends BaseModel, JSON, SearchParams> {
    protected abstract route: string
    protected abstract flags: number

    async readList(params?: LimitOffsetParams, subscriptionKey?: string, showDeleted?: boolean): Promise<T[]> {
        if (!(this.flags & RouterFlag.list)) {
            return Promise.reject('Invalid route')
        }
        const paramsString = new URLSearchParams()
        if (params) {
            Object.entries(params).forEach(([k, v]) => {
                paramsString.append(k, v)
            })
        }
        if (subscriptionKey) {
            paramsString.append('subscription-key', subscriptionKey)
        }
        if (showDeleted) {
            paramsString.append('include-deleted', 'true')
        }
        const jsons: JSON[] = await onAPI.fetchJSON(
            this.route + 's' + (paramsString.toString().length > 0 ? '?' + paramsString : '')
        )
        return this.buildArray(jsons)
    }

    async readListById(id: string): Promise<T[]> {
        if (!(this.flags & RouterFlag.list)) {
            return Promise.reject('Invalid route')
        }
        const jsons: JSON[] = await onAPI.fetchJSON(this.route + '/' + id)
        return this.buildArray(jsons)
    }

    async read(id: string): Promise<T> {
        if (!(this.flags & RouterFlag.read)) {
            return Promise.reject('Invalid route')
        }
        const json: JSON = await onAPI.fetchJSON(this.route + '/' + id)
        return this.newItem(json)
    }

    async create(item: T): Promise<T> {
        if (!(this.flags & RouterFlag.create)) {
            return Promise.reject('Invalid route')
        }
        // Create and Update are the same for now. Maybe check here to validate we don't have an id?
        return this.update(item)
    }

    async update(item: T): Promise<T> {
        if (!(this.flags & RouterFlag.update)) {
            return Promise.reject('Invalid route')
        }
        const returnedJSON: JSON = await onAPI.fetchJSON(this.route, item.toJSON!())
        return this.newItem(returnedJSON)
    }

    async delete(item: T): Promise<boolean> {
        if (!(this.flags & RouterFlag.delete)) {
            return Promise.reject('Invalid route')
        }
        return onAPI.fetchSuccess(HTTPMethod.delete, this.route + '/' + item.id)
    }

    async restore(item: T): Promise<boolean> {
        if (!(this.flags & RouterFlag.restore)) {
            return Promise.reject('Invalid route')
        }
        return onAPI.fetchSuccess(HTTPMethod.post, this.route + '/' + item.id + '/restore')
    }

    async permanentlyDelete(item: T): Promise<boolean> {
        if (!(this.flags & RouterFlag.permanentlyDelete)) {
            return Promise.reject('Invalid route')
        }
        return onAPI.fetchSuccess(HTTPMethod.delete, this.route + '/' + item.id + '/permanent')
    }

    async searchList(searchParams: SearchParams & LimitOffsetParams): Promise<T[]> {
        if (!(this.flags & RouterFlag.search)) {
            return Promise.reject('Invalid route')
        }
        const jsons: JSON[] = await onAPI.fetchJSON(this.route + 's/search', searchParams)
        return this.buildArray(jsons)
    }

    protected abstract newItem(json: JSON): T

    private async buildArray(jsons: JSON[]): Promise<T[]> {
        const items = Array<T>()
        for (const json of jsons) {
            items.push(this.newItem(json))
        }
        return items
    }
}
