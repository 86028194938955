import * as React from 'react'
import clsx from 'clsx'

export default function StatusIndicator({ active, disabled }: { active: boolean; disabled?: boolean }) {
    return (
        <span
            className={clsx(disabled ? 'bg-gray-100' : active ? 'bg-green-100' : 'bg-red-100', 'status-indicator')}
            aria-hidden='true'
        >
            <span
                className={clsx(
                    disabled ? 'bg-gray-400' : active ? 'bg-green-400' : 'bg-red-400',
                    'status-indicator-inner'
                )}
            />
        </span>
    )
}
