import * as React from 'react'

function PrivacyPolicy() {
    // Load scripts on page load
    React.useEffect(() => {
        const s = document.createElement('script')
        s.src = process.env.PUBLIC_URL + '/scripts/termly.js'
        s.async = true
        document.body.appendChild(s)
    }, [])

    return (
        <div className='privacy selectable-text'>
            {/* @ts-expect-error */}
            <div name='termly-embed' data-id='098bf46b-116e-49fc-9edd-b0583ea570e4' data-type='iframe' />
        </div>
    )
}

export default PrivacyPolicy
