import * as React from 'react'

// i18n
import i18n from 'src/i18n'
import { withNamespaces, WithNamespaces } from 'react-i18next'

// MobX
import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { eventPreventDefault } from 'src/common/utils/EventUtils'

import LanguageSelector from 'src/common/components/LanguageSelector'
import { Form, FormGroup, Button } from 'reactstrap'
import Select from 'react-select'
import { DropdownIndicator, Option, ValueLabelPair } from 'src/common/components/SelectComponents'
import moment from 'moment'
import 'moment-timezone'
import { UserPreferenceType } from 'src/common/models/UserSettings'

import { Switch } from '@blueprintjs/core'

@inject('store')
@observer
class PreferencesTab extends React.Component<{ store?: RootStore } & WithNamespaces> {
    handleUpdateTimeSetting = (type: UserPreferenceType, selectedOption?: ValueLabelPair) => {
        let value: string = selectedOption?.value
        if (type === UserPreferenceType.timeZone) {
            value = value ?? moment.tz.guess()
        }
        if (!value) {
            return
        }
        this.props.store!.settingsUIStore.updateUserPreferences(type, value)
    }

    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return null
        }

        const settingsUIStore = this.props.store!.settingsUIStore
        const { isDemoMode } = settingsUIStore

        const timeZoneOptions = moment.tz.names().map(name => ({ value: name, label: name }))

        const timeFormatOptions = [
            { value: 'h:mm:ss a', label: moment().format('h:mm:ss a') + ' (' + i18n.t('settingsPage.12hours') + ')' },
            { value: 'H:mm:ss', label: moment().format('H:mm:ss') + ' (' + i18n.t('settingsPage.24hours') + ')' },
        ]

        const dateFormats = ['DD/MM/YYYY', 'MM/DD/YYYY'] // NOTE: YYYY/MM/DD unsupported due to short date formatting
        const dateFormatOptions = dateFormats.map(format => ({
            value: format,
            label: moment().format(format) + ' (' + format + ')',
        }))

        const userTimezone = settingsUIStore.pendingUserPreferences?.timeZone ?? me.timeZone
        const userTimezoneValue = {
            value: userTimezone,
            label: userTimezone,
        }

        const userTimeFormat = settingsUIStore.pendingUserPreferences?.timeFormat ?? me.timeFormat
        const userTimeFormatValue = {
            value: userTimeFormat,
            label: moment().format(userTimeFormat),
        }

        const userDateFormat = settingsUIStore.pendingUserPreferences?.dateFormat ?? me.dateFormat
        const userDateFormatValue = {
            value: userDateFormat,
            label: moment().format(userDateFormat),
        }

        return (
            <div className='preferences-tab'>
                <h2>{i18n.t('settingsPage.languageAndTime')}</h2>
                <br />
                <h4>{i18n.t('settingsPage.language')}</h4>
                <LanguageSelector updateUserPreferences />
                <br />
                <h4>{i18n.t('settingsPage.timeZone')}</h4>
                <Form className='time-zone-form' inline onSubmit={eventPreventDefault}>
                    <Select
                        className='custom-select-wrapper'
                        classNamePrefix='custom-select'
                        blurInputOnSelect
                        onChange={this.handleUpdateTimeSetting.bind(this, UserPreferenceType.timeZone)}
                        options={timeZoneOptions}
                        placeholder={i18n.t('common.pleaseSelect')}
                        value={userTimezoneValue}
                        components={{ DropdownIndicator, Option }}
                    />
                    <h6>{i18n.t('common.or')}</h6>
                    <Button
                        onClick={this.handleUpdateTimeSetting.bind(this, UserPreferenceType.timeZone)}
                        className='custom-button-secondary'
                    >
                        {i18n.t('actions.autoDetect')}
                    </Button>
                </Form>
                <br />
                <h4>{i18n.t('settingsPage.timeFormat')}</h4>
                <Form inline onSubmit={eventPreventDefault}>
                    <FormGroup className='custom-form-group'>
                        <Select
                            className='custom-select-wrapper'
                            classNamePrefix='custom-select'
                            isSearchable={false}
                            blurInputOnSelect
                            onChange={this.handleUpdateTimeSetting.bind(this, UserPreferenceType.timeFormat)}
                            options={timeFormatOptions}
                            placeholder={i18n.t('common.pleaseSelect')}
                            value={userTimeFormatValue}
                            components={{ DropdownIndicator, Option }}
                        />
                    </FormGroup>
                </Form>
                <br />
                <h4>{i18n.t('settingsPage.dateFormat')}</h4>
                <Form inline onSubmit={eventPreventDefault}>
                    <FormGroup className='custom-form-group'>
                        <Select
                            className='custom-select-wrapper'
                            classNamePrefix='custom-select'
                            isSearchable={false}
                            blurInputOnSelect
                            onChange={this.handleUpdateTimeSetting.bind(this, UserPreferenceType.dateFormat)}
                            options={dateFormatOptions}
                            placeholder={i18n.t('common.pleaseSelect')}
                            value={userDateFormatValue}
                            components={{ DropdownIndicator, Option }}
                        />
                    </FormGroup>
                </Form>
                <br />
                <Button
                    onClick={settingsUIStore.handleSaveUserPreferences}
                    color='primary'
                    disabled={this.props.store!.userStore.isUpdating || !settingsUIStore.hasUnsavedUserPreferences}
                >
                    {i18n.t('actions.save')}
                </Button>

                {me.isSuperUser && (
                    <div className='mt-4'>
                        <h2>Superuser</h2>
                        <br />
                        <h4>Demo mode</h4>
                        <Switch checked={isDemoMode} onChange={settingsUIStore.handleUpdateDemoMode} large />
                    </div>
                )}
            </div>
        )
    }
}

export default withNamespaces()(PreferencesTab)
