import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import { animateScroll as scroll, scrollSpy } from 'react-scroll'
import backToTopIcon from '../../assets/images/backToTopIcon.svg'

@inject('store')
@observer
class BackToTopButton extends React.Component<{ store?: RootStore }> {
    componentDidMount() {
        scrollSpy.update()
    }

    handleBackToTop() {
        scroll.scrollToTop()
    }

    render() {
        return (
            <div className='back-to-top-button' onClick={this.handleBackToTop}>
                <img src={backToTopIcon} alt='Back to top icon' height={45} />
            </div>
        )
    }
}

export default BackToTopButton
