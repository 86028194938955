import RootStore from 'src/common/RootStore'
import { observable, action, computed, runInAction, autorun } from 'mobx'

import i18n from 'src/i18n'
import { subscriptionTopics } from '../utils/String'

import Screen from '../models/Screen'
import MqttSubscription from '../models/MqttSubscription'
import { ConsoleCommand } from '../models/ConsoleEnums'

import PopoutManager from './PopoutManager'
import TextLine from 'src/modules/diagnostics/screen/submodules/console/models/TextLine'
import consoleRouter from 'src/api/consoleRouter'

export default class ConsoleManager extends PopoutManager {
    @observable remoteCommand = ''
    @observable prevCommand = ''
    @observable prevCommandList = new Array<string>()
    @observable commandIndex = 0

    @observable messageList: TextLine[] = []
    @observable value: string
    @observable level: string

    @observable isConnecting: boolean = false

    constructor(rootStore: RootStore, screenId: string) {
        super(rootStore, screenId)

        if (!this.objectId) {
            return
        }

        // Clear console window
        this.messageList = []

        // React to comptroller connected
        autorun(() => {
            if (!this.isClosing && this.comptrollerConnected && !this.isConnecting && !this.subscription) {
                // Subscribe
                this.init()
            }
        })

        // React to dropped connection
        autorun(() => {
            if (this.connectionDropped) {
                // Invalidate subscription
                this.removeSubscription()
            }
        })
    }

    handleBeforeUnload = (event: BeforeUnloadEvent): string | undefined => {
        this.removeManager()
        return undefined
    }

    @computed get selectedScreen(): Screen | undefined {
        if (!this.objectId) {
            return undefined
        }
        return this.rootStore.screenStore.findItem(this.objectId)
    }

    @computed get title(): string {
        return (
            this.rootStore.appUIStore.title +
            ' | ' +
            (this.selectedScreen?.name ?? i18n.t('placeholders.missingData')) +
            ' Console'
        )
    }

    @computed get comptrollerConnected(): boolean {
        return !!this.selectedScreen && this.selectedScreen.connectAvailable
    }

    @computed get connectionEstablished(): boolean {
        return this.comptrollerConnected && !!this.subscription
    }

    @computed get connectionDropped(): boolean {
        return !this.comptrollerConnected
    }

    @computed get commandPublishTopic(): string | undefined {
        if (!this.selectedScreen || !this.subscription) {
            return undefined
        }
        return this.selectedScreen.supportsConsoleSubscription
            ? 'console/' + this.subscription.subscriptionKey + '/out'
            : 'console/' + this.selectedScreen.comptroller?.id
    }

    init = async () => {
        try {
            if (this.isConnecting) {
                // Ignore, already connecting
                throw new Error('Tried to init console connection while already connecting')
            }
            if (this.subscription) {
                // Ignore, already has active subscription
                throw new Error('Tried to init while subscription is already active')
            }
            runInAction('updateConnecting', () => {
                this.isConnecting = true
            })
            if (!this.selectedScreen?.id) {
                throw new Error('No screen id')
            }
            // Topics are managed through a brokered subscription
            const { subscriptionKey, timeout: ttl } = await consoleRouter.subscribeToConsole(this.selectedScreen?.id)
            const topics = subscriptionTopics('console', subscriptionKey)

            const subscription = new MqttSubscription({
                topics,
                subscriptionKey,
                callback: data => {
                    this.showMessage(data.value)
                },
            })
                .setTTL(ttl)
                .setKeepAliveCommand(ConsoleCommand.ping)
                .setOnClose(() =>
                    this.rootStore.mqttStore.publish(topics[1], [{ command: ConsoleCommand.stopConsoleFeed }])
                )
            this.rootStore.mqttStore.addSubscription(subscription)

            runInAction('updateSubscription', () => {
                this.subscription = subscription
            })

            this.handleFocusCommandInput()
        } catch (error) {
            console.error(error)
        } finally {
            runInAction('updateConnecting', () => {
                this.isConnecting = false
            })
        }
    }

    handleFocusCommandInput = () => {
        if (!this.popout) {
            return
        }
        const commandInput = this.popout.child?.document.getElementById(this.objectId + '-console-input')
        commandInput?.focus()
    }

    handleFocusPopout = () => {
        // Focus command input when refocusing popout
        this.handleFocusCommandInput()
    }

    @action removeSubscription = () => {
        // Close any MQTT subscriptions
        if (this.subscription) {
            this.rootStore.mqttStore.removeSubscription(this.subscription)
        }

        this.subscription = undefined
    }

    @action removeManager = () => {
        this.removeSubscription()
        this.rootStore.consoleStore.managerMap.delete(this.objectId)
    }

    // Unimplemented for now
    clearURL = () => {}

    // Operations when showing message
    @action showMessage(jsonArray: JSON) {
        // If payload is not JSON, Amplify PubSub will kill it before this point
        if (Array.isArray(jsonArray)) {
            // Iterate over array
            for (const json of jsonArray) {
                const textLine = new TextLine(json)
                if (textLine) {
                    this.messageList = [...this.messageList, textLine]
                }
            }
        } else {
            // If not an array, ignore and throw error
            console.error('Invalid message: ' + JSON.stringify(jsonArray))
        }
    }

    @action handleCommandHistory(arrowKey: any) {
        let arrowResult: number
        switch (arrowKey) {
            case 'ArrowDown':
                // Count down 1 when "down arrow" key is pressed
                arrowResult = this.commandIndex - 1
                break
            case 'ArrowUp':
                // Count up 1 when "up arrow" key is pressed
                arrowResult = this.commandIndex + 1
                break
            default:
                return
        }

        const index = arrowResult - 1
        if (index > this.prevCommandList.length || index < -1) {
            // Prevent index from going out of bounds
            return
        }
        if (this.prevCommandList.slice()[index] !== undefined) {
            // Update the value in the command input
            this.prevCommand = this.prevCommandList[index]
            this.commandIndex = arrowResult
        } else if (index === -1) {
            // Reset the input field
            this.prevCommand = ''
            this.commandIndex = 0
        }
    }

    // Handle sending message to MQTT server
    @action handleCommandPublish = () => {
        if (!this.commandPublishTopic) {
            console.error('No command publish topic')
            return
        }
        const command = this.prevCommand || this.remoteCommand

        // Ignore empty command
        if (command === '') {
            return
        }

        // Clear console if '!' is submitted
        if (command === '!') {
            this.messageList = []
        } else {
            // Publish the command to MQTT server
            this.rootStore.mqttStore.publish(this.commandPublishTopic, [{ remoteCommand: command }])

            if (this.selectedScreen?.supportsConsoleSubscription) {
                // Show message in console window
                this.showMessage(([{ remoteCommand: command }] as unknown) as JSON)
            }
        }

        // Add command into prevCommand array and clear input box
        this.prevCommandList = [command, ...this.prevCommandList]
        this.remoteCommand = ''

        // Reset command index
        this.prevCommand = ''
        this.commandIndex = 0
    }

    @action updateRemoteCommand = (value: any) => {
        // Reset the prevCommand value when inputting a new command
        this.prevCommand = ''
        this.remoteCommand = value
    }
}
