import DiagnosticsErrorContainer, { DiagnosticsErrorContainerJSON } from 'src/common/models/DiagnosticsErrorContainer'
import { ErrorSeverity } from 'src/common/models/DiagnosticErrorEnums'
import onAPI, { HTTPMethod } from './onAPI'
import moment from 'moment'
import DiagnosticsDigest from 'src/common/models/DiagnosticsDigest'

interface DigestCsvResponse {
    blob: Blob
    filename: string
}

class DiagnosticsRouter {
    protected route = '/diagnostics-error'

    async fetchErrors(
        organisationIds: string[],
        minSeverity: ErrorSeverity,
        subscriptionKey?: string
    ): Promise<DiagnosticsErrorContainer> {
        const json: any = await onAPI.fetchJSON(this.route + 's', {
            organisationIds,
            minSeverity,
            subscribe: true,
            subscriptionKey,
        })
        return this.newItem(json)
    }

    async fetchDigest(orgId: string, dateRange: [moment.Moment, moment.Moment]): Promise<DiagnosticsDigest> {
        const json: any = await onAPI.fetchJSONWithQueryString('/digest', {
            startTime: dateRange[0].toISOString(),
            endTime: dateRange[1].toISOString(),
            organisationId: orgId,
        })
        return new DiagnosticsDigest(json)
    }

    async fetchDigestCsv(orgId: string, dateRange: [moment.Moment, moment.Moment]): Promise<DigestCsvResponse> {
        const queryParams = new URLSearchParams({
            startTime: dateRange[0].toISOString(),
            endTime: dateRange[1].toISOString(),
            organisationId: orgId,
        })

        const response = await onAPI.fetchFileResponse(
            `/digest-csv?${queryParams}`,
            HTTPMethod.get,
            null,
            'application/zip'
        )

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }

        const contentDisposition = response.headers.get('Content-Disposition')
        let filename = 'digest.zip' // default filename

        console.log('headers', response.headers)
        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename=(.*)/i)
            if (filenameMatch && filenameMatch[1]) {
                filename = filenameMatch[1].trim()
            }
        }
        // Get the blob from the response
        const blob = await response.blob()

        return { blob, filename }
    }

    async assignDiagnosticsError(screenId: string, errorId: string, setAssigned: boolean): Promise<any> {
        const json: any = await onAPI.fetchJSON(this.route + '/' + errorId + '/assigned', {
            screenId,
            assigned: setAssigned,
        })
        return json
    }

    async assignDiagnosticsErrorGroup(screenId: string, diagnosticsErrorIds: string[]): Promise<any> {
        const json: any = await onAPI.fetchJSON(this.route + 's/assign-all', { screenId, diagnosticsErrorIds })
        return json
    }

    protected newItem(json: DiagnosticsErrorContainerJSON): DiagnosticsErrorContainer {
        return new DiagnosticsErrorContainer(json)
    }
}

const diagnosticsRouter = new DiagnosticsRouter()
export default diagnosticsRouter
