import BaseModel from '../models/BaseModel'
import { observable, runInAction, action, computed } from 'mobx'
import RootStore from '../RootStore'

import i18n from 'src/i18n'

import BaseRouter, { LimitOffsetParams } from 'src/api/BaseRouter'

import { AppToaster } from '../components/AppToaster'
import { Intent } from '@blueprintjs/core'
import eq from 'react-fast-compare'
import moment from 'moment'

export default class DomainStore<T extends BaseModel> {
    items = observable.array<T>()
    rootStore: RootStore
    router: BaseRouter<T, Record<string, any>, Record<string, any>>
    storeName: string

    @observable isFetching = false
    @observable fetchComplete = false
    lastSearch?: LimitOffsetParams

    protected readonly limit = 20
    protected offset = 0

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore
    }

    @action deleteItems(itemsToDelete: T[], permanent: boolean = false) {
        const me = this.rootStore.userStore.me
        if (!me) {
            return
        }
        if (!permanent && me.showDeletedItems) {
            const idsToDelete = itemsToDelete.map(i => i.id)
            const updatedItems = this.items.map((item, i) => {
                if (idsToDelete.includes(item.id)) {
                    item.deletedAt = moment()
                }
                return item
            })
            this.items.replace(updatedItems)
        } else {
            this.items.replace(this.items.filter(item => !itemsToDelete.includes(item)))
        }
    }

    @computed get itemMap(): Map<string, T> {
        const map = new Map<string, T>()
        for (const item of this.items) {
            map.set(item.id!, item)
        }
        return map
    }

    @computed get itemIds(): string[] {
        return this.items.map((item: T) => item.id!)
    }

    findItem(id: string): T | undefined {
        return this.itemMap.get(id)
    }

    @action addItemsToList = (newItems: T[], append: boolean) => {
        if (this.items.length === 0) {
            this.items.replace(newItems)
            this.fetchComplete = !append || newItems.length < this.limit
        } else if (append) {
            newItems.forEach(newItem => {
                const existingItem = this.items.find(item => item.id === newItem.id)
                if (existingItem) {
                    // Update only changed fields
                    Object.keys(newItem).forEach(key => {
                        if (newItem[key] !== existingItem[key]) {
                            existingItem[key] = newItem[key]
                        }
                    })
                } else {
                    // Add new item if it doesn't exist
                    this.items.push(newItem)
                }
            })
            if (newItems.length < this.limit) {
                this.fetchComplete = true
            }
            this.offset += newItems.length
        } else {
            // Map for quick lookup
            const newItemsMap = new Map(newItems.map(item => [item.id, item]))
            this.items.forEach(item => {
                const newItem = newItemsMap.get(item.id)
                if (newItem) {
                    Object.keys(newItem).forEach(key => {
                        if (newItem[key] !== item[key]) {
                            item[key] = newItem[key]
                        }
                    })
                    newItemsMap.delete(item.id)
                }
            })
            // Add remaining new items
            newItemsMap.forEach(newItem => this.items.push(newItem))
        }
        this.isFetching = false
    }

    refresh(subscriptionKey?: string, showDeleted?: boolean) {
        console.log('refreshing', this.storeName)
        return this.populateItemList(false, true, subscriptionKey, showDeleted)
    }

    populateItemList = (
        useLimitOffset: boolean = false,
        refresh: boolean = false,
        subscriptionKey?: string,
        showDeleted?: boolean
    ): Promise<void> => {
        if (!this.shouldContinue(refresh)) {
            return Promise.resolve()
        }
        if (refresh) {
            this.offset = 0
        }
        const limitOffset = useLimitOffset ? { limit: this.limit, offset: this.offset } : undefined
        return this.router
            .readList(limitOffset, subscriptionKey, showDeleted)
            .then(items => {
                this.addItemsToList(items, useLimitOffset)

                runInAction('increaseLoadingValue', () => {
                    // Used for loading status bar
                    this.rootStore.authStore.loadingValue += 0.1
                })
            })
            .catch(err => {
                AppToaster.show({
                    message: i18n.t('feedback.errors.errorFetchingItems') + i18n.t('stores.' + this.storeName),
                    intent: Intent.DANGER,
                })
                console.error(err)
            })
    }

    searchItemList = (searchParams: any, useLimitOffset: boolean = false, refresh: boolean = false): Promise<void> => {
        if (!this.shouldContinue(refresh, searchParams)) {
            return Promise.resolve()
        }
        const limitOffset = useLimitOffset ? { limit: this.limit, offset: this.offset } : undefined
        return this.router
            .searchList({ ...searchParams, ...limitOffset })
            .then(items => {
                this.addItemsToList(items, useLimitOffset)
            })
            .catch(err => {
                AppToaster.show({
                    message: i18n.t('feedback.errors.errorSearchingItems') + i18n.t('stores.' + this.storeName),
                    intent: Intent.DANGER,
                })
                console.error(err)
            })
    }

    @action createItem = (newItem: T): Promise<T> =>
        this.router
            .create(newItem)
            .then(returnedItem => {
                AppToaster.show({
                    message: i18n.t('feedback.successes.successfullyCreatedNew') + i18n.t('stores.' + this.storeName),
                    intent: Intent.SUCCESS,
                })

                runInAction('create' + this.storeName, () => {
                    this.items.push(returnedItem)
                })
                return returnedItem
            })
            .catch(err => {
                AppToaster.show({
                    message: i18n.t('feedback.errors.errorCreatingNew') + i18n.t('stores.' + this.storeName),
                    intent: Intent.DANGER,
                })

                return Promise.reject(err)
            })

    @action updateItem = (item: T): Promise<T> =>
        this.router
            .update(item)
            .then(response => {
                AppToaster.show({
                    message: i18n.t('feedback.successes.successfullyUpdated') + i18n.t('stores.' + this.storeName),
                    intent: Intent.SUCCESS,
                })

                runInAction('update' + this.storeName, () => {
                    const index = this.items.findIndex(c => c.id === item.id)
                    if (index !== -1) {
                        this.items[index] = response
                    }
                })
                return response
            })
            .catch(err => {
                AppToaster.show({
                    message: i18n.t('feedback.errors.errorUpdating') + i18n.t('stores.' + this.storeName),
                    intent: Intent.DANGER,
                })

                console.error(err)
                return Promise.reject()
            })

    @action deleteItem = (item: T, silenceToast: boolean): Promise<void> => {
        // I'm not sure where this item comes from, but it doesn't appear to be a "full" object with methods, so I have to get the real item first
        const trueItem = this.findItem(item.id!)
        if (!trueItem) {
            return Promise.resolve()
        }
        return this.router
            .delete(trueItem)
            .then(() => {
                if (!silenceToast) {
                    AppToaster.show({
                        message: i18n.t('feedback.successes.successfullyDeleted') + i18n.t('stores.' + this.storeName),
                        intent: Intent.SUCCESS,
                    })
                }

                this.deleteItems([trueItem])
            })
            .catch(err => {
                AppToaster.show({
                    message: i18n.t('feedback.errors.errorDeleting') + i18n.t('stores.' + this.storeName),
                    intent: Intent.DANGER,
                })

                console.error(err)
            })
    }

    @action restoreItem = async (item: T, silenceToast: boolean): Promise<void> => {
        const trueItem = this.findItem(item.id!)
        if (!trueItem) {
            return Promise.resolve()
        }
        try {
            await this.router.restore(trueItem)
            if (!silenceToast) {
                AppToaster.show({
                    message: i18n.t('feedback.successes.successfullyRestored') + i18n.t('stores.' + this.storeName),
                    intent: Intent.SUCCESS,
                })
            }
        } catch (err) {
            AppToaster.show({
                message: i18n.t('feedback.errors.errorRestoring') + i18n.t('stores.' + this.storeName),
                intent: Intent.DANGER,
            })

            console.error(err)
        }
    }

    @action permanentlyDeleteItem = (item: T, silenceToast: boolean): Promise<void> => {
        const trueItem = this.findItem(item.id!)
        if (!trueItem) {
            return Promise.resolve()
        }
        return this.router
            .permanentlyDelete(trueItem)
            .then(() => {
                if (!silenceToast) {
                    AppToaster.show({
                        message:
                            i18n.t('feedback.successes.successfullyPermanentlyDeleted') +
                            i18n.t('stores.' + this.storeName),
                        intent: Intent.SUCCESS,
                    })
                }

                this.deleteItems([trueItem], true)
            })
            .catch(err => {
                AppToaster.show({
                    message: i18n.t('feedback.errors.errorPermanentlyDeleting') + i18n.t('stores.' + this.storeName),
                    intent: Intent.DANGER,
                })

                console.error(err)
            })
    }

    @action shouldContinue(refresh: boolean, searchParams?: any): boolean {
        // FIXME: Use toJSON to convert Mobx observable to vanilla JS
        const jsonParams = searchParams ? JSON.parse(JSON.stringify(searchParams)) : undefined
        if (!refresh && eq(this.lastSearch, jsonParams)) {
            if (this.isFetching || this.fetchComplete) {
                return false
            }
        } else {
            if (refresh) {
                this.lastSearch = undefined
            }
            this.offset = 0
            this.items.replace([])
        }
        this.lastSearch = jsonParams
        this.isFetching = true
        this.fetchComplete = false
        return true
    }

    fetchById = (itemId: string): Promise<T> => this.router.read(itemId)
}
