export enum PlayerSyncState {
    unknown = 'unknown',
    inSync = 'inSync',
    syncing = 'syncing',
    outOfSync = 'outOfSync',
    notPlaying = 'notPlaying',
}

export enum PlayerName {
    outwatch = 'outwatch',
    candelic = 'candelic',
    internal = 'lednet',
    lednet = 'lednet',
    broadsign = 'broadsign',
    scala = 'scala',
    doohly = 'doohly',
    vistar = 'vistar',
    unknown = 'unknown',
}
