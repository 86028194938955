import { migrations } from '../migrations/BrowserStorageMigrations'

export enum StorageKey {
    version = 'version',
    i18nLang = 'i18nLang',
    timeZone = 'timeZone',
    timeFormat = 'timeFormat',
    dateFormat = 'dateFormat',
    missionControlFilters = 'missionControlFilters',
    missionControlSounds = 'missionControlSounds',
    liveFilters = 'liveFilters',
    currentView = 'currentView',
    fcRestoreId = 'fcRestoreId',
    userId = 'userId',
    diagnosticsCompactView = 'diagnosticsCompactView',
    demoMode = 'demoMode',
}

const CURRENT_VERSION = 2
const KEY_PREFIX = 'ON.'
const PROTECTED_KEY_PREFIXES = ['aws.', 'CognitoIdentityServiceProvider.', 'amplify-']

class BrowserStorageManager {
    updateLocalStorageItem = (key: StorageKey, value: string | Record<string, any>) => {
        this.updateStorageItem(localStorage, key, value)
    }

    readLocalStorageString = (key: StorageKey): string | undefined => this.readStorageString(localStorage, key)

    readLocalStorageObject = (key: StorageKey): Record<string, any> | undefined =>
        this.readStorageObject(localStorage, key)

    removeLocalStorageItem = (key: StorageKey) => {
        this.removeStorageItem(localStorage, key)
    }

    updateSessionStorageItem = (key: StorageKey, value: string | Record<string, any>) => {
        this.updateStorageItem(sessionStorage, key, value)
    }

    readSessionStorageString = (key: StorageKey): string | undefined => this.readStorageString(sessionStorage, key)

    readSessionStorageObject = (key: StorageKey): Record<string, any> | undefined =>
        this.readStorageObject(sessionStorage, key)

    removeSessionStorageItem = (key: StorageKey) => {
        this.removeStorageItem(sessionStorage, key)
    }

    clearAllStorageItems = () => {
        localStorage.clear()
        sessionStorage.clear()
    }

    clearNonProtectedKeys = () => {
        // Clear all non protected keys
        let localStorageKeys = Object.keys(localStorage)
        let sessionStorageKeys = Object.keys(sessionStorage)
        // Prevent clearing protected values so that user isn't logged out
        for (const prefix of PROTECTED_KEY_PREFIXES) {
            localStorageKeys = localStorageKeys.filter(key => !key.startsWith(prefix))
            sessionStorageKeys = sessionStorageKeys.filter(key => !key.startsWith(prefix))
        }
        for (const key of localStorageKeys) {
            localStorage.removeItem(key)
        }
        for (const key of sessionStorageKeys) {
            sessionStorage.removeItem(key)
        }
    }

    runMigrations = () => {
        const getVersion = this.readLocalStorageString(StorageKey.version)
        let userVersion = getVersion ? Number(getVersion) : 0

        // Check if user version is within range
        if (!(userVersion >= 0 && userVersion <= CURRENT_VERSION)) {
            userVersion = 0
        }
        for (let i = userVersion; i < CURRENT_VERSION; i++) {
            migrations[i]()
        }

        // Store new version once migrations are finished
        this.updateLocalStorageItem(StorageKey.version, String(CURRENT_VERSION))
    }

    private removeStorageItem = (storage: Storage, key: StorageKey) => {
        storage.removeItem(KEY_PREFIX + key)
    }

    private updateStorageItem = (storage: Storage, key: StorageKey, value: string | Record<string, any>) => {
        const storageItem = typeof value !== 'string' ? JSON.stringify(value) : value
        if (storageItem) {
            storage.setItem(KEY_PREFIX + key, storageItem)
        }
    }

    private readStorageString = (storage: Storage, key: StorageKey): string | undefined => {
        const getItem = storage.getItem(KEY_PREFIX + key)
        if (!getItem) {
            return undefined
        }
        return typeof getItem === 'string' ? getItem : undefined
    }

    private readStorageObject = (storage: Storage, key: StorageKey): Record<string, any> | undefined => {
        let parsedItem: Record<string, any> | undefined
        const getItem = this.readStorageString(storage, key)
        if (!getItem) {
            return undefined
        }
        try {
            parsedItem = JSON.parse(getItem)
        } catch (e) {
            console.error('Bad storage value')
        }
        return parsedItem
    }
}

export const browserStorageManager = new BrowserStorageManager()
