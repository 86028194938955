import { observable } from 'mobx'

import moment from 'moment'

class LiveImageStats {
    static newLiveImageStats(): LiveImageStats {
        return new LiveImageStats({
            averageTime: undefined,
            firstPlayed: undefined,
            lastPlayed: undefined,
            totalPlays: undefined,
            totalTime: undefined,
        })
    }

    @observable averageTime?: number
    @observable firstPlayed?: moment.Moment
    @observable lastPlayed?: moment.Moment
    @observable totalPlays?: number
    @observable totalTime?: number

    constructor(json: LiveImageStatsJSON) {
        this.averageTime = json.averageTime
        this.firstPlayed = json.firstPlayed
        this.lastPlayed = json.lastPlayed
        this.totalPlays = json.totalPlays
        this.totalTime = json.totalTime
    }
}

export type LiveImageStatsJSON = LiveImageStats

export interface LiveImageStatsParams {
    screenId: string
    displayImageId: string
}

export default LiveImageStats
