import * as React from 'react'

// i18n
import i18n from 'src/i18n'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'

import { Button, Form, FormGroup, Label } from 'reactstrap'
import Checkbox from 'src/common/components/Checkbox'
import collapseAll from 'src/assets/images/collapseAll.svg'
import compactView from 'src/assets/images/compactView.svg'

@inject('store')
@observer
export default class ErrorControls extends React.Component<{ store?: RootStore }> {
    handleCollapseAll = () => {
        this.props.store!.screenDiagnosticsUIStore.screenCurrentErrorsManager.collapseAllErrorGroups()
    }

    handleToggleCompactView = () => {
        this.props.store!.screenDiagnosticsUIStore.screenCurrentErrorsManager.toggleCompactView()
    }

    render() {
        const screenCurrentErrorsManager = this.props.store!.screenDiagnosticsUIStore.screenCurrentErrorsManager
        const isMobile = this.props.store!.appUIStore.isMobile

        return (
            <Form className='errors-table-compact-view' inline>
                {screenCurrentErrorsManager.isCompactView &&
                    screenCurrentErrorsManager.openErrorGroups &&
                    screenCurrentErrorsManager.openErrorGroups.size > 0 && (
                        <FormGroup className='custom-form-group custom-form-group-inline'>
                            <Button
                                className='errors-table-collapse-all custom-button-link custom-button-link-sm custom-button-link-black'
                                onClick={this.handleCollapseAll}
                                color='link'
                            >
                                {!isMobile ? (
                                    <span>
                                        <h5>{i18n.t('diagnosticsPage.collapseAll')}</h5>
                                    </span>
                                ) : (
                                    <img src={collapseAll} alt='Collapse All' height={18} />
                                )}
                            </Button>
                        </FormGroup>
                    )}
                <FormGroup className='custom-form-group custom-form-group-inline checkbox-form-group'>
                    <Label className='custom-label custom-label-inline'>
                        {!isMobile ? (
                            <span>
                                <h5>{i18n.t('diagnosticsPage.compactView')}</h5>
                            </span>
                        ) : (
                            <img src={compactView} alt='Compact View' height={18} />
                        )}
                    </Label>
                    <Checkbox
                        isChecked={screenCurrentErrorsManager.isCompactView}
                        handleOnClick={this.handleToggleCompactView}
                    />
                </FormGroup>
            </Form>
        )
    }
}
