export interface AuthError {
    code: string
    description: string
    message: string
}

export class AWSErrorHandler {
    errorDictionary: Map<string, AuthError> = new Map<string, AuthError>()
    undefinedError = 'Unknown error' // Returned if code not found in dictionary
    constructor() {
        this.errorDictionary.set('InternalErrorException', {
            code: 'InternalErrorException',
            description: 'This exception is thrown when Amazon Cognito encounters an internal error',
            message: 'Something wrong happend',
        })

        this.errorDictionary.set('InvalidParameterException', {
            code: 'InvalidParameterException',
            description: 'This exception is thrown when the Amazon Cognito service encounters an invalid parameter',
            message: 'Invalid paramether',
        })
        this.errorDictionary.set('InvalidPasswordException', {
            code: 'InvalidPasswordException',
            description: 'This exception is thrown when the Amazon Cognito service encounters an invalid password',
            message: 'Password is bad',
        })

        this.errorDictionary.set('UserNotFoundException', {
            code: 'UserNotFoundException',
            description: 'This exception is thrown when a user is not found.',
            message: 'User not found...',
        })

        this.errorDictionary.set('NotAuthorizedException', {
            code: 'NotAuthorizedException',
            description: 'This exception is thrown when password is incorrect',
            message: 'Incorrect username or password',
        })
    }

    getErrorMessageFromCode(code: string): string {
        return this.errorDictionary.has(code) ? this.errorDictionary.get(code)!.message : this.undefinedError + ':' + code;
    }
}
