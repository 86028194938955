import * as React from 'react'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'

import NavSidebar, { NavIcon } from 'src/common/components/navigation/NavSidebar'
import { Icon } from '@blueprintjs/core'
import views, { DiagnosticsGlobalTab } from 'src/config/views'
import ScreenDiagnostics from './screen/container'
import GlobalDiagnostics from './global/container'

@inject('store')
@observer
class Diagnostics extends React.Component<{ store?: RootStore }> {
    componentDidMount() {
        const screenDiagnosticsUIStore = this.props.store!.screenDiagnosticsUIStore
        screenDiagnosticsUIStore.initialiseSubscription()

        const isMobile = this.props.store!.appUIStore.isMobile
        if (isMobile) {
            // Show collapsed view by default on mobile
            screenDiagnosticsUIStore.screenCurrentErrorsManager.toggleCompactView()
        }
    }

    render() {
        const isMobile = this.props.store!.appUIStore.isMobile

        const router = this.props.store?.router
        const currentViewPath = router.currentView && router.currentView.path

        const icons: NavIcon[] = [
            {
                name: 'Screen',
                icon: <Icon icon='grid-view' iconSize={26} />,
                view: views.diagnosticsScreen,
                params: {
                    ...router.params,
                    screen: this.props.store!.screenDiagnosticsUIStore.currentScreen?.id,
                    tab: this.props.store!.screenDiagnosticsUIStore.activeScreenTab,
                },
                active: currentViewPath === views.diagnosticsScreen.path,
            },
            {
                name: 'Global',
                icon: <Icon icon='globe' iconSize={26} />,
                view: views.diagnosticsGlobal,
                params: {
                    ...router.params,
                    tab: DiagnosticsGlobalTab.digest,
                },
                active: currentViewPath === views.diagnosticsGlobal.path,
            },
        ]

        return (
            <div className='grey-container'>
                <div className='diagnostics-container custom-container-fluid has-sidebar'>
                    {!isMobile && <NavSidebar icons={icons} />}
                    <div className='diagnostics-content'>
                        {currentViewPath === views.diagnosticsScreen.path && <ScreenDiagnostics />}
                        {currentViewPath === views.diagnosticsGlobal.path && <GlobalDiagnostics />}
                    </div>
                </div>
            </div>
        )
    }
}

export default Diagnostics
