import * as React from 'react'

// i18n
import i18n from 'src/i18n'

import { inject, observer } from 'mobx-react'
import RootStore from 'src/common/RootStore'
import { eventPreventDefault } from 'src/common/utils/EventUtils'

import { Form, FormGroup, Label, Button, Badge } from 'reactstrap'
import DatePicker from 'src/common/components/DatePicker'
import padlockIcon from '../../../assets/images/padlockIcon.svg'
import searchIcon from '../../../assets/images/searchIcon.svg'
import Select from 'react-select'
import {
    ValueContainer,
    ClearIndicator,
    DropdownIndicator,
    Option,
    NoOptionsMessage,
    GroupHeading,
} from '../../../common/components/SelectComponents'
import HelpLabel from 'src/common/components/HelpLabel'
import moment from 'moment'
import 'moment-timezone'

@inject('store')
@observer
class ReportScope extends React.Component<{ store?: RootStore }> {
    handleScreenIdsChange = (value: any) => {
        this.props.store!.reportsUIStore.updateFiltersSelectParams('screenIds', value)
    }

    handleReportScopeSubmit = () => {
        this.props.store!.reportsUIStore.requestDisplayImages(true)
    }

    handleEditScope = () => {
        this.props.store!.reportsUIStore.clearFilters()
    }

    handleRangeChange = (newDateRange: [moment.Moment | undefined, moment.Moment | undefined]) => {
        this.props.store!.reportsUIStore.updateScopeDateRange(newDateRange)
    }

    render() {
        const me = this.props.store!.userStore.me
        if (!me) {
            return null
        }
        const reportsUIStore = this.props.store!.reportsUIStore
        const scopeStartDate = reportsUIStore.newSearchParams.startDate
        const scopeEndDate = reportsUIStore.newSearchParams.stopDate
        const hasMultipleOpsOrganisations = this.props.store!.orgStore.hasMultipleOpsOrganisations
        const selectedOrg = reportsUIStore.selectedOrg
        // NOTE: Grouping locations by selected org to enable "select/unselect all" button
        // Omitting group label as its redundant with single org
        const screenOptionsGrouped = selectedOrg && [
            {
                options: selectedOrg.screens
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(screen => ({ value: screen.id!, label: screen.name })),
            },
        ]

        return (
            <div className='reports-sticky-banner'>
                <div
                    className={
                        !reportsUIStore.contentPageScrolled
                            ? 'content-filters'
                            : 'content-filters content-filters-scrolled'
                    }
                >
                    <Form inline onSubmit={eventPreventDefault}>
                        <FormGroup
                            className={'reports-filter-group' + (reportsUIStore.reportScopeSet ? ' disabled' : '')}
                        >
                            <HelpLabel
                                label={
                                    i18n.t('reportsPage.reportRange') + ' (' + moment.tz(me.timeZone).format('z') + ')'
                                }
                            />
                            <DatePicker
                                handleChange={this.handleRangeChange}
                                startDate={scopeStartDate}
                                endDate={scopeEndDate}
                                maxDate={moment()}
                                disabled={reportsUIStore.reportScopeSet}
                            />
                        </FormGroup>
                        <FormGroup className='reports-filter-group'>
                            <Label className='custom-label'>
                                <h6>{i18n.t('reportsPage.screens')}</h6>
                            </Label>
                            <Select
                                className='custom-select-wrapper'
                                classNamePrefix='custom-select'
                                isMulti
                                isDisabled={reportsUIStore.reportScopeSet}
                                hideSelectedOptions={false}
                                closeMenuOnSelect={false}
                                blurInputOnSelect={false}
                                onChange={this.handleScreenIdsChange}
                                options={screenOptionsGrouped}
                                placeholder={
                                    hasMultipleOpsOrganisations ? i18n.t('common.pleaseSelect') : i18n.t('common.all')
                                }
                                value={reportsUIStore.selectedScreenNames}
                                components={{
                                    ValueContainer,
                                    ClearIndicator,
                                    DropdownIndicator,
                                    Option,
                                    NoOptionsMessage,
                                    GroupHeading,
                                }}
                                menuPlacement='auto'
                            />
                        </FormGroup>
                        <div className='content-filters-buttons'>
                            {!reportsUIStore.reportScopeSet && (
                                <Button
                                    onClick={this.handleReportScopeSubmit}
                                    className='custom-button-large'
                                    color='primary'
                                    disabled={
                                        !reportsUIStore.isRangeValid ||
                                        (hasMultipleOpsOrganisations
                                            ? reportsUIStore.selectedScreenNames.length === 0
                                            : false)
                                    }
                                >
                                    {i18n.t('actions.apply')}
                                </Button>
                            )}
                            {reportsUIStore.reportScopeSet && (
                                <React.Fragment>
                                    <Button
                                        className='custom-button-large content-filters-locked'
                                        color='primary'
                                        disabled
                                    >
                                        <img src={padlockIcon} alt='Locked' />
                                    </Button>
                                    <Button
                                        className='custom-button-link content-filters-edit'
                                        color='link'
                                        onClick={this.handleEditScope}
                                    >
                                        <span>{i18n.t('actions.edit')}</span>
                                    </Button>
                                </React.Fragment>
                            )}
                        </div>
                    </Form>
                </div>
                <div
                    className={
                        'refine-results-badge-container d-none d-md-flex' +
                        (reportsUIStore.contentPageScrolled ? ' show' : ' hide')
                    }
                >
                    <Badge className='refine-results-badge'>
                        {i18n.t('reportsPage.resolutions')}:{' '}
                        {reportsUIStore.selectedResolutions.length > 0
                            ? reportsUIStore.selectedResolutions.length
                            : i18n.t('reportsPage.allResolutions')}
                    </Badge>
                    <Badge className='refine-results-badge'>
                        {i18n.t('reportsPage.names')}:{' '}
                        {reportsUIStore.selectedUnnamedOptionLabel
                            ? reportsUIStore.selectedUnnamedOptionLabel
                            : i18n.t('reportsPage.allNames')}
                    </Badge>
                    <Badge className='refine-results-badge'>
                        <img src={searchIcon} alt='Search' height={14} />
                        {reportsUIStore.newSearchParams.searchTerm
                            ? reportsUIStore.newSearchParams.searchTerm
                            : i18n.t('reportsPage.noSearchTerm')}
                    </Badge>
                </div>
            </div>
        )
    }
}

export default ReportScope
