import { observable, computed } from 'mobx'
import { Omit } from 'react-router'

import ErrorData from './ErrorData'
import DiagnosticsError, { ErrorItemSource } from './DiagnosticsError'
import { LogTableData } from '../components/ErrorLogTable'
import { ErrorSeverity, ErrorCode, ErrorSource, UpgradeMap, DowngradeMap } from './DiagnosticErrorEnums'
import moment from 'moment'

export enum DiagnosticsErrorEvent {
    unresolved = 'unresolved',
    new = 'new',
    recur = 'recur',
    resolve = 'resolve',
    assign = 'assign',
    unassign = 'unassign',
    upgrade = 'upgrade',
    downgrade = 'downgrade',
}

class DiagnosticsErrorLog implements LogTableData {
    @observable diagnosticsErrorId: string
    @observable event: DiagnosticsErrorEvent
    @observable data?: ErrorData
    @observable time: moment.Moment
    @observable error: DiagnosticsError

    private pSeverity: ErrorSeverity

    constructor(json: DiagnosticsErrorLogJSON, error: DiagnosticsError) {
        this.event = json.event
        this.data = json.data
        this.time = moment(json.time)
        this.error = error
        this.pSeverity = json.severity
    }

    @computed get severity(): ErrorSeverity {
        return this.pSeverity || this.error.severity
    }

    @computed get code(): ErrorCode {
        let errorCode = this.error.code
        if (this.severity > this.error.severity) {
            errorCode = UpgradeMap.get(errorCode) || errorCode
        } else if (this.severity < this.error.severity) {
            errorCode = DowngradeMap.get(errorCode) || errorCode
        }
        return errorCode
    }

    @computed get source(): ErrorSource {
        return this.error.source
    }

    @computed get errorSource(): ErrorItemSource | undefined {
        return this.error.errorSource
    }

    @computed get errorLabel(): string | undefined {
        return this.error.errorLabel
    }

    @computed get dataColumnTitles(): string[] | undefined {
        if (!this.data) {
            return undefined
        }

        const titles = new Array<string>()
        for (const key of Object.keys(this.data)) {
            titles.push(key)
        }
        return titles
    }
}

export type DiagnosticsErrorLogJSON = Omit<
    DiagnosticsErrorLog,
    'code' | 'source' | 'errorSource' | 'errorLabel' | 'dataColumnTitles'
>

export default DiagnosticsErrorLog
