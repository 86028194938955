import * as React from 'react'

import RootStore from 'src/common/RootStore'
import { inject, observer } from 'mobx-react'
import i18n from 'src/i18n'

import LiveImage from 'src/common/models/LiveImage'

import LoadingSpinner from 'src/common/components/LoadingSpinner'
import LiveImageCard from './LiveImageCard'

@inject('store')
@observer
class LiveGrid extends React.Component<{ store?: RootStore }> {
    onLoad = (feedItem: LiveImage) => {}

    render() {
        const liveUIStore = this.props.store!.liveUIStore
        const liveStore = this.props.store!.liveStore

        if (!liveUIStore.selectionMatchesDisplayedImages) {
            return null
        }

        const isFetching = liveStore.isFetching
        if (isFetching) {
            return <LoadingSpinner />
        }

        const loadingImages = liveStore.loadingImagesForGrid

        if (!isFetching && loadingImages.length === 0) {
            return (
                <div className='live-grid'>
                    <h2>{i18n.t('common.noResults')}</h2>
                </div>
            )
        }

        return (
            <div className='live-grid'>
                {/* Load hidden images into DOM to detect if finished downloading */}
                {loadingImages &&
                    loadingImages.map((liveImage, i) => (
                        <img
                            key={i}
                            className='d-none'
                            onLoad={this.onLoad.bind(this, liveImage)}
                            src={liveImage.displayURL(liveUIStore.snapshotsMode)}
                            alt={''}
                        />
                    ))}
                {/* Render image cards for each screen */}
                {loadingImages?.map((loadedImage, i) => (
                    <LiveImageCard key={i} loadingImage={loadingImages[i]} loadedImage={loadedImage} />
                ))}
            </div>
        )
    }
}

export default LiveGrid
