import { action, observable } from 'mobx'
import RootStore from 'src/common/RootStore'
import displayImageRouter, { DisplayImageRouter } from 'src/api/displayImageRouter'

import DisplayImage, {
    DisplayImageSearchParams,
    DisplayImagePerceptualHashParams,
    ResolutionSearchParams,
    DisplayImageSummaryParams,
    DisplayImageHashParams,
} from '../models/DisplayImage'
import DomainStore from './DomainStore'
import resolutionRouter from 'src/api/resolutionRouter'
import reportRouter from 'src/api/reportRouter'
import DisplayImageSummary from '../models/DisplayImageSummary'
import { DisplayReportParameters } from '../models/DisplayReport'
import DisplayReport from '../models/DisplayReport'

export default class DisplayImageStore extends DomainStore<DisplayImage> {
    @observable isDisplayImagesFetching: boolean = false

    constructor(rootStore: RootStore) {
        super(rootStore)
        this.router = displayImageRouter
        this.storeName = 'DisplayImage'
    }

    @action populateDisplayImageList = (params: DisplayImageSearchParams | undefined = undefined) => {
        this.isDisplayImagesFetching = true
        if (params) {
            this.searchItemList(params).finally(() => {
                this.displayImagesFinishedFetching()
            })
        } else {
            this.populateItemList().finally(() => {
                this.displayImagesFinishedFetching()
            })
        }
    }

    @action displayImagesFinishedFetching = () => {
        this.isDisplayImagesFetching = false
    }

    fetchResolutions = (params: ResolutionSearchParams | undefined = undefined): Promise<string[]> =>
        params ? resolutionRouter.searchList(params) : resolutionRouter.readList()

    fetchSummary = (params: DisplayImageSummaryParams): Promise<DisplayImageSummary> =>
        (this.router as DisplayImageRouter).summary(params)

    fetchHashes = (params: DisplayImageHashParams): Promise<DisplayImage[]> =>
        (this.router as DisplayImageRouter).hash(params)

    fetchPerceptualHashes = (params: DisplayImagePerceptualHashParams): Promise<DisplayImage[]> =>
        (this.router as DisplayImageRouter).perceptualHash(params)

    downloadReport = (params: DisplayReportParameters): Promise<DisplayReport> => reportRouter.create(params)
}
