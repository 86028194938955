import * as React from 'react'
import { observer, inject } from 'mobx-react'
import RootStore from 'src/common/RootStore'
import { Link } from 'mobx-router'

import { Nav, NavItem } from 'reactstrap'
import Select from 'react-select'
import { ClearIndicator, DropdownIndicator, Option, ValueLabelPair } from '../SelectComponents'

export interface NavTab {
    name: string
    view: any
    params: Record<string, any>
    active: boolean
}

interface NavTabsParams {
    tabs: NavTab[]
    contextualElements?: JSX.Element[]
    showDropdownOnMobile?: boolean
    className?: string
}

@inject('store')
@observer
export default class NavTabs extends React.Component<NavTabsParams & { store?: RootStore }> {
    private router = this.props.store!.router

    handleScreenTabChange = (option: ValueLabelPair) => {
        this.router.goTo(option.value.view, { ...this.router.params, ...option.value.params }, this.props.store)
    }

    render() {
        const { tabs, contextualElements, showDropdownOnMobile, className } = this.props
        const isMobile = this.props.store!.appUIStore.isMobile

        const tabDropdownOptions: ValueLabelPair[] = tabs.map(tab => ({
            value: { view: tab.view, params: tab.params },
            label: tab.name,
        }))

        const defaultDropdownTab = tabDropdownOptions.find(
            tabOption => tabOption.label === tabs.find(tab => tab.active)?.name
        )

        return (
            <div className={'nav-tabs-container' + (className ? ' ' + className : '')}>
                {!showDropdownOnMobile || !isMobile ? (
                    <Nav className='nav-tabs hide-scrollbars' tabs>
                        {tabs.map(tab => (
                            <NavItem key={tab.name}>
                                <Link
                                    view={tab.view}
                                    store={this.props.store!}
                                    params={{ ...this.router.params, ...tab.params }}
                                >
                                    <h3 className={'nav-link' + (tab.active ? ' active' : '')}>{tab.name}</h3>
                                </Link>
                            </NavItem>
                        ))}
                    </Nav>
                ) : (
                    <Select
                        className='custom-select-wrapper'
                        classNamePrefix='custom-select'
                        isSearchable={false}
                        blurInputOnSelect
                        onChange={this.handleScreenTabChange}
                        options={tabDropdownOptions}
                        value={defaultDropdownTab}
                        components={{ ClearIndicator, DropdownIndicator, Option }}
                    />
                )}
                {contextualElements?.map((element, i) => (
                    <div key={i} className='nav-tabs-contextual-actions'>
                        {element}
                    </div>
                ))}
            </div>
        )
    }
}
